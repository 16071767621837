import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M9 20H5c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 013 18V4c0-.55.196-1.02.587-1.413A1.926 1.926 0 015 2h14c.55 0 1.02.196 1.413.587C20.803 2.98 21 3.45 21 4v14c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0119 20h-4l-2.3 2.3c-.1.1-.208.17-.325.212a1.106 1.106 0 01-.375.063c-.133 0-.258-.02-.375-.063a.877.877 0 01-.325-.212L9 20zm-4-3.15a10.08 10.08 0 013.137-2.088C9.33 14.255 10.617 14 12 14s2.67.254 3.863.762A10.08 10.08 0 0119 16.85V4H5v12.85zM12 12c.967 0 1.792-.342 2.475-1.025A3.372 3.372 0 0015.5 8.5c0-.967-.342-1.792-1.025-2.475A3.372 3.372 0 0012 5c-.967 0-1.792.342-2.475 1.025A3.372 3.372 0 008.5 8.5c0 .967.342 1.792 1.025 2.475A3.372 3.372 0 0012 12zm-5 6h10v-.25a7.313 7.313 0 00-2.325-1.313A8.183 8.183 0 0012 16c-.933 0-1.825.146-2.675.438-.85.291-1.625.729-2.325 1.312V18zm5-8c-.417 0-.77-.146-1.063-.438A1.446 1.446 0 0110.5 8.5c0-.417.146-.77.438-1.063A1.447 1.447 0 0112 7c.417 0 .77.146 1.063.438.291.291.437.645.437 1.062 0 .417-.146.77-.438 1.063A1.447 1.447 0 0112 10z"
                fill="currentColor"
            />
        </svg>
    )
}

export default SvgComponent
