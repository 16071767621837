import {DateTime} from 'luxon';
import {startDate} from '../../consts/dummy';

export function ordinalSuffixOf(i) {
    const j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

export const calculateTimeLeft = (targetDate) => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        let countdown = '';

        if (days > 0) {
            countdown += `${days}D `;
        }
        if (hours > 0 || countdown !== '') {
            countdown += `${hours}H `;
        }
        if (minutes > 0 || countdown !== '') {
            countdown += `${minutes}M `;
        }
        countdown += `${seconds}S`;

        timeLeft = {
            days,
            hours,
            minutes,
            seconds,
            countdown,
        };
    } else {
        timeLeft = {
            countdown: "Time's up!",
        };
    }

    return timeLeft;
};

export function getDurationUntilStart(date) {
    const decideDate = !!date ? date : startDate
    const targetDate = DateTime.fromISO(decideDate).setZone("America/New_York", {keepLocalTime: true});
    const now = DateTime.now().setZone("America/New_York");
    const dur = targetDate.diff(now, ['days', 'hours', 'minutes', 'seconds']);
    let countdown = '';
    countdown += dur.days > 0 ? `${dur.days}D ` : '';
    countdown += dur.hours > 0 ? `${dur.hours}H ` : '';
    countdown += dur.minutes > 0 ? `${dur.minutes}M ` : '';
    countdown += Math.floor(dur.seconds) > 0 ? `${Math.floor(dur.seconds)}S` : '';

    return {
        values: dur.toObject(),
        countdown: countdown.trim(),
    };
}


export function calculateExactTimeLeft(targetDateTimeString) {
    // Assuming Eastern Time for 'America/New_York'
    // Note: This simple version does not account for daylight saving time.
    const targetDateTime = new Date(targetDateTimeString);
    const now = new Date();

    // Determine if the target date is in daylight saving time
    const isDST = (date) => {
        let jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
        let jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
        return Math.max(jan, jul) !== date.getTimezoneOffset();
    };

    // Adjust for Eastern Time (ET) offset from UTC
    // -5 hours for EST, -4 hours for EDT
    const ET_OFFSET = isDST(targetDateTime) ? -4 : -5;
    const utcTargetDate = new Date(targetDateTime.getTime() + ET_OFFSET * 3600 * 1000);

    // Calculate the difference in milliseconds
    const difference = utcTargetDate - now;

    // Convert difference to days, hours, minutes, and seconds
    const timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
    };

    // Optional: Format the time left as a string for display
    let countdown = '';
    countdown += timeLeft.days > 0 ? `${timeLeft.days}D ` : '';
    countdown += timeLeft.hours > 0 ? `${timeLeft.hours}H ` : '';
    countdown += timeLeft.minutes > 0 ? `${timeLeft.minutes}M ` : '';
    countdown += timeLeft.seconds > 0 ? `${timeLeft.seconds}S` : '';

    timeLeft.countdown = countdown.trim();

    return timeLeft;
}

export const getGameStartAndEndTimes = (gameNumber) => {
    let day, month;
    // Round 1
    if (gameNumber < 8) { day = '19'; month = '03'; }
    else if (gameNumber < 16) { day = '20'; month = '03'; }
    else if (gameNumber < 24) { day = '21'; month = '03'; }
    else if (gameNumber < 32) { day = '22'; month = '03'; }
    // Round 2
    else if (gameNumber < 36) { day = '25'; month = '03'; }
    else if (gameNumber < 40) { day = '26'; month = '03'; }
    else if (gameNumber < 44) { day = '27'; month = '03'; }
    else if (gameNumber < 48) { day = '28'; month = '03'; }
    // Round 3
    else if (gameNumber < 52) { day = '01'; month = '04'; }
    else if (gameNumber < 56) { day = '01'; month = '04'; }
    // Round 4
    else if (gameNumber < 58) { day = '02'; month = '04'; }
    else if (gameNumber < 60) { day = '03'; month = '04'; }
    // Round 5
    else if (gameNumber < 62) { day = '04'; month = '04'; }
    // Round 6
    else if (gameNumber < 63) { day = '05'; month = '04'; }
    else return null; // or handle the "else" case as needed
    // Verified scores switch time
    const obj = {
        preMatch: `2024-${month}-${day}T04:00:00`,
        start: `2024-${month}-${day}T09:30:00`,
        end: `2024-${month}-${day}T16:00:00`,
        scoresFinal: `2024-${month}-${day}T17:00:00`,
    }

    return obj;
}


export const lockBodyScroll = () => {
    document.body.style.overflow = 'hidden';
};

export const unlockBodyScroll = () => {
    document.body.style.overflow = '';
};


export const getColorFromTicker = (ticker) => {
    if (!ticker) return '#000000'
    const tickers = {
        "UA": '#FF0000',
        "PINS": '#BD081C',
        "NFLX": '#E50914',
        "TGT": "#CC0000",
        "MSFT": "#00a2ed",
        "COIN": "#345ef5",
        "CELH": "#f69638",
        "BMBL": "#efbb50",
        "KO": "#dc241f",
        "SHOP": "#96bf48",
        "WMT": "#007DC6",
        "DPZ": "#0B648F",
        "MNST": "#b6d15f",
        "CROX": "#80a343",
        "LULU": "#d41834",
        "DKNG": "#FFC72C",
        "PZZA": "#006634",
        "RBLX": "#EE4C94",
        "ABNB": "#FF5A5F",
        "ARM": "#00B64E",
        "DIS": "#033A90",
        "SQ": "#e3b0d8",
        "SPOT": "#1DB954",
        "CMG": "#bf8469",
        "SBUX": "#00704a",
        "NKE": "#bff006",
        "HOOD": "#21B040",
        "NVDA": "#81ab43",
        "T": "#00A8E0",
        "AMD": "#EE2A24",
        "MA": "#FFA500",
        "GOOG": "#46ad5f",
        "BKNG": "#0071c1",
        "SNAP": "#FFFC00",
        "GM": "#1eb0c1",
        "TSLA": "#CC0000",
        "RIVN": "#F68C16",
        "META": "#1877f2",
        "MCD": "#FFC72C",
        "GME": "#003745",
        "LYFT": "#FF00BF",
        "PYPL": "#003087",
        "UBER": "#01bd69",
        "AAPL": "#b6b6b6",
        "PDD": "#44C28D",
        "V": "#1a1f71",
        "DNUT": "#ff9052",
        "LOW": "#004990",
        "PEP": "#0052b4",
        "SMCI": "#007DC3",
        "AMZN": "#ff9900",
        "LUV": "#FFCC00",
        "F": "#1C3966",
        "TLRY": "#006d5b",
        "WEN": "#ee7f2d",
        "EA": "#bae7ff",
        "HD": "#F96302",
        "PENN": "#7B9EDB",
        "MTCH": "#FF0066",
        "SIRI": "#30aeed",
        "DAL": "#003399",
        "BABA": "#fe6725",
        "VZ": "#CD040B",
        "AMC": "#EC0C00",

    };
    return tickers[ticker]
}
