import React from 'react';

const TextInputWrapper = ({icon: Icon, ref, ...props}) => {
    if (props.variant === 'ghost') {
        return (
            <input
                {...props}
                ref={props.ref}
                className={`font-body font-medium bg-black text-white outline-none placeholder-white/50 ${props.className}`}
                style={{border: 'none'}}
            />
        )
    }
    return (
        <div className={`${props.className} flex flex-col gap-2`}>
            {props.label &&
                <label htmlFor={props.id}
                       className="block text-sm font-medium opacity-50 font-body">{props.label}</label>
            }
            <div
                className="flex items-center border-2 border-green-500 overflow-hidden rounded-full p-4 bg-black text-white">
                {Icon && <Icon className="text-green-500 mx-2"/>}
                <input
                    ref={props.ref}
                    {...props}
                    className="flex-1 font-body font-medium bg-black text-white outline-none placeholder-white/50"
                    style={{border: 'none'}}
                />
            </div>
        </div>
    );
};

export default TextInputWrapper;
