import React from 'react';
import ModalComponent from "../atoms/ModalComponent";
import PageHeader from "../navigation/PageHeader";
import {Button} from "../atoms/Button";
import SvgClose from "../icons/SvgClose";
import StockLogo from "../atoms/StockLogo";

function BracketPointsBreakdownModal(props) {
    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={props.mobile ? 'full-mobile' : 'lg'}
            toggle={props.close}
            backdropClass={'bg-purple-500/40'}
        >
            <div className='ml-auto absolute top-8 right-8 md:top-12 md:right-12 z-[10]'>
                <Button onClick={props.close} size={'icon'} variant={'secondary'}>
                    <SvgClose/>
                </Button>
            </div>
            <div
                className='flex-1 max-h-[95dvh] border border-white/10 rounded-xl md:max-h-[80dvh] px-3 md:px-8 flex flex-col gap-8 overflow-y-scroll w-full overflow-x-hidden'>
                <PageHeader
                    title={'Points History'}
                />
                <p className='font-body text-xl font-semibold'>
                    First Round
                </p>
                {[1, 2, 3].map(item =>
                    <div key={item} className='flex flex-row text-xl items-center justify-between'>
                        <div className='flex flex-row gap-5 items-center'>
                            <StockLogo ticker={'TSLA'} className={'w-14 h-14'}/>
                            <p className='text-2xl'>
                                TSLA
                            </p>
                        </div>
                        <p className='text-4xl text-yellow-400'>
                            +100
                        </p>
                    </div>
                )}
            </div>
        </ModalComponent>
    );
}

export default BracketPointsBreakdownModal;
