import React from 'react';

function BracketRoundHeaders({columnWidth}) {
    const width =!! columnWidth ? `${columnWidth}` : 'min-w-[140px]'
    return (
        <div className='flex z-[3] flex-row gap-4 justify-between mb-1 md:mb-2 mt-3 font-body text-sm font-semibold'>
            <div className='flex flex-row items-center gap-4'>
                <div className={`${width} w-[160px] text-center`}>
                    <p>
                        1st Round {}
                    </p>
                    <p className='text-xs font-normal opacity-70'>
                        Tue 3/19 - Fri 3/22
                    </p>
                </div>
                <div className={`${width} text-center`}>
                    <p>
                        2nd Round
                    </p>
                    <p className='text-xs font-normal opacity-70'>
                        Mon 3/25 - Thu 3/28
                    </p>
                </div>
                <div className={`${width} text-center -ml-8`}>
                    <p>
                        Third Round
                    </p>
                    <p className='text-xs font-normal opacity-70'>
                        Mon 4/1
                    </p>
                </div>
                <div className={`${width} text-center -ml-12`}>
                    <p>
                        Quarterfinals
                    </p>
                    <p className='text-xs font-normal opacity-70'>
                        Tue 4/2 - Wed 4/3
                    </p>
                </div>
            </div>
            <div className='flex hidden md:flex flex-row items-center gap-4'>
                <div className={`${width} text-center -mr-12`}>
                    <p>
                        Quarterfinals
                    </p>
                    <p className='text-xs font-normal opacity-70'>
                        Tue 4/2 - Wed 4/3
                    </p>
                </div>
                <div className={`${width} text-center -mr-8`}>
                    <p>
                        Third Round
                    </p>
                    <p className='text-xs font-normal opacity-70'>
                        Mon 4/1
                    </p>
                </div>
                <div className={`${width} text-center`}>
                    <p>
                        2nd Round
                    </p>
                    <p className='text-xs font-normal opacity-70'>
                        Mon 3/25 - Thu 3/28
                    </p>
                </div>
                <div className={`${width} text-center`}>
                    <p>
                        1st Round
                    </p>
                    <p className='text-xs font-normal opacity-70'>
                        Tue 3/19 - Fri 3/22
                    </p>
                </div>
            </div>
        </div>
    );
}

export default BracketRoundHeaders;
