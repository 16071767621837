import React, {useEffect, useState} from 'react';
import {Button} from "../atoms/Button";
import ModalComponent from "../atoms/ModalComponent";
import StonkMadnessLogo from "../brand/logos/StonkMadnessLogo";
import AfterHourWordmark from "../brand/logos/AfterHourWordmark";
import TextInputWrapper from "../atoms/TextInputWrapper";
import SvgMail from "../icons/SvgMail";
import shareprizes from '../../img/share-prizes.png'
import afterhour from '../../img/afterhourpromo.png'
import {joinWaitlist, getWaitlistCount} from '../../api/waitlist';
import {SHARE_TO_SOCIAL_TEXT, WEBSITE_LINK} from "../../consts/dummy";
import SvgCheck from "../icons/SvgCheck";

function JoinWaitlistFlow({open, close, mobile}) {
    const [step, setStep] = useState(1);
    const [switchingStep, setSwitchingStep] = useState(false);
    const [renderedStep, setRenderedStep] = useState(1);
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [buttonText, setButtonText] = useState("Share For More Prizes")
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (!switchingStep) {
            setRenderedStep(step);
        }
    }, [switchingStep, step]);

    useEffect(() => {
        const handleEnterKeyPress = (event) => {
            if (event.key === "Enter") {
                goNextStep();
            }
        };

        document.addEventListener("keydown", handleEnterKeyPress);
        return () => {
            document.removeEventListener("keydown", handleEnterKeyPress);
        };
    });

    const changeStep = (newStep) => {
        setSwitchingStep(true);
        setTimeout(() => {
            setStep(newStep);
            setSwitchingStep(false);
        }, 300);
    }

    const goNextStep = () => {
        if (step === 1) {
            if (verifyEmail(email)) {
                submit();
            } else {
                setErrorMessage("Invalid email address. Please try again.")
            }
        }

        if (step !== 3) {
           changeStep(step + 1);
        } else {
            closeFlow()
        }


    }

    const goBackStep = () => {
        changeStep(step - 1);
    }

    const verifyEmail = (email) => {
        // https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
        const re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        return re.test(email)
    }

    const submit = async () => {
        joinWaitlist(email);
    }

    const stepStyle = {
        opacity: switchingStep ? 0 : 1,
        transform: switchingStep ? 'translateY(20px)' : 'translateY(0)',
        transition: 'opacity 300ms, transform 300ms'
    };

    const closeFlow = () => {
        close()
        setStep(1)
        setEmail("")
    }

    const linkToAfterhour = () => {
        window.open(WEBSITE_LINK, '_blank', 'noreferrer');
    }

    const shareForPrizes = () => {
        if (mobile) {
            navigator.share({
                title: 'Join Stonk Madness Now!',
                text: SHARE_TO_SOCIAL_TEXT,
                url: 'https://stonkmadness.com/'
            })
        } else {
            navigator.clipboard.writeText('https://stonkmadness.com/').then(() => {
                setCopied(true);
                setButtonText('Link copied');

                // Reset state after 3 seconds
                setTimeout(() => {
                    setCopied(false);
                    setButtonText('Share on socials');
                }, 6000);
            });
        }
    }

    const renderStep = () => {
        switch (renderedStep) {
            case 1:
                return (
                    <div className='flex flex-col' style={stepStyle}>
                        <div className='flex flex-col gap-3 items-center mb-3'>
                            <StonkMadnessLogo className={'h-[80px]'}/>
                            <div className='flex flex-col items-center gap-0 -mt-2'>
                                <p className='text-xs uppercase opacity-50'>
                                    Presented by
                                </p>
                                <a href={WEBSITE_LINK} target="_blank" rel={'noreferrer'}>
                                    <AfterHourWordmark className={'w-20 h-8'}/>
                                </a>
                            </div>
                        </div>
                        <TextInputWrapper
                            id="email"
                            type="email"
                            label={'Your Email'}
                            placeholder="example@gmail.com"
                            icon={SvgMail}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <p className="mt-2 font-body ml-2 text-red-400 text-xs">
                            {errorMessage}
                        </p>
                        <Button onClick={goNextStep} variant={'green'} className={'mt-6'}>
                            Join the Waitlist
                        </Button>
                    </div>
                );
            case 2:
                return (
                    <div className='flex flex-col' style={stepStyle}>
                        {copied ? (
                                <p className='text-2xl'>
                                    <span className='text-yellow-400'>LINK COPIED.</span> THANKS!
                                </p>
                            ) :
                            <p className='text-2xl'>
                                YOU’RE ALL SET!
                            </p>
                        }
                        <p className={'font-body text-lg font-medium'}>
                            <span
                                className='text-yellow-400 font-semibold'>Thanks for joining the waitlist!</span> You’ll
                            be notified of any updates & we’ll send a reminder when bracket entry opens.
                        </p>
                        <img alt={'prize'} src={shareprizes} className='w-full'/>
                        <Button onClick={shareForPrizes} variant={'default'} className={'mt-6 text-xl'}>
                            {copied && <SvgCheck/>} {buttonText}
                        </Button>
                        <Button onClick={goNextStep} variant={'secondary'} className={'text-xl mt-2'}>
                            Next
                        </Button>
                    </div>
                )
            case 3:
                return (
                    <div className='flex flex-col' style={stepStyle}>
                        <p className='text-2xl mb-3'>
                            HUNGRY FOR MORE?
                        </p>
                        <img alt={'afterhour'} src={afterhour} className='w-1/2 mx-auto'/>
                        <p className={'font-body text-lg font-medium'}>
                            <span className='text-yellow-400 font-semibold'>Continue the convo on AfterHour!</span> Join
                            5000+ verified degens in the AfterHour community and celebrate your wins (or losses)
                            together!
                        </p>
                        <Button variant={'default'} className={'mt-6 text-xl'} onClick={linkToAfterhour}>
                            Join the convo
                        </Button>
                        <Button onClick={closeFlow} variant={'secondary'} className={'text-xl mt-2'}>
                            Close
                        </Button>
                    </div>
                )
            default:
                return null;
        }
    };

    return (
        <>
            <ModalComponent
                isOpen={open}
                size={'sm'}
                backdropClass={'bg-green-500/30'}
                toggle={closeFlow}
                confetti={renderedStep === 2}
            >
                {renderStep()}
            </ModalComponent>
        </>
    );
}

export default JoinWaitlistFlow;
