import React from 'react';
import StockLogo from "../atoms/StockLogo";
import SvgCheck from "../icons/SvgCheck";

function BracketGameCardTeamRow(props) {
    const ticker = props.ticker
    const isWinnerPicked = props.winner !== null
    return (
        <div
            // todo: only add back in with free form selection
            // onClick={() => !props.disablePointerEvents && props.onPick(props.gameNumber, props.ticker, true)}
            className={
                `flex font-body flex-row gap-1 md:gap-2 items-center 
            ${(!props.disablePointerEvents && !props.viewOnly) && 'hover:bg-white/15'} 
            ${(isWinnerPicked && !props.loading) && (props.winner === ticker ? 'hover:bg-transparent' : 'line-through opacity-30')} 
            rounded-md
            `}>
            {props.loading ?
                <div
                    className={'w-6 md:w-7 h-6 md:h-7 bg-white/20 rounded-full'}/>
                :
                <StockLogo ticker={ticker} tbd={!ticker} border/>
            }
            {props.loading ?
                <p className={`text-xs md:text-sm font-medium opacity-0`}>
                    --
                </p>
                :
                <p className={`text-xs md:text-sm font-medium ${isWinnerPicked && (props.winner === ticker) && 'font-semibold'}`}>
                    {ticker && <span className='text-[10px]'>{props?.seed}</span>} {props.tbd && false ? 'TBD' : ticker}
                </p>
            }
            {(isWinnerPicked && (props.winner === ticker) && !props.loading) ?
                <div
                    className={'w-5 h-5 border border-none ml-auto rounded-full flex flex-col items-center bg-yellow-400 justify-center'}>
                    < SvgCheck className={'w-4 h-4 text-black'}/>
                </div>
                :
                <div className={'w-5 h-5 border border-neutral-600 ml-auto rounded-full'}/>
            }

        </div>
    )
        ;
}

export default BracketGameCardTeamRow;
