import React from 'react';

function PageHeader({title, desc, titleClass}) {
    return (
        <div className='flex flex-col pt-12 gap-2 items-center text-center px-3'>
            <p className={`text-4xl uppercase ${titleClass}`}>
                {title}
            </p>
            <p className='font-body text-lg font-medium'>
                {desc}
            </p>
        </div>
    );
}

export default PageHeader;
