import React, {useState} from 'react';
import StockLogo from '../components/atoms/StockLogo';
import SvgChevronDown from '../components/icons/SvgChevronDown';
import {Button} from '../components/atoms/Button';
import AlertBanner from '../components/atoms/AlertBanner';
import CollapseComponent from '../components/atoms/CollapseComponent';
import {useNavigate} from "react-router-dom";
import LottieWrapper from "../components/LottieWrapper";
import hand from '../img/lottie/hand.json'
import SvgClose from "../components/icons/SvgClose";
import confetti from '../img/lottie/confetti.json'
import shine from "../img/lottie/shine.json";

const StockInfo = ({ticker, isTBD, loading, eliminated}) => {
    return (
        <div className={`flex flex-col items-center gap-1 relative`}>

            <StockLogo ticker={ticker} loading={loading} tbd={isTBD} border eliminated={eliminated}
                       className={`w-12 h-12 border-2`}/>
            <p className={`font-body text-md font-semibold ${loading && 'opacity-0'} ${eliminated && 'line-through opacity-30'}`}>{loading ? '--' : isTBD ? 'TBD' : ticker}</p>
        </div>
    );
};

const Top8Stocks = ({isTBD, userBracket, loading, eliminatedTickers}) => {
    let set = new Set([userBracket.g62]);
    return (
        <div className='flex flex-col items-center gap-1 py-4'>
            <div className='flex flex-row items-center gap-3'>
                {[62, 61, 60, 59].map((item) => {
                    const i1 = (item - 32) * 2;
                    const i2 = (item - 32) * 2 + 1;
                    const t1 = userBracket?.[`g${i1}`];
                    const t2 = userBracket?.[`g${i2}`];
                    const t = set.has(t1) ? t2 : t1;
                    set.add(t);
                    return <StockInfo loading={loading} key={item} ticker={t} isTBD={isTBD}
                                      // eliminated={eliminatedTickers.includes(t)}
                    eliminated={false}/>
                })
                }
            </div>
            <div className='flex flex-row items-center gap-3'>
                {[58, 57, 56].map((item) => {
                    const i1 = (item - 32) * 2;
                    const i2 = (item - 32) * 2 + 1;
                    const t1 = userBracket?.[`g${i1}`];
                    const t2 = userBracket?.[`g${i2}`];
                    const t = set.has(t1) ? t2 : t1;
                    set.add(t);
                    return <StockInfo loading={loading} key={item} ticker={t} isTBD={isTBD}
                                      // eliminated={eliminatedTickers.includes(t)}
                    eliminated={false}/>
                })
                }
            </div>
        </div>
    )
}

function TopStock({loading, hasBracket, userBracket, eliminatedTickers}) {
    const navigate = useNavigate()
    const eliminated = false
    // const eliminated = eliminatedTickers.includes(userBracket?.g62)
    return (
        <div onClick={() => {
            navigate('/bracket', {state: {isEditing: true}})
        }}>
            <div className='flex flex-col items-center gap-1 py-4 relative'>
                {hasBracket ? (
                    <>
                        <div className='relative w-full'>
                            {/*<div className='flex flex-row items-center gap-5'>*/}
                            {/*    <img*/}
                            {/*        src={require('../img/stickers/SM_TROPHY.png')}*/}
                            {/*        alt={'trophy'}*/}
                            {/*        className={`w-20 h-20 object-contain scale-80`}/>*/}
                            {/*    <div className='relative'>*/}
                            {/*        {!loading &&*/}
                            {/*            <LottieWrapper json={shine}*/}
                            {/*                           className={'absolute z-[-1] scale-[1.5]'}/>*/}
                            {/*        }*/}
                            {/*        <StockLogo loading={loading && !hasBracket} ticker={userBracket?.g62} border*/}
                            {/*                   className={`w-20 h-20 border-2 ${eliminated && 'opacity-20'}`}/>*/}
                            {/*    </div>*/}
                            {/*    <img*/}
                            {/*        src={`https://zyidwzbarunqmcnmfukk.supabase.co/storage/v1/object/public/characters/md/${userBracket?.g62}.png`}*/}
                            {/*        alt={userBracket?.g62}*/}
                            {/*        className={`w-20 h-20 object-contain scale-80`}/>*/}
                            {/*</div>*/}
                            <div className='flex flex-col relative w-full items-center gap-5'>
                                <div className='relative'>
                                    {!eliminated &&
                                        <div className='absolute z-[-1]'>
                                            <LottieWrapper json={shine}
                                                           alwaysVisible
                                                           className={'scale-[1.5]'}/>
                                        </div>
                                    }
                                    <StockLogo loading={loading && !hasBracket} ticker={userBracket?.g62} border
                                               className={`w-24 h-24 border-2 ${eliminated && 'opacity-20'}`}/>
                                </div>
                            </div>
                            {eliminated &&
                                <div
                                    className='absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center'>
                                    <SvgClose className={'w-10 h-10'}/>
                                </div>
                            }
                        </div>
                        <p className={`font-body text-lg font-semibold ${eliminated && 'line-through opacity-30'}`}>{userBracket?.g62}</p>
                    </>
                ) : (
                    <div className='flex flex-col items-center gap-1 relative'>
                        {/*{!loading &&*/}
                        {/*    <div className='absolute right-2'>*/}
                        {/*        <LottieWrapper json={hand} width={80}/>*/}
                        {/*    </div>*/}
                        {/*}*/}
                        <StockLogo loading={loading && !hasBracket} tbd border
                                   className={`w-24 h-24 border-2 animate-pulse`}/>
                        {/*<p className='text-xl text-yellow-400 mt-2 animate-bounce'>{!loading && 'PICK YOUR CHAMPION'}</p>*/}
                    </div>
                )}
            </div>
        </div>
    )
}

function MyBracketSummary({userBracket, authUser, loading, mobile, eliminatedTickers}) {
    const hasBracket = userBracket?.submitted;
    const navigate = useNavigate()
    const [top8Collapsed, setTop8Collapsed] = useState(false);

    const toggleTop8Collapse = () => setTop8Collapsed(!top8Collapsed);

    return (
        <div className='flex flex-col gap-3 w-full -mt-12 relative'>
            {/*todo: banner shown leading up to and during tourney, hide when over*/}
            {/*<AlertBanner hasBracket={hasBracket}/>*/}
            <div className={`bg-white/15 rounded-3xl w-full overflow-hidden relative`}>
                {(hasBracket && !mobile) &&
                    <LottieWrapper
                        json={confetti}
                        speed={.5}
                        className={'absolute left-0 right-0 top-0 bottom-0 pointer-events-none'}
                    />
                }
                <div className='flex flex-row items-center justify-center py-2 bg-white/10'>
                    <p className='text-lg font-semibold font-body'>MY CHAMPION</p>
                </div>
                <TopStock
                    loading={loading}
                    hasBracket={hasBracket}
                    userBracket={userBracket}
                    eliminatedTickers={eliminatedTickers}
                />
                <div onClick={toggleTop8Collapse}
                     className='flex flex-row items-center justify-center py-2 bg-white/10 cursor-pointer hover:bg-white/15'>
                    <p className='text-lg font-semibold font-body'>MY TOP 8</p>
                    <SvgChevronDown
                        className={`transition-transform duration-300 ease ${!top8Collapsed ? 'rotate-180' : ''}`}/>
                </div>
                <CollapseComponent isOpen={!top8Collapsed}>
                    <Top8Stocks userBracket={userBracket} isTBD={!hasBracket} loading={loading && !hasBracket}
                                eliminatedTickers={eliminatedTickers}/>
                </CollapseComponent>
            </div>
            <>
                <div
                    onClick={() => hasBracket ? navigate('/bracket', {state: {isEditing: true}}) : navigate('/bracket', {state: {isEditing: true}})}
                >
                    <Button onClick={null} className='w-full'>
                        {hasBracket ? 'View Your bracket' : 'View bracket'}
                    </Button>
                </div>
            </>
            {hasBracket &&
                <img
                    src={`https://zyidwzbarunqmcnmfukk.supabase.co/storage/v1/object/public/characters/md/${userBracket?.g62}.png`}
                    alt={userBracket?.g62}
                    className={`w-24 h-20 md:w-40 md:h-32 absolute object-contain right-3 rotate-6 top-8 md:top-8 pointer-events-none`}/>
            }
        </div>
    );
}

export default MyBracketSummary;
