import {getAuth} from 'firebase/auth'
import {initializeApp} from 'firebase/app'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "auth.stonkmadness.com",
  projectId: "afterhour-63b2b",
  storageBucket: "afterhour-63b2b.appspot.com",
  messagingSenderId: "780284245705",
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: "G-810SWZBLY0"
}

const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);

