import React, {useEffect, useState} from 'react';
import {calculateTimeLeft} from "../utils/helpers";

function MatchupStatusIndicator(props) {
    const targetDate = `03/18/${new Date().getFullYear()}`;
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft(targetDate));
        }, 1000);

        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
    });

    return (
        <div className='flex flex-col items-center relative justify-center font-body -mt-[36px]'>
            <div className='flex flex-row items-center gap-1'>
              <span className="relative flex h-2.5 w-2.5 flex flex-col items-center justify-center">
                    <span className="animate-ping  absolute inline-flex h-full w-full rounded-full bg-orange-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-orange-400"></span>
                </span>
                <p className='text-orange-400 text-xs md:text-sm font-semibold'>
                    Starts soon
                </p>
            </div>
            <p className='text-md opacity-50 text-xs'>
                {timeLeft.countdown}
            </p>
        </div>
    );
}

export default MatchupStatusIndicator;
