import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import StockLogo from "./atoms/StockLogo";
import LottieWrapper from "./LottieWrapper";
import crown from "../img/lottie/crown.json";
import {Button} from "./atoms/Button";
import confetti from "../img/lottie/confetti.json";
import AfterHourWordmark from "./brand/logos/AfterHourWordmark";
import hand from "../img/lottie/hand.json";
import {prizes, WEBSITE_LINK} from "../consts/dummy";
import SvgClose from "./icons/SvgClose";
import ProfilePic from "./atoms/ProfilePic";
import Marquee from "react-fast-marquee";


function FinaleStateOverlay(props) {
    const ref = useRef(null);
    const navigate = useNavigate();
    const [topPosition, setTopPosition] = useState('100vh');
    const [isFinaleStateVisible, setIsFinaleStateVisible] = useState(true)

    useEffect(() => {
        const updateTopPosition = () => {
            if (ref.current) {
                const componentHeight = ref.current.clientHeight + (!props.mobile && 24);
                const viewportHeight = window.innerHeight;
                const topValue = `${(viewportHeight - componentHeight) / 1}px`;
                setTopPosition(topValue);
            }
        };

        updateTopPosition(); // Call immediately in case the component is already correctly sized
        window.addEventListener('resize', updateTopPosition);

        return () => {
            window.removeEventListener('resize', updateTopPosition);
        };
    }, [isFinaleStateVisible]);

    const winner = 'rjaleman123'
    const winner_pts = '7300pts'
    const winning_stock = {
        ticker: "DIS",
        name: 'The Walt Disney Corporation'
    }
    return (
        <div
            ref={ref}
            className={'bg-black fixed left-1/2 z-[3] -translate-x-1/2 rounded-3xl overflow-hidden p-3 md:p-5 mb-2 -mt-2 border border-green-500'}
            style={{
                top: topPosition
            }}
        >
            {!isFinaleStateVisible ?
                <Button
                    variant={'secondary'}
                    onClick={() => setIsFinaleStateVisible(true)}
                >
                    Show Winner
                </Button>
                :
                <div className='flex w-[90vw] md:w-fit flex-col items-center gap-3 md:gap-5 relative overflow-hidden'>
                    <Button
                        className={'absolute top-0 right-0'}
                        size={'iconsm'}
                        variant={'secondary'}
                        onClick={() => setIsFinaleStateVisible(false)}
                    >
                        <SvgClose/>
                    </Button>
                    <LottieWrapper
                        json={confetti}
                        speed={.5}
                        className={'absolute left-0 right-0 top-0 bottom-0 pointer-events-none'}
                    />
                    <p className='font-body text-center text-xs md:text-lg mx-auto mt-8 mb-1 md:mt-0 md:w-2/3'>
                        A winner has been crowned. Thanks everyone for playing the first ever Stonk Madness tournament!
                    </p>
                    <div className='relative'>
                        <StockLogo
                            ticker={winning_stock.ticker}
                            className={'w-20 h-20 md:w-32 md:h-32'}
                        />
                        <img
                            src={`https://zyidwzbarunqmcnmfukk.supabase.co/storage/v1/object/public/characters/md/${winning_stock.ticker}.png`}
                            alt={winning_stock.winning_stock}
                            className={`w-16 max-h-20 absolute -bottom-2 md:bottom-0 -right-8 md:-right-6 object-contain ${props.className} `}/>
                        <div className='absolute -right-8 md:-right-4 -top-6 rotate-12'>
                            <LottieWrapper json={crown} width={100}/>
                        </div>
                    </div>
                    <div className='flex flex-col items-center gap-0'>
                        <p className='text-2xl md:text-5xl'>
                            {winning_stock.ticker}
                        </p>
                        <p className='font-body -mt-1 mb-1 opacity-50'>
                            {winning_stock.name}
                        </p>
                        <p className='text-xl text-yellow-400'>
                            STONK MADNESS CHAMPION
                        </p>
                    </div>
                    <div className='bg-green-500/30 hover:bg-green-500/50 px-3 py-2 rounded-xl cursor-pointer'
                         onClick={() => navigate(`/bracket/${winner}`)}
                    >
                        <div className='flex-[1.1] flex flex-row items-center gap-3 truncate'>
                            <p className='font-body text-sm md:text-lg text-green-500 font-semibold'>
                                1st Place:
                            </p>
                            <div className='flex flex-row font-body font-bold items-center gap-2'>
                                <ProfilePic
                                    size={props.mobile ? '1' : '2'}
                                    username={winner}
                                />
                                <p className={`text-sm md:text-lg truncate`}>
                                    {winner} ({winner_pts})
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col w-full items-center gap-3'>
                        <div className='-mb-1'>
                            <p className='text-xs opacity-50 font-body'>
                                20 prizes won. Winners to be contacted via email.
                            </p>
                        </div>
                        <Button size={'sm'} onClick={() => navigate('/leaderboard')}
                                variant={'secondary'}
                                className={'block md:hidden'}
                        >
                            View Final Leaderboard
                        </Button>
                        <Button onClick={() => navigate('/leaderboard')}
                                variant={'secondary'}
                                className='hidden md:block'
                        >
                            View Final Leaderboard
                        </Button>
                        <div className='flex text-purple-500 text-xs md:text-sm font-body gap-2 flex-row items-center'>
                            <p>
                                Continue the convo on
                            </p>
                            <a href={WEBSITE_LINK} target={'_blank'} rel={'noreferrer'} className='relative'>
                                <AfterHourWordmark className={'h-12 w-20'}/>
                                <div className='absolute scale-50 md:scale-100 -right-12 -top-8 rotate-[-80deg]'>
                                    <LottieWrapper json={hand} width={80} height={80}/>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default FinaleStateOverlay
