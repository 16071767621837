import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import HeroSection from "../sections/HeroSection";
import HowToPlaySection from "../sections/HowToPlaySection";
import BracketsOpenSoonSection from "../sections/BracketsOpenSoonSection";
import DownloadAfterHourCard from "../sections/cards/DownloadAfterHourCard";
import ScheduleSection from "../sections/ScheduleSection";
import ShareForPrizesCard from "../sections/cards/ShareForPrizesCard";
import {pageClass} from "../consts/dummy";
import FAQSection from "../sections/FAQSection";
import PrizesSection from "../sections/PrizesSection";
import {getWaitlistCount} from "../api/supabase";

function mapStateToProps(state) {
    return {
        mobile: state.userReducer.mobile
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleWaitlistModal: () => {
            dispatch({
                type: "TOGGLE_WAITLIST_MODAL",
            })
        },
    }
}

function HomePage(props) {
    const [waitlistCount, setWaitlistCount] = useState(0);

    useEffect(() => {
        _getWaitlistCount();
    }, [])

    const _getWaitlistCount = async () => {
        const count = (await getWaitlistCount())?.count || 0;
        setWaitlistCount(count);
    }

    return (
        <div className={`${pageClass} max-w-[100vw] overflow-hidden md:overflow-visible`}>
            <HeroSection/>
            <PrizesSection onHome={true} waitlistCount={waitlistCount}/>
            <HowToPlaySection onHome={true}/>
            <BracketsOpenSoonSection toggleWaitlistModal={props.toggleWaitlistModal} waitlistCount={waitlistCount}/>
            <DownloadAfterHourCard/>
            <ScheduleSection onHome={true}/>
            <ShareForPrizesCard mobile={props.mobile}/>
            <FAQSection/>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
