import React, {PureComponent, createContext} from "react";
import {firebaseAuth} from "../api/firebase";

export const UserContext = createContext({user: null});

class UserProvider extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            user: 0,
        }
    }

    componentDidMount() {
        firebaseAuth.onAuthStateChanged(userAuth => {
            if (userAuth) {
                userAuth.getIdTokenResult().then(idTokenResult => {
                    this.setState({user: userAuth})
                })
            } else {
                this.setState({user: null})
            }
        })
    }

    render() {
        return (
            <UserContext.Provider value={this.state.user}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

export default UserProvider;
