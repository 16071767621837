import React from 'react';
import StockLogo from "../../atoms/StockLogo";
import HelperTip from "../../atoms/HelperTip";
import {roundTo, truncateString} from "../../utils/strings";
import {AnimatePresence, motion} from "framer-motion";

function BracketCreationMatchupDisplay(props) {
    const variants = {
        initial: {opacity: 0, x: 60},
        animate: {opacity: 1, x: 0},
        exit: {opacity: 0, x: -60}
    };
    if (props.matchup.ticker1?.length === 0 || props.matchup.ticker1?.length === 0) {
        return null
    }

    const matchup = props?.preloadedMatchup || props.matchup;
    const matchupLiveData = props.matchupLiveData
    return (
        <AnimatePresence>
            <motion.div
                key={props.gameNumber || 0} // Use gameNumber as the key to trigger animations on change
                variants={variants} // Pass the defined variants
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{duration: 0.5}}
                className='flex flex-row truncate items-center flex-1 font-body my-3 absolute left-4 right-4'>
                {!props.hideTip &&
                    <HelperTip className='left-1/2 -translate-x-1/3 md:-translate-x-1/4 -top-10 md:-top-5'/>
                }
                <div className='flex flex-row flex-1 gap-2 items-center justify-end cursor-pointer truncate'
                     onClick={() => props.openMatchPreviewModal(props.matchup)}>
                    <div className='text-right flex flex-col flex-1 truncate'>
                        <p className='text-sm md:text-xl font-bold'>
                            {matchup?.ticker1}
                        </p>
                        <div className='text-xs md:text-sm opacity-70 truncate'>
                            {truncateString(matchup?.[matchup?.ticker1]?.name, 32)}
                        </div>
                    </div>
                    <StockLogo loading={!matchup?.ticker1} ticker={matchup?.ticker1}
                               className={'w-10 h-10 md:w-14 md:h-14'} border
                               tbd={!matchup?.ticker1}/>
                </div>
                <p className='mx-2 text-sm md:text-lg font-semibold opacity-50 cursor-pointer'
                   onClick={() => props.openMatchPreviewModal(props.matchup)}>
                    vs.
                </p>
                <div className='flex flex-row flex-1 gap-2 items-center cursor-pointer truncate'
                     onClick={() => props.openMatchPreviewModal(props.matchup)}>
                    <StockLogo loading={!matchup?.ticker2} ticker={matchup?.ticker2}
                               className={'w-10 h-10 md:w-14 md:h-14'} border
                               tbd={!matchup?.ticker2}/>
                    <div className='text-left flex flex-col flex-1 truncate'>
                        <p className='text-sm md:text-xl font-bold'>
                            {matchup?.ticker2}
                        </p>
                        <p className='text-xs md:text-sm opacity-70 truncate'>
                            {truncateString(matchup?.[matchup?.ticker2]?.name, 32)}
                        </p>
                    </div>
                </div>
            </motion.div>
            <div className='flex flex-row items-center  gap-5 md:gap-12  w-full justify-center mt-20'>
                <div className={'flex flex-col flex-1 text-right items-end gap-1'}>
                    <p className='font-body text-lg font-semibold'>
                        {'$' + roundTo(matchupLiveData?.[matchupLiveData?.ticker1]?.['price'], 2)}
                    </p>
                    <p className={`font-body text-sm -mt-2 font-semibold ${matchupLiveData?.[matchupLiveData?.ticker1]?.['change'] >= 0 ? 'text-green-500' : 'text-red-400'}`}>
                        {roundTo(matchupLiveData?.[matchupLiveData?.ticker1]?.['change_percent'] * 100, 2) + '%'}
                    </p>
                </div>
                <div className={'flex flex-col flex-1 gap-1'}>
                    <p className='font-body text-lg font-semibold'>
                        {'$' + roundTo(matchupLiveData?.[matchupLiveData?.ticker2]?.['price'], 2)}
                    </p>
                    <p className={`font-body text-sm -mt-2 font-semibold ${matchupLiveData?.[matchupLiveData?.ticker2]?.['change'] >= 0 ? 'text-green-500' : 'text-red-400'}`}>
                        {roundTo(matchupLiveData?.[matchupLiveData?.ticker2]?.['change_percent'] * 100, 2) + '%'}
                    </p>
                </div>
            </div>
        </AnimatePresence>
    );
}

export default BracketCreationMatchupDisplay;
