import React from 'react';
import ModalComponent from "../atoms/ModalComponent";
import DownloadAfterHourCard from "../../sections/cards/DownloadAfterHourCard";
import PageHeader from "../navigation/PageHeader";
import HowToPlaySection from "../../sections/HowToPlaySection";
import PointsPerRoundSection from "../../sections/PointsPerRoundSection";
import PrizesSection from "../../sections/PrizesSection";
import FAQSection from "../../sections/FAQSection";
import {Button} from "../atoms/Button";
import SvgClose from "../icons/SvgClose";

function RulesModal(props) {
    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={props.mobile ? 'full-mobile' : 'lg'}
            toggle={props.close}
            backdropClass={'bg-purple-500/40'}
        >
            <div className='ml-auto absolute top-8 right-8 md:top-12 md:right-12 z-[10]'>
                <Button onClick={props.close} size={'icon'} variant={'secondary'}>
                    <SvgClose/>
                </Button>
            </div>
            <div className='flex-1 max-h-[95dvh] border border-white/10 rounded-xl md:max-h-[80dvh] px-3 md:px-8 flex flex-col gap-8 overflow-y-scroll w-full overflow-x-hidden'>
                <PageHeader
                    title={'HOW TO PLAY'}
                    desc={'We kept it simple so even the smoothest of brains can play.'}
                />
                <HowToPlaySection/>
                <PointsPerRoundSection/>
                <PrizesSection/>
                <FAQSection/>
            </div>
        </ModalComponent>
    );
}

export default RulesModal;
