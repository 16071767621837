import React from 'react';
import {SCHEDULE, sectionClass} from "../consts/dummy";
import PointsPerRoundCard from "../components/PointsPerRoundCard";
import casino from "../img/stickers/SM_CASINO.png";

function PointsPerRoundSection(props) {
    return (
        <div className={`${sectionClass}`}>
            <div className='flex flex-col gap-3 '>
                <p className='text-4xl'>
                    POINTS PER ROUND:
                </p>
                <p className={'font-body text-lg font-medium'}>
                    <span className='text-yellow-400 font-semibold'>Points are earned for every correct pick</span> and
                    you earn more points for every correct prediction as
                    the tournament progresses.
                </p>
                <div className='grid grid-cols-2 gap-2 py-3 relative'>
                    <div className='absolute -top-4 md:-top-12 right-1'>
                        <img alt={'X'}
                             src={casino}
                             className={'w-12 md:w-24 -mb-5 scale-125'}
                        />
                    </div>
                    {SCHEDULE.map(round =>
                        <PointsPerRoundCard round={round} key={round.title}/>
                    )}
                </div>
                <p className={'font-body text-lg font-medium'}>
                    The later the round, the more valuable the pick. A perfect bracket would be <span
                    className='text-yellow-400 font-semibold'>63 correct games.</span>
                </p>
            </div>
        </div>
    );
}

export default PointsPerRoundSection;
