import StockLogo from "../atoms/StockLogo";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {
        readonlyMasterBracket: state.userReducer.readonlyMasterBracket,
    };
}


function SelectedPickTab(props) {
    const [eliminatedTickers, setEliminatedTickers] = useState([]);
    const isImpossible = (!!props.actualTicker && props.actualTicker !== props.userTicker) || eliminatedTickers.includes(props.userTicker);
    const isPossible = props.actualTicker === props.userTicker && (!props.winner || !props.userWinner);
    const isCorrect = !!props.winner && props.winner === props.userWinner;
    const isIncorrect = !!props.winner && !isCorrect;

    useEffect(() => {
        const eliminatedTickers = Object.values(props.readonlyMasterBracket).filter(m => m.winner_id !== null).map(m => m.ticker1 === m.winner_id ? m.ticker2 : m.ticker1);
        setEliminatedTickers(eliminatedTickers);
    }, [])


    return (
        <div
            className={`flex flex-row items-center ${isPossible ? 'bg-white/10' : isCorrect ? 'bg-green-500/10' : isIncorrect ? 'bg-red-400/10' : isImpossible ? 'bg-white/5' : ''} font-body ${props.lg ? 'py-2 px-4' : 'py-1 px-2'}`}>
            <StockLogo className={'w-3 h-3'} ticker={props.userTicker} border/>
            <p className={`${props.lg ? 'text-sm' : 'text-xs'} ${isImpossible ? 'text-white/50 line-through' : isPossible ? 'text-white/70' : isCorrect ? 'text-green-500' : isIncorrect ? 'text-red-400' : ''} font-semibold ml-0.5`}>
                {props.userTicker}
            </p>
        </div>
    )
}

export default connect(mapStateToProps, null)(SelectedPickTab)
