import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import AfterHourWordmark from "../../brand/logos/AfterHourWordmark";
import StockLogo from "../../atoms/StockLogo";
import TooltipComponent from "../../atoms/TooltipComponent";
import {Button} from "../../atoms/Button";
import ProfilePic from '../../atoms/ProfilePic';
import SvgClock from "../../icons/SvgClock";
import SvgRedo from "../../icons/SvgRedo";
import SvgPodium from "../../icons/SvgPodium";
import {calculateExactTimeLeft, calculateTimeLeft, getDurationUntilStart} from "../../utils/helpers";
import LottieWrapper from "../../LottieWrapper";
import hand from "../../../img/lottie/hand.json";
import {useNavigate} from 'react-router-dom';
import SvgBracket from "../../icons/custom/SvgBracket";
import {startDate} from "../../../consts/dummy";

function mapStateToProps(state) {
    return {
        mobile: state.userReducer.mobile,
        authUser: state.userReducer.authUser,
        readonlyMasterBracket: state.userReducer.readonlyMasterBracket,
    };
}

function BracketPlayerCard(props) {
    const ref = useRef(null);
    const navigate = useNavigate();
    const [topPosition, setTopPosition] = useState('100vh');
    const targetDate = startDate;
    const [timeLeft, setTimeLeft] = useState(getDurationUntilStart());
    const [eliminatedTickers, setEliminatedTickers] = useState([]);

    useEffect(() => {
        // Use setInterval to call calculateExactTimeLeft every second
        const timer = setInterval(() => {
            setTimeLeft(getDurationUntilStart());
        }, 1000);

        // Clear interval when the component unmounts to prevent memory leaks
        return () => clearInterval(timer);
    }, [targetDate]);

    const updateTopPosition = () => {
        if (ref.current) {
            const componentHeight = ref.current.clientHeight + (!props.mobile && 24);
            const viewportHeight = window.innerHeight;
            const topValue = `${viewportHeight - componentHeight}px`;
            setTopPosition(topValue);
        }
    };


    useEffect(() => {
        updateTopPosition();
        window.addEventListener('resize', updateTopPosition);
        return () => {
            window.removeEventListener('resize', updateTopPosition);
        };
    }, []);

    useEffect(() => {
        const eliminatedTickers = Object.values(props.readonlyMasterBracket).filter(m => m.winner_id !== null).map(m => m.ticker1 === m.winner_id ? m.ticker2 : m.ticker1);
        setEliminatedTickers(eliminatedTickers);
    }, [])

    let set = new Set([props.bracket.g62]);
    return (
        <div
            ref={ref}
            className={'fixed left-1/2 z-[2] -translate-x-1/2 w-[96vw] md:w-[90vw] rounded-3xl overflow-hidden p-3 md:p-5 mb-2 -mt-2 border border-white/10'}
            style={{
                top: props.mobile ? topPosition : topPosition,
                opacity: props.matchPreviewModalOpen && !props.mobile && 0,
                background: 'linear-gradient(to top, black, black, black, black, #00000000)',
            }}
        >
            <div className={'flex flex-row items-center gap-2 flex-wrap'}>
                {/*{!props.showEditBracket && !props.authUser &&*/}
                {/*    <div className='flex flex-col md:flex-row w-full gap-3 md:gap-8 mt-4 mb-3'>*/}
                {/*        <div className={'flex-1 text-center md:text-left'}>*/}
                {/*            <p className='uppercase text-yellow-400 text-sm md:text-lg'>*/}
                {/*                There can only be one...*/}
                {/*            </p>*/}
                {/*            <p className='font-body text-sm font-semibold flex-1'>*/}
                {/*                Bracket entry locks in <SvgClock*/}
                {/*                className={'inline-flex w-4 h-4 mb-1 mr-1 text-yellow-400'}/><span*/}
                {/*                className='text-yellow-400'>{timeLeft.countdown}</span>*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*        <div className='relative flex'>*/}
                {/*            <Button*/}
                {/*                className='animate-pulse w-full'*/}
                {/*                size={'lg'} onClick={() => navigate('/authentication')}*/}
                {/*            >*/}
                {/*                Join the Madness*/}
                {/*                <SvgBracket className={'mb-1.5 ml-1'}/>*/}
                {/*            </Button>*/}
                {/*            <div className='absolute -right-6 -top-8 rotate-[-80deg]'>*/}
                {/*                <LottieWrapper json={hand} width={80}/>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}
                <div className='flex flex-row items-center w-full gap-3 justify-between flex-wrap'>
                    <div className='w-full md:w-fit'>
                        <p className='uppercase text-xs md:text-sm'>
                            VIEWING Bracket:
                        </p>
                        <div className='flex flex-row flex-wrap w-full items-center gap-3'>
                            <div className='flex flex-row items-center gap-2 mr-4 md:mr-12'>
                                <ProfilePic size={3} username={props.bracket?.username}/>
                                <div>
                                    <p className='font-semibold text-sm md:text-xl'>
                                        @{props.bracket?.username}
                                    </p>
                                    {/*<p className='inline-flex text-xs'>*/}
                                    {/*    on <AfterHourWordmark className={'w-14 ml-1 h-[15px]'}/>*/}
                                    {/*</p>*/}
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-8'>
                                <div className='-mt-[4px]'>
                                    <p className='uppercase text-xs md:text-sm'>
                                        Rank
                                    </p>
                                    <p className='text-3xl text-white'>
                                        {props.bracket?.rank || '--'}
                                    </p>
                                </div>
                                <div className='-mt-[4px]'>
                                    <p className='uppercase text-xs md:text-sm'>
                                        Score
                                    </p>
                                    <p className='text-3xl text-white'>
                                        {props.bracket?.score || '--'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row items-center gap-4'>
                        <div>
                            <p className='uppercase text-xs md:text-sm'>
                                Champion
                            </p>
                            <TooltipComponent
                                ToggleComponent={
                                    <StockLogo
                                        ticker={props.bracket['g62']}
                                        tbd={props.bracket['g62'] === '' || props.bracket['g62'] === null}
                                        border
                                        // eliminated={eliminatedTickers.includes(props.bracket['g62'])}
                                        eliminated={false}
                                        className={`w-10 aspect-square h-10 border-2`}/>
                                }
                                hover
                                placement={'top'}
                                label={props.bracket['g62']}
                            />
                        </div>
                        <div>
                            <p className='uppercase text-xs md:text-sm'>
                                Top 8
                            </p>
                            <div className='flex flex-row items-center'>
                                {[62, 61, 60, 59, 58, 57, 56].map((item, index) => {
                                    const i1 = (item - 32) * 2;
                                    const i2 = (item - 32) * 2 + 1;
                                    const t1 = props.bracket?.[`g${i1}`];
                                    const t2 = props.bracket?.[`g${i2}`];
                                    const t = set.has(t1) ? t2 : t1;
                                    set.add(t);
                                    return <div className={`${index > 0 && '-ml-3'} rounded-full`} key={item}>
                                        <TooltipComponent
                                            ToggleComponent={
                                                <StockLogo
                                                    key={item}
                                                    ticker={t}
                                                    tbd={t === "" || t === null}
                                                    // eliminated={eliminatedTickers.includes(t)}
                                                    eliminated={false}
                                                    className={`w-10 aspect-square h-10 border-2 border-gray-800 ${eliminatedTickers.includes(t) && 'z-[-1]'}`}/>
                                            }
                                            hover
                                            placement={'top'}
                                            label={t}
                                        />
                                    </div>
                                })
                                }
                            </div>
                        </div>
                        <div className='flex flex-row hidden md:flex items-center mt-5 justify-center relative'>
                            {!(!props.showEditBracket && !props.authUser) &&
                                <div className='absolute -right-6 -top-8 rotate-[-80deg]'>
                                    <LottieWrapper json={hand} width={80}/>
                                </div>
                            }
                            <Button onClick={props.openShareModal} size={props.mobile ? 'sm' : 'lg'}
                                    variant={(!props.showEditBracket && !props.authUser) ? 'secondary' : 'default'}
                                    className={'px-4 text-sm md:text-lg'}>
                                Share Bracket
                            </Button>
                        </div>
                    </div>
                    <div className='flex w-full md:hidden flex-row items-center justify-center'>
                        <Button variant={(!props.showEditBracket && !props.authUser) ? 'secondary' : 'default'}
                                onClick={props.openShareModal} size={props.mobile ? 'sm' : 'lg'}
                                className={'px-4 text-sm md:text-lg w-full'}>
                            Share
                        </Button>
                    </div>
                </div>
            </div>
            {/*{props.showEditBracket &&*/}
            {/*    <div className='flex flex-col md:flex-row w-full gap-3 md:gap-8 mt-4'>*/}
            {/*        <div className={'flex-1'}>*/}
            {/*            <p className='uppercase text-yellow-400 text-sm'>*/}
            {/*                Change your mind?*/}
            {/*            </p>*/}
            {/*            <p className='font-body text-sm md:text-lg font-semibold flex-1'>*/}
            {/*                Bracket entry locks in <SvgClock*/}
            {/*                className={'inline-flex w-4 h-4 mb-1 mr-1 text-yellow-400'}/><span*/}
            {/*                className='text-yellow-400'>{timeLeft.countdown}</span>. <span*/}
            {/*                className='hidden md:inline-flex'>*/}
            {/*                You can always redo your bracket*/}
            {/*                before*/}
            {/*                then.*/}
            {/*            </span>*/}
            {/*            </p>*/}
            {/*        </div>*/}
            {/*        <Button size={props.mobile ? 'sm' : 'lg'} onClick={props.handleEditBracket} variant={'secondary'}*/}
            {/*                className='pr-12 pl-10'>*/}
            {/*            <SvgRedo className={'mb-2 scale-150 mr-2'}/>*/}
            {/*            Repick*/}
            {/*        </Button>*/}
            {/*    </div>*/}
            {/*}*/}
        </div>
    );
}

export default connect(mapStateToProps)(BracketPlayerCard);
