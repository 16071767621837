import axios from './axios';
import store from "../Store";

export const getFinalFourImages = async (username) => {
    const endpoint = `/social/final_four?username=${username}`
    try {
        const config = {
        }
        const res = await axios.get(endpoint, config)
        return res.data;
    } catch (err) {
        console.log(err)
    }

}

export const getTopEightImages = async (username) => {
    const endpoint = `/social/top_eight?username=${username}`
    try {
        const config = {
        }
        const res = await axios.get(endpoint, config)
        return res.data;
    } catch (err) {
        console.log(err)
    }

}

// https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/guides/web-intent
export const tweetIntent = async ({text, url, hashtags, via, related, in_reply_to}) => {
    let link = `https://twitter.com/intent/tweet`;
    link += `?text=${text ? text : ""}`
    link += `&url=${url ? url : ""}`
    link += `&hashtags=${hashtags ? hashtags : ""}`
    if (!!via) link += `&via=${via}`
    link += `&related=${related ? related : ""}`
    link += `&in_reply_to=${in_reply_to ? in_reply_to : ""}`
    return link;
    // const endpoint = `/waitlist?email=${email}`
    // try {
    //     const config = {
    //         params: params
    //     }
    //     const res = await axios.post(endpoint, config);
    //     return res.data;
    // } catch (err) {
    //     console.log(err)
    // }   
}
