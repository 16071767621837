import React from 'react';
import BracketLeftQuarter from "../bracket/BracketLeftQuarter";
import SvgChevronDown from "../icons/SvgChevronDown";
import BracketLiveLeftQuarter from "./BracketLiveLeftQuarter";
import BracketLiveRightQuarter from "./BracketLiveRightQuarter";

function BracketHalf(props) {
    return (
        <div className='flex flex-col md:flex-row md:items-center gap-8 justify-between w-full'>
            <BracketLiveLeftQuarter
                start={props.top ? 0 : !props.mobile ? 8 : 16}
                liveBracket={props.liveBracket}
                openMatchPreviewModal={props.openMatchPreviewModal}
                viewOnly={props.viewOnly}
                loading={props.loading}
                userMatchups={props.userMatchups}
                setFocusedGameNumber={props.setFocusedGameNumber}
                focusedGameNumber={props.focusedGameNumber}
            />
            {(!props.mobile) ?
                <div className='hidden md:block'>
                    <BracketLiveRightQuarter start={props.top ? 16 : 24}
                                             liveBracket={props.liveBracket}
                                             openMatchPreviewModal={props.openMatchPreviewModal}
                                             viewOnly={props.viewOnly}
                                             loading={props.loading}
                                             userMatchups={props.userMatchups}
                                             setFocusedGameNumber={props.setFocusedGameNumber}
                                             focusedGameNumber={props.focusedGameNumber}
                    />
                </div>
                :
                <>
                    <div className='block md:hidden w-4/5 h-0 flex flex-row justify-end font-body opacity-50 text-sm'>
                        Scroll {props.top ? 'down' : 'up'} for Semis <SvgChevronDown
                        className={`w-5 h-5 ${!props.top && 'rotate-180'}`}/>
                    </div>
                    <div className='block md:hidden'>
                        <BracketLiveLeftQuarter start={props.top ? 8 : 24}
                                                liveBracket={props.liveBracket}
                                                openMatchPreviewModal={props.openMatchPreviewModal}
                                                viewOnly={props.viewOnly}
                                                loading={props.loading}
                                                userMatchups={props.userMatchups}
                                                setFocusedGameNumber={props.setFocusedGameNumber}
                                                focusedGameNumber={props.focusedGameNumber}
                        />
                    </div>

                </>
            }
        </div>
    );
}

export default BracketHalf;
