import React, {useState, useEffect} from 'react';
import StockLogo from "../atoms/StockLogo";
import SvgCheck from "../icons/SvgCheck";
import SvgChevronDown from "../icons/SvgChevronDown";
import SvgTriangle from "../icons/SvgTriangle";
import {getScore} from '../../api/stock';
import {renderLiveGameBracketTickerColor} from "../utils/timing";

function BracketLiveGameCardTeamRow(props) {
    const [ticker, setTicker] = useState("");
    const [isWinnerPicked, setIsWinnerPicked] = useState(false);
    const [score, setScore] = useState(null);

    useEffect(() => {
        setTicker(props.ticker)
        setIsWinnerPicked(props.winner !== null && !!ticker)
        getScore(props.gameNumber, props.stockNum)
            .then(res => setScore(res))
    }, [props.ticker, props.userMatchups, props.gameNumber])

    return (
        <div
            // todo: only add back in with free form selection
            // onClick={() => !props.disablePointerEvents && props.onPick(props.gameNumber, props.ticker, true)}
            className={
                `flex font-body flex-row gap-1 md:gap-1 items-center 
            ${(!props.disablePointerEvents && !props.viewOnly) && 'hover:bg-white/15'} 
            ${(isWinnerPicked && !props.loading) && (props.winner === ticker ? 'hover:bg-transparent' : 'line-through opacity-30')} 
            rounded-md
            `}>
            <div className='flex flex-row items-center gap-1'>
                {props.loading ?
                    <div
                        className={'w-6 md:w-6 h-6 md:h-6 bg-white/20 rounded-full'}/>
                    :
                    <StockLogo className={'w-6 h-6'} ticker={ticker} tbd={!ticker} border/>
                }
                <div className='relative'>
                    {props.loading ?
                        <p className={`text-xs md:text-sm font-medium opacity-0`}>
                            --
                        </p>
                        :
                        <p className={`text-xs md:text-sm font-medium ${isWinnerPicked && (props.winner === ticker) && 'font-semibold'}
                         ${renderLiveGameBracketTickerColor(props.gameNumber)}
                         `}>
                            {ticker &&
                                <span
                                    className='text-[10px]' style={{textDecorationLine:'none'}}>{props?.seed} </span>}{props.tbd && false ? 'TBD' : ticker}
                        </p>
                    }
                </div>
            </div>
            {(((props.winner === ticker) && !!ticker && (props.selectedToWin === ticker)) && !props.loading) ?
                <div
                    className={`border text-xs border-yellow-400 ml-auto rounded-md py-1 text-center w-14 ${!ticker && 'opacity-40'}`}>
                    {!ticker ? '-' : score === null ? '-' : `${score >= 0 ? '+' : ''}${score.toFixed(2)}%`}
                </div>
                :
                <div
                    className={`border text-xs border-neutral-600 ml-auto rounded-md py-1 text-center w-14 ${!ticker && 'opacity-40'}`}>
                    {!ticker ? '-' : score === null ? '-' : `${score >= 0 ? '+' : ''}${score.toFixed(2)}%`}
                </div>
            }
            {((props.selectedToWin === ticker) && ticker && !props.incorrectSelection) &&
                <div className={`absolute -rotate-90 -left-2 ${((!!props.winner && !props.loading) && (props.winner === ticker)) ? 'text-yellow-400' : (!props.winner ? 'text-yellow-400' : 'text-red-500')}`}>
                    <SvgTriangle className={'w-4 h-4'}/>
                </div>
            }
        </div>
    )
        ;
}

export default BracketLiveGameCardTeamRow;
