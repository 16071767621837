
function simpleHash(str) {
    let sum = 0;
    if (!str || str === undefined)
        return sum;
    for (const char of str) {
        sum += char.charCodeAt(0);
    }
    return sum;
}

function switchEmoji(username) {
    const emojis = [
        "🚀", "🎈", "🍦", "🍭", "🦄",
        "🐱", "🐶", "🦄", "🌈", "🍉",
        "🍍", "🍓", "🐵", "🍔", "🍕",
        "🐮", "👻", "🐘", "🧸", "🎮",

        "🚗", "🦁", "🍩", "🦚", "👾",
        "🐻", "🐰", "🐺", "🌼", "🥝",
        "🍇", "🍌", "🦊", "🍟", "🍔",
        "🐯", "💀", "🤠", "🐼", "🐉",
    ];
    const hash = simpleHash(username);
    return emojis[hash % emojis.length];
}

function switchBackgroundColor(username) {
    const colors = [
        "FDB9FC", "CCF6C8", "FAF4D3", "D7E3FC", "FDE4CF",
        "F6C6EA", "D5ECC2", "FFF0F5", "FFE4E1", "E6E6FA",

        "C2DFFF", "FFDFDD", "D8FCD7", "FCE1F0", "BCE9FF",
        "F2E6B6", "E0F8E6", "FFCAF8", "D6E4FF", "FED8B1",
        "BEE7F2", "F7F0D7", "FFCCE5", "C5F3E0", "F5F5DC",

        "AFDCEC", "ECC7CD", "FFEFD5", "F0FFFF", "E0BBE4",
        "957DAD", "FFD3B4", "99C1DE", "F7DAD9", "A3E4D7",

    ];
    //
    const hash = simpleHash(username);
    return colors[hash % colors.length];
}


export function DefaultProfilePic({backgroundColor, emoji, size, username}) {
    return (
        <span
            className={`float-left shrink-0 rounded-full text-center aspect-square rounded-full overflow-hidden border-2 border-white/50`}
            style={{
                backgroundColor: `#${backgroundColor || switchBackgroundColor(username)}`,
                width: `${size}rem`,
                height: `${size}rem`,
                lineHeight: `${size}rem`,
                fontSize: `${(size * 5) / 10}rem`,
            }}
        >
      {emoji || switchEmoji(username)}
    </span>
    );
}
