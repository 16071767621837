import React from 'react';
import BracketLiveEightBatch from "./BracketLiveEightBatch";
import BracketLiveGameCard from "./BracketLiveGameCard";

function BracketLiveRightQuarter({
                                     start,
                                     liveBracket,
                                     openMatchPreviewModal,
                                     viewOnly,
                                     setFocusedGameNumber,
                                     loading,
                                     userMatchups,
                                     focusedGameNumber
                                 }) {
    return (
        <div className='flex flex-row items-center'>

            {/*/!*Only show on mobile*!/*/}
            {/*<div className='flex block md:hidden flex-col gap-8 -ml-8'>*/}
            {/*    <BracketPageEightBatch/>*/}
            {/*    <BracketPageEightBatch/>*/}
            {/*</div>*/}
            {/*<div className='block md:hidden'>*/}
            {/*    <BracketGameCard tbd/>*/}
            {/*</div>*/}

            {/*Only show on desktop*/}
            <div className='relative'>
                <BracketLiveGameCard right tbd gameNumber={(start / 8) + 56} liveBracket={liveBracket}
                                     openMatchPreviewModal={openMatchPreviewModal}
                                     userMatchups={userMatchups}
                                     viewOnly={viewOnly} loading={loading} setFocusedGameNumber={setFocusedGameNumber} focusedGameNumber={focusedGameNumber}/>
                <div
                    className='absolute top-0 flex flex-col text-xl md:text-2xl justify-center bottom-0 -right-48 w-40 text-center items-center uppercase'>
                    <p className='text-xs -mt-2 opacity-50 -mb-0'>
                        {start === 0 ? 'Conference 1' : start === 8 ? "Conference 2" : start === 16 ? 'Conference 3' : 'Conference 4'}
                    </p>
                    <p className='my-0' style={{lineHeight: .9}}>
                        {start === 0 ? "Pelosi's Picks" : start === 8 ? "Retail Therapy" : start === 16 ? 'Chips and Salsa' : 'Our Intern Quit'}
                    </p>
                </div>
            </div>
            <div className='flex flex-col gap-8 -ml-8'>
                <BracketLiveEightBatch right start={start} liveBracket={liveBracket}
                                       openMatchPreviewModal={openMatchPreviewModal}
                                       viewOnly={viewOnly}
                                       userMatchups={userMatchups}
                                       loading={loading} setFocusedGameNumber={setFocusedGameNumber} focusedGameNumber={focusedGameNumber}/>
                <BracketLiveEightBatch right start={start + 4} liveBracket={liveBracket}
                                       openMatchPreviewModal={openMatchPreviewModal}
                                       viewOnly={viewOnly}
                                       userMatchups={userMatchups}
                                       loading={loading} setFocusedGameNumber={setFocusedGameNumber} focusedGameNumber={focusedGameNumber}/>
            </div>
        </div>
    );
}

export default BracketLiveRightQuarter;
