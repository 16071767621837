import StockLogo from "../atoms/StockLogo";
import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import ProfilePic from "../atoms/ProfilePic";
import LottieWrapper from "../LottieWrapper";
import crown from "../../img/lottie/crown.json";
import SvgClose from "../icons/SvgClose";
import TooltipComponent from "../atoms/TooltipComponent";

const mapStateToProps = (state) => {
    return {
        authUser: state.userReducer.authUser,
        userBracket: state.userReducer.userBracket,
        readonlyMasterBracket: state.userReducer.readonlyMasterBracket,
    }
}

function LeaderboardItem(props) {
    const you = props.authUser.displayName === props.leaderboard?.[props.index]?.username;
    const losers = Object.values(props.readonlyMasterBracket).map(m => m.winner_id === null ? null : m.ticker1 === m.winner_id ? m.ticker2 : m.ticker1).filter(e => !!e);
    const top8 = [0, 1, 2, 3, 4, 5, 6, 7].map(i => props.leaderboard[props.index][`g${i + 48}`]);
    const [eliminatedTickers, setEliminatedTickers] = useState([]);

    useEffect(() => {
        const eliminatedTickers = Object.values(props.readonlyMasterBracket).filter(m => m.winner_id !== null).map(m => m.ticker1 === m.winner_id ? m.ticker2 : m.ticker1);
        setEliminatedTickers(eliminatedTickers);
    }, [])

    const eliminated = eliminatedTickers.includes(props.leaderboard?.[props.index]?.winner)
    return (
        <Link to={`/bracket/${props.leaderboard?.[props.index]?.username}`}
              className={`text-md relative px-3 py-0.5 rounded-xl md:text-lg font-semibold w-full flex flex-row items-center gap-1 hover:bg-white/10 ${you && 'bg-yellow-400/20'}`}>
            <div className='flex-[1.1] flex flex-row items-center gap-3 truncate'>
                <div className='relative flex min-w-8 overflow-visible'>
                    <ProfilePic
                        size={'2.5'}
                        username={props.leaderboard?.[props.index]?.username || '--'}
                    />
                    <div
                        className='absolute -top-0 -left-0 bg-[#262626] border-white/80 border rounded-full text-xs md:text-xs px-1'>
                        {props.leaderboard?.[props.index]?.rank || 1 || 'NR'}
                    </div>
                </div>
                <p className={`${you && 'text-yellow-400'} text-sm md:text-lg truncate`}>
                    {props.leaderboard?.[props.index]?.username || '--'}
                </p>
            </div>
            <div className='flex-[.4]'>
                <TooltipComponent
                    ToggleComponent={
                        <div className='relative w-8 h-8'>
                            <StockLogo className={`w-8 h-8`} border
                                       tbd={you && !props.userBracket.submitted}
                                       ticker={props.leaderboard?.[props.index]?.winner}
                                       eliminated={eliminated}
                            />
                        </div>
                    }
                    hover
                    placement={'left'}
                    label={props.leaderboard?.[props.index]?.winner}
                />
            </div>
            {/*<div className='flex-[.4]'>*/}
            {/*    <p className={`${you && 'text-yellow-400'}`}>*/}
            {/*        {top8.filter(e => !losers.includes(e)).length}<span*/}
            {/*        className='text-xs opacity-50 md:text-sm'>/8</span>*/}
            {/*    </p>*/}
            {/*</div>*/}
            <div className='flex-[.4]'>
                <p className={`${you && 'text-yellow-400'}`}>
                    {props.leaderboard?.[props.index]?.wins}
                </p>
            </div>
            <div className='flex-[.25]'>
                <p className={`${you && 'text-yellow-400'}`}>
                    {props.leaderboard?.[props.index]?.score === null ? '--' : props.leaderboard?.[props.index]?.score}
                </p>
            </div>
        </Link>
    )
}

export default connect(mapStateToProps, null)(LeaderboardItem);
