import { useState, useEffect, useRef } from 'react';
import TextInputWrapper from "../atoms/TextInputWrapper";

const VerificationInput = (props) => {
    const inputRef = useRef(null);
    const [focused, setFocused] = useState(false);

    // autofocus
    useEffect(() => {
        inputRef.current?.focus();
    }, [])

    const handleFocus = () => {
        inputRef.current.focus();
        setFocused(true);
    }

    const handleBlur = () => {
        setFocused(false);
    }

    const getValue = (i) => {
        return props?.value?.[i] || "";
    }

    const isActive = (i) => {
        return focused && i === props.value?.length;
    }

    const handleInputChange = (e) => {
        const input = e.target.value;

        if (RegExp(`^[0-9]{0,${props?.length || 6}}$`).test(input)) {
            props.onChange?.(input);
        }
    }

    return (
        <div
            className="cursor-text"
            onClick={handleFocus}
        >
            <input
                ref={inputRef}
                type="tel"
                inputMode="tel"
                autoComplete="one-time-code"
                value={props.value}
                spellCheck={false}
                placeholder={props.placeholder}
                autoFocus={props?.autoFocus ? props.autoFocus : false}
                onChange={handleInputChange}
                variant="ghost"
                className={input_s}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            <div className={container_s}>
                {[...Array(6)].map((_, i) => (
                    <div
                        key={`verification-input-box-${i}`}
                        className={`
                            ${isActive(i) ? character_active_s : character_inactive_s}
                            ${character_s}
                        `}
                    >
                        <p>{getValue(i)}</p>
                    </div>
                  ))}
            </div>
        </div>
    )
}

export default VerificationInput;

// ## styles ##
// actual html input element
const input_s = "h-0 w-0 absolute start-0 bg-transparent outline-none caret-transparent text-transparent";

// container around all input boxes
const container_s = "flex relative gap-2 h-[75px] w-full";

// individual input box
const character_s = `
    flex
    text-gray-200
    text-4xl
    font-light
    h-full
    w-[50px]
    bg-white/15
    rounded-lg
    justify-center
    items-center
`;

// input box that is inactive
const character_inactive_s = "";

const character_active_s = "border-2 border-green-500"

// <VerificationInput
//     value={code}
//     onChange={onChangeCode}
//     onComplete={onChangeCode}
//     onBlur={(event) => console.log('blur', event)}
//     length={6}
//     placeholder={''}
//     autoFocus={true}
//     validChars="0-9"
//     classNames={{
//         container: "container text-3xl md:text-4xl   font-body ",
//         character: "rounded-xl border bg-white/10 h-20 py-3.5 outline-none",
//         characterInactive: "bg-white/10 border-white/10",
//         characterSelected: "bg-green-500/20 border-green-500 text-white",
//         characterFilled: "bg-white/10 border-white/10 text-white",
//     }}
//     inputProps={{ autoComplete: "one-time-code" , className:'no-yellow-autofill', inputMode: "tel", type: "tel"}}
// />
