import React from "react";
import ProfilePic from "./atoms/ProfilePic";
import StockLogo from "./atoms/StockLogo";


function LeaderboardLeader({leaderboard, rank, ...props}) {
    const badgeColor = rank === 0 ? 'bg-[#C6B200] text-black' : rank === 1 ? 'bg-[#fffce4] text-black' : 'bg-[#da935e] text-black'
    return (
        <div className={`flex flex-col flex-1 items-center ${props.className}`}>
            <div className='relative flex flex-col items-center min-w-20 overflow-visible'>
                <ProfilePic
                    size={'5'}
                    username={leaderboard?.[rank]?.username || '--'}
                />
                <div
                    className={`absolute -top-0 -left-0 ${badgeColor} border-white/80 border rounded-full text-lg md:text-xl px-2`}>
                    {leaderboard?.[rank]?.rank || '--'}
                </div>
                <div className='absolute bottom-0 -right-1'>
                    <StockLogo
                        ticker={leaderboard?.[rank]?.winner}
                        tbd={!leaderboard?.[rank]?.winner}
                        border
                        className={'w-10 h-10 border-2'}
                    />
                </div>
            </div>
            <p className={`font-body font-semibold text-sm md:text-lg truncate mt-2`}>
                {leaderboard?.[rank]?.username || '--'}
            </p>
            <p className={`font-body opacity-70 text-sm md:text-lg truncate`}>
                {leaderboard?.[rank]?.score}
            </p>
        </div>
    )
}

export default LeaderboardLeader
