import React, {useRef, useState, useEffect} from 'react';
import BracketLiveGameCardTeamRow from "./BracketLiveGameCardTeamRow";
import {connect} from "react-redux";
import StockLogo from "../atoms/StockLogo";
import SelectedPickTab from "./SelectedPickTab";
import {useLocation, useNavigate} from 'react-router-dom';
import TooltipComponent from "../atoms/TooltipComponent";
import MatchupBody from "../matchupdetails/MatchupBody";

const mapStateToProps = (state) => {
    return {
        mobile: state.userReducer.mobile,
        authUser: state.userReducer.authUser,
        userBracket: state.userReducer.userBracket,
        masterBracket: state.userReducer.masterBracket,
        readonlyMasterBracket: state.userReducer.readonlyMasterBracket,
    }
}


function BracketLiveGameCard(props) {
    const location = useLocation()
    const navigate = useNavigate();
    const matchup = !!props.liveBracket ? props.liveBracket[props.gameNumber] : {};
    const isFocused = props.focusedGameNumber === props.gameNumber
    const isValidMatchup = matchup?.ticker1 && matchup?.ticker2
    const [showPreview, setShowPreview] = useState(false)
    const disablePointerEvents = false
    const [incorrectSelections, setIncorrectSelections] = useState({});

    let mouseDelay = useRef(null);  // delay modal popup
    const handleMouseEnter = () => {
        if (!disablePointerEvents && isValidMatchup) {
            props.setFocusedGameNumber(props.gameNumber);
            mouseDelay.current = setTimeout(() => {
                setShowPreview(true)
                window.amplitude.track("Matchup Preview Opened");
                // setPrevFocus(props.focusedGameNumber)
                // props.setGameNumber(props.gameNumber);
            }, 700)
        }
    };

    const handleMouseLeave = () => {
        if (props.mobile) {
            setShowPreview(false)
        }
        if (!disablePointerEvents && !props.mobile) {
            setShowPreview(false)
            props.setFocusedGameNumber(null);
            clearTimeout(mouseDelay.current);
        }
    };
    const winner = matchup?.winner_id || null;
    const selectedToWin = location.pathname === '/' ?   props.userBracket[`g${props.gameNumber}`] : props.masterBracket?.[props.gameNumber]?.winner_id

    useEffect(() => {
        const incorrectSelections = {
            ticker1: props.liveBracket[props.gameNumber]?.ticker1 === props.userMatchups[props.gameNumber]?.ticker1 ? null : props.userMatchups[props.gameNumber]?.ticker1,
            ticker2: props.liveBracket[props.gameNumber]?.ticker2 === props.userMatchups[props.gameNumber]?.ticker2 ? null : props.userMatchups[props.gameNumber]?.ticker2,
        }
        setIncorrectSelections(incorrectSelections);
    }, [])

    const isFirstRound = props.gameNumber < 32;
    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => {
                if (isValidMatchup) {
                    if (props.mobile && props.viewOnly) {
                        props.setFocusedGameNumber(props.gameNumber);
                        props.openMatchPreviewModal({...props.masterBracket[props.gameNumber], ...matchup})
                    } else {
                        navigate(`/matchup/${props.gameNumber + 1}`);
                    }
                }
            }}
            id={`game-${props.gameNumber}`}
            className={`rounded-md relative group overflow-hidden z-[1]
                 ${props.isChampionship ? 'bg-green-500/20 outline outline-offset-2 mx-2 mt-2 outline-green-500' : 'bg-white/10'}  
                ${(isValidMatchup && !winner) && (isFocused ? `outline-2 ${props.isChampionship ? 'outline-green-400' : 'outline-yellow-400'}` : `outline outline-yellow-400/30`)}
                ${isFocused && `outline outline-2  ${props.isChampionship ? 'outline-green-400' : 'outline-yellow-400'}`}
                flex flex-col w-[160px] 
                ${isFirstRound && 'my-5'}
                cursor-pointer
                `}>
            {/*Todo: use for previewing styling on hover*/}
            <div
                className={`absolute top-0 left-0 z-0 bottom-0 ${showPreview ? 'bg-white/10' : 'bg-white/10'} ${isFocused ? 'w-full' : 'w-0'}`}
                style={{transition: 'width 1000ms ease'}}
            />
            <div
                className={`absolute top-0 right-0 z-0 bottom-0 w-full ${showPreview ? 'bg-yellow-400/20' : 'bg-yellow-400/10'} ${showPreview ? 'opacity-100' : 'opacity-0'}`}
                style={{transition: 'opacity 150ms ease'}}
            />
            <div className='absolute -top-12'/>
            {(!isFirstRound && !!props.userBracket?.submitted) &&
                <SelectedPickTab
                    userTicker={props.userMatchups[props.gameNumber]?.ticker1}
                    actualTicker={props.liveBracket[props.gameNumber]?.ticker1}
                    winner={props.liveBracket[props.gameNumber]?.winner_id}
                    userWinner={props.userBracket[`g${props.gameNumber}`] === props.userMatchups[props.gameNumber]?.ticker1 ? props.userBracket[`g${props.gameNumber}`] : null}
                />
            }
            <div className='flex flex-col flex-1 gap-2 z-[1] p-2'>
                <BracketLiveGameCardTeamRow ticker={matchup?.ticker1}
                                            gameNumber={props.gameNumber}
                                            onPick={props.onPick}
                                            winner={winner}
                                            viewOnly={props.viewOnly}
                                            loading={props.loading}
                                            selectedToWin={selectedToWin}
                                            right={props.right}
                                            userMatchups={props.userMatchups}
                                            seed={props.masterBracket?.[props.gameNumber]?.[matchup?.ticker1]?.seed}
                                            stockNum={1}
                />
                <BracketLiveGameCardTeamRow ticker={matchup?.ticker2}
                                            gameNumber={props.gameNumber}
                                            onPick={props.onPick}
                                            disablePointerEvents={disablePointerEvents}
                                            winner={winner}
                                            viewOnly={props.viewOnly}
                                            loading={props.loading}
                                            selectedToWin={selectedToWin}
                                            right={props.right}
                                            userMatchups={props.userMatchups}
                                            seed={props.masterBracket?.[props.gameNumber]?.[matchup?.ticker2]?.seed}
                                            stockNum={2}
                />
            </div>
            {(!isFirstRound && !!props.userBracket?.submitted) &&
                <SelectedPickTab
                    userTicker={props.userMatchups[props.gameNumber]?.ticker2}
                    actualTicker={props.liveBracket[props.gameNumber]?.ticker2}
                    winner={props.liveBracket[props.gameNumber]?.winner_id}
                    userWinner={props.userBracket[`g${props.gameNumber}`] === props.userMatchups[props.gameNumber]?.ticker2 ? props.userBracket[`g${props.gameNumber}`] : null}
                />
            }

            {/*Todo: Componentize preview with the other game card to show when hovering*/}
            <div className='relative z-30 bg-black'>
                {(isValidMatchup && !props.mobile) &&
                    <TooltipComponent
                        ToggleComponent={<div/>}
                        toggleClassName={''}
                        ContentComponent={
                            <div className={props.right ? 'pr-3' : 'pl-3'}>
                                <div
                                    className='w-[600px] relative z-10 my-4 p-3 rounded-3xl border border-white/20 overflow-x-hidden overflow-y-scroll max-h-[80dvh] bg-black text-white flex flex-col gap-8 pb-5'
                                >
                                    <div className='absolute backdrop-blur-lg top-0 left-0 right-0 bottom-0 z-[-1]'
                                         style={{background: `linear-gradient(-180deg, ${matchup?.[matchup?.ticker1]?.color + '22'}, ${matchup?.[matchup?.ticker2]?.color + '22'}, black)`}}>

                                    </div>
                                    {isFocused ?
                                        <MatchupBody
                                            gameNumber={props?.gameNumber}
                                            onPick={props.onPick}
                                            setShowPreview={setShowPreview}
                                            isPreview={true}
                                            viewOnly={props.viewOnly}
                                            preloadedMatchup={{...props.readonlyMasterBracket[props.gameNumber], ...matchup}}
                                        />
                                        : null
                                    }
                                </div>
                            </div>
                        }
                        offsetX={200}
                        offsetY={(!!props.right) ? -16 : -16}
                        placement={(!!props.right) ? 'right' : 'right'}
                        disabled={false}
                        visible={showPreview}
                        delayShow={5000}
                    />
                }
            </div>
        </div>
    );
}

export default connect(mapStateToProps, null)(BracketLiveGameCard);
