import React from 'react';
import {sectionClass} from "../../consts/dummy";
import {Button} from "../atoms/Button";
import eyes from '../../img/stickers/SM_EYES EMOJI.png'
import StockLogo from "../atoms/StockLogo";
import SvgSlash from "../icons/custom/SvgSlash";
import verified from "../../img/stickers/verified-sticker.png";

function CrowdFavoriteSection(props) {
    const stock1 = props.matchup?.[props.matchup?.ticker1];
    const stock2 = props.matchup?.[props.matchup?.ticker2];
    const picks1 = Math.max(1, stock1?.preloaded_details?.picks?.[props.matchup?.round - 1]);
    const picks2 = Math.max(1, stock2?.preloaded_details?.picks?.[props.matchup?.round - 1]);
    const stock = picks1 >= picks2 ? stock1 : stock2;
    const picks = picks1 >= picks2 ? picks1 : picks2;
    const pickPercent = Math.round((picks / (picks1 + picks2)) * 100, 2);

    return (
        <div className={`${sectionClass} relative`}>
            <img alt={'eyes'} src={eyes} className={'absolute w-20 top-24 md:top-8 right-1 rotate-6'}/>
            <div className='flex flex-col items-center w-full gap-3'>
                <p className='text-2xl md:text-4xl text-center uppercase w-full'>
                    Crowd Favorite
                </p>
                <p className={'font-body text-lg font-medium md:w-2/3 text-center'}>
                    {pickPercent}% of people have <span className={'font-semibold'}
                                             style={{color: stock?.color}}>{stock?.preloaded_details?.ticker}</span> winning this
                    game.
                </p>
            </div>
            <div className='flex flex-col items-center w-full gap-3'>
                <div className='flex flex-col w-[120px] md:w-[180px]  flex-1 gap-2 items-center'>
                    <StockLogo ticker={stock?.preloaded_details?.ticker} className={'w-16 h-16 md:w-24 md:h-24'} border/>
                    <div className='text-center flex flex-col flex-1 truncate'>
                        <div className='flex flex-row items-center justify-center gap-1 ml-2'>
                            <p className='text-4xl font-bold'>
                                {stock?.preloaded_details?.ticker}
                            </p>
                            <SvgSlash className={`h-[1.9rem] w-[10px] -mt-0.5`} style={{color: stock?.color}}/>
                        </div>
                        <div className='text-sm font-body opacity-70 truncate'>
                            {stock?.name}
                        </div>
                    </div>
                </div>
                <div className='h-4 rounded-full w-full relative' style={{backgroundColor: stock?.color + '30'}}>
                    <div className='h-4 rounded-full relative'
                         style={{width: `${pickPercent}%`, backgroundColor: stock?.color}}>
                    </div>
                    <div className={`shake-element absolute -top-0.5`} style={{left: `${pickPercent}%`}}>
                        <div className=''>
                            <img alt={'rocket'} src={verified} style={{width: 20}} className={'scale-[2] md:scale-[2.8] -ml-1'}/>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='flex flex-col w-full'>
                <Button variant={'secondary'} onClick={null}>
                    Share
                </Button>
            </div> */}
        </div>
    );
}

export default CrowdFavoriteSection;
