import React, {useEffect, useState} from "react";
import SM_AIR_JORDAN from '../img/stickers/SM_AIR JORDAN.png';
import SM_BEAR from '../img/stickers/SM_BEAR.png';
import SM_BULL from '../img/stickers/SM_BULL.png';
import SM_CASINO from '../img/stickers/SM_CASINO.png';
import SM_DIAMOND_HANDS from '../img/stickers/SM_DIAMOND HANDS.png';
import SM_FLYINGMONEY from '../img/stickers/SM_FLYINGMONEY.png';
import SM_HODL from '../img/stickers/SM_HODL.png';
import SM_LUCKYCAT from '../img/stickers/SM_LUCKYCAT.png';
import SM_PRAYER from '../img/stickers/SM_PRAYER.png';
import SM_RIP from '../img/stickers/SM_RIP.png';
import SM_ROCKETSHIP from '../img/stickers/SM_ROCKETSHIP.png';
import SM_SHIT from '../img/stickers/SM_SHIT.png';
import SM_SKULL from '../img/stickers/SM_SKULL.png';
import SM_TROPHY from '../img/stickers/SM_TROPHY.png';
import SM_777 from '../img/stickers/SM_777.png';
import SM_69 from '../img/stickers/SM_69.png';
import SM_EX from '../img/stickers/SM_EX.png';
import SM_420 from '../img/stickers/SM_420.png';

const emojiList = [
    SM_AIR_JORDAN,
    SM_BEAR,
    SM_BULL,
    SM_CASINO,
    SM_DIAMOND_HANDS,
    SM_FLYINGMONEY,
    SM_HODL,
    SM_LUCKYCAT,
    SM_PRAYER,
    SM_RIP,
    SM_ROCKETSHIP,
    SM_SHIT,
    SM_SKULL,
    SM_TROPHY,
    SM_69,
    SM_777,
    SM_EX,
    SM_420,
];

function LoadingSection(props) {
    const [emoji, setEmoji] = useState(null)


    useEffect(() => {
        setEmoji(getRandomEmoji())
    }, [props.trigger]);


    const getRandomEmoji = () => {
        const emojis = Object.values(emojiList); // Convert emoji object to array
        const randomIndex = Math.floor(Math.random() * emojis.length);
        return emojis[randomIndex];
    };


    return (
        <img src={emoji} alt={'loader'} className={`w-12 ${props.className} `}/>
    )
}

export default LoadingSection
