import React, {useEffect, useRef, useState} from 'react';
import Spinner from "../atoms/Spinner";

function BracketLoadingStateOverlay(props) {
    const ref = useRef(null);
    const [topPosition, setTopPosition] = useState('50vh');
    const [ellipsis, setEllipsis] = useState('');

    const animateEllipsis = () => {
        const ellipsisStates = ['', '.', '..', '...'];
        setEllipsis(prevState => {
            const currentIndex = ellipsisStates.indexOf(prevState);
            const nextIndex = (currentIndex + 1) % ellipsisStates.length;
            return ellipsisStates[nextIndex];
        });
    };

    useEffect(() => {
        const intervalId = setInterval(animateEllipsis, 500);
        return () => clearInterval(intervalId);
    }, []);


    const updateTopPosition = () => {
        if (ref.current) {
            const componentHeight = ref.current.clientHeight + (!props.mobile && 24);
            const viewportHeight = window.innerHeight;
            const topValue = `${(viewportHeight / 2) - componentHeight}px`;
            setTopPosition(topValue);
        }
    };

    useEffect(() => {
        updateTopPosition();
        window.addEventListener('resize', updateTopPosition);
        return () => {
            window.removeEventListener('resize', updateTopPosition);
        };
    }, []);

    return (
        <div
            ref={ref}
            className={'fixed z-[2] left-0 right-0 flex flex-col text-center items-center gap-3'}
            style={{top: props.mobile ? topPosition : topPosition,}}
        >
            <p className='text-sm opacity-50 font-body font-semibold'>
                Loading bracket{ellipsis}
            </p>
            <Spinner/>
        </div>
    )
}

export default BracketLoadingStateOverlay;
