import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={29}
            height={33}
            viewBox="0 0 29 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_142_48)">
                <path
                    d="M14.506 9.342a7.17 7.17 0 00-7.181 7.182 7.17 7.17 0 007.181 7.181 7.17 7.17 0 007.181-7.181 7.17 7.17 0 00-7.18-7.182zm0 11.85a4.677 4.677 0 01-4.668-4.668 4.673 4.673 0 014.668-4.669 4.673 4.673 0 014.669 4.669c0 2.575-2.1 4.668-4.669 4.668zm9.15-12.143c0 .931-.75 1.675-1.675 1.675a1.675 1.675 0 111.675-1.675zm4.757 1.7c-.107-2.244-.62-4.232-2.263-5.869-1.637-1.638-3.625-2.15-5.869-2.263-2.312-.13-9.243-.13-11.556 0-2.237.107-4.225.62-5.869 2.257C1.213 6.51.706 8.499.594 10.743c-.131 2.312-.131 9.243 0 11.556.106 2.244.619 4.231 2.262 5.869 1.644 1.637 3.625 2.15 5.869 2.262 2.313.131 9.244.131 11.556 0 2.244-.106 4.232-.619 5.869-2.262 1.638-1.638 2.15-3.625 2.263-5.87.13-2.312.13-9.237 0-11.55zM25.425 24.78a4.727 4.727 0 01-2.662 2.662c-1.844.732-6.22.563-8.257.563s-6.418.163-8.256-.563a4.727 4.727 0 01-2.662-2.662c-.732-1.844-.563-6.219-.563-8.256 0-2.038-.162-6.419.563-8.257A4.727 4.727 0 016.25 5.605c1.844-.731 6.219-.563 8.256-.563 2.038 0 6.42-.162 8.257.563a4.726 4.726 0 012.662 2.662c.731 1.844.563 6.22.563 8.257s.168 6.418-.563 8.256z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_142_48">
                    <path fill="currentColor" transform="translate(.5 .53)" d="M0 0H28V32H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default SvgComponent
