import React from 'react';
import ModalComponent from "../atoms/ModalComponent";
import {Button} from "../atoms/Button";
import {useNavigate} from "react-router-dom";

function ConfirmationModal(props) {
    const navigate = useNavigate()
    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            toggle={props.close}
        >
            <div className='flex flex-col gap-3 '>
                <p className='text-3xl uppercase'>
                    {props.title}
                </p>
                <p className={'font-body text-lg font-medium mb-5'}>
                    {props.description}
                </p>
                {props.onConfirm ?
                    <Button onClick={() => {
                        props.onConfirm()
                        props.close()
                    }} variant={'destructive'}>
                        {props.confirmText || 'Confirm'}
                    </Button>
                    :
                    <Button onClick={() => {
                        props.close()
                        navigate('/hub')
                    }} variant={'destructive'}>
                        Exit
                    </Button>
                }
                <Button onClick={props.close} variant={'secondary'}>
                    Cancel
                </Button>
            </div>
        </ModalComponent>
    );
}

export default ConfirmationModal;
