import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {pageClass} from "../consts/dummy";
import LeaderboardWidget from "../sections/LeaderboardWidget";
import {getLeaderboard} from '../api/brackets';
import PageHeader from "../components/navigation/PageHeader";
import AnimatedTabs from "../components/atoms/AnimatedTabs";
import LeaderboardLeader from "../components/LeaderboardLeader";
import LoadingSection from "../components/atoms/LoadingSection";
import LottieWrapper from "../components/LottieWrapper";
import fireworks from "../img/lottie/fireworks.json";
import shine from "../img/lottie/shine.json";

function mapStateToProps(state) {
    return {
        mobile: state.userReducer.mobile,
        authUser: state.userReducer.authUser,
        userBracket: state.userReducer.userBracket,
    };
}

function LeaderboardPage(props) {
    const [leaderboard, setLeaderboard] = useState({});
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('1');
    const [paginated] = useState(true);

    // analytics
    useEffect(() => {
        window.amplitude.track("Leaderboard Page Viewed");
    }, [])

    useEffect(() => {
        if (!props.userBracket?.username)
            return
        getLeaderboard(props.authUser, props.userBracket?.username)
            .then(async (res) => {
                setLeaderboard(res);
                setLoading(false);
            })
    }, [props.userBracket?.username])

    return loading ?
        <LoadingSection type={'leaderboard'}/>
        :
        <div className={pageClass}>
            <PageHeader
                title={'LEADERBOARD'}
                desc={'Scores updated after each gameday.'}
            />
            <div className='flex flex-row justify-center gap-5 w-full md:px-12 -mt-8 relative'>
                <LottieWrapper json={fireworks} height={200} width={340} className={'absolute z-[0] -top-20'}/>
                <LottieWrapper json={shine}
                               className={'w-[200px] md:w-[340px] absolute z-[0] -top-16 md:-top-32 opacity-10 scale-150'}/>
                <LeaderboardLeader leaderboard={leaderboard.top} rank={1} className={'mt-4'}/>
                <div className='relative'>
                    <LeaderboardLeader leaderboard={leaderboard.top} rank={0}/>
                </div>
                <LeaderboardLeader leaderboard={leaderboard.top} rank={2} className={'mt-4'}/>
            </div>
            <div className='relative w-full -mb-16 md:-mb-10'>
                <img alt={'podiums'} src={require('../img/podiums.png')}
                     className={'absolute -top-44 z-[-1] opacity-30'}/>
            </div>
            {/*Todo: same widget as hub, should be truncated leaderboard around you*/}
            <LeaderboardWidget
                label={'Your spot'}
                leaderboard={leaderboard}
                userBracket={props.userBracket}
                hideButton
                truncated
                local
            />
            {/*Todo: bigger leaderboard with pagination*/}
            <div className='w-full flex flex-col gap-3'>
                <LeaderboardWidget
                    label={'Top 200'}
                    description={`${leaderboard?.total} total brackets`}
                    leaderboard={leaderboard}
                    tab={activeTab}
                    hideButton
                    hideBanner
                    paginated={paginated}
                />
                {paginated &&
                    <AnimatedTabs
                        activeTab={activeTab}
                        changeTab={setActiveTab}
                        tabs={[...Array(Math.ceil(leaderboard.top.length / 20)).keys()].map((index) => {
                            return {title: (index + 1).toString()}
                        })}
                    />
                }
            </div>
        </div>
}

export default connect(mapStateToProps, null)(LeaderboardPage);
