import './App.css';
import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from "react-redux";
import { datadogRum } from '@datadog/browser-rum';
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE_API_KEY } from './api/axios';

import UserProvider, {UserContext} from "./providers/UserProvider";
import Store from "./Store";
import Routes from "./Routes";

// init data dog: https://docs.datadoghq.com/logs/log_collection/javascript/
const DD_CLIENT_TOKEN = process.env.REACT_APP_DD_CLIENT_TOKEN;
const DD_APP_ID = process.env.REACT_APP_DD_APP_ID;
datadogRum.init({
    applicationId: DD_APP_ID,
    clientToken: DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'stonkmadness',
    env: 'dev',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    // Connect to backend traces
    allowedTracingUrls: ["https://afterhour-63b2b.wl.r.appspot.com", "https://dev-dot-afterhour-63b2b.wl.r.appspot.com"],
});

// init amplitude
amplitude.init(AMPLITUDE_API_KEY, {
    defaultTracking: false,
});
window.amplitude = amplitude;

function App() {
    return (
        <UserProvider>
            <Router>
                <Provider store={Store}>
                    <UserContext.Consumer>
                        {(user) => (
                            <div className={'relative'}>
                                <Routes user={user}/>
                                <div className='grainy-effect'/>
                            </div>
                        )}
                    </UserContext.Consumer>
                </Provider>
            </Router>
        </UserProvider>
    );
}

export default App;
