import React from 'react';
import AfterHourWordmark from "../components/brand/logos/AfterHourWordmark";
import lambo from '../img/lambo.png';
import rays from '../img/hero/rays.png';
import {Link} from 'react-router-dom';
import Lottie from "react-lottie";
import animationData from "../img/hero/bracket-main.json";
import Marquee from "react-fast-marquee";
import {WEBSITE_LINK} from "../consts/dummy";
import PerfectRequirementsCard from "../components/PerfectRequirementsCard";

function HeroSection() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };


    return (
        <div className='flex flex-col gap-0 items-center -mx-4 max-w-[100vw] overflow-hidden md:overflow-visible'>
            {/*<StonkMadnessLogo className={'h-[130px]'}/>*/}
            <div className=' mt-8 md:mt-12 '>
                <p className='text-4xl md:text-6xl z-[2] text-center uppercase'>
                    Pick the right stocks and <span className='text-green-500'>win prizes.</span>
                </p>
            </div>
            <div className='flex flex-col mt-3 items-center w-full -rotate-6'>
                <div className='w-full max-w-[300px] md:max-w-[500px]'>
                    <Lottie options={defaultOptions} width={"100%"}/>
                </div>
            </div>
            <div
                className='flex flex-col max-w-[100vw] md:max-w-[620px] relative mt-10 md:mt-16 z-[0] py-1 bg-yellow-400/30'>
                <div className='w-16 absolute left-0 top-0 bottom-0 z-[2]'
                     style={{background: 'linear-gradient(to right, black, #00000000)'}}
                />
                <div className='w-16 absolute right-0 top-0 bottom-0 z-[2]'
                     style={{background: 'linear-gradient(to left, black, #00000000)'}}
                />
                <Marquee
                    className='overflow-hidden'>
                    <p className='text-xl md:text-3xl text-yellow-400 text-center uppercase mx-3'>
                        FREE TO PLAY!
                    </p>
                    <div className='flex flex-row items-center mx-3'>
                        <p className='text-xs uppercase opacity-50 mr-2'>
                            Presented by
                        </p>
                        <a href={WEBSITE_LINK} target="_blank" rel={'noreferrer'}>
                            <AfterHourWordmark className={'w-24 h-8'}/>
                        </a>
                    </div>
                    <p className='text-xl md:text-3xl text-yellow-400 text-center uppercase mx-3'>
                        FREE TO PLAY!
                    </p>
                    <div className='flex flex-row items-center mx-3'>
                        <p className='text-xs uppercase opacity-50 mr-2'>
                            Presented by
                        </p>
                        <a href={WEBSITE_LINK} target="_blank" rel={'noreferrer'}>
                            <AfterHourWordmark className={'w-24 h-8'}/>
                        </a>
                    </div>
                </Marquee>
            </div>
            {/*<img alt={'bracket'} src={bracket} className='w-5/6 max-w-[600px] z-[2] -mr-12 -rotate-6'/>*/}
            <Link to={'/prizes'}>
                <div className='relative flex flex-col items-center mt-2 md:mt-5 z-[3]'>
                    <img alt={'rays'} src={rays}
                         className='w-3/4 opacity-10 scale-[2] pointer-events-none -top-20 block blur-[6px] md:hidden absolute z-[3]'/>
                    <img alt={'rays2'} src={rays}
                         className='w-3/4 opacity-10 scale-[5] pointer-events-none -top-72 hidden blur-[6px] md:block absolute z-[3]'/>
                    <img alt={'lambo'} src={lambo} className='w-3/4'/>
                </div>
            </Link>
            <PerfectRequirementsCard/>
        </div>
    );
}

export default HeroSection;
