import { useState, useEffect } from 'react';
import {DefaultProfilePic} from "./DefaultProfilePic";
import {connect} from "react-redux";
import {getAfterhourUser} from '../../api/auth';
import Spinner from './Spinner';

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser
    };
}

function ProfilePic({size, username, className, ...props}) {
    const [backgroundColor, setBackgroundColor] = useState(null);
    const [emoji, setEmoji] = useState(null);
    const [profilePhotoUrl, setProfilePhotoUrl] = useState(null);
    const [loading, setLoading] = useState(false);

    const isOwnProfile = props.authUser?.displayName === username
    const hasProfilePicture = !!profilePhotoUrl;

    return loading ?
        <div className='bg-white/10 rounded-full flex flex-col items-center justify-center '     style={{width: `${size}rem`, height: `${size}rem`, minWidth: `${size}rem`, minHeight: `${size}rem`}}>
            <Spinner />
        </div>
        :
        hasProfilePicture ?
        <img src={profilePhotoUrl}
            style={{width: `${size}rem`, height: `${size}rem`, minWidth: `${size}rem`, minHeight: `${size}rem`}}
            className={`float-left shrink-0 rounded-full text-center aspect-square rounded-full overflow-hidden border-2 border-white/50`}
        />
        :
        (
        <DefaultProfilePic size={size} backgroundColor={backgroundColor} emoji={emoji} className={className}
                           username={username}/>
    )
}

export default connect(mapStateToProps, null)(ProfilePic);
