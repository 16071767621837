import React from 'react';
import SvgFacebook from "../icons/social/SvgFacebook";
import SvgInstagram from "../icons/social/SvgInstagram";
import SvgTikTok from "../icons/social/SvgTikTok";
import SvgX from "../icons/social/SvgX";
import SocialButton from "../atoms/SocialButton";
import SvgMail from "../icons/SvgMail";
import SvgArrowOutward from "../icons/SvgArrowOutward";
import SvgDiscord from "../icons/social/SvgDiscord";
import {WEBSITE_LINK} from '../../consts/dummy';

function FindUsOnSocials(props) {



    return (
        <div className={`flex flex-col gap-8 items-center`}>
            <div className='flex flex-col gap-3 items-center'>
                {/*<p className='font-body font-medium text-md'>*/}
                {/*    Find us on:*/}
                {/*</p>*/}
                <div className='flex flex-row items-center gap-3'>
                    <SocialButton link={'https://twitter.com/afterhour_hq'}>
                        <SvgX className={'w-6 h-6'}/>
                    </SocialButton>
                    <SocialButton link={'https://discord.gg/afterhour-lounge-970246105319239690'}>
                        <SvgDiscord className={'w-6 h-6'}/>
                    </SocialButton>
                    <SocialButton link={'https://www.instagram.com/afterhourhq'}>
                        <SvgInstagram className={'w-6 h-6'}/>
                    </SocialButton>
                    <SocialButton link={'https://www.tiktok.com/@afterhourhq'}>
                        <SvgTikTok className={'w-6 h-6'}/>
                    </SocialButton>
                </div>
                <p className='uppercase mt-2 font-medium text-md'>
                    Download our app <a href={WEBSITE_LINK} target="_blank"
                                        className='text-purple-400 opacity-100 cursor-pointer hover:text-purple-500'>HERE<SvgArrowOutward
                    className={'w-5 h-5 inline mb-0.5'}/></a>
                </p>
            </div>
            <div className='flex flex-row justify-center gap-2 items-center opacity-50'>
                <SvgMail className={'w-4 h-4'}/>
                <div className='text-xs uppercase mt-[1px]'>
                    <a href='mailto:stonkmadness@afterhour.com'>stonkmadness@afterhour.com</a>
                </div>
            </div>
        </div>
    );
}

export default FindUsOnSocials;
