import React from "react";
import LottieWrapper from "../LottieWrapper";
import hand from "../../img/lottie/hand.json";

const PickButtonsHand = () => {
    return (
        <div className='hand-animation pointer-events-none absolute -top-6 rotate-[-80deg] z-[10]'>
            <LottieWrapper json={hand} width={80}/>
        </div>
    )
}

export default PickButtonsHand
