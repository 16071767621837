import React from 'react';
import Spinner from "./Spinner";
import SvgClose from "../icons/SvgClose";

function BracketGameCardTeamRow(props) {
    if (props.loading) {
        return (
            <div
                className={`${props.className || 'w-6 md:w-7 h-6 md:h-7'} bg-white/20 rounded-full flex flex-col items-center justify-center ${props.border && 'border border-white/20'}`}>
                <Spinner size={20}/>
            </div>
        )
    }
    if (props.tbd) {
        return (
            <div
                className={`${props.className || 'w-6 md:w-7 h-6 md:h-7'} bg-white/20 rounded-full ${props.border && 'border border-white/20'}`}/>
        )
    }
    return (
        <div className={`${props.className} border-none relative`}>
            {props.eliminated &&
                <div
                    className='absolute top-0 left-0 opacity-50 right-0 bottom-0 flex flex-col items-center justify-center'>
                    <SvgClose width={20} height={20}/>
                </div>
            }
            <img
                src={`${process.env.PUBLIC_URL}/logos/${props.ticker}.png`}
                alt={props.ticker + 'logo'}
                className={`${props.className || 'w-6 md:w-7 h-6 md:h-7'} ${props.eliminated && "opacity-30"} bg-white/20 rounded-full ${props.border && 'border border-white/20'}`}/>
        </div>
    );
}

export default BracketGameCardTeamRow;
