import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {pageClass} from "../consts/dummy";
import MyBracketSummary from "../sections/MyBracketSummary";
import MatchupsTable from "../sections/MatchupsTable";
import LeaderboardWidget from "../sections/LeaderboardWidget";
import {getLeaderboard} from '../api/brackets';
import AfterHourWordmark from "../components/brand/logos/AfterHourWordmark";
import SvgEdit from "../components/icons/SvgEdit";
import AfterHourModal from "../components/modals/AfterHourModal";
import ProfilePic from '../components/atoms/ProfilePic';
import trophy from "../img/stickers/SM_TROPHY.png";
import ShareSelectionModal from '../components/modals/ShareSelectionModal';
import JoinTheConvoWidget from "../components/JoinTheConvoWidget";
import {getColorFromTicker} from "../components/utils/helpers";
import SvgArrowOutward from "../components/icons/SvgArrowOutward";
import SvgArrowRight from "../components/icons/SvgArrowRight";
import BracketPointsBreakdownModal from "../components/modals/BracketPointsBreakdownModal";
import bracketPointsBreakdownModal from "../components/modals/BracketPointsBreakdownModal";

function mapStateToProps(state) {
    return {
        mobile: state.userReducer.mobile,
        userBracket: state.userReducer.userBracket,
        masterBracket: state.userReducer.masterBracket,
        readonlyMasterBracket: state.userReducer.readonlyMasterBracket,
        authUser: state.userReducer.authUser,
        afterhourUsers: state.userReducer.afterhourUsers,
        leaderboard: state.userReducer.leaderboard,
        user: state.userReducer.user,
    };
}

function HubPage(props) {
    const [leaderboard, setLeaderboard] = useState({});
    const [leaderboardLoading, setLeaderboardLoading] = useState(true);
    const [afterHourModalOpen, setAfterHourModalOpen] = useState(false);
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const [winnerColor, setWinnerColor] = useState('');
    const [eliminatedTickers, setEliminatedTickers] = useState([]);
    const [bracketPointsBreakdownModalOpen, setBracketPointsBreakdownModalOpen] = useState(false)

    const openAfterHourModal = () => {
        setAfterHourModalOpen(true)
    }
    const closeAfterHourModal = () => {
        setAfterHourModalOpen(false)
    }

    const openShareModal = () => {
        setShareModalOpen(true)
    }

    const closeShareModal = () => {
        setShareModalOpen(false)
    }

    const openBracketPointsBreakdownModal = () => {
        setBracketPointsBreakdownModalOpen(true)
    }

    const closeBracketPointsBreakdownModal = () => {
        setBracketPointsBreakdownModalOpen(false)
    }


    const leaderboardLock = useRef(false);
    useEffect(() => {
        if (leaderboardLock.current || !!props.leaderboard?.top)
            return;
        leaderboardLock.current = true;

        getLeaderboard(props.authUser, props.userBracket?.username)
            .then(async (res) => {
                setLeaderboard(res);
                setLeaderboardLoading(false);
            })
    }, [props.leaderboard?.top])

    useEffect(() => {
        if (props.userBracket?.g62) {
            setWinnerColor(getColorFromTicker(props.userBracket['g62']));
        }
    }, [props.userBracket]);

    useEffect(() => {
        const eliminatedTickers = Object.values(props.readonlyMasterBracket).filter(m => m.winner_id !== null).map(m => m.ticker1 === m.winner_id ? m.ticker2 : m.ticker1);
        setEliminatedTickers(eliminatedTickers);
    }, [])

    return (
        <>
            <div className={pageClass}>
                {!!winnerColor &&
                    <div className='absolute top-0 left-0 right-0 h-[100dvh] z-[-1]'
                         style={{background: `linear-gradient(to bottom, ${winnerColor + '40'}, ${winnerColor + '25'}, black)`}}
                    />
                }
                <div className='flex flex-row pt-12 items-center z-[1] font-body w-full justify-between -mb-8'>
                    <div className={'flex flex-row items-center gap-2'}>
                        <ProfilePic size={3} username={props.authUser?.displayName}/>
                        <div>
                            <p className='font-semibold text-xl -mb-1'>
                                @{props.authUser?.displayName}
                            </p>
                            <p className='inline-flex text-xs'>
                                on <AfterHourWordmark className={'w-14 ml-1 h-[15px]'}/>
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-row items-center gap-5'>
                        <button onClick={openAfterHourModal}>
                            <SvgEdit className={'text-white/70 hover:text-white cursor-pointer'}/>
                        </button>
                    </div>
                </div>
                <div className='flex flex-col z-[1] w-full items-center justify-center -mb-4'>
                    <p className='text-4xl'>
                        MY BRACKET
                    </p>
                    {/*Todo: add back in*/}
                    {!!props.userBracket?.submitted &&
                        <>
                            <div
                                // onClick={openBracketPointsBreakdownModal}
                                // hover:text-yellow-500 cursor-pointer
                                className='flex flex-row items-center text-yellow-400

                         gap-0'>
                                <p className='text-6xl mt-2'>
                                    {props.userBracket?.score || 0}<span className='text-5xl'>pts</span>
                                </p>
                                {/*<SvgArrowRight className={'mt-3'}/>*/}
                            </div>
                            <p className='font-body font-medium opacity-70'>
                                Top {((props.userBracket.rank / props.leaderboard?.total) * 100).toFixed(2)}% of all
                                brackets
                            </p>
                        </>
                    }
                </div>
                <MyBracketSummary
                    authUser={props.authUser}
                    userBracket={props.userBracket}
                    loading={!props.masterBracket}
                    mobile={props.mobile}
                    eliminatedTickers={eliminatedTickers}
                />
                <div className='w-full relative'>
                    <div className='absolute right-0 md:-right-6 md:-top-16'>
                        <div className='-rotate-[-20deg]'>
                            <img alt={'rocket'} src={trophy} className={'w-16 md:w-36 shake-animation'}/>
                        </div>
                    </div>
                    <LeaderboardWidget
                        leaderboard={props.leaderboard}
                        loading={leaderboardLoading}
                        description={`${props.leaderboard?.total} brackets`}
                        truncated
                        userBracket={props.userBracket}
                        truncatedTo={10}
                        openShareModal={openShareModal}/>
                </div>
                <MatchupsTable masterBracket={props.readonlyMasterBracket}/>
                <div className='bg-purple-500/10 w-full md:w-fit rounded-2xl p-1'>
                    <JoinTheConvoWidget/>
                </div>
                <AfterHourModal
                    isOpen={afterHourModalOpen}
                    title={'Edit your profile on AfterHour'}
                    description={"Stonk Madness uses AfterHour profiles. Visit your profile in app to make edits! You'll see changes here."}
                    buttonTitle={'Go to app'}
                    close={closeAfterHourModal}
                />
                <ShareSelectionModal isOpen={shareModalOpen} close={closeShareModal} username={props.user.username}
                                     champion={props.userBracket?.g62}/>
            </div>
            <BracketPointsBreakdownModal
                isOpen={bracketPointsBreakdownModalOpen}
                close={closeBracketPointsBreakdownModal}
            />
        </>
    )
}

export default connect(mapStateToProps, null)(HubPage);
