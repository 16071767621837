import React from 'react';
import {sectionClass} from "../consts/dummy";
import rocket from "../img/stickers/SM_FLYINGMONEY.png";
import {Button} from "../components/atoms/Button";
import {Link} from "react-router-dom";

function BracketsOpenSoonSection(props) {
    // const formatter = Intl.NumberFormat('en', {notation: 'compact'});
    if (true) {
        return (
            <div className={`${sectionClass}`}>
                <div className='flex flex-col gap-3 w-full relative'>
                    <div className='absolute right-0 md:right-6 -top-8 md:-top-12'>
                        <div className='-rotate-[40deg]'>
                            <img alt={'rocket'} src={rocket} className={'w-28 md:w-36'}/>
                        </div>
                    </div>
                    <p className='text-4xl'>
                        GAMES ARE NOW<br/><span className='text-green-500'> LIVE!</span>
                    </p>
                    <p className={'font-body text-lg font-medium'}>
                        Tune in to watch the madness unfold. <span
                        className='text-green-500 font-semibold'> 64 stocks</span>, one winner to rule them all. Check
                        the full schedule for more details.
                    </p>
                </div>
                <div className='flex flex-col w-full items-center gap-2'>
                    <Link to={'/'} className='w-full'>
                        <Button variant={'green'} className='w-full'>
                            Watch Live
                        </Button>
                    </Link>
                </div>
            </div>
        )
    }
    return (
        <div className={`${sectionClass}`}>
            <div className='flex flex-col gap-3 w-full relative'>
                <div className='absolute right-0 md:right-6 -top-8 md:-top-12'>
                    <div className='-rotate-[40deg]'>
                        <img alt={'rocket'} src={rocket} className={'w-28 md:w-36'}/>
                    </div>
                </div>
                <p className='text-4xl'>
                    BRACKET ENTRY<br/><span className='text-green-500'> NOW OPEN!</span>
                </p>
                <p className={'font-body text-lg font-medium'}>
                    Bracket creation has kicked off and will be open for <span
                    className='text-green-500 font-semibold'> just  one week</span> Selections will be
                    locked before the first games start March 19th and the tournament begins.
                </p>
            </div>
            {/*<div className='flex flex-col w-full items-center gap-2'>*/}
            {/*    <Button variant={'green'} className='w-full' onClick={props.toggleWaitlistModal}>*/}
            {/*        Get notified*/}
            {/*    </Button>*/}
            {/*    <p className='font-body text-sm  text-green-500'>*/}
            {/*        {formatter.format(props.waitlistCount)} already on waitlist*/}
            {/*    </p>*/}
            {/*</div>*/}
        </div>
    );
}

export default BracketsOpenSoonSection;
