import {useState, useEffect} from 'react';
import StockLogo from "./atoms/StockLogo";
import TooltipComponent from "./atoms/TooltipComponent";

function MatchupVersusSection(props) {
    const matchup = props?.preloadedMatchup || props.matchup;


    return props.loading ?
        (
            <div className='w-full h-2 flex flex-row rounded-full bg-white/10 overflow-visible mt-2 md:mt-5'>
            </div>
        )
        :
        (
            <TooltipComponent
                ToggleComponent={
                    <div className='hover:bg-white/5 px-4 rounded-lg -mt-5 md:mx-20'>
                        <p className='uppercase text-xs md:text-md mx-auto opacity-70 w-full text-center mb-5'>
                            Stuey's Win Probability
                        </p>
                        <div className='w-full flex flex-row rounded-full overflow-visible'>
                            <div className='h-2 text-end relative px-2 rounded-l-full' style={{
                                backgroundColor: matchup?.[matchup?.ticker1]?.color + (props.stock1WinPercent > props.stock2WinPercent ? 'A0' : '80'),
                                width: (props.stock1WinPercent || 50) + '%',
                                transition: 'width 300ms ease'
                            }}>
                            </div>
                            <div className='h-2  px-1 rounded-r-full relative' style={{
                                backgroundColor: matchup?.[matchup?.ticker2]?.color + (props.stock2WinPercent > props.stock1WinPercent ? 'A0' : '80'),
                                width: (props.stock2WinPercent || 50) + '%',
                                transition: 'width 300ms ease'
                            }}>
                               <div className='absolute '>
                                   <StockLogo
                                       loading={!props.stock2WinPercent || !props.stock1WinPercent}
                                       ticker={props.stock1WinPercent > props.stock2WinPercent ? matchup.ticker1 : matchup.ticker2}
                                       className={'w-7 min-w-7 md:min-w-6 h-7 min-h-7 md:min-h-6 md:w-7 md:h-7  absolute left-0 -translate-x-[9px] -top-3 z-[2] aspect-square'}
                                       border
                                       absolute
                                   />
                               </div>
                            </div>
                        </div>
                        <div className='w-full flex flex-row rounded-full overflow-hidden text-xs md:text-sm'>
                            <div className='text-end px-2' style={{
                                width: (props.stock1WinPercent || 50) + '%',
                                opacity: props.stock1WinPercent > props.stock2WinPercent ? 1 : .5,
                                transition: 'width 300ms ease'
                            }}>
                                {(props.stock1WinPercent || '--') + '%'}
                            </div>
                            <div className=' px-1' style={{
                                width: (props.stock2WinPercent || 50) + '%',
                                opacity: props.stock2WinPercent > props.stock1WinPercent ? 1 : .5,
                                transition: 'width 300ms ease'
                            }}>
                                {(props.stock2WinPercent || '--') + '%'}
                            </div>
                        </div>
                    </div>
                }
                hover
                placement={'bottom'}
                label={"Win probabilities are spit out by a random formula we came up with made up of stock price and beta. We have no idea whats gonna happen in reality, we're all in this together."}
            />
        );
}

export default MatchupVersusSection;
