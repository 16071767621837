import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={10}
            height={10}
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M.53 0l.667 7.558c.173 1.959 2.773 2.518 3.736.803L9.63 0H.53z"
                fill="currentColor"
            />
        </svg>
    )
}

export default SvgComponent
