import React, {useState} from 'react';
import {Button} from '../../components/atoms/Button';
import socialscollage from '../../img/socials-collage.png';
import {cardClass, SHARE_TO_SOCIAL_TEXT} from '../../consts/dummy';
import ShareOnSocials from '../../components/modals/ShareOnSocials';
import SvgCheck from "../../components/icons/SvgCheck";
import { useLocation } from 'react-router-dom';

function ShareForPrizesCard(props) {
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const [buttonText, setButtonText] = useState('Share on socials');
    const [copied, setCopied] = useState(false);
    const location = useLocation();

    const openShareModal = () => {
        window.amplitude.track("Link Copied", {path: location.pathname})
        if (props.mobile) {
            navigator.share({
                title: 'Join Stonk Madness Now!',
                text: SHARE_TO_SOCIAL_TEXT,
                url: 'https://stonkmadness.com/',
            });
        } else {
            navigator.clipboard.writeText('https://stonkmadness.com/').then(() => {
                setCopied(true);
                setButtonText('Link copied');

                // Reset state after 3 seconds
                setTimeout(() => {
                    setCopied(false);
                    setButtonText('Share on socials');
                }, 6000);
            });
        }
    };

    const closeShareModal = () => {
        setShareModalOpen(false);
    };

    return (
        <div className={`${cardClass} ${copied && 'bg-yellow-400/20'}`}>
            <div className='flex flex-col gap-3'>
                {copied ? (
                    <p className='text-4xl'>
                        <span className='text-yellow-400'>LINK COPIED.</span> THANKS FOR SHARING!
                    </p>
                ) : (
                    <p className='text-4xl'>
                        SHARE TO GROW<span className='text-yellow-400'> THE MADNESS</span>
                    </p>
                )}
                {/*<p className={'font-body text-lg font-medium'}>*/}
                {/*    Each bracket created counts towards{' '}*/}
                {/*    <span*/}
                {/*        className='text-yellow-400 font-semibold'>reducing the 'perfect bracket' requirements</span> and{' '}*/}
                {/*    <span className='text-yellow-400 font-semibold'>unlocking new top prizes</span>. Share with friends*/}
                {/*    to better*/}
                {/*    your chances!*/}
                {/*</p>*/}
                <img alt={'X'} src={socialscollage} className={'w-full'}/>
            </div>
            <Button onClick={openShareModal} variant={'secondary'} className='w-full -mt-4'>
                {copied && <SvgCheck/>} {buttonText}
            </Button>
            <ShareOnSocials isOpen={shareModalOpen} close={closeShareModal}/>
        </div>
    );
}

export default ShareForPrizesCard;
