import React, {useRef, useState} from 'react';
import BracketGameCardTeamRow from "./BracketGameCardTeamRow";
import {connect} from "react-redux";
import MatchupBody from "../matchupdetails/MatchupBody";
import TooltipComponent from "../atoms/TooltipComponent";

const mapStateToProps = (state) => {
    return {
        mobile: state.userReducer.mobile,
        masterBracket: state.userReducer.masterBracket,
    }
}


function BracketGameCard(props) {
    const matchup = !!props.masterBracket ? props.masterBracket[props.gameNumber] : {};
    const isFocused = props.focusedGameNumber === props.gameNumber
    const isValidMatchup = matchup.ticker1 && matchup.ticker2
    const [showPreview, setShowPreview] = useState(false)

    const disablePointerEvents = !matchup?.ticker1 || !matchup?.ticker2 || props.mobile || props.isEditingBracket
    let mouseDelay = useRef(null);  // delay modal popup
    const handleMouseEnter = () => {
        if (!disablePointerEvents) {
            props.setGameNumber(props.gameNumber);
            mouseDelay.current = setTimeout(() => {
                setShowPreview(true)
                window.amplitude.track("Matchup Preview Opened");
                // setPrevFocus(props.focusedGameNumber)
                // props.setGameNumber(props.gameNumber);
            }, 700)
        }
    };

    const handleMouseLeave = () => {
        if (!disablePointerEvents) {
            setShowPreview(false)
            props.setGameNumber(null);
            clearTimeout(mouseDelay.current);
            // wait(1000).then(() => {
            // props.setGameNumber(null);
            // })
            // setPrevFocus(null)
        }
    };
    const winner = matchup.winner_id || null
    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => {
                if (props.mobile && props.viewOnly) {
                    props.setGameNumber(props.gameNumber);
                    props.openMatchPreviewModal(matchup)
                }
            }}
            id={`game-${props.gameNumber}`}
            className={`rounded-md p-2 relative group overflow-hidden z-[1]
                 ${props.isChampionship ? 'bg-green-500/20 outline outline-offset-2 mx-2 mt-2 outline-green-500' : 'bg-white/10'}  
                ${(isValidMatchup && !winner) && (isFocused ? `outline-2 ${props.isChampionship ? 'outline-green-400' : 'outline-yellow-400'}` : `outline outline-yellow-400/30`)}
                ${isFocused && `outline outline-2  ${props.isChampionship ? 'outline-green-400' : 'outline-yellow-400'}`}
                flex flex-row items-center  min-w-[140px] 
                cursor-pointer
                `}>
            <div
                className={`absolute top-0 left-0 z-0 bottom-0 ${showPreview ? 'bg-white/10' : 'bg-white/10'} ${isFocused ? 'w-full' : 'w-0'}`}
                style={{transition: 'width 1000ms ease'}}
            />
            <div
                className={`absolute top-0 right-0 z-0 bottom-0 w-full ${showPreview ? 'bg-yellow-400/20' : 'bg-yellow-400/10'} ${showPreview ? 'opacity-100' : 'opacity-0'}`}
                style={{transition: 'opacity 150ms ease'}}
            />
            <div className='absolute -top-12'/>
            <div className='flex flex-col flex-1 gap-2 z-[1]'>
                <BracketGameCardTeamRow tbd={props.tbd} ticker={matchup?.ticker1} gameNumber={props.gameNumber}
                                        onPick={props.onPick} disablePointerEvents={disablePointerEvents}
                                        winner={winner} viewOnly={props.viewOnly} loading={props.loading}
                                        seed={props.masterBracket?.[props.gameNumber]?.[matchup?.ticker1]?.seed}/>
                <BracketGameCardTeamRow tbd={props.tbd} ticker={matchup?.ticker2} gameNumber={props.gameNumber}
                                        onPick={props.onPick} disablePointerEvents={disablePointerEvents}
                                        winner={winner} viewOnly={props.viewOnly} loading={props.loading}
                                        seed={props.masterBracket?.[props.gameNumber]?.[matchup?.ticker2]?.seed}/>
            </div>
            <div className='relative z-30 bg-black'>
                {(isValidMatchup && !props.mobile) &&
                    <TooltipComponent
                        ToggleComponent={<div/>}
                        toggleClassName={''}
                        ContentComponent={
                            <div className={props.right ? 'pr-3' : 'pl-3'}>
                                <div
                                    className='w-[600px] relative z-10 my-4 p-3 rounded-3xl border border-white/20 overflow-x-hidden overflow-y-scroll max-h-[80dvh] bg-black text-white flex flex-col gap-8 pb-5'
                                >
                                    <div className='absolute backdrop-blur-lg top-0 left-0 right-0 -bottom-[800px] z-[-1]'
                                         style={{background: `linear-gradient(-180deg, ${matchup?.[matchup?.ticker1]?.color + '22'}, ${matchup?.[matchup?.ticker2]?.color + '22'}, black)`}}>

                                    </div>
                                    {isFocused ?
                                        <MatchupBody
                                            gameNumber={props?.gameNumber}
                                            onPick={props.onPick}
                                            setShowPreview={setShowPreview}
                                            isPreview={true}
                                            viewOnly={props.viewOnly}
                                            preloadedMatchup={matchup}
                                        />
                                        : null
                                    }
                                </div>
                            </div>
                        }
                        offsetX={200}
                        offsetY={(!!props.right) ? 132 : 0}
                        placement={(!!props.right) ? 'right' : 'right'}
                        disabled={false}
                        visible={showPreview}
                        delayShow={5000}
                    />
                }
            </div>
        </div>
    );
}

export default connect(mapStateToProps, null)(BracketGameCard);
