import React from 'react';
import {Link} from "react-router-dom";
import {Button} from "../components/atoms/Button";
import error from '../img/404.png'

function NotFoundPage() {
    return (
        <div className='flex flex-col w-full h-[80dvh] items-center justify-center gap-3 px-8 text-center'>
            <img alt={'404'} src={error} className='aspect-square w-1/3 mb-3'/>
            <h1 className='text-6xl text-yellow-400'>404</h1>
            <h1 className='text-2xl uppercase -mt-4'>Page Not Found</h1>
            <p className='font-medium font-body text-lg opacity-50'>Sorry, the page you are looking for does not
                exist.</p>
            <Link to={'/'} className='mt-3'>
                <Button variant={'default'}>
                    Back home
                </Button>
            </Link>
        </div>
    );
}

export default NotFoundPage;
