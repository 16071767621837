import React from 'react';
import logo from './SM_LOGO.png'
import logo_secondary from './SM_LOGO_SECONDARY.png'
function StonkMadnessLogo(props) {
    return (
        <img src={props.secondary ? logo_secondary : logo} alt={'logo'} className={props.className}/>
    );
}

export default StonkMadnessLogo;
