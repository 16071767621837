import React from 'react';
import MatchupStatItem from "../atoms/MatchupStatItem";

function MatchupInfoHalf(props) {
    return (
        <div className={`flex-1 flex flex-col`}>
            <div className='flex-1 flex flex-col gap-3'>
                <p className={`text-xs md:text-lg h-24 md:h-36 ${props.left && 'text-right'}`}>
                    {props.stock?.headline}
                </p>
                <MatchupStatItem
                    label={'Head coach'}
                    value={props.stock?.ceo}
                    column
                    left={props.left}
                />
                {/*<MatchupStatItem*/}
                {/*    label={'Specialties'}*/}
                {/*    value={props.stock?.specialties.join(', ')}*/}
                {/*    column*/}
                {/*/>*/}
                <div className='w-full h-[1px] bg-white/10'/>
                <MatchupStatItem
                    label={'CEO net worth'}
                    value={props.stock?.preloaded_details?.networth}
                    left={props.left}
                />
                <MatchupStatItem
                    label={'Does Cramer hodl?'}
                    value={props.stock?.preloaded_details?.cramer_holds ? 'Y' : 'N'}
                    left={props.left}
                />
                <MatchupStatItem
                    label={'# holders in Congress'}
                    value={props.stock?.preloaded_details?.congress_positions}
                    left={props.left}
                />
                <MatchupStatItem
                    label={"% loss in 2008"}
                    value={props.stock?.preloaded_details?.['2008_loss'] === 'NA' ? 'NA' : Math.floor(props.stock?.preloaded_details?.['2008_loss']?.toFixed(2) * 100) + '%'}
                    left={props.left}
                />
                <MatchupStatItem
                    label={"If pokemon?"}
                    value={props.stock?.preloaded_details?.pokemon}
                    left={props.left}
                />
                <MatchupStatItem
                    label={'Layoffs ytd'}
                    value={props.stock?.preloaded_details?.layoffs}
                    left={props.left}
                />
                <MatchupStatItem
                    label={'WSB Mentions'}
                    value={props.stock?.preloaded_details?.wsb_mentions}
                    left={props.left}
                />
                <div className='bg-white/5 rounded-lg text-white p-4 flex flex-col gap-2'>
                    <p className='font-bold text-xs md:text-sm'>
                        Biggest Scandal?
                    </p>
                    <p className='text-xs md:text-sm'>
                        {props.stock?.preloaded_details?.worst_pr}
                    </p>
                </div>
            </div>
        </div>
    )
}

function MatchupInfoComparison(props) {
    const matchup = props?.preloadedMatchup || props.matchup;
    return (
        <div className='flex flex-row justify-between items-stretch font-body'>
            <MatchupInfoHalf stock={matchup?.[matchup?.ticker1]} left/>
            <div className=' h-auto w-[1px] mx-3 md:mx-5'/>
            <MatchupInfoHalf stock={matchup?.[matchup?.ticker2]}/>
        </div>
    );
}

export default MatchupInfoComparison;
