import React from "react";

function MatchupDateBanner({gameNumber}) {
    const mapGameToRound = (gameNumber) => {
        if (gameNumber < 32) return 1;
        if (gameNumber < 48) return 2;
        if (gameNumber < 56) return 3;
        if (gameNumber < 60) return 4;
        if (gameNumber < 62) return 5;
        if (gameNumber < 63) return 6;
    }

    const humanizeRoundNumber = (round) => {
        switch (round) {
            case 1:
                return "First Round";
            case 2:
                return "Second Round";
            case 3:
                return "Third Round";
            case 4:
                return "Quarterfinals";
            case 5:
                return "Semifinals";
            case 6:
                return "Championship";
        }
    }

    const getGameDate = (gameNumber) => {
        // round 1
        if (gameNumber < 8) return 'Tue 3/19';
        if (gameNumber < 16) return 'Wed 3/20';
        if (gameNumber < 24) return 'Thu 3/21';
        if (gameNumber < 32) return 'Fri 3/22';
        // round 2
        if (gameNumber < 36) return 'Mon 3/25';
        if (gameNumber < 40) return 'Tue 3/26';
        if (gameNumber < 44) return 'Wed 3/27';
        if (gameNumber < 48) return 'Thu 3/28';
        // round 3
        if (gameNumber < 52) return 'Mon 4/1';
        if (gameNumber < 56) return 'Mon 4/1';
        // round 4
        if (gameNumber < 58) return 'Tue 4/2';
        if (gameNumber < 60) return 'Wed 4/3';
        // round 5
        if (gameNumber < 62) return 'Thu 4/4';
        // round 6
        if (gameNumber < 63) return 'Fri 4/5';
    }

    return (
        <div
            className='font-body w-full bg-white/10 px-5 py-2 rounded-full flex flex-row items-center justify-between md:text-lg font-semibold'>
            <p>
                {humanizeRoundNumber(mapGameToRound(gameNumber))}: <span
                className='opacity-50'>GAME {gameNumber + 1}</span>
            </p>
            <p>
                {getGameDate(gameNumber)}
            </p>
        </div>
    )
}

export default MatchupDateBanner
