import React from 'react';
import SvgCheck from "../icons/SvgCheck";

function MatchupTableCardScoreBox(props) {
    return (
        <div className='flex flex-row items-center'>
            {!!props.realWinner &&
                <SvgCheck className={`w-5 h-5 mr-1 text-yellow-400 ${!props.isRealWinner && "opacity-0"}`}/>
            }
            <div
                className={`bg-white/10 min-w-24 rounded-lg py-1 px-2 text-center font-body  border border-white/10
                ${props.tbd && 'opacity-50'}
                ${props.realWinner && props.isRealWinner && 'border-yellow-400'}
                `}>
                {/*Todo: show -- pregame, update to live % during game, lock at finish % once game is complete*/}
                <p className=' text-md md:text-lg font-semibold'>
                    {props.score !== null && props.score !== undefined ? `${props.score >= 0 ? '+' : ''}${props.score.toFixed(2)}%` : '--'}
                </p>
                {/*<p className=' text-md md:text-lg font-semibold'>*/}
                {/*    2.3%*/}
                {/*</p>*/}
            </div>
        </div>
    );
}

export default MatchupTableCardScoreBox;
