import RandomCharacter from "../RandomCharacter";
import React from "react";


function BracketBGCharactersA(props) {
    return (
        <>

            {props.start === 0 &&
                <div className='absolute -right-24 md:-right-32 -top-0'>
                    <div className='-rotate-[12deg] relative opacity-80 '>
                        <RandomCharacter className={'w-20 md:w-28'}/>
                    </div>
                </div>
            }
            {(props.start === 20 || props.start === 4) &&
                <div className='absolute -left- -top-0'>
                    <div className='-rotate-[12deg] relative opacity-80 '>
                        <RandomCharacter className={'w-26 md:w-20'}/>
                    </div>
                </div>
            }
            {props.start === 8 &&
                <div className='absolute -right-12 md:-right-20 -top-0'>
                    <div className='-rotate-[12deg] relative opacity-80 '>
                        <RandomCharacter className={'w-12 md:w-20'}/>
                    </div>
                </div>
            }

            {(props.start === 12 || props.start === 16) &&
                <div className='absolute md:right-12 -top-2'>
                    <div className='-rotate-[-22deg] relative opacity-80 '>
                        <RandomCharacter className={'w-26 md:w-20'}/>
                    </div>
                </div>
            }
            {props.start === 24 &&
                <div className='absolute -right-12 md:-left-20 -top-10'>
                    <div className='-rotate-[-12deg] relative opacity-80 '>
                        <RandomCharacter className={'w-24 md:w-28'}/>
                    </div>
                </div>
            }
            {props.start === 28 &&
                <div className='absolute -right-32 md:-left-20 top-16'>
                    <div className='-rotate-[-12deg] relative opacity-80 '>
                        <RandomCharacter className={'w-24 md:w-28'}/>
                    </div>
                </div>
            }
        </>
    )
}

export default BracketBGCharactersA
