import React from 'react';
import {pageClass} from "../consts/dummy";
import FAQSection from "../sections/FAQSection";
import PageHeader from "../components/navigation/PageHeader";
import ScheduleSection from "../sections/ScheduleSection";

function SchedulePage() {
    return (
        <div className={pageClass}>
            <PageHeader
                title={'FULL SCHEDULE'}
                desc={'Just remember, no trading days on weekends.'}
            />
            <ScheduleSection/>
            <FAQSection/>
        </div>
    );
}

export default SchedulePage;
