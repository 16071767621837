import React, {useEffect} from 'react';
import {Navigate, useParams} from 'react-router-dom';
import {pageClass} from "../consts/dummy";
import PageHeader from "../components/navigation/PageHeader";
import MatchupBody from "../components/matchupdetails/MatchupBody";
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
    return {
        userBracket: state.userReducer.userBracket,
        masterBracket: state.userReducer.readonlyMasterBracket,
    }
}

function MatchupPage(props) {
    const {matchup_id} = useParams();

    useEffect(() => {
        window.amplitude.track("Matchup Page Viewed");
    }, [])

    return (matchup_id < 1 || matchup_id > 63) ?
        <Navigate to="/404" replace={true}/>
        : (
            <>
                <div className='absolute sc top-0 left-0 right-0 bottom-0 z-[-1]'
                     style={{background: `linear-gradient(-180deg, ${props.masterBracket?.[matchup_id - 1][props.masterBracket?.[matchup_id - 1].ticker1].color + '20'}, ${props.masterBracket?.[matchup_id - 1][props.masterBracket?.[matchup_id - 1].ticker2].color + '20'}, black)`}}>
                </div>
                <div className={`${pageClass} relative`}>
                    <PageHeader
                        title={'MATCHUP'}
                        desc={''}
                    />
                    <MatchupBody
                        gameNumber={matchup_id - 1}
                        userBracket={props.userBracket}
                        className={'-mt-20'}
                        preloadedMatchup={props.masterBracket?.[matchup_id - 1]}
                        fullPage
                    />
                </div>
            </>
        );
}

export default connect(mapStateToProps, null)(MatchupPage);
