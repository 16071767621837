import React from 'react';
import {Button} from "../components/atoms/Button";
import ScheduleRoundBlock from "../components/ScheduleRoundBlock";
import {SCHEDULE, sectionClass} from "../consts/dummy";
import {Link} from "react-router-dom";

function ScheduleSection(props) {
    const onHome = props.onHome
    const schedule = onHome ? SCHEDULE?.slice(0, 1) : SCHEDULE
    return (
        <div className={`${sectionClass}`}>
            <div className='flex flex-col gap-8 w-full'>
                <p className='text-4xl'>
                    SCHEDULE
                </p>
                {schedule.map(round =>
                    <ScheduleRoundBlock round={round} key={round.title}/>
                )}
            </div>
            {onHome &&
                <Link to={'/schedule'} className='w-full'>
                    <Button className='w-full'>
                        Full Schedule
                    </Button>
                </Link>
            }
        </div>
    );
}

export default ScheduleSection;
