import React from "react";

function TimeframeSelectRow ({ activeTimeframe, onTimeframeChange }) {
    const timeframes = ['1d', '1w', '1m', '3m', '1y'];

    return (
        <div className="flex justify-center space-x-4 mx-auto mt-2">
            {timeframes.map((timeframe) => (
                <button
                    key={timeframe}
                    className={`px-3 py-1 rounded-full text-xs md:text-sm font-semibold uppercase ${
                        activeTimeframe === timeframe ? 'bg-white/20 text-white' : 'bg-transparent opacity-50 hover:opacity-100 cursor-pointer'
                    }`}
                    onClick={() => onTimeframeChange(timeframe)}
                >
                    {timeframe}
                </button>
            ))}
        </div>
    );
};

export default TimeframeSelectRow
