import React from 'react';

function BracketCreationProgressBar({gameNumber, ...props}) {
    const progress = {
        roundSizes: [32, 16, 8, 4, 2, 1],
        roundStarts: [0, 32, 48, 56, 60, 62]
    }

    // Todo: Add progress bar variant to show on desktop that isnt broken down by round
    if (props.desktop) {
        return (
            <div className={`flex bg-yellow-400/50 h-1`} style={{flex: 1}}>
                <div className='bg-yellow-400 h-1' style={{width: `${props.numSelected / 63 * 100}%`}}/>
            </div>
        )
    }
    return (
        <div className='flex flex-row flex-1 gap-2'>
            {progress.roundStarts.map((startNumber, round) => {
                const barWidth = round === 0 ? 15 : round === 1 ? 12 : round === 2 ? 8 : round === 3 ? 6 : round === 4 ? 4 : 2;
                    const roundCompleteWidth = ((gameNumber - progress.roundStarts[round]) / progress.roundSizes[round]) * 100;
                    return (
                        gameNumber >= startNumber ?
                            <div key={startNumber + gameNumber} className={`flex bg-yellow-400/50 h-1`} style={{flex: barWidth}}>
                                <div className='bg-yellow-400 h-1' style={{width: `${roundCompleteWidth}%`}} />
                            </div>
                            :
                            <div
                                key={startNumber + gameNumber}
                                style={{flex: barWidth}}
                                className={`flex bg-white/20 h-1`}>
                            </div>
                    )
                }
            )}
        </div>
    )
}

export default BracketCreationProgressBar;
