import React from 'react';
import SvgInfo from "../icons/SvgInfo";
import Spinner from "./Spinner";

function MatchupStatItem({label, value, img, column, info, loading, left}) {
    if (column) {
        return (
            <div className={`flex flex-col w-full gap-2 justify-between text-sm md:text-lg ${left && 'text-right items-end'}`}>
                <p className='text-xs md:text-sm opacity-70'>
                    {label}
                </p>
                <div className={`flex flex-row items-center gap-2 ${left && 'flex-row-reverse'}`}>
                    {img &&
                        <img src={'https://personalexcellence.co/files/ceo.jpg'} alt={'headcoach'}
                             className='w-8 h-8 rounded-full object-cover'/>
                    }
                    <p className='text-xs md:text-lg font-semibold'>
                        {value}
                    </p>
                </div>
            </div>
        );
    }
    return (
        <div className={`flex flex-row items-center gap-2 justify-between text-sm md:text-lg ${!left && 'flex-row-reverse'}`}>
            <div className='flex flex-row items-center gap-0.5'>
                <p className={`text-xs md:text-sm opacity-70 ${!left && 'text-right'}`}>
                    {label}
                {info && <SvgInfo className={'w-4 h-4 opacity-50 hover:opacity-100 mb-0.5 inline-flex'}/>}
                </p>
            </div>
            <div className='flex flex-row items-center gap-2'>
                {img &&
                    <img src={'https://personalexcellence.co/files/ceo.jpg'} alt={'headcoach'}
                         className='w-8 h-8 rounded-full object-cover'/>
                }
                {loading ?
                    <Spinner size={14}/>
                    :
                    <p className='text-xs md:text-lg font-semibold'>
                        {value}
                    </p>
                }
            </div>
        </div>
    );
}

export default MatchupStatItem;
