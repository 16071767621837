import React, {useEffect, useState} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {AsYouType} from 'libphonenumber-js';
import {Button} from "../atoms/Button";
import TextInputWrapper from "../atoms/TextInputWrapper";
import SvgGoogle from "../icons/custom/SvgGoogle";
import SvgApple from "../icons/custom/SvgApple";
import {
    initializeRecaptcha,
    sendVerificationCode,
    sendVerifyEmail,
    setAuthUsername,
    setUserEmail,
    signInWithApple,
    signInWithGoogle,
    validateUsername,
    verifyPhoneCode,
    getAfterhourUser,
    checkPhoneExists
} from '../../api/auth';
import {checkUserExists} from '../../api/users';
import {connect} from 'react-redux';
import SvgHelp from "../icons/SvgHelp";
import AfterHourWordmark from "../brand/logos/AfterHourWordmark";
// import VerificationInput from "react-verification-input";
import VerificationInput from "./VerificationInput";
import StonkMadnessLogo from "../brand/logos/StonkMadnessLogo";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

const LoginCard = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [authUser, setAuthUser] = useState(props.authUser);
    const [authFlowStep, setAuthFlowStep] = useState(0);
    const [authSuccessful, setAuthSuccessful] = useState(false);
    // step 0 - enter phone number
    const [rawPhoneNumber, setRawPhoneNumber] = useState("");
    const [parsedPhoneNumber, setParsedPhoneNumber] = useState("");
    const [displayedPhoneNumber, setDisplayedPhoneNumber] = useState("");
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
    const [isCertified, setIsCertified] = useState(true);
    const [googleHover, setGoogleHover] = useState(false);
    const [appleHover, setAppleHover] = useState(false);
    const [googleLoading, setGoogleLoading] = useState(false);
    // step 1 - verify SMS code
    const [code, setCode] = useState("");
    // step 2 - enter username
    const [username, setUsername] = useState("");
    // step 3 - enter email
    const [email, setEmail] = useState("");
    // step 4 - verify email
    const [bypassEmailVerification, setBypassEmailVerification] = useState(true);

    // initialize recaptcha for sending sms verification code
    useEffect(() => {
        if (!authUser?.emailVerified)
            initializeRecaptcha('recaptcha');
        return () => window?.recaptchaVerifier?.clear();
    }, [])

    useEffect(() => {
        if (!authUser)
            setAuthUser(props.authUser)
        if (!props.authUser) {
            setAuthFlowStep(0);
        } else if (!validateUsername(props.authUser?.displayName)) {
            // check if user already exists on AH
            getAfterhourUser(props.authUser)
            .then((user) => {
                if (!validateUsername(user?.username)) {
                    setAuthFlowStep(2);
                } else {
                    setAuthUsername(user.username);
                    // authUser.displayName = user.username;
                    if (bypassEmailVerification && user?.email) {
                        setAuthSuccessful(true);
                    } else {
                        setAuthFlowStep(!user?.email ? 3 : 4);
                    }
                }
            })
        } else if (!props.authUser?.email) {
            setAuthFlowStep(3);
        } else {
            bypassEmailVerification ? setAuthSuccessful(true) : setAuthFlowStep(4);
        }
    }, [props.authUser]);

    // Handle input changes
    const onChangePhone = (input) => {
        // Enable backspacing through parentheses
        input = input[0] == '(' && input[2] !== ')' && input[3] !== ')' && input[4] !== ')' ? input.slice(0, input.length - 1) : input;
        // Enable parentheses appearing prior to 3 digits being entered
        input = input[0] === '(' ? input.slice(1) : input;
        input = input[input.length - 2] === ')' ? input.slice(0, input.length - 2) + input[input.length - 1] : input;

        setError("");
        setRawPhoneNumber(input.toLowerCase());
        const parser = new AsYouType('US');     // TODO: make country code dynamic
        const result = parser.input(input);
        setDisplayedPhoneNumber(input.length > 0 && input.length < 3 ? `(${result})` : result);
        setIsValidPhoneNumber(parser.isValid());
        setParsedPhoneNumber(parser.getNumberValue());
    }

    const onChangeCode = (input) => {
        setCode(input);
        setError("");
    }

    const onChangeUsername = async (input) => {
        setUsername(input);
        if (!validateUsername(input)) {
            const _error =
                input.length < 3 ? "Must be at least 3 characters" :
                    input.length > 20 ? "Must be 20 characters or fewer" :
                        "Can only contain letters, numbers, and underscores (_)";
            setError(_error);
        } else {
            setError("");
        }
    }

    const onChangeEmail = (input) => {
        setEmail(input);
        setError("");
    }

    // Handle button presses
    const logInPressed = async () => {
        setLoading(true);
        if (!isValidPhoneNumber) {
            setError("Phone number is invalid");
            setLoading(false);
            return;
        }

        // too late
        if (! (await checkPhoneExists(parsedPhoneNumber))) {
            navigate("/toolate")
        }

        try {
            await sendVerificationCode(parsedPhoneNumber);
            window.scrollTo(0, 0);
            setAuthFlowStep(1);
            setLoading(false);
        } catch (err) {
            setError("Incorrect login info.");
            setLoading(false);
        }
    };

    const verifyCodePressed = async () => {
        const _authUser = await verifyPhoneCode(code);
        setAuthUser(_authUser);
        setCode("");
        console.log(_authUser)

        if (!_authUser) {
            setError("Code is incorrect");
            return;
        }

        if (!validateUsername(_authUser?.displayName)) {
            // check if user already exists on AH
            getAfterhourUser(_authUser)
            .then((user) => {
                if (!validateUsername(user?.username)) {
                    setAuthFlowStep(2);
                } else {
                    setAuthUsername(user.username);
                    _authUser.displayName = user.username;
                    setAuthUser(_authUser)
                    if (bypassEmailVerification && user?.email) {
                        setAuthSuccessful(true);
                    } else {
                        setAuthFlowStep(!user?.email ? 3 : 4);
                    }
                }
            })
            setAuthFlowStep(2);
        } else if (!_authUser.emailVerified || (!bypassEmailVerification && !!_authUser?.email)) {
            setAuthFlowStep(3);
        } else {
            setAuthSuccessful(true);
            window.location.reload(true);
        }
    }

    const enterUsernamePressed = async () => {
        if (await checkUserExists(username)) {
            setError("This username is already taken");
        } else if (!validateUsername(username)) {
            if (!error)
                setError("Please enter a username")
        } else {
            await setAuthUsername(username);
            authUser.displayName = username;
            // skip email entry if signed in using google or apple
            (authUser?.emailVerified || (bypassEmailVerification && authUser?.email)) ? setAuthSuccessful(true) : setAuthFlowStep(3);
        }
    }

    const verifyEmailPressed = async () => {
        let _error = "";
        if (_error = (await setUserEmail(authUser, email))) {
            if (!bypassEmailVerification) {
                setError(_error);
                if (_error === 'Credentials expired') {
                    await sendVerificationCode(authUser.phoneNumber);
                    setAuthFlowStep(1);
                }
                return;
            } else {
                if (_error === 'Credentials expired') {
                    setError("Credentials expired, please re-enter code")
                    await sendVerificationCode(authUser.phoneNumber);
                    setAuthFlowStep(1);
                    return;
                }
                const emailRegex = /^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i;
                if (!emailRegex.test(email)) {
                    setError("Email format is not valid");
                    return;
                }

                // generate unique email to bypass error but allow supabase auth user creation
                const tokens = email.split("@");
                const address = tokens?.[0] || "invalidname";
                const domain = tokens?.[1] || "com";
                
                await setUserEmail(authUser, `${address}@stonkmadness3829sdifal.${domain}`)
            }
        }

        if (bypassEmailVerification) {
            setAuthSuccessful(true);
            window.location.reload(true);
        } else {
            sendVerifyEmail(authUser);
            setAuthFlowStep(4);
        }
    }

    const buttonPressed = () => {
        switch (authFlowStep) {
            case 0:
                return logInPressed();
            case 1:
                return verifyCodePressed();
            case 2:
                return enterUsernamePressed();
            case 3:
                return verifyEmailPressed();
            case 4:
                return handleResendEmailVerification();
        }
    };

    const handleGoogleSignIn = async () => {
        if (isCertified) {
            signInWithGoogle();
            setGoogleLoading(true);
        }
    }

    const handleAppleSignIn = async () => {
        if (isCertified)
            signInWithApple();
    }

    const handleResendVerificationCode = async () => {
        sendVerificationCode(parsedPhoneNumber);
    }

    const handleResendEmailVerification = async () => {
        sendVerifyEmail(authUser)
            .then((res) => {
                setError(res ? "" : "Please wait...")
            })
    }

    // bypass auth page if already signed in
    useEffect(() => {
        if (((bypassEmailVerification && !!props.authUser?.email) || props.authUser?.emailVerified) && validateUsername(props.authUser?.displayName))
            setAuthSuccessful(true);
    }, [props.authUser])

    return authSuccessful ?
        <Navigate to='/hub' replace={true}/>
        : (
            <form className={`mb-5 w-[95vw] md:w-[440px]`}
                  onSubmit={e => e.preventDefault()}
            >
                <div className="flex flex-col w-full gap-3" style={{zIndex: 0}}>
                    {{
                        0:
                            <div>
                                <p className='text-center uppercase mb-1 -mt-2 opacity-50'>
                                    Welcome to
                                </p>
                                <StonkMadnessLogo secondary className={'h-[140px] mx-auto mb-5'}/>
                                <p className='text-2xl text-center mb-8 font-body font-semibold text-balance'>
                                    What number should we
                                    text your code to? 📱
                                </p>
                                <div
                                    className="flex ml-0 flex-row gap-2 items-center text-3xl md:text-5xl justify-center">
                                    <p className='mt-2'>
                                        🇺🇸
                                    </p>
                                    {/* TODO: Fix autocomplete for safari */}
                                    <TextInputWrapper
                                        value={displayedPhoneNumber}
                                        onChange={(e) => onChangePhone(e.target.value)}
                                        placeholder={'(555) 555-5555'}
                                        type="tel"
                                        inputMode="tel"
                                        autoComplete="tel"
                                        className={'w-[240px] md:w-[380px] bg-transparent no-yellow-autofill'}
                                        variant={'ghost'}
                                    />
                                </div>
                            </div>
                        , 1:
                            <div>
                                <p className='text-2xl text-center mb-1 font-body font-semibold text-balance'>
                                    Enter the code we texted to<br/>
                                    {displayedPhoneNumber} 💬
                                </p>
                                <div className='flex w-full justify-center font-body mb-8'>
                                    <button className='text-white/50 hover:underline hover:text-yellow-400'
                                            onClick={handleResendVerificationCode}>
                                        Resend text?
                                    </button>
                                </div>
                                <div className="flex flex-col gap-4 items-center mb-8">
                                    <VerificationInput
                                        value={code}
                                        placeholder={''}
                                        onChange={onChangeCode}
                                    />
                                </div>
                            </div>
                        , 2:
                            <div className='text-3xl w-full'>
                                <p className='text-2xl text-center mb-8 font-body font-semibold text-balance'>
                                    Enter your username 🦧
                                </p>
                                <TextInputWrapper
                                    value={username}
                                    onChange={(e) => onChangeUsername(e.target.value)}
                                    placeholder={'@'}
                                    className={'text-2xl w-full text-center bg-transparent'}
                                    type="text"
                                    variant={'ghost'}
                                    autoCapitalize='off'
                                    autoCorrect='off'
                                />
                            </div>
                        , 3:
                            <div className='text-3xl w-full'>
                                <p className='text-2xl text-center mb-8 font-body font-semibold text-balance'>
                                    Enter your email 🥇
                                </p>
                                <TextInputWrapper
                                    value={email}
                                    onChange={(e) => onChangeEmail(e.target.value)}
                                    placeholder={'exampledegen@afterhour.com'}
                                    className={'text-2xl w-full text-center bg-transparent'}
                                    type="email"
                                    variant={'ghost'}
                                    autoComplete="email"
                                />
                                <div
                                    className='flex flex-row mt-2 items-center justify-center mt-5 text-green-500 gap-2'>
                                    <SvgHelp className={'w-5 h-5'}/>
                                    <p className='font-body text-sm'>
                                        We need your email in order to notify winners and award prizes.
                                    </p>
                                </div>
                            </div>
                        , 4:
                            <div className="flex flex-col gap-4 font-body text-center">
                                <p>A verification email has been sent to {email}. Refresh this page once you have
                                    verified your email.</p>
                            </div>
                    }[authFlowStep]}
                    <div className='flex flex-col w-full'>
                        <div className='text-center font-body mb-2'>
                            <p className="text-red-500 text-xs" style={{minHeight: 18}}>
                                {error}
                            </p>
                        </div>
                        <Button id='recaptcha'
                                disabled={authFlowStep === 0 ? !isValidPhoneNumber || !isCertified : false}
                                loading={loading} variant='default' onClick={buttonPressed}>
                            {{
                                0: 'Sign In With Phone',
                                1: 'Verify Code',
                                2: 'Enter Username',
                                3: bypassEmailVerification ? "Submit" : 'Verify Email',
                                4: 'Resend Verification Email',
                            }[authFlowStep]}
                        </Button>
                        {authFlowStep === 0 &&
                            <div className='flex flex-col gap-5 mt-3'>
                                <div className='flex flex-row items-center gap-3 font-body font-medium'>
                                    <div onClick={handleGoogleSignIn} onMouseEnter={() => setGoogleHover(true)}
                                         onMouseLeave={() => setGoogleHover(false)}
                                         style={{
                                             backgroundColor: isCertified ? googleHover ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0.15)' : 'rgba(255, 255, 255, 0.1)',
                                             color: isCertified ? googleHover ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.15)',
                                             cursor: isCertified ? 'pointer' : 'default',
                                         }}
                                         className={`flex-1 rounded-full gap-2 text-xs md:text-sm flex flex-row items-center justify-center p-3`}>
                                        {googleLoading ? <p>Loading Google user...</p> :
                                            <>
                                                <SvgGoogle className={'w-6 h-6'}
                                                           style={{opacity: isCertified ? googleHover ? 0.3 : 1.0 : 0.1}}/>
                                                <div>
                                                    Login with Google
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div onClick={handleAppleSignIn} onMouseEnter={() => setAppleHover(true)}
                                         onMouseLeave={() => setAppleHover(false)}
                                         style={{
                                             backgroundColor: isCertified ? appleHover ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0.15)' : 'rgba(255, 255, 255, 0.1)',
                                             color: isCertified ? appleHover ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.15)',
                                             cursor: isCertified ? 'pointer' : 'default',
                                         }}
                                         className={`flex-1 rounded-full gap-2 text-xs md:text-sm bg-white/15 flex flex-row items-center justify-center cursor-pointer hover:bg-white/30 p-3`}>
                                        <SvgApple className={'w-6 h-6'}
                                                  style={{opacity: isCertified ? appleHover ? 0.3 : 1.0 : 0.1}}/>
                                        <div>
                                            Login with Apple
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="flex flex-col text-center gap-3 mt-5  items-center">
                            <div className='body2 font-body flex flex-row items-center justify-center'>
                                <p className='opacity-50'>
                                    One login for all
                                </p>
                                <AfterHourWordmark className={'h-4 w-16 mx-1 grayscale opacity-50'}/>
                                <p className='opacity-50'>
                                    apps.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
}

export default connect(mapStateToProps, null)(LoginCard);
