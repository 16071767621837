import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import MatchupTableCardTeamRow from './MatchupTableCardTeamRow';
import MatchupTableCardScoreBox from './MatchupTableCardScoreBox';
import SvgChevronDown from "../icons/SvgChevronDown";
import SvgTriangle from "../icons/SvgTriangle";
import PerformanceDifferenceGraph from "../../graphs/PerformanceDifferenceGraph";
import SelectedPickTab from "../bracketlive/SelectedPickTab";
import {connect} from 'react-redux';
import {DateTime} from "luxon";
import {getDurationUntilStart, getGameStartAndEndTimes} from "../utils/helpers";
import {renderGameTimer} from "../utils/timing";

const mapStateToProps = (state) => {
    return {
        userBracket: state.userReducer.userBracket,
        readonlyMasterBracket: state.userReducer.readonlyMasterBracket,
    }
}

function generateData() {
    const data = [];
    const startTime = new Date(0, 0, 0, 9, 30);
    const endTime = new Date(0, 0, 0, 16, 0);
    for (let time = new Date(startTime); time <= endTime; time.setMinutes(time.getMinutes() + 30)) {
        data.push({
            time: time.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}),
            percentageDifference: Math.random() * 10 - 5, // Random percentage difference between -5% to 5%
        });
    }
    return data;
}

const sampleData = generateData();

function MatchupTableCard(props) {
    const navigate = useNavigate();
    const [timerMessage, setTimerMessage] = useState(null);
    const isValidMatchup = props.matchup?.ticker1 && props.matchup?.ticker2 && !props.tbd;

    const handleCardClick = () => {
        if (isValidMatchup)
            navigate(`/matchup/${props.matchup.id + 1}`);
    };

    const gameNumber = props.matchup?.id;
    const realWinner = props.readonlyMasterBracket?.[props.matchup?.id]?.winner_id;
    const incorrectSelections = {
        ticker1: props.readonlyMasterBracket[gameNumber]?.ticker1 === props.userMatchups[gameNumber]?.ticker1 ? null : props.userMatchups[gameNumber]?.ticker1,
        ticker2: props.readonlyMasterBracket[gameNumber]?.ticker2 === props.userMatchups[gameNumber]?.ticker2 ? null : props.userMatchups[gameNumber]?.ticker2,
    }
    const isFirstRound = props.matchup?.id < 32


    useEffect(() => {
        const updateGameStatus = () => {
            const timerMessage = renderGameTimer(props.matchup?.id)
            setTimerMessage(timerMessage)
        };

        updateGameStatus();
        const intervalId = setInterval(updateGameStatus, 1000);

        return () => clearInterval(intervalId);
    }, [props.gameNumber]);

    return (
        <div key={props.gameNumber} className='rounded-xl bg-white/10 overflow-hidden'>
            {!isFirstRound &&
                <SelectedPickTab
                    lg
                    userTicker={props.userMatchups[gameNumber]?.ticker1}
                    actualTicker={props.readonlyMasterBracket[gameNumber]?.ticker1}
                    winner={props.readonlyMasterBracket[gameNumber]?.winner_id}
                    userWinner={props.userBracket[`g${gameNumber}`] === props.userMatchups[gameNumber]?.ticker1 ? props.userBracket[`g${gameNumber}`] : null}
                />
            }
            <div
                onClick={handleCardClick}
                className={`cursor-pointer w-full p-3 md:p-5  gap-3 flex flex-row items-center justify-between ${isValidMatchup && 'hover:bg-white/15'}`}
            >
                <div className='flex flex-col gap-3'>
                    <MatchupTableCardTeamRow gameNumber={props.matchup?.id} incorrectSelection={incorrectSelections.ticker1} realWinner={realWinner}
                                             isRealWinner={realWinner === props.matchup?.ticker1}
                                             userSelected={props.userBracket[`g${gameNumber}`] === props.matchup?.ticker1 ? true : null}
                                             topGame tbd={!props.matchup?.ticker1} ticker={props.matchup?.ticker1}/>
                    <MatchupTableCardTeamRow gameNumber={props.matchup?.id} incorrectSelection={incorrectSelections.ticker2} realWinner={realWinner}
                                             isRealWinner={realWinner === props.matchup?.ticker2}
                                             userSelected={props.userBracket[`g${gameNumber}`] === props.matchup?.ticker2 ? true : null}
                                             tbd={!props.matchup?.ticker2} ticker={props.matchup?.ticker2}/>
                </div>
                {/*<div className={'flex-1 flex flex-col items-center justify-center'}>*/}
                {/*    <PerformanceDifferenceGraph*/}
                {/*        ticker1={props.matchup?.ticker1}*/}
                {/*        ticker2={props.matchup?.ticker2}*/}
                {/*        width={200}*/}
                {/*        height={80}*/}
                {/*        data={sampleData}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className='flex flex-row items-center'>
                    {!!timerMessage &&
                        <div className='flex flex-row items-center justify-center mr-3 gap-2'>
                        <span className="relative flex h-2 w-2">
                          <span
                              className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
                          <span className="relative inline-flex rounded-full h-2 w-2 bg-yellow-500"></span>
                        </span>
                            <p className='text-center text-yellow-400 text-xs md:text-sm font-body font-medium'>
                                {timerMessage}
                            </p>
                        </div>
                    }
                    <div className='flex flex-col gap-3'>
                        <MatchupTableCardScoreBox realWinner={realWinner}
                                                  score={props.matchup?.ticker1_cached_score}
                                                  isRealWinner={realWinner === props.matchup?.ticker1} tbd={props.tbd}/>
                        <MatchupTableCardScoreBox realWinner={realWinner}
                                                  score={props.matchup?.ticker2_cached_score}
                                                  isRealWinner={realWinner === props.matchup?.ticker2} tbd={props.tbd}/>
                    </div>
                    <SvgChevronDown className={`-rotate-90 -mr-2 ml-1 ${!isValidMatchup && 'opacity-0'}`}/>
                </div>
            </div>
            {!isFirstRound &&
                <SelectedPickTab
                    lg
                    userTicker={props.userMatchups[gameNumber]?.ticker2}
                    actualTicker={props.readonlyMasterBracket[gameNumber]?.ticker2}
                    winner={props.readonlyMasterBracket[gameNumber]?.winner_id}
                    userWinner={props.userBracket[`g${gameNumber}`] === props.userMatchups[gameNumber]?.ticker2 ? props.userBracket[`g${gameNumber}`] : null}
                />
            }
        </div>
    );
}

export default connect(mapStateToProps, null)(MatchupTableCard);
