import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={29}
            height={33}
            viewBox="0 0 29 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_142_46)">
                <path
                    d="M28.5 13.649a13.131 13.131 0 01-7.675-2.457v11.175a10.163 10.163 0 11-8.762-10.069v5.62a4.662 4.662 0 103.262 4.45V.53h5.5c-.003.465.037.93.119 1.387a7.637 7.637 0 003.369 5.013A7.588 7.588 0 0028.5 8.186v5.463z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_142_46">
                    <path fill="currentColor" transform="translate(.5 .53)" d="M0 0H28V32H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default SvgComponent
