import AfterHourStu from "./brand/logos/AfterHourStu";
import SvgHelp from "./icons/SvgHelp";
import CollapseComponent from "./atoms/CollapseComponent";
import {getStueyPick} from "../api/matchups";
import React, {useEffect, useState} from "react";
import {wait} from "@testing-library/user-event/dist/utils";
import Spinner from "./atoms/Spinner";
import SvgClose from "./icons/SvgClose";


function StueyThinksSection({loading, stock1WinPercent, stock2WinPercent, masterBracket, gameNumber}) {
    const [stueyThinking, setStueyThinking] = useState(false)
    const [stueyOpen, setStueyOpen] = useState(false)
    const [phrase, setPhrase] = useState("");
    const [textOpacity, setTextOpacity] = useState("opacity-100");

    const handleHeaderClick = () => {
        if (stueyOpen) {
            setStueyThinking(false)
            setStueyOpen(false)
        } else {
            setStueyOpen(true)
            setStueyThinking(true)
            wait(1000).then(() =>
                setStueyThinking(false)
            )
        }
    }

    useEffect(() => {
        // Before fetching a new phrase, set the text to be fully transparent
        setTextOpacity("opacity-0");
        // Simulate a delay for the collapse animation
        wait(300).then(() => {
            const winner = stock1WinPercent >= stock2WinPercent ? masterBracket?.[gameNumber]?.ticker1 || "" : masterBracket?.[gameNumber]?.ticker2 || "";
            const loser = winner === masterBracket?.[gameNumber]?.ticker1 ? masterBracket?.[gameNumber]?.ticker2 : masterBracket?.[gameNumber]?.ticker1;
            const newPhrase = getStueyPick(winner, loser);
            setPhrase(newPhrase);
            // After fetching and setting the new phrase, wait a bit and then make the text opaque again
            wait(300).then(() => setTextOpacity("opacity-100"));
        });
        // Reset thinking and open states when gameNumber changes
        setStueyThinking(false);
        setStueyOpen(false);
    }, [stock1WinPercent, stock2WinPercent]);

    // const phrase = getStueyPick(stock1WinPercent >= stock2WinPercent ? masterBracket?.[gameNumber]?.ticker1 || "" : masterBracket?.[gameNumber]?.ticker2 || "", stock1WinPercent >= stock2WinPercent ? masterBracket?.[gameNumber]?.ticker2 || "" : masterBracket?.[gameNumber]?.ticker1 || "")
    return (
        <div onClick={handleHeaderClick}
             className='bg-yellow-400/20 group hover:bg-yellow-500/20 cursor-pointer md:mx-20 px-2 py-1 md:py-2 md:px-3 rounded-3xl'>
            <div
                className=' p-1 flex flex-row items-center gap-3 text-yellow-400'>
                <AfterHourStu className={'min-w-6 min-h-6 w-6 h-6 shake-animation'}/>
                <p className='font-body font-semibold'>
                    Ask what Stuey thinks?
                </p>
                <div
                    className={`${!stueyThinking && !stueyOpen && 'md:opacity-0'} group-hover:opacity-80  min-w-6 min-h-6 w-6 h-6 w-6 h-6 ml-auto flex flex-col items-center justify-center group-hover:bg-white/10 text-black rounded-full`}>
                    {(stueyOpen && !stueyThinking) ?
                        <SvgClose className={'w-5 h-5 text-white/50'}/>:
                        stueyThinking ?
                        <Spinner size={16}/>
                        :
                        <SvgHelp className={'w-5 h-5 text-yellow-400'}/>
                    }
                </div>
            </div>
            <CollapseComponent isOpen={stueyOpen && !stueyThinking}>
                {!loading &&
                    <div className={`flex flex-col px-2 mt-1 pb-2 ${textOpacity}`}>
                        <p className='text-sm md:text-md font-body font-medium'>
                            {phrase}
                        </p>
                    </div>
                }
            </CollapseComponent>
        </div>
    )
}

export default StueyThinksSection
