import React, {useEffect, useRef, useState} from 'react';
import BracketRoundHeaders from "../../components/bracket/BracketRoundHeaders";
import {connect} from 'react-redux';
import BracketLiveHalf from "./BracketLiveHalf";
import BracketLiveCenter from "./BracketLiveCenter";
import MatchPreviewModal from "../modals/MatchPreviewModal";
import FinaleStateOverlay from "../FinaleStateOverlay";

const mapStateToProps = (state) => {
    return {
        authUser: state.userReducer.authUser,
        userBracket: state.userReducer.userBracket,
        mobile: state.userReducer.mobile,
        user: state.userReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserBracket: (userBracket) => {
            dispatch({
                type: "SET_USER_BRACKET",
                userBracket
            })
        },
        updateLeaderboard: (username, winner) => {
            dispatch({
                type: "UPDATE_LEADERBOARD",
                username: username,
                winner: winner,
            })
        }
    }
}

function BracketLiveComponent({masterBracket, mobile, username, ...props}) {
    const scrollContainerRef = useRef(null); // Ref for the bracket body
    const headerContainerRef = useRef(null); // Ref for the header to apply scroll changes
    const [loading] = useState(false);
    const [userBracket, setUserBracket] = useState(props.userBracket);
    const [userMatchups, setUserMatchups] = useState([]);
    const [focusedGameNumber, setFocusedGameNumber] = useState(0);
    const [matchPreviewModalOpen, setMatchPreviewModalOpen] = useState(false);
    const [matchPreviewMatchup, setMatchPreviewMatchup] = useState(null)

    // Function to synchronize scroll position
    const handleScroll = () => {
        if (scrollContainerRef.current && headerContainerRef.current) {
            // Apply the scroll position from scrollContainerRef to headerContainerRef
            // console.log(scrollContainerRef.current.scrollLeft)
            headerContainerRef.current.scrollLeft = scrollContainerRef.current.scrollLeft;
        }
    };

    useEffect(() => {
        // Add scroll event listener to the scroll container
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
        }

        // Remove event listener on cleanup
        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const _generateUserMatchups = () => {
        const userMatchups = [];
        for (var i = 0; i < 32; i++)
            userMatchups.push({ticker1: props.liveBracket[i]?.ticker1, ticker2: props.liveBracket[i]?.ticker2});
        for (var i = 0; i < 62; i += 2) {
            const ticker1 = props.userBracket[`g${i}`];
            const ticker2 = props.userBracket[`g${i + 1}`];
            userMatchups.push({ticker1: ticker1, ticker2: ticker2})
        }
        return userMatchups
    }

    useEffect(() => {
        setUserMatchups(_generateUserMatchups);
    }, [props.userBracket])

    const openMatchPreviewModal = (matchup) => {
        setMatchPreviewMatchup(matchup)
        setMatchPreviewModalOpen(true)
        document.body.style.overflowY = 'hidden'
    }
    const closeMatchPreviewModal = () => {
        setMatchPreviewModalOpen(false)
        document.body.style.overflowY = 'scroll'
    }

    return (
        <>
            <div
                ref={headerContainerRef}
                className='sticky  pointer-events-none top-[4rem] z-[2] overflow-scroll hide-scrollbar'>
                <div
                    className=' min-w-[780px] md:min-w-[1100px] px-5  border-b border-white/10 bg-black/80 backdrop-blur'>
                    <BracketRoundHeaders columnWidth={'w-[160px]'}/>
                </div>
            </div>
            <div
                ref={scrollContainerRef}
                className={`overflow-x-scroll bg-white/5 w-full p-5 pb-20 flex flex-col gap-5 pb-32`}
                id={'scrollable-bracket'}>
                <div className='min-w-[780px] md:min-w-[1100px] relative'>
                    <BracketLiveHalf
                        loading={(loading)}
                        top
                        liveBracket={props.liveBracket}
                        mobile={mobile}
                        viewOnly={true}
                        userBracket={props.userBracket}
                        userMatchups={userMatchups}
                        setFocusedGameNumber={setFocusedGameNumber}
                        focusedGameNumber={focusedGameNumber}
                        openMatchPreviewModal={openMatchPreviewModal}
                    />
                    <BracketLiveCenter
                        loading={loading}
                        liveBracket={props.liveBracket}
                        mobile={mobile}
                        viewOnly={true}
                        userBracket={props.userBracket}
                        userMatchups={userMatchups}
                        setFocusedGameNumber={setFocusedGameNumber}
                        focusedGameNumber={focusedGameNumber}
                        openMatchPreviewModal={openMatchPreviewModal}
                    />
                    <BracketLiveHalf
                        loading={loading}
                        liveBracket={props.liveBracket}
                        mobile={mobile}
                        viewOnly={true}
                        userBracket={props.userBracket}
                        userMatchups={userMatchups}
                        setFocusedGameNumber={setFocusedGameNumber}
                        focusedGameNumber={focusedGameNumber}
                        openMatchPreviewModal={openMatchPreviewModal}
                    />
                </div>
            </div>
            <MatchPreviewModal
                isOpen={matchPreviewModalOpen}
                close={closeMatchPreviewModal}
                gameNumber={focusedGameNumber}
                masterBracket={props.liveBracket}
                viewOnly={true}
                mobile={mobile}
                preloadedMatchup={matchPreviewMatchup}
            />
            <FinaleStateOverlay
                mobile={mobile}
            />
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(BracketLiveComponent);
