import React from 'react';
import {cardClass, sectionClass} from '../consts/dummy';
import LeaderboardBanner from "../components/atoms/LeaderboardBanner";
import {Button} from "../components/atoms/Button";
import LeaderboardItem from "../components/leaderboard/LeaderboardItem";
import LeaderboardHeaders from "../components/leaderboard/LeaderboardHeaders";
import {Link} from "react-router-dom";
import LoadingSection from "../components/atoms/LoadingSection";

function LeaderboardWidget(props) {
    const paginatedStart = (parseInt(props?.tab) - 1) * 20 || 0;
    const paginatedEnd = paginatedStart + 20;
    const _leaderboardTop = props?.paginated ? props.leaderboard?.top.slice(paginatedStart, paginatedEnd) : props.leaderboard?.top;

    return (
        <div className={`${sectionClass}`}>
            <div className='flex flex-col items-center w-full'>
                <p className='text-4xl text-center mx-auto uppercase'>{props.label || 'Leaderboard'}</p>
                {props.description &&
                    <p className='text-center w-full font-body font-medium opacity-50'>
                        {props.description}
                    </p>
                }
            </div>
            <div className='flex flex-col gap-3 w-full'>
                {!props.hideBanner &&
                    <LeaderboardBanner rank={props.userBracket?.rank} leaderboard={props.leaderboard}/>
                }
                <div className={`${cardClass} relative font-body flex flex-col gap-0`} style={{gap: 12, padding: 0}}>
                    <LeaderboardHeaders/>
                    {!!props.local ?
                        <div className='flex flex-col gap-3 md:gap-3 w-full px-2 pb-5'>
                            {props.leaderboard.local.map((item, index) => 
                                <LeaderboardItem
                                     leaderboard={props.leaderboard?.local}
                                     index={index}
                                     key={`leaderboard-widget-local${index}`}
                                />
                            )}
                        </div>
                        :
                        !props.leaderboard?.top ?
                            <LoadingSection className={'h-80 w-full py-32'} type={'leaderboard'}/>
                            :
                            <div className='flex flex-col gap-3 md:gap-3 w-full px-2 pb-5'>
                                {_leaderboardTop?.slice(0, props.truncated ? (props.truncatedTo || 3) : 20).map((item, index) =>
                                    <LeaderboardItem leaderboard={props.leaderboard?.top}
                                                     index={(20 * (props?.tab - 1 || 0)) + index}
                                                     key={`leaderboard-widget${index}`}/>
                                )}
                            </div>
                    }
                </div>
                {!props.hideButton &&
                    <div className='w-full'>
                        <Button className='w-full' onClick={props.openShareModal}>
                            Share Your Bracket
                        </Button>
                    </div>
                }
                {!props.hideButton &&
                    <Link to={'/leaderboard'} className='w-full'>
                        <Button variant={'secondary'} className='w-full'>
                            Full leaderboard
                        </Button>
                    </Link>
                }
            </div>
        </div>
    );
}

export default LeaderboardWidget;
