import React from "react";
import SvgClock from "../icons/SvgClock";
import SvgStar from "../icons/SvgStar";
import SvgPodium from "../icons/SvgPodium";


function LeaderboardBanner(props) {
    // const rank = props.leaderboard?.rank;
    const rank =  props.rank;
    return (
        <div
            className='flex w-full flex-row font-body items-center justify-between rounded-3xl bg-yellow-400/30 text-yellow-400 text-sm md:text-lg font-semibold px-5 py-2'>
            <p>
                My bracket:
            </p>
            <div className='flex flex-row items-center gap-3'>
                <div className='flex flex-row text-sm items-center gap-1.5'>
                    <SvgPodium className={'w-4 h-4'}/>
                    {!rank ?
                        <p>
                            --
                        </p>
                        :
                        <p>
                            {rank === -1 ? '' : `${rank}${rank % 10 === 1 ? 'st' : rank % 10 === 2 ? 'nd' : rank % 10 === 3 ? 'rd' : 'th'} place`}
                        </p>
                    }
                </div>
                <div className='flex flex-row text-sm items-center gap-1'>
                    <SvgStar className={'w-4 h-4'}/>
                    <p>
                        Top {((rank / props.leaderboard?.total) * 100).toFixed(2)}%
                    </p>
                </div>
            </div>
        </div>
    )
}

export default LeaderboardBanner
