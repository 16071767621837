import React, {useState} from 'react';
import {pageClass} from "../consts/dummy";
import PageHeader from "../components/navigation/PageHeader";
import DownloadAfterHourCard from "../sections/cards/DownloadAfterHourCard";
import TextInputWrapper from "../components/atoms/TextInputWrapper";
import SvgMail from "../components/icons/SvgMail";
import LottieWrapper from "../components/LottieWrapper";
import hand from "../img/lottie/hand.json";
import {Button} from "../components/atoms/Button";
import {joinWaitlistToolate} from '../api/waitlist';

function GetRemindersPage() {
    const [email, setEmail] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const submitEmail = () => {
        joinWaitlistToolate(email);
        setEmailSent(true)
    }

    const emailRegex = /^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i;
    return (
        <div className={pageClass}>
            <div className='absolute top-0 left-0 right-0 h-[100dvh] z-[-1]'
                 style={{background: `linear-gradient(to bottom, ${'#ae61f7' + '40'}, ${'#ae61f7' + '25'}, black)`}}
            />

            <div className='flex flex-col gap-5'>
                <PageHeader
                    title={"We're already live. But don't miss out on the fun again!"}
                    desc={"We'll keep you up to date so you get in on the action next time."}
                    titleClass={'text-balance'}
                />
                <TextInputWrapper
                    id="email"
                    type="email"
                    disabled={emailSent}
                    placeholder="example@gmail.com"
                    icon={SvgMail}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <div className='relative w-full'>
                    {!emailSent &&
                        <div className='absolute -right-6 -top-4 rotate-[-80deg] z-[2]'>
                            <LottieWrapper json={hand} width={80}/>
                        </div>
                    }
                    <Button   disabled={!emailRegex.test(email) || emailSent} onClick={submitEmail} variant={'green'}
                            className={'w-full'}>
                        {emailSent ? 'Email sent!' : ' GET REMINDERS'}
                    </Button>
                </div>
            </div>
            <DownloadAfterHourCard

            />
        </div>
    );
}

export default GetRemindersPage;
