import React from 'react';
import BubbleTail from "../icons/custom/BubbleTail";
import SvgClose from "../icons/SvgClose";

function HelperTip(props) {
    return (
        <div className={`absolute group cursor-pointer rounded-lg px-2 ${props.className}`}>
            <div className='animate-bounce '>
                <div className='bg-yellow-400 text-black flex flex-row items-center gap-2 rounded-lg px-2 py-1'>
                    <p className='text-xs font-semibold'>
                        Tap on a matchup to view details
                    </p>
                    <SvgClose className={'w-4 h-4 opacity-30 group-hover:opacity-100'}/>
                </div>
                <BubbleTail className={'ml-12 h-2 text-yellow-400'}/>
            </div>
        </div>
    );
}

export default HelperTip;
