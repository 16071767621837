import axios from './axios'
import { DateTime } from 'luxon';
import store from '../Store';
import supabase from './supabase';

export const getScore = async (matchup, ticker) => {
    const {data, error} = await supabase.from("matchups").select(`ticker${ticker}_cached_score`).eq("id", matchup);
    return data[0][`ticker${ticker}_cached_score`];
}

// deprecated
export const getLiveData = async (tickers) => {
    const endpoint = `/stock/live?tickers=${tickers}`
    try {
        const config = {
        }
        const res = await axios.get(endpoint, config);
        return res.data;
    } catch (err) {
        console.log(err)
    }   
}

// deprecated
export const getHistoricData = async (ticker, date="", start="", end="", multiplier=5, timespan="minute", span=null) => {
    if (!date && !start && !end) 
        start = DateTime.now().minus({weeks: 1})

    // cached from local storage
    const cachedChartData = store.getState().userReducer.chartData;
    if (!!span && !!cachedChartData?.[ticker]?.[span])
        return cachedChartData[ticker][span]

    // cached from supabase
    if (start === DateTime.now().minus({weeks: 1}).toFormat("yyyy-MM-dd")) {
        const {data, error} = await supabase.from("historic_data").select("*").eq("ticker", ticker);
        const dataArr = data[0].data;
        const chartData = {[ticker]: {'1w': dataArr}}
        await store.dispatch({
            type: "UPDATE_CHART_DATA",
            chartData
        })
        return {results: dataArr}
    }

    const endpoint = `/stock/historic?ticker=${ticker}&date=${date}&start=${start}&end=${end}&multiplier=${multiplier}&timespan=${timespan}`;
    try {
        const config = {
        };
        const res = await axios.get(endpoint, config);
        return res.data;
    } catch (err) {
        console.log(err)
    }
}
