export const baseBlack70 = "";
export const baseWhite70 = "";
export const brightRed = "";
export const gradientGreenEnd = "#00cf00";
export const gradientGreenStart = "#007f00";
export const gradientPinkEnd = "";
export const gradientPinkMid = "";
export const gradientPinkStart = "";
export const gradientRedEnd = "#cf0000";
export const gradientRedStart = "#7f0000";
export const gradientYellowEnd = "";
export const gradientYellowEndMid = "";
export const gradientYellowStart = "";
export const gradientYellowStartMid = "";
export const neonGreen = "";
export const neonYellow = "";
export const gradientGrayEnd = "#d0d0d0";
export const gradientGrayStart = "#d0d0d0";
export const scrubber = "#b3b3b3";
