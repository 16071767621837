import React, {useEffect, useRef, useState} from 'react';
import {Button} from "../../atoms/Button";
import BracketCreationProgressBar from "./BracketCreationProgressBar";
import {getGameDateByNumber, humanizeRoundNumber} from "../../utils/strings";
import {connect} from "react-redux";
import MatchupBody from "../../matchupdetails/MatchupBody";
import SvgExpand from "../../icons/SvgExpand";
import {wait} from "@testing-library/user-event/dist/utils";
import SvgShrink from "../../icons/SvgShrink";
import ConfirmationModal from "../../modals/ConfirmationModal";
import LottieWrapper from "../../LottieWrapper";
import firstroundstart from '../../../img/lottie/firststarted.json'
import firstroundhalfway from '../../../img/lottie/halfwayfirst.json'
import secondroundstarted from '../../../img/lottie/secondstarted.json'
import thirdstarted from '../../../img/lottie/thirdstarted.json'
import quartersstarted from '../../../img/lottie/quartersstarted.json'
import semisstarted from '../../../img/lottie/semisstarted.json'
import championship from '../../../img/lottie/championship.json'
import StonkMadnessLogo from "../../brand/logos/StonkMadnessLogo";
import SvgVisibility from "../../icons/SvgVisibility";
import RulesModal from "../../modals/RulesModal";
import PickButtonsHand from "../../atoms/PickButtonsHand";

function mapStateToProps(state) {
    return {
        mobile: state.userReducer.mobile
    };
}

function BracketCreationBottomSheet(props) {
    const ref = useRef(null);
    const [topPosition, setTopPosition] = useState('100vh');
    const [isExpanded, setIsExpanded] = useState(true)
    const scrollableSectionRef = useRef(null);
    const [confirmExitModalOpen, setConfirmExitModalOpen] = useState(false)
    const [isActivelySelectingSide, setIsActivelySelectingSide] = useState()
    const [isPickerVisible, setIsPickerVisible] = useState(true)
    const [rulesModalOpen, setRulesModalOpen] = useState(false)

    const toggleExpand = () => {
        setIsExpanded(!isExpanded)
        wait(0).then(() =>
            updateTopPosition()
        )
    }
    const updateTopPosition = () => {
        if (ref.current) {
            const componentHeight = ref.current.clientHeight + (!props.mobile && 24);
            const viewportHeight = window.innerHeight;
            const topValue = `${viewportHeight - componentHeight}px`;
            setTopPosition(topValue);
        }
    };

    useEffect(() => {
        updateTopPosition();
        window.addEventListener('resize', updateTopPosition);
        return () => {
            window.removeEventListener('resize', updateTopPosition);
        };
    }, []);

    useEffect(() => {
        if (scrollableSectionRef.current) {
            scrollableSectionRef.current.scrollTop = 0;
        }
    }, [props.currentGame]);


    const openConfirmExitModal = () => {
        setConfirmExitModalOpen(true)
    }

    const closeConfirmExitModal = () => {
        setConfirmExitModalOpen(false)
    }

    const handleClickResume = () => {
        setIsPickerVisible(true)
        wait(0).then(() =>
            updateTopPosition()
        )
    }

    const openRulesModal = () => {
        setRulesModalOpen(true)
    }

    const closeRulesModal = () => {
        setRulesModalOpen(false)
    }


    if ((props.currentGame?.ticker1 && props.currentGame?.ticker2) || props.mobile) {
        return (
            <>
                <div
                    className='fixed top-0 left-0 right-0 h-[76px] z-[100] backdrop-blur-lg bg-black  flex flex-row items-center gap-3 px-5 justify-center'>
                    <div className='flex max-w-[620px] w-full flex-row items-center justify-between '>
                        <div className='flex flex-row items-center gap-2'>
                            <StonkMadnessLogo className={'h-[60px]'}/>
                            <p className='uppercase text-sm md:text-lg' style={{lineHeight: .93}}>
                                Bracket<br/>Creation
                            </p>
                            {isPickerVisible ?
                                <Button size={'sm'} onClick={openRulesModal} variant={'secondary'}>
                                    Rules
                                </Button>
                                :
                                <Button size={'sm'} onClick={handleClickResume} className='shake-animation-light'>
                                    Resume
                                </Button>
                            }
                        </div>
                        <div className='flex flex-row gap-1 md:gap-3'>
                            <Button size={'sm'} onClick={openConfirmExitModal} variant={'secondary'}>
                                Leave
                            </Button>
                        </div>
                    </div>
                </div>
                <div
                    ref={ref}
                    className={props.mobile ? 'fixed left-0 right-0 z-50 p-4 pt-12 items-center flex flex-col' :
                        // 'hidden'
                        `fixed left-1/2 z-[2] -translate-x-1/2 w-[90vw] backdrop-blur-sm ${isExpanded ? 'max-w-[720px]' : 'max-w-[700px]'} rounded-3xl p-5 border border-white/10 ${props.gameNumber === 62 && 'border-yellow-400'}`
                    }
                    style={{
                        transition: '150ms ease',
                        top: props.mobile ? topPosition : topPosition,
                        opacity: props.matchPreviewModalOpen && !props.mobile && 0,
                        background: props.gameNumber === 62 ? 'linear-gradient(to top, black, black, black, black, #f8ce3350, #f8ce3300)' : 'linear-gradient(to top, black, black, black, black, black, #00000000)',
                        display: !isPickerVisible && 'none'
                    }}
                >
                    <div className='flex flex-col w-full gap-2'>
                        <div
                            className='flex w-full flex-row items-center justify-between uppercase md:text-lg font-semibold'>
                            <div className='flex flex-row items-center gap-2'>
                                <p>
                                    {humanizeRoundNumber(props.currentGame?.round)}: <span
                                    className='opacity-70'>{props.mobile ? 'GM' : 'GAME'} {props.gameNumber + 1}</span>
                                </p>
                                {props.gameNumber + 1 >= 2 &&
                                    <div className='animate-pulse'>
                                        <Button size={'sm'} variant={'secondary'}
                                                className={'mb-1 shake-animation-light'}
                                                onClick={props.onBack}>
                                            Back
                                        </Button>
                                    </div>
                                }
                            </div>
                            <div className='flex flex-row items-center gap-2'>
                                <p className='hidden md:block'>
                                    {getGameDateByNumber(props.gameNumber)}
                                </p>
                                <p className='md:hidden'>
                                    {getGameDateByNumber(props?.gameNumber || 0).slice(4)}
                                </p>
                                {/*Todo: Cancel repick. Only show if you already had a bracket. Should not show on first creation unless it works differently and resets wizard back to zero*/}
                                <Button
                                    size={'iconsm'}
                                    variant={'secondary'}
                                    onClick={() => setIsPickerVisible(false)}
                                >
                                    <SvgVisibility/>
                                </Button>
                            </div>
                        </div>
                        <BracketCreationProgressBar gameNumber={props.gameNumber}/>
                        {/*<div className='absolute left-0 right-0 flex flex-col items-center top-4  z-[3]'>*/}
                        {/*    <div className=''>*/}
                        {/*        <Button size={'sm'} variant={'secondary'} className={''}*/}
                        {/*                onClick={() => null}>*/}
                        {/*            Go back*/}
                        {/*        </Button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className='relative'>
                            {/*{scroll this section to top when props.currentGame changes}*/}
                            <div
                                ref={scrollableSectionRef}
                                className={`max-w-[460px] md:w-full md:max-w-full relative z-10 p-2 rounded-2xl border border-neutral-900 overflow-x-hidden overflow-y-scroll ${isExpanded ? 'max-h-[62dvh] md:max-h-[65vh]' : 'max-h-[30dvh] md:max-h-[35dvh]'} bg-black text-white flex flex-col gap-8 pb-5`}
                            >
                                {(props.gameNumber === 0) &&
                                    <div
                                        className='flex pointer-events-none flex-col items-center justify-center absolute top-16 left-0 right-0 z-[3] scale-[2]'>
                                        <LottieWrapper speed={2} loop={false} width={180} height={88}
                                                       json={firstroundstart}/>
                                    </div>
                                }
                                {props.showMilestoneLottie &&
                                    <div
                                        className='flex pointer-events-none flex-col items-center justify-center absolute top-28 left-0 right-0 z-[3] scale-[2]'>
                                        <LottieWrapper speed={2} loop={false} width={160} json={
                                            props.showMilestoneLottie === 16 ? firstroundhalfway :
                                                props.showMilestoneLottie === 32 ? secondroundstarted :
                                                    props.showMilestoneLottie === 48 ? thirdstarted :
                                                        props.showMilestoneLottie === 56 ? quartersstarted :
                                                            props.showMilestoneLottie === 60 ? semisstarted :
                                                                championship
                                        }/>
                                    </div>
                                }
                                <div className='absolute backdrop-blur-lg top-0 left-0 right-0 -bottom-[800px] z-[-1]'
                                     style={{background: `linear-gradient(-180deg, ${props.currentGame?.[props.currentGame?.ticker1]?.color + '20'}, ${props.currentGame?.[props.currentGame?.ticker2]?.color + '20'}, black)`}}>
                                </div>
                                <MatchupBody
                                    gameNumber={props.gameNumber}
                                    masterBracket={props.masterBracket}
                                    preloadedMatchup={props.masterBracket?.[props.gameNumber]}
                                    isPreview={true}
                                    isBottomSheet={true}
                                    onPick={props.onPick}
                                    isActivelySelectingSide={isActivelySelectingSide}
                                    isCreatingBracket={true}
                                />
                            </div>
                            <div onClick={toggleExpand}

                                 className='absolute z-10 top-2 right-2 cursor-pointer opacity-70 hover:opacity-100'>
                                {isExpanded ?
                                    <SvgShrink/>
                                    :
                                    <SvgExpand/>
                                }
                            </div>
                            <div className='w-full flex flex-row gap-5 items-center justify-center relative'>
                                {props.gameNumber === 0 &&
                                    <PickButtonsHand/>
                                }
                                <Button size={'sm'} className={'block md:hidden w-full mt-2'}
                                        onMouseDown={() => {
                                            setIsActivelySelectingSide('left')
                                        }}
                                        onMouseUp={() => setIsActivelySelectingSide(false)}
                                        onMouseLeave={() => setIsActivelySelectingSide(false)}
                                        onTouchStart={() => {
                                            setIsActivelySelectingSide('left')
                                        }}
                                        onTouchCancel={() => setIsActivelySelectingSide(false)}
                                        onClick={() => props.onPick(props.gameNumber, props.currentGame?.ticker1)}>
                                    Pick {props.currentGame?.ticker1}
                                </Button>
                                <Button size={'md'} className={'hidden md:block w-full mt-3'}
                                        onMouseDown={() => setIsActivelySelectingSide('left')}
                                        onMouseUp={() => setIsActivelySelectingSide(false)}
                                        onTouchStart={() => {
                                            setIsActivelySelectingSide('left')
                                        }}
                                        onTouchCancel={() => setIsActivelySelectingSide(false)}
                                        onClick={() => props.onPick(props.gameNumber, props.currentGame?.ticker1)}>
                                    Pick {props.currentGame?.ticker1}
                                </Button>
                                <Button size={'sm'} className={'block md:hidden w-full mt-2'}
                                        onMouseDown={() => setIsActivelySelectingSide('right')}
                                        onMouseUp={() => setIsActivelySelectingSide(false)}
                                        onTouchStart={() => {
                                            setIsActivelySelectingSide('right')
                                        }}
                                        onTouchCancel={() => setIsActivelySelectingSide(false)}
                                        onClick={() => props.onPick(props.gameNumber, props.currentGame?.ticker2)}>
                                    Pick {props.currentGame?.ticker2}
                                </Button>
                                <Button size={'md'} className={'hidden md:block w-full mt-3'}
                                        onMouseDown={() => setIsActivelySelectingSide('right')}
                                        onMouseUp={() => setIsActivelySelectingSide(false)}
                                        onTouchStart={() => {
                                            setIsActivelySelectingSide('right')
                                        }}
                                        onTouchCancel={() => setIsActivelySelectingSide(false)}
                                        onClick={() => props.onPick(props.gameNumber, props.currentGame?.ticker2)}>
                                    Pick {props.currentGame?.ticker2}
                                </Button>
                            </div>
                        </div>
                        <div className='flex flex-col items-center justify-center absolute top-28 left-0 right-0 z-[3]'>
                            <LottieWrapper speed={1} width={200} height={100} json={firstroundhalfway}/>
                        </div>
                    </div>
                    <ConfirmationModal
                        isOpen={confirmExitModalOpen}
                        close={closeConfirmExitModal}
                        title={'Are you sure you want to exit bracket creation mode?'}
                        description={'If you had a bracket before, we\'ll keep your old one. If this is your first rodeo, make sure you\n come back to submit a bracket before the deadline.'}
                    />
                    <RulesModal
                        isOpen={rulesModalOpen}
                        mobile={props.mobile}
                        close={closeRulesModal}
                    />
                </div>
            </>
        );
    }
}

export default connect(mapStateToProps)(BracketCreationBottomSheet);
