import React from 'react';
import StonkMadnessLogo from "../brand/logos/StonkMadnessLogo";
import AfterHourWordmark from "../brand/logos/AfterHourWordmark";
import FindUsOnSocials from "../atoms/FindUsOnSocials";
import {WEBSITE_LINK} from "../../consts/dummy";

function Footer() {
    return (
        <div
            className='flex flex-col gap-8 max-w-[620px] items-center w-full  px-4 rounded-t-3xl py-8 pb-32 relative'
        >
            <div className='absolute top-0 left-0 bottom-0 right-0 blur-xl z-[-1]'
                 style={{background: 'linear-gradient(to top, #35d98440, #35d98410, #00000000)',}}
            >

            </div>
            <div className='flex flex-col gap-3 items-center'>
                <StonkMadnessLogo className={'h-[120px] md:h-[160px] mb-4'}/>
                <div className='flex flex-col items-center gap-0 -mt-2'>
                    <p className='text-xs uppercase opacity-50'>
                        Presented by
                    </p>
                    <a href={WEBSITE_LINK} target="_blank" rel={'noreferrer'}>
                        <AfterHourWordmark className={'w-24 h-12'}/>
                    </a>
                </div>
            </div>
            <FindUsOnSocials/>
            <p className='text-[12px] font-body opacity-50 text-center'>
                Stonk Madness is not associated with the NCAA or March Madness in any way nor intended to be financial
                advice.
                Predictions and results are for entertainment purposes only. Investing involves risk and investments may
                lose value.
                Past performance does not guarantee future results.

                The logos of various companies are for informational purposes only. Stonk Madness is not affiliated
                with,
                sponsored by, or endorsed by these companies. All logos are trademarks of their respective owners and
                are used under the principles of fair use. We make no claim of ownership over these trademarks.

                AfterHour © 2024 All rights reserved. This website is operated by AfterHour Inc. AfterHour is a
                technology platform, not a registered broker-dealer or registered investment adviser, and does not
                provide investment advice.
            </p>
        </div>
    );
}

export default Footer;
