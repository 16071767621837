import React from 'react';

function ScheduleDayRow(props) {
    return (
        <div className='flex flex-row items-center text-right text-sm font-semibold'>
            <div className='flex-1 text-left uppercase text-xl'>
                {props.day.title}
            </div>
            <p className='flex-[0.5] font-body text-lg'>
                {props.day.stocksLeft}
            </p>
            <p className='flex-[0.75] font-body text-lg'>
                {props.day.date}
            </p>
        </div>
    );
}

export default ScheduleDayRow;
