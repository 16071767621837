import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={6}
            height={19}
            viewBox="0 0 6 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path fill={'currentColor'} strokeWidth={.5} stroke={'#ffffff40'} d="M2.497.544L5.5.5l-2.503 18H.009L2.497.545z"/>
        </svg>
    )
}

export default SvgComponent
