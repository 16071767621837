import React from 'react';
import BracketLiveEightBatch from "./BracketLiveEightBatch";
import BracketLiveGameCard from "./BracketLiveGameCard";

function BracketLiveLeftQuarter({
                                    start,
                                    liveBracket,
                                    openMatchPreviewModal,
                                    viewOnly,
                                    setFocusedGameNumber,
                                    loading,
                                    userMatchups,
                                    focusedGameNumber,
                                }) {
    return (
        <div className='flex flex-row items-center'>
            <div className='flex flex-col gap-8 -mr-8'>
                <BracketLiveEightBatch start={start} liveBracket={liveBracket}
                                       openMatchPreviewModal={openMatchPreviewModal} viewOnly={viewOnly}
                                       userMatchups={userMatchups}
                                       loading={loading}
                                       setFocusedGameNumber={setFocusedGameNumber}
                                       focusedGameNumber={focusedGameNumber}
                />
                <BracketLiveEightBatch start={start + 4} liveBracket={liveBracket}
                                       openMatchPreviewModal={openMatchPreviewModal} viewOnly={viewOnly}
                                       userMatchups={userMatchups}
                                       loading={loading}
                                       setFocusedGameNumber={setFocusedGameNumber}
                                       focusedGameNumber={focusedGameNumber}
                />
            </div>
            <div className='relative'>
                <div
                    className='absolute top-0 flex flex-col justify-center text-xl md:text-2xl bottom-0 -left-48 w-40 text-center  items-center uppercase'>
                    <p className='text-xs -mt-2 opacity-50 -mb-0'>
                        {start === 0 ? 'Conference 1' : start === 8 ? "Conference 2" : start === 16 ? 'Conference 3' : 'Conference 4'}
                    </p>
                    <p className='my-0' style={{lineHeight: .9}}>
                        {start === 0 ? "Pelosi's Picks" : start === 8 ? "Retail Therapy" : start === 16 ? 'Chips and Salsa' : 'Our Intern Quit'}
                    </p>
                </div>
                <BracketLiveGameCard tbd gameNumber={(start / 8) + 56} liveBracket={liveBracket}
                                     openMatchPreviewModal={openMatchPreviewModal}
                                     userMatchups={userMatchups}
                                     viewOnly={viewOnly} loading={loading}
                                     setFocusedGameNumber={setFocusedGameNumber}
                                     focusedGameNumber={focusedGameNumber}
                />
            </div>
        </div>

    );
}

export default BracketLiveLeftQuarter;
