export const federalHolidays = [
    "2024-01-01", // New Year's Day
    "2024-01-15", // Martin Luther King Jr. Day
    "2024-02-19", // Presidents' Day
    "2024-05-27", // Memorial Day
    "2024-07-04", // Independence Day
    "2024-09-02", // Labor Day
    "2024-10-14", // Columbus Day
    "2024-11-11", // Veterans Day
    "2024-11-28", // Thanksgiving Day
    "2024-12-25"  // Christmas Day
];

