import React, {useState} from 'react';
import LamboModal from "../components/modals/LamboModal";
import SvgMegaphone from "../components/icons/SvgMegaphone";
import {PERFECT_REQUIREMENT, PERFECT_TOTAL} from "../consts/dummy";

function PerfectRequirementsCard() {
    const [lamboModalOpen, setLamboModalOpen] = useState(false)

    const openLamboModal = () => {
        setLamboModalOpen(true)
    }
    const closeLamboModal = () => {
        setLamboModalOpen(false)
    }

    const total = PERFECT_TOTAL
    const requirement = PERFECT_REQUIREMENT

    return (
        <div className='flex flex-col gap-0 items-center -mt-2' onClick={openLamboModal}>
            <p className='font-body text-xl md:text-2xl font-medium'>
                Lamborghini Huracan
            </p>
            <div
                className='flex flex-col items-center mt-1 bg-green-500/30 rounded-xl p-2 px-3 cursor-pointer hover:bg-green-500/40'>
                <p className='font-body text-sm text-green-500 -mt-1 font-semibold'>
                    Perfect Bracket Grand Prize
                </p>
                <p className='font-body font-semibold text-md'>
                    {requirement < total && <span className='opacity-50 line-through'>{total}</span>} <span className='font-bold'>{requirement}</span> correct picks
                </p>
                <div className='flex flex-row items-center gap-1 opacity-80'>
                    <SvgMegaphone className={'w-4 h-4'}/>
                    <p className='font-body text-xs'>
                        <span className='hidden md:inline'>Click</span><span
                        className='inline md:hidden'>Tap</span> to see how to increase odds
                    </p>
                </div>
            </div>
            <LamboModal
                isOpen={lamboModalOpen}
                close={closeLamboModal}
            />
        </div>
    );
}

export default PerfectRequirementsCard;
