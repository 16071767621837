import React, {useEffect, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import LoginCard from "../components/auth/LoginCard";
import StonkMadnessLogo from "../components/brand/logos/StonkMadnessLogo";
import SignUpCard from "../components/auth/SignUpCard";
import RandomSticker from "../components/RandomSticker";

const AuthenticationPage = (props) => {
    const [activeTab, setActiveTab] = useState('Login'); // 'Login' or 'Signup'

    const toggleTab = () => {
        setActiveTab(prevActiveTab => prevActiveTab === 'Login' ? 'Signup' : 'Login');
    };

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        const activeTabFromQuery = queryParameters.get("activeTab");
        if (activeTabFromQuery === "signup") {
            setActiveTab('Signup');
        }
    }, []);

    const commonProps = {history: props.history, mobile: props.mobile, toggleTab: toggleTab};

    return (
        <div
            className="min-h-[85dvh] flex flex-col relative items-center justify-center w-full mb-5"
            style={{background: `linear-gradient(-180deg, black, #0b3f1e`}}
        >
            <div className='relative'>
                <AnimatePresence mode={'wait'}>
                    {activeTab === 'Login' ? (
                        <motion.div
                            key="login"
                            initial={{opacity: 0, y: 20}}
                            animate={{opacity: 1, y: 0}}
                            exit={{opacity: 0, y: 50}}
                            transition={{duration: 0.3, ease: "easeInOut"}}
                        >
                            <LoginCard {...commonProps} />
                        </motion.div>
                    ) : (
                        <motion.div
                            key="signup"
                            initial={{opacity: 0, y: 20}}
                            animate={{opacity: 1, y: 0}}
                            exit={{opacity: 0, y: 50}}
                            transition={{duration: 0.3, ease: "easeInOut"}}
                        >
                            <SignUpCard {...commonProps} />
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
}

export default AuthenticationPage;
