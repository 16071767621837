import React, {useEffect} from 'react';
import {connect} from "react-redux";

import SvgClose from "../icons/SvgClose";
import StonkMadnessLogo from "../brand/logos/StonkMadnessLogo";
import AfterHourWordmark from "../brand/logos/AfterHourWordmark";
import {Link, useLocation} from "react-router-dom";
import {authenticatedRoutes, routes, WEBSITE_LINK} from "../../consts/dummy";
import {Button} from "../atoms/Button";
import FindUsOnSocials from "../atoms/FindUsOnSocials";

function mapStateToProps(state) {
    return {
        waitlistModalOpen: state.userReducer.waitlistModalOpen,
        authUser: state.userReducer.authUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleWaitlistModal: () => {
            dispatch({
                type: "TOGGLE_WAITLIST_MODAL",
            })
        },
    }
}

function NavMenu(props) {
    const location = useLocation();

    useEffect(() => {
        props.close();
    }, [location.pathname]);

    return (
        <div
            className={`fixed bg-black top-0 z-50 duration-300 ${props.open ? 'h-[100dvh] overflow-y-scroll border-b-4  md:border-b-8 border-green-500' : 'h-0 overflow-hidden'} w-full`}
            style={{background: 'linear-gradient(13deg, #0b3f1e, black)'}}
        >
            <div className='flex flex-col items-center w-full px-4 mt-5'>
                <div onClick={props.close}
                     className='flex flex-col w-full items-end pt-3 opacity-80 hover:opacity-100 cursor-pointer'>
                    <SvgClose className={'w-8 h-8'}/>
                </div>
                <div className='flex flex-col gap-3 items-center -mt-10 md:mt-2'>
                    <StonkMadnessLogo className={'h-[80px] md:h-[100px]'}/>
                    <div className='flex flex-col items-center gap-0 -mt-2'>
                        <p className='text-xs uppercase opacity-50'>
                            Presented by
                        </p>
                        <a href={WEBSITE_LINK} target="_blank" rel={'noreferrer'}>
                            <AfterHourWordmark className={'w-20 h-8'}/>
                        </a>
                    </div>
                </div>
                <div className='py-8 flex flex-col items-center gap-4'>
                    {(!!props.authUser ? authenticatedRoutes : routes).map((route, index) => {
                        const isActive = location.pathname === route.route;
                        if (route.divider) {
                            return (
                                <div key={'divider'} className='w-full h-[1px] bg-white/20'/>
                            )
                        }
                        return (
                            <Link to={route.route} key={route.title}>
                                <p className={`text-2xl uppercase ${isActive ? '' : `opacity-50 hover:opacity-100 cursor-pointer ${!!props.authUser && (index > 3 && 'text-xl')}`}`}>
                                    {route.title}
                                </p>
                            </Link>
                        );
                    })}
                    {!props.authUser &&
                        <Link to={'/hub'} className={'mb-2'}>
                            <Button variant={'green'}>
                                Login
                            </Button>
                        </Link>
                    }
                    <FindUsOnSocials/>
                </div>
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);
