import React from 'react';
import ScheduleDayRow from "./ScheduleDayRow";
import trophy from "../img/stickers/SM_TROPHY.png";

function ScheduleRoundBlock(props) {
    const round = props.round
    const isChampionship = round.title === 'Championship'
    return (
        <div className={`flex flex-col gap-1 ${isChampionship && 'bg-yellow-400/30 rounded-xl p-4 mt-3'}`}>
            <div className='flex flex-row items-center justify-between relative'>
                <p className='text-yellow-400 text-2xl uppercase'>
                    {round.title}
                </p>
                {!isChampionship &&
                    <p className='text-sm text-yellow-400 font-body'>
                        {round.gamesPerDay} games{(round.title !== 'Semifinals' && round.title !== 'Third Round') && '/day'}
                    </p>
                }
                {isChampionship &&
                    <img alt={'trophy'} src={trophy} className={'absolute w-20 md:w-20 -top-12 right-0 md:-right-6 rotate-6'}/>
                }
            </div>
            <div
                className='flex flex-row items-center font-body text-right text-sm font-semibold opacity-50'>
                <div className='flex-1'/>
                <p className='flex-[0.5]'>
                    Stocks left
                </p>
                <p className='flex-[0.75]'>
                    Date
                </p>
            </div>
            {round.days.map(day =>
                <ScheduleDayRow day={day} key={day.title}/>
            )}
        </div>
    );
}

export default ScheduleRoundBlock;
