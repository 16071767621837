import React from 'react';
import {Button} from '../atoms/Button';
import ModalComponent from '../atoms/ModalComponent';
import StockLogo from "../atoms/StockLogo";
import LottieWrapper from "../LottieWrapper";
import crown from "../../img/lottie/crown.json";
import {getConferenceFromTicker} from "../../consts/dummy";

function BracketConfirmationModal(props) {
    const champtionshipMatchup = !!props.masterBracket && props.masterBracket[62]
    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'md'}
            toggle={props.close}
        >
            <div className="flex flex-col gap-3 items-center relative">
                <p className='text-2xl mb-3'>
                    SUBMIT THIS BRACKET!
                </p>
                <div className='flex flex-row items-center gap-3'>
                    <div className='relative bg-yellow-400/20 px-5 py-2 rounded-xl'>
                        <div className='relative'>
                            <StockLogo ticker={champtionshipMatchup.winner_id} className={'w-20 h-20'} border/>
                            <div className='absolute -right-4 -top-6 rotate-12'>
                                <LottieWrapper json={crown} width={80}/>
                            </div>
                        </div>
                        <p className='uppercase text-center mt-2'>
                            Winner
                        </p>
                    </div>
                </div>
                <p className='uppercase -mb-1 mt-1'>
                    Conference Champions
                </p>
                <div className='flex flex-row items-center justify-center gap-5 w-full'>
                    {[56, 57, 58, 59].map((gameNumber) =>
                        <div
                            key={gameNumber + 'conf-champ'}
                            className={'font-body text-xs text-center max-w-[80px] flex flex-col flex-1 truncate'}>
                            <div className='relative mx-auto'>
                                <StockLogo ticker={props.masterBracket[gameNumber]?.winner_id} className={'w-12 h-12'}
                                           border/>
                            </div>
                            <p className='uppercase text-center font-semibold mt-1'>
                                {props.masterBracket[gameNumber]?.winner_id}
                            </p>
                            <p className='truncate mb-2  opacity-70'>
                                {getConferenceFromTicker(props.masterBracket[gameNumber]?.winner_id)?.name}
                            </p>
                        </div>
                    )}
                </div>
                <p className='font-body text-center text-xs text-balance'>
                    You are about to submit your bracket for StonkMadness. Only US residents over the age of 18 are
                    eligible to win prizes. By submitting your bracket, you are agreeing to the
                    <a href="https://zyidwzbarunqmcnmfukk.supabase.co/storage/v1/object/public/public_assets/Stonk%20Madness%202024%20Contest%20Rules%20(1).pdf" target="_blank" className="text-sky-500"> Official Contest Rules</a>, 
                    <a href="https://afterhour.com/terms" target="_blank" className="text-sky-500"> Terms and Conditions</a>, and
                    <a href="https://afterhour.com/privacy" target="_blank" className="text-sky-500"> Privacy Policy</a>.
                </p>
                <Button
                    variant='default'
                    onClick={props.onClick}
                    className='w-full animate-pulse'
                >
                    Submit Bracket
                </Button>
                <Button onClick={props.close} variant={'ghost'} size={'sm'} className='w-full mb-1'>
                    Cancel
                </Button>
            </div>
        </ModalComponent>
    )
}

export default BracketConfirmationModal;
