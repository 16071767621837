import axios from "./axios";
import store from "../Store";
import supabase from "./supabase";

export const getMasterBracket = async () => {
    const endpoint = `/brackets/master`

    try {
        const config = {
        }
        const res = await axios.get(endpoint, config)
        return res.data;
    } catch (err) {
        console.log(err)
    }

}

// export const saveUserBracket = async (authUser, userBracket) => {
//     const endpoint = `/brackets/submit`
//     try {
//         const config = {
//             headers: {
//                 jwt: authUser.sbToken
//             }
//         }
//         const res = await axios.post(endpoint, {bracket: userBracket}, config);
//         return res.data;
//     } catch (err) {
//         console.log(err)
//     }
// }

export const fetchBracket = async (authUser, username) => {
    const {data, error} = await supabase.from("brackets").select("*").eq("username", username);
    return data;
}

export const getLeaderboard = async (authUser) => {
    if (store.getState().userReducer.leaderboard?.top)
        return store.getState().userReducer.leaderboard;

    const endpoint = `/brackets/leaderboard?username=${authUser.displayName}`
    try {
        const config = {
        }
        const res = await axios.get(endpoint, config);
        await store.dispatch({
            type: "SET_LEADERBOARD",
            leaderboard: res.data,
        })
        return res.data;
    } catch (err) {
        console.log(err)
    }
}
