import React from 'react';

function SocialButton({children, props, link}) {
    return (
        <a href={link} target='_blank' rel='noreferrer'>
            <div className={`bg-white/10 hover:bg-yellow-400/30 hover:text-yellow-400 cursor-pointer rounded-full ${props?.sm ? 'w-8 h-8' : 'w-12 h-12'} flex flex-col items-center justify-center`}>
                {children}
            </div>
        </a>
    );
}

export default SocialButton;
