export const hasLowercaseCharacter = string => {
    return /[a-z]/.test(string);
};

export const hasUppercaseCharacter = string => {
    return /[A-Z]/.test(string);
};

export const hasDigit = string => {
    return /[0-9]/.test(string);
};

export const hasSymbol = string => {
    return /[^0-9a-zA-Z]/.test(string);
};

export function validateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return (true)
    }
    return (false)
}

export function truncateString(str, num) {
    if (str?.length <= num) {
        return str;
    }

    const truncated = str?.slice(0, num);
    return truncated?.trimEnd() + '...';
}

export const humanizeRoundNumber = (round) => {
    switch (round) {
        case 1:
            return "First Round";
        case 2:
            return "Second Round";
        case 3:
            return "Third Round";
        case 4:
            return "Quarterfinals";
        case 5:
            return "Semifinals";
        case 6:
            return "Championship";
    }
}

 export const getGameDateByNumber = (gameNumber) => {
    // round 1
    if (gameNumber < 8) return 'Tue 3/19';
    if (gameNumber < 16) return 'Wed 3/20';
    if (gameNumber < 24) return 'Thu 3/21';
    if (gameNumber < 32) return 'Fri 3/22';
    // round 2
    if (gameNumber < 36) return 'Mon 3/25';
    if (gameNumber < 40) return 'Tue 3/26';
    if (gameNumber < 44) return 'Wed 3/27';
    if (gameNumber < 48) return 'Thu 3/28';
    // round 3
    if (gameNumber < 52) return 'Mon 4/1';
    if (gameNumber < 56) return 'Mon 4/1';
    // round 4
    if (gameNumber < 58) return 'Tue 4/2';
    if (gameNumber < 60) return 'Wed 4/3';
    // round 5
    if (gameNumber < 62) return 'Thu 4/4';
    // round 6
    if (gameNumber < 63) return 'Fri 4/5';

     // should never be reached
     console.log('invalid game number', gameNumber);
     return "Tue 3/18";
}

export const roundTo = (number, digits) => {
    if (number !== null && number !== undefined && !isNaN(number)) {
        return parseFloat(number).toLocaleString("en-US", {
            maximumFractionDigits: digits,
            minimumFractionDigits: digits
        })
    } else {
        return ""
    }
}

export const formatStatistics = (num, digits) => {
    if (num === undefined || num === null || num === "--") {
        return '--'
    }
    var si = [
        {value: 1, symbol: ""},
        {value: 1E3, symbol: "K"},
        {value: 1E6, symbol: "M"},
        {value: 1E9, symbol: "B"},
        {value: 1E12, symbol: "T"},
        {value: 1E15, symbol: "P"},
        {value: 1E18, symbol: "E"}
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}
