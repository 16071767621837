import React from 'react';
import trophy from "../img/stickers/SM_TROPHY.png";

function PointsPerRoundCard(props) {
    const round = props.round
    const isChampionship = round.title === 'Championship'
    return (
        <div className={`flex flex-col items-center p-3 rounded-xl relative ${isChampionship ? 'bg-yellow-400/30' : 'bg-white/20'}`}>
            {isChampionship &&
                <img alt={'trophy'} src={trophy} className={'absolute scale-[2] w-8 md:w-12 -top-6 md:-top-2 right-1 md:-right-4 rotate-6'}/>
            }
            <div className='flex-1 uppercase text-lg'>
                {round.title}
            </div>
            <div className='flex-1 uppercase text-3xl -mt-1 text-yellow-400'>
                {round.points}<span className='lowercase'>pts</span>
            </div>
            <div className='flex-1 font-body text-sm'>
                {round.gamesPerRound} MATCHUP{round.gamesPerDay !== 1 && 'S'}
            </div>
        </div>
    );
}

export default PointsPerRoundCard;
