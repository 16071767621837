import React from 'react';
import ModalComponent from "../atoms/ModalComponent";
import {Button} from "../atoms/Button";
import {Link} from "react-router-dom";
import StockLogo from "../atoms/StockLogo";
import LottieWrapper from "../LottieWrapper";
import fireworks from "../../img/lottie/fireworks.json";
import {getConferenceFromTicker} from "../../consts/dummy";

function BracketCompleteModal(props) {

    const matchup = !!props.masterBracket && props.masterBracket[62]
    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'md'}
            toggle={props.close}
            backdropClass={'bg-green-500/40'}
            confetti
        >
            <div className='flex flex-col gap-0 items-center relative'>
                <p className='text-3xl mt-3 text-center'>
                    YOU'RE ALL SET!
                </p>
                <div className='relative flex flex-row items-center justify-between gap-5'>
                    <LottieWrapper json={fireworks} height={140} width={260} className={'absolute z-[0]'}/>
                    <img alt={'cat'} src={require('../../img/stickers/SM_TROPHY.png')}
                         className='w-32 shake-animation'/>
                    <div className='flex flex-col items-center mt-3'>
                        <div
                            className='relative bg-yellow-400/20 px-5 py-2 rounded-xl text-center items-center flex flex-col gap-1'>
                            <StockLogo ticker={matchup?.winner_id} className={'w-20 h-20'} border/>
                            <p className='uppercase text-yellow-400'>
                                Your winner
                            </p>
                        </div>
                    </div>
                </div>
                <p className='uppercase mt-2 mb-2'>
                    Conference Champions
                </p>
                <div className='flex flex-row items-center justify-center gap-5 w-full'>
                    {[56, 57, 58, 59].map((gameNumber) =>
                        <div
                            key={gameNumber + 'conf-champ'}
                            className={'font-body text-xs text-center max-w-[80px] flex flex-col flex-1 truncate'}>
                            <div className='relative mx-auto'>
                                <StockLogo ticker={props.masterBracket[gameNumber]?.winner_id} className={'w-12 h-12'}
                                           border/>
                            </div>
                            <p className='uppercase text-center font-semibold mt-1'>
                                {props.masterBracket[gameNumber]?.winner_id}
                            </p>
                            <p className='truncate mb-2  opacity-70'>
                                {getConferenceFromTicker(props.masterBracket[gameNumber]?.winner_id)?.name}
                            </p>
                        </div>
                    )}
                </div>
                <p className='font-body mt-3 text-center text-sm'>
                    Thanks for filling out your Stonk Madness bracket! You can review your picks and check back in on
                    your bracket any time.
                </p>
            </div>
            <Button className='w-full mt-3' variant={'default'} onClick={props.openShareModal}>
                Share your bracket
            </Button>
            <Link to={'/hub'}>
                <Button className='w-full mt-3' variant={'secondary'} onClick={props.close}>
                    Finish
                </Button>
            </Link>
        </ModalComponent>
    );
}

export default BracketCompleteModal;
