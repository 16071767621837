import axios from "./axios";
import {all} from 'axios';
import store from '../Store';
import {getHistoricDataArgs} from '../graphs/ComparisonGraph';
import {STUEY_PICKS} from '../consts/consts';
import supabase from './supabase';

export const getMatchupStaticData = async (matchup_id, ticker1, ticker2) => {
    if (!matchup_id && !ticker1 && !ticker2)
        return {};
    const controller = new AbortController;
    const endpoint = `/matchup/info?matchup=${matchup_id}&ticker1=${ticker1}&ticker2=${ticker2}`
    try {
        const config = {
            signal: controller.signal,
        }
        const res = axios.get(endpoint, config);
        const payload = {promise: res, controller};
        return payload;
    } catch (err) {
        console.log(err)
    }
}

export const getMatchupLiveData = async (ticker1, ticker2) => {
    if (!ticker1 && !ticker2)
        return {};
    const controller = new AbortController;
    const endpoint = `/matchup/live?ticker1=${ticker1}&ticker2=${ticker2}`;
    try {
        const config = {
            signal: controller.signal,
        }
        const res = axios.get(endpoint, config);
        const payload = {promise: res, controller};
        return payload;
    } catch (err) {
        console.log(err)
    }
}

export const preloadCharts = async (tickers, cachedChartData=null) => {
    if (!cachedChartData)
        cachedChartData = store.getState().userReducer.chartData;

    // ensure this is only run once per refresh
    if (!!cachedChartData['AAPL'])
        return;

    const {data, error} = await supabase.from("historic_data").select("*");
    const chartData = data.reduce((obj, d) => ({...obj, [d.ticker]: {'1w': d.data} }), cachedChartData)
    await store.dispatch({
        type: "SET_CHART_DATA",
        chartData
    })
    return chartData;

    // deprecated logic
    const span = '1w';
    const {start, multiplier, timespan} = getHistoricDataArgs(span);

    const BAD_STOCKS = [
        "DOGE",
        "DIDI",
        "CCIV",
        "FB",
        "BBBY",
        "AGC",
        "IRNT",
        "TWTR",
    ]

    const _tickers = tickers.filter(e => !!e).filter(e => !BAD_STOCKS.includes(e)).filter(e => !cachedChartData?.[e]?.[span])
    
    const endpoints = _tickers.map((ticker) => `/stock/historic?ticker=${ticker}&start=${start.toFormat("yyyy-MM-dd")}&multiplier=${multiplier}&timespan=${timespan}`);
    return await all(endpoints.map((endpoint) => axios.get(endpoint)))
        .then(async (res) => {
            const data = res.map(e => e.data)
            const chartData = data.reduce((obj, d) => ({...obj, [d.ticker]: {[span]: d.results} }), cachedChartData);
            store.dispatch({
                type: "SET_CHART_DATA",
                chartData
            })
            return chartData;
        })
}

let stueyPicksPosition = 0;
export const getStueyPick = (winner, loser) => {
    // const idx = Math.floor(Math.random() * STUEY_PICKS.length);
    const template = STUEY_PICKS[stueyPicksPosition++ % STUEY_PICKS.length];
    const text = template.replace("{winner}", winner).replace("{loser}", loser);
    return text;
}
