import React from 'react';
import AfterHourWordmark from "../../components/brand/logos/AfterHourWordmark";
import {Button} from "../../components/atoms/Button";
import afterhour from '../../img/afterhourpromo.png'
import {cardClass, WEBSITE_LINK} from "../../consts/dummy";

function DownloadAfterHourCard(props) {
    return (
        <div className={`${props.inModal ? 'bg-transparent p-0 text-white' : cardClass}`}>
            <div className='flex flex-col gap-3 '>
                <p className={props.inModal ? 'text-3xl uppercase' : 'text-4xl uppercase'}>
                    {props.title || 'FLEX YOUR PICKS'}
                </p>
                {props.description ?
                    <p className={'font-body text-lg font-medium'}>
                        {props.description}
                    </p>
                    :
                    <p className={'font-body text-lg font-medium'}>
                        Bring your bracket smack talk and <span className='text-purple-500 font-semibold'>follow real trades </span>from 10,000+ verified investors in the social AfterHour app.
                    </p>
                }
                <div className='flex flex-col items-center gap-0'>
                    <img
                        alt={'afterhour'}
                        src={afterhour}
                        className={props.inModal ? 'w-1/2 md:w-2/3' : 'w-4/5 max-h-[50vh] object-contain'}
                    />
                    <a href={WEBSITE_LINK} target="_blank" rel={'noreferrer'}>
                        <AfterHourWordmark className={'w-32 h-8'}/>
                    </a>
                </div>
            </div>
            <div className={`flex flex-col w-full items-center gap-2 ${props.inModal && 'mt-4'}`}>
                <a className='w-full' target={'_blank'} rel={'noreferrer'}
                   href={WEBSITE_LINK}>
                    <Button variant={'purple'} className='w-full'>
                        {props.buttonTitle || 'GET STARTED'}
                    </Button>
                </a>
                {props.inModal &&
                    <Button onClick={props.closeModal} variant={'ghost'} size={'sm'} className='w-full mb-1'>
                        CLOSE
                    </Button>
                }
            </div>
        </div>
    );
}

export default DownloadAfterHourCard;
