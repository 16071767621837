import React, { useState } from 'react';
// import {firebaseAnalytics, firebaseAuth} from "../api/firebase";
// import {signUp} from "../api/authentication";
// import {signInWithEmailAndPassword} from "firebase/auth";
import { Button } from "../atoms/Button";
import TextInputWrapper from "../atoms/TextInputWrapper";
import { cardClass } from "../../consts/dummy";
import SvgGoogle from "../icons/custom/SvgGoogle";
import SvgApple from "../icons/custom/SvgApple";

const SignUpCard = (props) => {
    const [phone, setPhone] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState("");
    const [codeError, setCodeError] = useState("");
    const [showCodeInput, setShowCodeInput] = useState(false);
    const [isCertified, setIsCertified] = useState(false);

    const onChangePhone = (phone) => {
        setPhone(phone.toLowerCase());
        setError("");
    }

    const onChangeCode = (code) => {
        setCode(code.toLowerCase());
        setCodeError("");
    }

    const signUpPressed = async () => {
        let localError;
        if (!phone) {
            // TODO: validate phone
            localError = "Please enter a valid phone number.";
        }

        if (localError) {
            setError(localError);
            return;
        }

        setLoading(true);

        // Simulate signup process
        console.log("SIGNED UP");

        // Reset loading state and potentially navigate or show code input
        setLoading(false);
        setShowCodeInput(true); // Assuming you want to show the code input after signing up

        // Catch block for handling errors if using async operations like network requests
        // setError("Failure on sign up");
        // setLoading(false);
    };

    return (
        <form className={`${cardClass} mb-5 sm:w-[95vw] md:w-[440px]`}
              onSubmit={e => e.preventDefault()}>
            <div className="flex flex-col w-full gap-3">
                <div className="flex flex-col gap-0 mb-5 ">
                    <div className='text-3xl uppercase mb-1'>
                        Create Your Account
                    </div>
                    <div className='body2 font-body opacity-50'>
                        Sign up to get started!
                    </div>
                </div>
                {showCodeInput ?
                    <TextInputWrapper
                        label={'Enter your code'}
                        value={code}
                        onChange={(e) => onChangeCode(e.target.value)}
                        placeholder={''}
                        type="number"
                    />
                    :
                    <>
                        <TextInputWrapper
                            label={'Phone'}
                            value={phone}
                            onChange={(e) => onChangePhone(e.target.value)}
                            placeholder={'Enter phone number'}
                            type="number"
                        />
                        <div className="flex font-body items-center">
                            <input
                                type="checkbox"
                                checked={isCertified}
                                onChange={(e) => setIsCertified(e.target.checked)}
                                id="certificationCheckbox"
                                className="mr-2"
                            />
                            <label htmlFor="certificationCheckbox" className="text-sm opacity-50">
                                I certify I am in the US and over 18
                            </label>
                        </div>
                    </>
                }
                <div className='flex flex-col w-full'>
                    <div className='text-center mb-2'>
                        <p className="text-red-500 text-xs" style={{minHeight: 18}}>
                            {error}
                        </p>
                    </div>
                    <Button variant='default' loading={loading} onClick={signUpPressed}>
                        {showCodeInput ? 'Verify code' : 'Sign Up'}
                    </Button>
                    <div className='flex flex-col gap-5'>
                        <p className='mx-auto mt-5'>OR</p>
                        <div className='flex flex-row items-center gap-3 font-body font-medium'>
                            <a className={`flex-1 rounded-full gap-2 text-xs bg-white/15 flex flex-row items-center cursor-pointer hover:bg-white/30 p-3`}>
                                <SvgGoogle className={'w-8 h-8'}/> Sign up with Google
                            </a>
                            <a className={`flex-1 rounded-full gap-2 text-xs bg-white/15 flex flex-row items-center cursor-pointer hover:bg-white/30 p-3`}>
                                <SvgApple className={'w-8 h-8'}/> Sign up with Apple
                            </a>
                        </div>
                    </div>
                    <div className='flex font-body flex-row text-sm items-center justify-center gap-2 mt-3'>
                        <p className='opacity-50'>
                            Already have an account?
                        </p>
                        <p onClick={props.toggleTab}
                           className='text-yellow-400 hover:text-yellow-500 font-semibold cursor-pointer'>
                            Log In
                        </p>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default SignUpCard;
