import React from 'react';
import ModalComponent from "../atoms/ModalComponent";
import {Button} from "../atoms/Button";
import {SHARE_OPTIONS} from "../../consts/dummy";

function ShareOnSocials(props) {
    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            toggle={props.close}
            backdropClass={'bg-yellow-400/30'}
        >
            <div className='flex flex-col gap-3 '>
                <p className={props.inModal ? 'text-3xl' : 'text-4xl'}>
                    SHARE TO SPREAD THE WORD
                </p>
                <p className={'font-body text-lg font-medium'}>
                    Each bracket created counts towards <span className='text-yellow-400 font-semibold'>reducing the 'perfect
                    bracket' requirements</span> and <span className='text-yellow-400 font-semibold'>unlocking new top prizes</span>. Share with friends to
                    better your chances!
                </p>
                <div className='flex flex-row flex-wrap justify-around items-stretch gap-2'>
                    {SHARE_OPTIONS.slice(0, 2).map(option =>
                        <div
                            className='bg-white/15 flex-1 hover:bg-white/20 cursor-pointer  rounded-xl p-3 gap-2 flex flex-col items-center justify-center'
                            onClick={option.onClick} key={option.title}>
                            <img src={option.img} alt={'sticker'} className='w-16 w-16 object-contain'/>
                        </div>
                    )}
                </div>
                <div className='flex flex-row flex-wrap justify-around items-stretch gap-2'>
                    {SHARE_OPTIONS.slice(2).map(option =>
                        <div
                            className='bg-white/15 flex-1 hover:bg-white/20 cursor-pointer  rounded-xl p-3 gap-2 flex flex-col items-center justify-center'
                            key={option.title} onClick={option.onClick}>
                            <img src={option.img} alt={'sticker'} className='w-16 w-16 object-contain'/>
                        </div>
                    )}
                </div>
                <Button onClick={props.close} variant={'secondary'}>
                    Close
                </Button>
            </div>
        </ModalComponent>
    );
}

export default ShareOnSocials;
