import { createClient } from '@supabase/supabase-js'

const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY)

export const getWaitlistCount = async () => {
    try {
        const { data, error } = await supabase.functions.invoke('waitlist-count');
        return data;
    } catch (err) {
        console.log(err)
    }
}

export default supabase;
