import React from 'react';
import ModalComponent from "../atoms/ModalComponent";
import DownloadAfterHourCard from "../../sections/cards/DownloadAfterHourCard";
import SvgMegaphone from "../icons/SvgMegaphone";
import lambo from "../../img/lambo.png";
import {Button} from "../atoms/Button";
import {PERFECT_REQUIREMENT, PERFECT_TOTAL} from "../../consts/dummy";

function LamboModal(props) {
    const total = PERFECT_TOTAL
    const requirement = PERFECT_REQUIREMENT
    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            toggle={props.close}
            backdropClass={'bg-green-500/40'}
        >
            <div className='flex flex-col gap-0 items-center'>
                <p className='text-3xl'>
                    PERFECT PRIZE RULES:
                </p>
                <img alt={'lambo'} src={lambo} className='w-5/6'/>
                <p className='font-body text-xl md:text-2xl font-medium'>
                    Lamborghini Huracan
                </p>
                <div className='flex flex-col items-center mt-1 bg-green-500/30 rounded-xl p-2 px-3'>
                    <p className='font-body text-sm text-green-500 -mt-1 font-semibold'>
                        Perfect Bracket Grand Prize
                    </p>
                    <p className='font-body font-semibold text-md'>
                        {requirement < total && <span className='opacity-50 line-through'>{total}</span>} <span
                        className='font-bold'>{requirement}</span> correct picks
                    </p>
                </div>
                <div className='w-full mt-3'>
                    <p className='text-2xl'>
                        HOW TO INCREASE ODDS:
                    </p>
                    <p className='font-body text-sm'>
                    Each bracket created counts towards <span className='text-yellow-400 font-semibold'>reducing the 'perfect
                    bracket' requirements</span> and <span className='text-yellow-400 font-semibold'>unlocking new top prizes</span>.
                        Share with friends to better your chances! If 1M brackets are created, we'll count a bracket with 42 of 63 correct
                        picks as
                        perfect. Good
                        luck!
                    </p>
                </div>
            </div>
            <Button className='w-full mt-3' variant={'secondary'} onClick={props.close}>
                Close
            </Button>
        </ModalComponent>
    );
}

export default LamboModal;
