import axios from 'axios';
import { firebaseAuth } from './firebase';

const env = process.env.NODE_ENV;
const vercelEnv = process.env.REACT_APP_VERCEL_ENV;


const localURL = `http://localhost:8000/`;
const devURL = `https://dev-dot-afterhour-63b2b.wl.r.appspot.com/`;
const prodURL = `https://afterhour-63b2b.wl.r.appspot.com/`;

let baseURL = localURL;
var _AMPLITUDE_API_KEY;
let frontendUrl;
switch (vercelEnv) {
    case 'development':
        baseURL = localURL;
        _AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_KEY_DEV;
        frontendUrl = "http://localhost:3000"
        break;
    case 'preview':
        baseURL = devURL;
        _AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_KEY_DEV;
        frontendUrl = "https://stonk-madness-frontend.vercel.app"
        break;
    case 'production':
        baseURL = prodURL;
        _AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_KEY_PROD;
        frontendUrl = "https://stonkmadness.com"
        break;
    default:
        baseURL = localURL;
        _AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_KEY_DEV;
        frontendUrl = "http://localhost:3000"
}

export const AMPLITUDE_API_KEY = _AMPLITUDE_API_KEY;
export const FRONTEND_URL = frontendUrl

export default axios.create({
    baseURL: baseURL + "api",
});
