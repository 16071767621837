import React, {useEffect, useRef, useState} from 'react';
import {Button} from "./atoms/Button";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";

function mapStateToProps(state) {
    return {
        userBracket: state.userReducer.userBracket,
        authUser: state.userReducer.authUser
    };
}

function JoinWaitlistStickyButton({openJoinWaitlist, ...props}) {
    const ref = useRef(null);
    const [topPosition, setTopPosition] = useState('100vh');
    const hasBracket = props.userBracket?.submitted;
    const navigate = useNavigate()
    const updateTopPosition = () => {
        if (ref.current) {
            const componentHeight = ref.current.clientHeight;
            const viewportHeight = window.innerHeight;
            const topValue = `${viewportHeight - componentHeight}px`;
            setTopPosition(topValue);
        }
    };

    useEffect(() => {
        updateTopPosition();
        window.addEventListener('resize', updateTopPosition);
        return () => {
            window.removeEventListener('resize', updateTopPosition);
        };
    }, []);

    return (
        <div
            ref={ref}
            className='fixed left-0 right-0 z-50 p-4 pt-6 items-center flex flex-col'
            style={{top: topPosition, background: 'linear-gradient(to top, black, black, #00000000)'}}
        >
            {props.authUser ?
                (hasBracket ?
                        <Button onClick={() => navigate('/bracket', {state: {isEditing: true}})} variant={'green'} className='w-full max-w-[602px]'>
                            View my bracket
                        </Button>
                        :
                        <Button onClick={() => navigate('/bracket', {state: {isEditing: true}})} variant={'green'}
                                className='w-full max-w-[602px]'>
                            Create a Bracket
                        </Button>
                )
                :
                // <Button onClick={openJoinWaitlist} variant={'green'} className='w-full max-w-[602px]'>
                //     Join the waitlist
                // </Button>
                <Button onClick={() => navigate('/authentication')} variant={'green'} className='w-full max-w-[602px]'>
                    Create a bracket
                </Button>
            }
        </div>
    );
}

export default connect(mapStateToProps, null)(JoinWaitlistStickyButton);

