import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {first_place_prizes, pageClass, prizes} from "../consts/dummy";
import PageHeader from "../components/navigation/PageHeader";
import lambo from "../img/lambo.png";
import {ordinalSuffixOf} from "../components/utils/helpers";
import ShareForPrizesCard from "../sections/cards/ShareForPrizesCard";
import rocket from "../img/stickers/rocket.png";
import wsb from "../img/prizes/wsb.webp";
import ConfettiComponent from "../components/atoms/ConfettiComponent";
import SvgLock from "../components/icons/SvgLock";
import rays from "../img/hero/rays.png";
import SvgLockOpen from "../components/icons/SvgLockOpen";
import PerfectRequirementsCard from "../components/PerfectRequirementsCard";

function mapStateToProps(state) {
    return {
        mobile: state.userReducer.mobile
    };
}

function PrizesPage({mobile}) {
    const first_place = [...first_place_prizes].reverse();
    const [highlightIndex, setHighlightIndex] = useState(null);


    useEffect(() => {
        const interval = setInterval(() => {
            const randomIndex = Math.floor(Math.random() * prizes.length);
            setHighlightIndex(randomIndex);
        }, 2500);

        return () => clearInterval(interval);
    }, []);
    return (
        <div className={`${pageClass} max-w-[100vw] overflow-hidden md:overflow-visible`}>
            <PageHeader
                title={'PRIZES'}
                desc={'But we’re just playing for fun right?'}
            />
            <div className='flex flex-col items-center'>
                <div className='relative flex flex-col items-center'>
                    {/*<img alt={'rays'} src={rays}*/}
                    {/*     className='w-3/4 opacity-15 scale-[2] -top-28 block md:hidden absolute'/>*/}
                    <img alt={'rays2'} src={rays}
                         className='w-3/4 opacity-15 scale-[2] blur-[6px] -top-48 block md:block absolute'/>
                    <img alt={'lambo'} src={lambo} className='w-5/6 -mt-16'/>
                </div>
                <PerfectRequirementsCard/>
            </div>
            <div className='w-full -mt-10'>
                <p className='text-4xl uppercase mb-4 md:mb-8'>
                    Best Bracket Prize:
                </p>
                <div className='flex flex-row gap-5 w-full'>
                    {/*<div className={'w-4 bg-green-500/20 rounded-full flex flex-col-reverse relative'}>*/}
                    {/*    <div className='w-full h-6 bg-green-500 rounded-full relative overflow-visible'>*/}
                    {/*    </div>*/}
                    {/*    <div className='shake-element'>*/}
                    {/*        <img alt={'rocket'} src={rocket} style={{width: 20}}*/}
                    {/*             className={'scale-[1.8] md:scale-[2] -mt-4'}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className='flex flex-col w-full gap-2'>
                        {first_place.slice(-1).map(prize =>
                            <div key={prize.title}
                                 className={`flex flex-1 flex-row items-center w-full gap-3 ${prize.locked && 'opacity-50  hover:opacity-80'}`}>
                                {prize.locked ?
                                    <SvgLock className={'w-8 h-8'}/>
                                    :
                                    <SvgLockOpen className={'w-8 h-8 text-green-500'}/>
                                }
                                <img src={prize.img} alt={prize.title}
                                     className={'w-[24vw] md:w-48 h-[24vw] md:h-48 bg-white/10 rounded-xl p-2 aspect-square object-contain '}/>
                                <div className='flex flex-col w-full gap-1'>
                                    <p className='text-sm md:text-lg font-body  font-semibold'>
                                        {prize.title}
                                    </p>
                                    {prize.locked ?
                                        <div className='flex flex-row gap-1'>
                                            <p className='text-xs md:text-sm opacity-70 font-medium font-body text-center'>
                                                Unlocked at {prize.unlock} created brackets
                                            </p>
                                        </div>
                                        :
                                        <div className='flex flex-row gap-1'>
                                            <p className='text-xs md:text-sm opacity-70 font-medium font-body'>
                                                {prize.desc}
                                            </p>
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div>
                <p className='text-4xl uppercase mb-4 md:mb-8'>
                    2nd-20th Place Prizes:
                </p>
                <div className='grid grid-cols-3 gap-3'>
                    {prizes.map((prize, index) =>
                        <div
                            key={prize.title}
                            className={`${index === highlightIndex ? 'bg-yellow-400/20 outline outline-offset-2 outline-yellow-400/40' : 'bg-white/10 outline-yellow-800'} duration-300 p-2 flex-1 rounded-xl text-center flex flex-col items-center justify-between gap-2`}>
                            <div className='flex flex-col flex-1 items-center justify-center'>
                                <img alt={prize.title} src={prize.img}
                                     className='w-ful object-contain'/>
                            </div>
                            <div className={`${index === highlightIndex ? 'text-yellow-400' : ''}`}>
                                <p className='text-xs md:text-lg font-body'>
                                    {prize.title.slice(0, 26) + ((prize.title.length > 26) ? '...' : '')}
                                </p>
                                <p className='text-xs md:text-sm font-body opacity-50'>
                                    {ordinalSuffixOf(index + 2)} place
                                </p>
                            </div>
                        </div>
                    )}
                </div>
                <div className='bg-white/10 p-4 mt-3 flex-1 rounded-xl flex flex-row items-center gap-4'>
                    <div className='flex flex-row relative'>
                        <img alt={'prize'} src={wsb}
                             className='w-16 md:w-24 object-contain'/>
                        <img alt={'prize'} src={wsb}
                             className='w-16 md:w-24 object-contain -ml-8'/>
                        <img alt={'prize'} src={wsb}
                             className='w-16 md:w-24 object-contain -ml-8'/>
                        <div className='bg-yellow-400 text-black -bottom-2 right-0 absolute px-1 rounded-full'>
                            <p>
                                10
                            </p>
                        </div>
                    </div>
                    <div>
                        <a
                            href={'https://youtooz.com/products/wsb-kid'}
                            target={'_blank'}
                            rel={'noreferrer'}
                            className='text-xs md:text-lg font-body'>
                            WSB Kid Youtooz Figurine
                        </a>
                        <p className='text-xs font-body opacity-50'>
                            11th - 20th place
                        </p>
                    </div>
                </div>
                <div className='flex flex-col mt-8 items-center text-center'>
                    <p className='text-lg font-body font-semibold'>
                        Rather have cash?
                    </p>
                    <p className='text-sm opacity-50 font-body w-3/4 '>
                        Yes, if you don't need an Air Fryer (or any of our prizes), you can request the equivalent in
                        cash. Honestly makes our
                        lives easier.
                    </p>
                </div>
            </div>
            <ShareForPrizesCard mobile={mobile}/>
            <div className='font-body text-center px-5 flex flex-col gap-3 opacity-50'>
                <p className='text-sm font-semibold'>
                    Perfect Bracket Odds?
                </p>
                <p className='text-xs'>
                    Odds to pick a perfect bracket (correct pick for every single game for all 63 rounds) are <span
                    className='font-bold'>1 in
                    9,223,372,036,854,775,808</span>. Slim yes, but each share counts towards lowering the 'perfect
                    bracket' goal. If 1M brackets are created, we'll count a bracket with 42 of 63 correct picks as
                    perfect. Each bracket creation milestone along the way can be found in the full rules document. Good
                    luck!
                </p>
            </div>
            <div className='w-[90vw] overflow-hidden'>
                <ConfettiComponent/>
            </div>
        </div>
    );
}

export default connect(mapStateToProps)(PrizesPage);
