import React, {useEffect, useRef, useState} from 'react';
import MatchupVersusSection from "../../components/matchupdetails/MatchupVersusSection";
import MatchupDateBanner from "../../components/matchupdetails/MatchupDateBanner";
import BracketCreationMatchupDisplay from "../../components/bracket/creation/BracketCreationMatchupDisplay";
import ComparisonGraph from "../../graphs/ComparisonGraph";
import GamedayGraph from "../../graphs/GamedayGraph";
import FinishedGraph from "../../graphs/FinishedGraph";
import MatchupInfoComparison from "../../components/matchupdetails/MatchupInfoComparison";
import CrowdFavoriteSection from "../../components/matchupdetails/CrowdFavoriteSection";
import {getMatchupLiveData} from '../../api/matchups';
import {connect} from 'react-redux';
import WinPercentageBar from "../WinPercentageBar";
import RandomSticker from "../RandomSticker";
import JoinTheConvoWidget from "../JoinTheConvoWidget";
import {getConferenceMatchupMessage} from "../../consts/dummy";
import StueyThinksSection from "../StueyThinksSection";
import {DateTime} from "luxon";
import {getDurationUntilStart, getGameStartAndEndTimes} from "../utils/helpers";
import {renderGameTimer} from "../utils/timing";
import { USE_LIVE_VIEW } from 'consts/consts';

const mapStateToProps = (state) => {
    return {
        authUser: state.userReducer.authUser,
        userBracket: state.userReducer.userBracket,
        masterBracket: state.userReducer.masterBracket,
        mobile: state.userReducer.mobile,
        readonlyMasterBracket: USE_LIVE_VIEW ? state.userReducer.readonlyMasterBracket : state.userReducer.masterBracket,
    }
}

function MatchupBody(props) {
    const graphContainerRef = useRef(null);
    const [graphWidth, setGraphWidth] = useState(0);
    const [loading, setLoading] = useState(true);
    const [matchup, setMatchup] = useState({});     // TODO: deprecated, remove refs
    const [matchupLiveData, setMatchupLiveData] = useState({});
    const [stock1WinPercent, setStock1WinPercent] = useState(50);
    const [stock2WinPercent, setStock2WinPercent] = useState(50);
    const [stueyOpened, setStueyOpened] = useState(false);
    const [timerMessage, setTimerMessage] = useState(null);
    const [isUpcoming, setIsUpcoming] = useState(true);
    const [isFinished, setIsFinished] = useState(false);

    useEffect(() => {
        const now = DateTime.now().setZone("America/New_York").toISO();
        const matchupDate = props.preloadedMatchup?.date || "2025-01-01";
        const matchupEndDate = `${matchupDate}T16:00:00`;
        setIsUpcoming(now < matchupDate);
        setIsFinished(now >= matchupEndDate);
    }, [props.preloadedMatchup])

    useEffect(() => {
        const updateGraphWidth = () => {
            if (graphContainerRef.current) {
                setGraphWidth(graphContainerRef.current.offsetWidth);
            }
        };

        updateGraphWidth();

        window.addEventListener('resize', updateGraphWidth);

        return () => {
            window.removeEventListener('resize', updateGraphWidth);
        };
    }, []);

    // get stock data
    useEffect(() => {
        // avoid 404
        if (!props.masterBracket)
            return;

        const ticker1 = props.readonlyMasterBracket?.[props.gameNumber]?.ticker1;
        const ticker2 = props.readonlyMasterBracket?.[props.gameNumber]?.ticker2;
        if (!ticker1 || !ticker2)
            return;

        let _controller;
        const matchupLiveData = getMatchupLiveData(ticker1, ticker2)
            .then(({promise, controller}) => {
                _controller = controller;
                promise?.then((res) => {
                    const matchupLiveData = res.data;
                    const matchup = {
                        [ticker1]: {
                            ...matchupLiveData?.results[ticker1],
                            ticker: ticker1,
                            color: props.preloadedMatchup?.[ticker1]?.color
                        },
                        [ticker2]: {
                            ...matchupLiveData?.results[ticker2],
                            ticker: ticker2,
                            color: props.preloadedMatchup?.[ticker2]?.color
                        },
                        ticker1: ticker1,
                        ticker2: ticker2,
                    }
                    setMatchupLiveData(matchup);
                    setMatchup(matchup);
                    setLoading(false);
                }).catch((err) => {
                    // request cancelled
                })
            })
        return () => {
            _controller?.abort();
        }
    }, [props?.gameNumber, props.masterBracket])


    const getGameNumber = (round, id) => {
        return id + {
            0: 0,
            1: 32,
            2: 48,
            3: 56,
            4: 60,
            5: 62
        }[round - 1];
    }

    const computeWinPercent = () => {
        const matchup = props.readonlyMasterBracket?.[props?.gameNumber];
        const ticker1 = matchup?.ticker1;
        const ticker2 = matchup?.ticker2;
        const gameNumber = getGameNumber(matchup?.round, matchup?.id);

        const stock1ChangePercent = matchupLiveData?.[ticker1]?.change_percent;
        const stock2ChangePercent = matchupLiveData?.[ticker2]?.change_percent;
        if (!stock1ChangePercent)
            return;

        // compute volatility factor
        const stock1Volatility = matchup?.[ticker1]?.preloaded_details?.historic_volatility;
        const stock2Volatility = matchup?.[ticker2]?.preloaded_details?.historic_volatility;
        const stock1Dev = (stock1ChangePercent / stock1Volatility);
        const stock2Dev = (stock2ChangePercent / stock2Volatility);
        const stock1DevRatio = Math.pow(Math.abs(stock1Dev - stock2Dev), (stock1Dev - stock2Dev) >= 0 ? 1 : -1);
        const stock1VolatilityFactor = Math.max(0.3, Math.min(0.7, stock1DevRatio / (1 + stock1DevRatio)));
        const stock2VolatilityFactor = 1 / stock1VolatilityFactor;

        const stock1PriceAdvantage = Math.min(0.15, Math.max(-0.15, (stock1ChangePercent - stock2ChangePercent) / stock1Volatility));
        const stock1Factor = stock1VolatilityFactor + stock1PriceAdvantage;

        const stock1WinPercent = Math.max(1, Math.min(99, (stock1Factor * 100).toFixed(0)));
        setStock1WinPercent(stock1WinPercent);
        setStock2WinPercent(100 - stock1WinPercent);
    }

    useEffect(() => {
        if (!matchupLiveData?.[matchup?.ticker1] || !matchupLiveData?.[matchup?.ticker2])
            return;
        computeWinPercent();
    }, [props.masterBracket, matchupLiveData])

    useEffect(() => {
        const updateGameStatus = () => {
            const gameNumber = props.isPreview ? (props.masterBracket?.[props?.gameNumber]?.id) : (props.gameNumber)
            const timerMessage = renderGameTimer(gameNumber, true)
            setTimerMessage(timerMessage)
        };

        updateGameStatus();
        const intervalId = setInterval(updateGameStatus, 1000);

        return () => clearInterval(intervalId);
    }, [props.gameNumber]);

    return (
        <>
            <div className={`w-full flex flex-col gap-5 relative ${props.className}`}>
                {props.fullPage &&
                    <div className='absolute right-0 -top-12 md:-top-20'>
                        <div className='-rotate-[-2deg]'>
                            <RandomSticker className={'w-12 md:w-16'}/>
                        </div>
                    </div>
                }
                {!props.isBottomSheet &&
                    <MatchupDateBanner gameNumber={props.gameNumber}/>
                }
                {getConferenceMatchupMessage(props.preloadedMatchup?.ticker1, props.preloadedMatchup?.ticker2, (props.gameNumber + 1), props.isCreatingBracket)}

                <MatchupVersusSection
                    loading={loading}
                    isActivelySelectingSide={props.isActivelySelectingSide}
                    preloadedMatchup={props.preloadedMatchup}
                    liveData={matchupLiveData}
                    predictedWinner={props.isPreview ? props.readonlyMasterBracket?.[props?.gameNumber]?.winner_id : props.userBracket?.[`g${matchup?.id}`]}
                    matchup={(props.readonlyMasterBracket?.[props?.gameNumber]?.winner_id) || matchupLiveData}
                    readonlyMasterBracket={props.readonlyMasterBracket}
                />
                {!!timerMessage &&
                    <div className='flex flex-row items-center justify-center  mb-3 -mt-2 gap-2'>
                        <span className="relative flex h-2 w-2">
                          <span
                              className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
                          <span className="relative inline-flex rounded-full h-2 w-2 bg-yellow-500"></span>
                        </span>
                        <p className='text-center text-yellow-400 text-sm font-body font-medium'>
                            {timerMessage}
                        </p>
                    </div>
                }
                <WinPercentageBar
                    matchup={props.readonlyMasterBracket?.[props?.gameNumber]}
                    loading={!props.readonlyMasterBracket?.[props?.gameNumber]?.ticker2 || !props.readonlyMasterBracket?.[props?.gameNumber]?.ticker1}
                    stock1WinPercent={stock1WinPercent}
                    stock2WinPercent={stock2WinPercent}
                />
                <StueyThinksSection
                    loading={loading}
                    stock1WinPercent={stock1WinPercent}
                    stock2WinPercent={stock2WinPercent}
                    masterBracket={props.readonlyMasterBracket}
                    gameNumber={props.gameNumber}
                />
                <div
                    ref={graphContainerRef}
                    style={{height: (props.fullPage || (props.mobile && props.isPreview)) ? (props.mobile ? 350 : 310) : (props.mobile ? 240 : 280)}}
                    className={`flex flex-col items-center justify-center ${props.isPreview && 'mx-4'} ${props.fullPage ? 'my-3 w-full' : 'mx-4 -mt-2 md:mx-8'}`}>
                    {isUpcoming || !USE_LIVE_VIEW ?
                        <ComparisonGraph
                            leftStock={{
                                ticker: props.readonlyMasterBracket?.[props?.gameNumber]?.ticker1,
                                color: props.readonlyMasterBracket?.[props?.gameNumber]?.[props.readonlyMasterBracket?.[props?.gameNumber]?.ticker1]?.color
                            }}
                            rightStock={{
                                ticker: props.readonlyMasterBracket?.[props?.gameNumber]?.ticker2,
                                color: props.readonlyMasterBracket?.[props?.gameNumber]?.[props.readonlyMasterBracket?.[props?.gameNumber]?.ticker2]?.color
                            }}
                            width={graphWidth}
                            height={props.fullPage ? 240 : 200}
                        />
                        :
                        isFinished ?
                            <FinishedGraph
                                leftStock={{
                                    ticker: props.readonlyMasterBracket?.[props?.gameNumber]?.ticker1,
                                    color: props.readonlyMasterBracket?.[props?.gameNumber]?.[props.readonlyMasterBracket?.[props?.gameNumber]?.ticker1]?.color,
                                    previous_close: props.preloadedMatchup?.ticker1_previous_close,
                                }}
                                rightStock={{
                                    ticker: props.readonlyMasterBracket?.[props?.gameNumber]?.ticker2,
                                    color: props.readonlyMasterBracket?.[props?.gameNumber]?.[props.readonlyMasterBracket?.[props?.gameNumber]?.ticker2]?.color,
                                    previous_close: props.preloadedMatchup?.ticker2_previous_close,
                                }}
                                width={graphWidth}
                                height={(props.fullPage || (props.mobile && props.isPreview)) ? (props.mobile ? 350 : 310) : (props.mobile ? 240 : 280)}
                                date={props.preloadedMatchup?.date || ""}
                            />
                            :
                            <GamedayGraph
                                leftStock={{
                                    ticker: props.readonlyMasterBracket?.[props?.gameNumber]?.ticker1,
                                    color: props.readonlyMasterBracket?.[props?.gameNumber]?.[props.readonlyMasterBracket?.[props?.gameNumber]?.ticker1]?.color,
                                    previous_close: props.preloadedMatchup?.ticker1_previous_close,
                                    score: props.preloadedMatchup?.ticker1_cached_score?.toFixed(1),
                                }}
                                rightStock={{
                                    ticker: props.readonlyMasterBracket?.[props?.gameNumber]?.ticker2,
                                    color: props.readonlyMasterBracket?.[props?.gameNumber]?.[props.readonlyMasterBracket?.[props?.gameNumber]?.ticker2]?.color,
                                    previous_close: props.preloadedMatchup?.ticker2_previous_close,
                                    score: props.preloadedMatchup?.ticker2_cached_score?.toFixed(1),
                                }}
                                width={graphWidth}
                                height={(props.fullPage || (props.mobile && props.isPreview)) ? (props.mobile ? 350 : 310) : (props.mobile ? 240 : 280)}
                            />
                    }
                </div>
                {!props.isPreview &&
                    <div className='my-12'>
                        <CrowdFavoriteSection matchup={props.preloadedMatchup}/>
                    </div>
                }
            </div>
            <div
                className={`w-full flex flex-row items-end justify-center gap-3 -mb-6  ${props.fullPage ? 'md:gap-8' : 'mt-8'}`}>
                <div className='flex flex-1 items-end flex-col justify-end'>
                    <img
                        src={`https://zyidwzbarunqmcnmfukk.supabase.co/storage/v1/object/public/characters/md/${props.readonlyMasterBracket?.[props?.gameNumber]?.ticker1}.png`}
                        alt={props.stock?.preloaded_details?.ticker}
                        className={`max-h-24 md:max-h-28 max-w-[100px] md:max-w-[140px] object-contain ${props.className} `}/>
                </div>
                <div className='flex flex-1 flex-col justify-end'>
                    <img
                        src={`https://zyidwzbarunqmcnmfukk.supabase.co/storage/v1/object/public/characters/md/${props.readonlyMasterBracket?.[props?.gameNumber]?.ticker2}.png`}
                        alt={props.readonlyMasterBracket?.[props?.gameNumber]?.ticker2}
                        className={`max-h-24 md:max-h-28 max-w-[100px] md:max-w-[140px] object-contain ${props.className} `}/>
                </div>
            </div>
            <div className={`flex flex-col gap-3 relative ${props.fullPage && '-mt-12'}`}>
                <div className='w-full flex flex-col gap-2'>
                    {!!matchup && matchupLiveData &&
                        <BracketCreationMatchupDisplay
                            noAnimation hideTip
                            matchup={matchup}
                            matchupLiveData={matchupLiveData}
                            preloadedMatchup={props.preloadedMatchup}
                            openMatchPreviewModal={() => {
                            }}
                        />
                    }
                </div>
                <MatchupInfoComparison matchup={matchup} preloadedMatchup={props.preloadedMatchup}/>
            </div>
            <JoinTheConvoWidget/>
        </>
    );
}

export default connect(mapStateToProps, null)(MatchupBody);
