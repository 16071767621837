import React from 'react';
import StonkMadnessLogo from "../brand/logos/StonkMadnessLogo";
import SvgChevronDown from "../icons/SvgChevronDown";
import {Link} from "react-router-dom";
import BracketLiveGameCard from "./BracketLiveGameCard";
import MatchPreviewModal from "../modals/MatchPreviewModal";

function BracketLiveCenter({
                               liveBracket,
                               mobile,
                               openMatchPreviewModal,
                               viewOnly,
                               loading,
                               userMatchups,
                               focusedGameNumber,
                               setFocusedGameNumber,
                           }) {
    return (
        <>
            <div
                className='flex flex-col items-center justify-center px-4 absolute mx-auto right-2 md:left-0 -mt-36'>
                <p className='text-lg md:text-xl uppercase'>
                    Stonk Madness Bracket
                </p>
                <div className='flex flex-row items-center text-sm mt-2 gap-3'>
                    <Link to={'/how-to-play'}>
                        <p className='text-body text-yellow-400 hover:underline uppercase'>
                            How To Play
                        </p>
                    </Link>
                    <Link to={'/prizes'}>
                        <p className='text-body text-yellow-400 hover:underline uppercase'>
                            Prizes
                        </p>
                    </Link>
                    <Link to={'/schedule'}>
                        <p className='text-body text-yellow-400 hover:underline uppercase'>
                            Schedule
                        </p>
                    </Link>
                </div>
            </div>
            {mobile ?
                <div className='block md:hidden flex flex-row w-full gap-8 items-center justify-end relative'>
                    <div className='block md:hidden left-0 absolute flex flex-col font-body opacity-50 text-sm'>
                        <div>
                            <div className='flex flex-row mr-6'>
                                <SvgChevronDown className={`w-5 h-5 rotate-180`}/> Scroll up for 1st half
                            </div>
                            <div className='w-full h-[1px] bg-white/50 my-2'/>
                            <div/>
                            <div className='flex flex-row mr-6'>
                                <SvgChevronDown className={`w-5 h-5`}/> Scroll down for 2nd half
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-5 items-center'>
                        <div className='flex flex-col gap-2'>
                            <div className='w-full font-body text-sm font-semibold text-center'>
                                <p>
                                    Semifinals
                                </p>
                                <p className='text-xs font-normal opacity-50'>
                                    Thu 4/4
                                </p>
                            </div>
                            <BracketLiveGameCard tbd gameNumber={60} liveBracket={liveBracket}
                                                 openMatchPreviewModal={openMatchPreviewModal} viewOnly={viewOnly}
                                                 userMatchups={userMatchups}
                                                 loading={loading}
                                                 setFocusedGameNumber={setFocusedGameNumber}
                                                 focusedGameNumber={focusedGameNumber}
                            />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <div className='w-full font-body text-sm font-semibold text-center'>
                                <p>
                                    Semifinals
                                </p>
                                <p className='text-xs font-normal opacity-50'>
                                    Thu 4/4
                                </p>
                            </div>
                            <BracketLiveGameCard tbd gameNumber={61} liveBracket={liveBracket}
                                                 openMatchPreviewModal={openMatchPreviewModal} viewOnly={viewOnly}
                                                 userMatchups={userMatchups}
                                                 loading={loading}
                                                 setFocusedGameNumber={setFocusedGameNumber}
                                                 focusedGameNumber={focusedGameNumber}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <div className='w-full text-sm font-semibold text-center flex flex-col items-center'>
                            <StonkMadnessLogo className={'h-[100px] -mt-20 mb-3'}/>
                            <p className='uppercase text-yellow-400'>
                                Championship
                            </p>
                            <p className='text-xs font-body font-normal opacity-50'>
                                Fri 4/5
                            </p>
                        </div>
                        <div className='relative'>
                            <BracketLiveGameCard isChampionship tbd gameNumber={62} liveBracket={liveBracket}
                                                 openMatchPreviewModal={openMatchPreviewModal} viewOnly={viewOnly}
                                                 userMatchups={userMatchups}
                                                 loading={loading}
                                                 setFocusedGameNumber={setFocusedGameNumber}
                                                 focusedGameNumber={focusedGameNumber}
                            />
                            <img alt={'cat'} src={require('../../img/stickers/SM_TROPHY.png')}
                                 className='w-12 absolute shake-animation z-[1] -top-8 -right-0'/>
                        </div>
                        {/*{userBracket.submitted &&*/}
                        {/*    <p className='text-center mt-2 uppercase text-green-500'>*/}
                        {/*        {isViewingSelf ? 'YOUR' : 'Their'} winner!*/}
                        {/*    </p>*/}
                        {/*}*/}
                    </div>
                </div>
                :
                <div className='hidden md:flex flex-row w-full gap-3 items-center justify-center'>
                    <div className='flex flex-col gap-2'>
                        <div className='w-full font-body text-sm font-semibold text-center'>
                            <p>
                                Semifinals
                            </p>
                            <p className='text-xs font-normal opacity-50'>
                                Thu 4/4
                            </p>
                        </div>
                        <BracketLiveGameCard tbd gameNumber={60} liveBracket={liveBracket}
                                             openMatchPreviewModal={openMatchPreviewModal} viewOnly={viewOnly}
                                             userMatchups={userMatchups}
                                             loading={loading}
                                             setFocusedGameNumber={setFocusedGameNumber}
                                             focusedGameNumber={focusedGameNumber}
                        />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <div className='w-full text-sm font-semibold text-center  flex flex-col items-center'>
                            <StonkMadnessLogo className={'h-[120px] -mt-16 mb-3'}/>
                            <p className='uppercase text-yellow-400'>
                                Championship
                            </p>
                            <p className='text-xs font-body font-normal opacity-50'>
                                Fri 4/5
                            </p>
                        </div>
                        <div className='relative'>
                            <BracketLiveGameCard
                                tbd
                                isChampionship
                                gameNumber={62} liveBracket={liveBracket}
                                openMatchPreviewModal={openMatchPreviewModal} viewOnly={viewOnly}
                                userMatchups={userMatchups}
                                loading={loading}
                                setFocusedGameNumber={setFocusedGameNumber}
                                focusedGameNumber={focusedGameNumber}
                            />
                            <img alt={'cat'} src={require('../../img/stickers/SM_TROPHY.png')}
                                 className='w-12 absolute shake-animation z-[1] -top-8 -right-3'/>
                        </div>
                        {/*{userBracket.submitted &&*/}
                        {/*    <p className='text-center mt-2 uppercase text-green-500'>*/}
                        {/*        {isViewingSelf ? 'YOUR' : 'Their'} winner!*/}
                        {/*    </p>*/}
                        {/*}*/}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <div className='w-full font-body text-sm font-semibold text-center'>
                            <p>
                                Semifinals
                            </p>
                            <p className='text-xs font-normal opacity-50'>
                                Thu 4/4
                            </p>
                        </div>
                        <BracketLiveGameCard right tbd gameNumber={61} liveBracket={liveBracket}
                                             openMatchPreviewModal={openMatchPreviewModal} viewOnly={viewOnly}
                                             userMatchups={userMatchups}
                                             loading={loading}
                                             setFocusedGameNumber={setFocusedGameNumber}
                                             focusedGameNumber={focusedGameNumber}
                        />
                    </div>
                </div>
            }
        </>

    );
}

export default BracketLiveCenter;
