import React from 'react';
import StonkMadnessLogo from "../brand/logos/StonkMadnessLogo";
import AfterHourWordmark from "../brand/logos/AfterHourWordmark";
import SvgMail from "../icons/SvgMail";
import {WEBSITE_LINK} from "../../consts/dummy";
import SvgArrowOutward from "../icons/SvgArrowOutward";
import SocialButton from "../atoms/SocialButton";
import SvgX from "../icons/social/SvgX";
import SvgDiscord from "../icons/social/SvgDiscord";
import SvgTikTok from "../icons/social/SvgTikTok";
import SvgInstagram from "../icons/social/SvgInstagram";
import SvgReddit from "../icons/social/SvgReddit";

function Footer() {
    return (
        <div className='flex flex-col  md:flex-row p-4 md:pt-0 flex-wrap justify-between w-full'>
            <div className='flex flex-col md:flex-row flex-wrap items-center gap-3'>
                <StonkMadnessLogo className={'h-[40px]'}/>
                <div className='flex flex-row mb-3 md:mb-0 justify-center gap-2 items-center opacity-50'>
                    <SvgMail className={'w-4 h-4'}/>
                    <div className='text-xs uppercase mt-[1px]'>
                        <a href='mailto:stonkmadness@afterhour.com'>stonkmadness@afterhour.com</a>
                    </div>
                </div>
            </div>
            <div className='flex flex-col  md:flex-row items-center gap-5 z-[0] flex-wrap'>
                <div className='flex flex-col items-center gap-0'>
                    <p className='text-[8px] z-0 uppercase opacity-50'>
                        Presented by
                    </p>
                    <a href={WEBSITE_LINK} target="_blank" rel={'noreferrer'}>
                        <AfterHourWordmark className={'w-16 h-8 -mt-1'}/>
                    </a>
                </div>
                <p className='uppercase font-medium text-sm'>
                    Download our app <a href={WEBSITE_LINK} target="_blank"
                                        className='text-purple-400 opacity-100 cursor-pointer hover:text-purple-500'>HERE<SvgArrowOutward
                    className={'w-5 h-5 inline mb-0.5'}/></a>
                </p>
                <div className='flex flex-row items-center gap-2'>
                    <SocialButton sm link={'https://twitter.com/afterhour_hq'}>
                        <SvgX className={'w-5 h-5'}/>
                    </SocialButton>
                    <SocialButton sm link={'https://discord.gg/afterhour-lounge-970246105319239690'}>
                        <SvgDiscord className={'w-5 h-5'}/>
                    </SocialButton>
                    <SocialButton link={'https://www.instagram.com/afterhourhq'} sm>
                        <SvgInstagram className={'w-5 h-5'}/>
                    </SocialButton>
                    <SocialButton sm link={'https://www.tiktok.com/@afterhourhq'}>
                        <SvgTikTok className={'w-5 h-5'}/>
                    </SocialButton>
                </div>
            </div>
        </div>
    );
}

export default Footer;
