import React, {useState, useEffect} from 'react';
import ModalComponent from "../atoms/ModalComponent";
import {Button} from "../atoms/Button";
import Spinner from '../atoms/Spinner';
import SvgCheck from '../icons/SvgCheck';
import {connect} from 'react-redux';
import {getFinalFourImages, getTopEightImages} from '../../api/social';
import style1 from '../../img/share/style1.png'
import style2 from '../../img/share/style2.png'
import style3 from '../../img/share/style3.png'
import AnimatedTabs from "../atoms/AnimatedTabs";


const mapStateToProps = (state) => {
    return {
        mobile: state.userReducer.mobile,
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        userBracket: state.userReducer.userBracket
    }
}

const isIOS = () => {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
}


function ShareSelectionModal(props) {
    const [buttonText, setButtonText] = useState("Share Now")
    const [copied, setCopied] = useState(false);
    const [championImages, setChampionImages] = useState([]);
    const [finalFourImages, setFinalFourImages] = useState([]);
    const [topEightImages, setTopEightImages] = useState([]);
    const [selectedStyle, setSelectedStyle] = useState(0);
    const [activeType, setActiveType] = useState('Winner');
    const TABS = ['Winner', 'Top 4', 'Top 8'];
    const [loadingStyle, setLoadingStyle] = useState(false)

    useEffect(() => {
        if (props.isOpen) {
            _setChampions();
            _getFinalFourOptions();
            _getTopEightOptions();
        }
    }, [props.isOpen]);

    const _getFinalFourOptions = () => {
        getFinalFourImages(props.username).then(res => {
            if (!res?.images.length) return;
            setFinalFourImages(res.images);
        })
        
    }

    const _getTopEightOptions = () => {
        getTopEightImages(props.username).then(res => {
            if (!res?.images.length) return;
            setTopEightImages(res.images);
        })
        
    }

    const _setChampions = () => {
        const championTicker = props.champion;
        setChampionImages([
            {"url": get_supabase_url("champion", `${championTicker}${1}`), "round": "champion", "hash": `${championTicker}${1}`},
            {"url": get_supabase_url("champion", `${championTicker}${2}`), "round": "champion", "hash": `${championTicker}${2}`},
            {"url": get_supabase_url("champion", `${championTicker}${3}`), "round": "champion", "hash": `${championTicker}${3}`},
        ])
    }

    const get_supabase_url = (round, file_name) => {
        return `https://zyidwzbarunqmcnmfukk.supabase.co/storage/v1/object/public/og_images/${round}/${file_name}.jpg`
    }

    const share = () => {
        const mobile = props.mobile;
        const image = getCurrentImage();
        if (mobile) {
            navigator.share({
                title: 'My Stonk Madness Bracket!',
                url: `https://bracket.stonkmadness.com/bracket/${props.username}/${image.round}/${image.hash}`
            })
        } else {
            navigator.clipboard.writeText(`https://bracket.stonkmadness.com/bracket/${props.username}/${image.round}/${image.hash}`).then(() => {
                setCopied(true);
                setButtonText('Link copied');

                // Reset state after 3 seconds
                setTimeout(() => {
                    setCopied(false);
                    setButtonText('Share Now');
                }, 6000);
            });
        }
    }

    const getCurrentImage = () => {
        if (activeType === "Winner") return championImages[selectedStyle];
        if (activeType === "Top 4") return finalFourImages[selectedStyle];
        if (activeType === "Top 8") return topEightImages[selectedStyle];
    }

    const currentUrl = getCurrentImage()?.url || "https://zyidwzbarunqmcnmfukk.supabase.co/storage/v1/object/public/public_assets/STONKMADNESS_OG_COMPRESSED.png";
    const loading = (activeType === 'Top 4' && finalFourImages.length === 0) || (activeType === 'Top 8' && topEightImages.length === 0);
    const downloadImage = async () => {
        try {
            if (props.mobile && isIOS) {
                try {
                    // Fetch the image
                    const response = await fetch(currentUrl);
                    const blob = await response.blob();

                    // Create a file from the blob
                    const file = new File([blob], 'bracket.png', {type: 'image/png'});

                    // Use the native share sheet on iOS
                    if (navigator.canShare && navigator.canShare({files: [file]})) {
                        navigator.share({
                            files: [file],
                            title: 'My Stonk Madness Bracket!',
                            text: 'Play stonkmadness.com free now. Pick the right stocks, win prizes!',
                        })
                            .then(() => console.log('Share was successful.'))
                            .catch((error) => console.log('Sharing failed', error));
                    } else {
                        // Fallback for devices where navigator.canShare({files: []}) is false
                        console.log('File sharing is not supported on this device.');
                    }
                } catch (error) {
                    console.error('Error sharing the image:', error);
                }
            } else {
                const response = await fetch(currentUrl);
                if (!response.ok) throw new Error('Network response was not ok.');

                const blob = await response.blob();
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = "share-image.png"; // You can customize the filename

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(downloadUrl); // Clean up
            }
        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    };

    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={props.mobile ? 'full-mobile' : 'lg'}
            toggle={props.close}
            header={'PICK YOUR SHARE IMAGE!'}
            headerToggle
            backdropClass={'bg-yellow-400/30'}
        >
            <div className='flex flex-col gap-3 flex-1 min-w-[300px] relative'>
                <div className='flex flex-row justify-between gap-3 bg-white/10 p-3 rounded-xl'>
                    <div className='flex flex-col gap-2'>
                        <p className='font-body text-sm md:text-lg font-semibold'>
                            What to share
                        </p>
                        <div className='flex flex-row items-center gap-3 mt-2.5'>
                            <AnimatedTabs
                                activeTab={activeType}
                                changeTab={setActiveType}
                                textClassName={'sm:text-sm md:text-lg'}
                                tabs={TABS.map(tab => {
                                    return ({title: tab})
                                })}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p className='font-body text-sm md:text-lg font-semibold'>
                            Select a style
                        </p>
                        <div className='flex flex-row items-center gap-3'>
                            {[style1, style2, style3].map((style, index) =>
                                <div key={style}
                                     onClick={() => setSelectedStyle(index)}
                                     className={`rounded-lg ${selectedStyle === index ? 'outline outline-2 outline-yellow-400' : 'opacity-70 hover:opacity-100 outline outline-2 outline-transparent hover:outline-yellow-400/50 cursor-pointer'}`}>
                                    <img alt={'style'} className='w-12 md:w-20 h-12 md:h-14 rounded-lg object-cover'
                                         src={style}/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='relative w-full mx-auto flex flex-1 items-center justify-center'
                     style={{aspectRatio: 2}}>
                    {loading ?
                        <div
                            className='absolute rounded-xl top-0 left-0 bottom-0 right-0 backdrop-blur-lg flex items-center justify-center flex flex-col gap-5'>
                            <Spinner/>
                            <p className='text-lg opacity-50 font-body font-semibold'>
                                Loading shareable images...
                            </p>
                        </div>
                        :
                        <img src={currentUrl}
                             alt={'image to share'}
                             style={{aspectRatio: 2}}
                             className='flex flex-1 bg-white/10 border-2 border-white/20 rounded-xl w-full mx-auto object-cover'/>
                    }
                    {loadingStyle &&
                        <div
                            className='absolute top-0 left-0 bottom-0 right-0 backdrop-blur-lg flex flex-1 rounded-xl items-center justify-center'
                            style={{aspectRatio: 2}}
                        >
                            <Spinner/>
                        </div>
                    }
                </div>
                {!loading && (
                    <div className='flex flex-col md:flex-row items-center gap-3 mb-16 md:mb-0'>
                        <Button size={''} onClick={share}
                                className={'shadow-2xl animate-pulse border-2 w-full md:mb-0 border-black'}>
                            {copied && <SvgCheck/>} {buttonText}
                        </Button>
                        <Button onClick={downloadImage}
                                className={'shadow-2xl border-2 w-full mb-16 md:mb-0 border-black'}
                                variant={'secondary'}>
                            Download Image
                        </Button>
                    </div>
                )}
            </div>
        </ModalComponent>
    );
}

export default connect(mapStateToProps)(ShareSelectionModal);
