import model3 from '../img/prizes/model3.png'
import applevision from '../img/prizes/applevision.png'
import lvball from '../img/prizes/lvball.png'
import airpods from '../img/prizes/airpods.png'
import fryer from '../img/prizes/fryer.png'
import meta from '../img/prizes/meta.png'
import ps5 from '../img/prizes/ps5.png'
import sonos from '../img/prizes/sonos.png'
import sony from '../img/prizes/sony.png'
import steamdeck from '../img/prizes/steamdeck.png'
import xbox from '../img/prizes/xbox.png'
import stanley from '../img/prizes/stanley.png'
import reddit_sticker from '../img/stickers/reddit.png'
import imessage_sticker from '../img/stickers/imessage (3).png'
import x_sticker from '../img/stickers/x-sticker.png'
import {tweetIntent} from "../api/social";
import tenders from '../img/prizes/tenders.webp'
import {toast} from "react-toastify";
import LottieWrapper from "../components/LottieWrapper";
import crown from "../img/lottie/crown.json";
import React from "react";


export const pageClass = 'flex flex-col gap-24 items-center px-4 pb-24'
export const sectionClass = 'flex flex-col gap-8 items-start w-full'
export const cardClass = 'flex flex-col gap-8 items-start w-full bg-white/15 rounded-3xl md:rounded-3xl p-5 md:p-6'

export const SHARE_TO_SOCIAL_TEXT = `Join me on Stonk Madness and fill out your bracket for a chance to win 20 prizes including a Lambo or an Apple Vision Pro!`;

export const TWEET_TEXT = `\n\n\n`;
export const SHARE_OPTIONS = [
    {
        title: 'Message',
        img: imessage_sticker,
        onClick: () => {
            window.amplitude.track("Shared To Messages");
            window.location = `sms://?&body=${SHARE_TO_SOCIAL_TEXT} https://stonkmadness.com/`;
        }
    },
    {
        title: 'Post on X',
        img: x_sticker,
        onClick: async () => {
            window.amplitude.track("Shared To X");
            window.open(await tweetIntent({
                text: SHARE_TO_SOCIAL_TEXT,
                hashtags: 'StonkMadness,StockMarket,Stocks',
                url: 'stonkmadness.com',
            }), '_blank', 'noreferrer')
        }
    },
    {
        title: 'Reddit',
        img: reddit_sticker,
        onClick: () => {
            window.amplitude.track("Shared To Reddit");
            window.open('https://www.reddit.com/submit?title=Come%20join%20me%20on%20Stonk%20Madness&url=https://stonkmadness.com', '_blank', 'noreferrer');
        }
    },
]

export const SCHEDULE = [
    {
        title: '1st Round',
        gamesPerDay: 8,
        gamesPerRound: 32,
        points: 100,
        days: [
            {
                title: 'Day 1',
                date: 'Tue 3/19',
                stocksLeft: 64
            },
            {
                title: 'Day 2',
                date: 'Wed 3/20',
                stocksLeft: 56
            },
            {
                title: 'Day 3',
                date: 'Thu 3/21',
                stocksLeft: 48
            },
            {
                title: 'Day 4',
                date: 'Fri 3/22',
                stocksLeft: 40
            }
        ]
    },
    {
        title: '2nd Round',
        gamesPerDay: 4,
        gamesPerRound: 16,
        points: 200,
        days: [
            {
                title: 'Day 5',
                date: 'Mon 3/25',
                stocksLeft: 32
            },
            {
                title: 'Day 6',
                date: 'Tue 3/26',
                stocksLeft: 28
            },
            {
                title: 'Day 7',
                date: 'Wed 3/27',
                stocksLeft: 24
            },
            {
                title: 'Day 8',
                date: 'Thu 3/28',
                stocksLeft: 20
            }
        ]
    },
    {
        title: 'Third Round',
        gamesPerDay: 8,
        gamesPerRound: 8,
        points: 300,
        days: [
            {
                title: 'Day 9',
                date: 'Mon 4/1',
                stocksLeft: 16
            },
            // {
            //     title: 'Day 10',
            //     date: 'Mon 4/1',
            //     stocksLeft: 12
            // }
        ]
    },
    {
        title: 'Quarterfinals',
        gamesPerDay: 2,
        gamesPerRound: 4,
        points: 400,
        days: [
            {
                title: 'Day 10',
                date: 'Tue 4/2',
                stocksLeft: 8
            },
            {
                title: 'Day 11',
                date: 'Wed 4/3',
                stocksLeft: 6
            }
        ]
    },
    {
        title: 'Semifinals',
        gamesPerDay: 2,
        gamesPerRound: 2,
        points: 600,
        days: [
            {
                title: 'Day 12',
                date: 'Thu 4/4',
                stocksLeft: 4
            }
        ]
    },
    {
        title: 'Championship',
        gamesPerDay: 1,
        gamesPerRound: 1,
        points: 1000,
        days: [
            {
                title: 'Day 13',
                date: 'Fri 4/5',
                stocksLeft: 2
            }
        ]
    }
];


export const FAQ = [
    {
        question: 'What is the game time window?',
        answer: 'The game window is defined as previous market close to current market close. If the game is on a Tuesday, then the match window would be Mon 4pm ET to Tue 4pm ET price.',
    },
    {
        question: 'What stocks will make the bracket?',
        answer: 'The final list of 64 is still being decided. Cast your vote using #StonkMadness on X to see your favorites make the cut. No index funds and no crypto, only stocks.'
    },
    {
        question: 'What happens in a tie?',
        answer: "In the extremely rare case of a tie, winner chosen is the the stock with the larger '% high of day' than the other stock.",
    },
    {
        question: 'Can I edit my bracket?',
        answer: 'Yes. Each email can only submit one final bracket, but for all of the paperhands out there, we let you redo your bracket at any point before the first game. Once the first game starts, all brackets will be locked.'
    },
    {
        question: 'How is my score calculated?',
        answer: 'Picking the right stock at each round earns points. The later the round the more points are awarded for a correct pick: 100 points for first round, 200 points for second round, 300 points for third round, 400th for fourth round (quarterfinals), 600 for fifth round (semifinals) and 1,000 for sixth round (championship). More info can be found on our How to Play page.'
    },
    {
        question: 'Why is AfterHour hosting this?',
        answer: 'Why not?',
        link: 'https://www.youtube.com/watch?v=L-l6tHeseDY'
    },
];


export const routes = [
    {
        title: 'About',
        route: '/about',
    },
    {
        title: 'Leaderboard',
        route: '/leaderboard',
    },
    {
        title: 'Prizes',
        route: '/prizes',
    },
    {
        title: 'How To Play',
        route: '/how-to-play',
    },
    {
        title: 'Schedule',
        route: '/schedule',
    },
]

export const authenticatedRoutes = [
    {
        title: 'Tournament Hub',
        route: '/hub',
    },
    {
        title: 'My Bracket',
        route: '/bracket',
    },
    {
        title: 'Leaderboard',
        route: '/leaderboard',
    },
    {
        divider: true,
    },
    {
        title: 'About',
        route: '/about',
    },
    {
        title: 'Prizes',
        route: '/prizes',
    },
    {
        title: 'How To Play',
        route: '/how-to-play',
    },
    {
        title: 'Schedule',
        route: '/schedule',
    },
]


export const first_place_prizes = [
    {
        title: '$1000 McDonalds Gift Card',
        desc: 'Get as many tendies as your heart desires',
        img: tenders,
        locked: false,
        unlock: '1k'
    },
    {
        title: 'Apple Vision Pro',
        img: applevision,
        locked: true,
        unlock: '50k'
    },
    {
        title: 'Louis Vuitton Basketball',
        img: lvball,
        locked: true,
        unlock: '100k'
    },
    {
        title: 'Tesla Model 3',
        img: model3,
        locked: true,
        unlock: '1M'
    },
]

export const prizes = [
    {
        title: 'Steamdeck',
        img: steamdeck,
    },
    {
        title: 'Xbox Series X',
        img: xbox,
    },
    {
        title: 'Playstation 5 (Digital)',
        img: ps5,
    },
    {
        title: 'Sonos Era 300',
        img: sonos,
    },
    {
        title: 'Sony WH-1000XM4',
        img: sony,
    },
    {
        title: 'Meta Smart Glasses',
        img: meta,
    },
    {
        title: 'AirPods Pro',
        img: airpods,
    },
    {
        title: 'Instant Pot Vortex Plus 6-Quart Air Fryer',
        img: fryer,
    },
    {
        title: 'Stanley Tumbler',
        img: stanley,
    },
]

export const BACKEND_URL = 'http://localhost:8000'
export const HISTORIC_REFRESH_MILLIS = 1 * 60 * 1000
export const LIVE_REFRESH_MILLIS = 3 * 1000;
export const APP_STORE_LINK = 'https://apps.apple.com/us/app/afterhour-wallstreetbets-2-0/id1640766056'

export const WEBSITE_LINK = 'https://afterhour.app.link/stonkmadness'

export const PERFECT_TOTAL = 63
export const PERFECT_REQUIREMENT = 63


export const randomNotifications = [
    {
        message: "Fart detected. That's a real stinker. You alright buddy?"
    },
    {
        message: "Your mom just picked $MCD. Get it? Lol"
    },
    {
        message: "Keep on chugging pal. That is all."
    },
    {
        message: "Go get em sport! Make your FBI agent proud."
    },
]

export const triggerRandomNotification = () => {
    const emojis = Object.values(randomNotifications); // Convert emoji object to array
    const randomIndex = Math.floor(Math.random() * randomNotifications.length);
    const message = emojis[randomIndex].message;
    return toast(message)
};


export const startDate = "2024-03-18T16:00:00"

export const gamesBegin = "2024-03-19T09:30:00";

export const getConferenceFromTicker = (ticker) => {
    const tickers1 = ["AAPL", "META", "GOOG", "MSFT", "AMZN", "SHOP", "V", "MA", "NFLX", "DIS", "TSLA", "RIVN", "PYPL", "SQ", "RBLX", "EA"];
    const tickers2 = ['PDD', 'BABA', 'NKE', 'LULU', 'UA', 'CROX', 'HD', 'LOW', 'T', 'VZ', 'WMT', 'TGT', 'GME', 'AMC', 'PENN', 'DKNG']
    const tickers3 = ["NVDA", "AMD", "SMCI", "ARM", 'MCD', "CMG", "DPZ", "PZZA", "PEP", "KO", "MNST", "CELH", "SBUX", "DNUT", "TLRY", "WEN"];
    const tickers4 = ['BKNG', 'ABNB', 'COIN', 'HOOD', 'UBER', 'LYFT', 'SNAP', 'PINS', 'MTCH', 'BMBL', 'LUV', 'DAL', 'SPOT', 'SIRI', 'F', 'GM']
    if (tickers1.includes(ticker)) {
        return {
            name: "Pelosi's Picks",
            number: 1
        }
    }
    if (tickers2.includes(ticker)) {
        return {
            name: "Retail Therapy",
            number: 2
        }
    }
    if (tickers3.includes(ticker)) {
        return {
            name: "Chips and Salsa",
            number: 3,
        }
    }
    if (tickers4.includes(ticker)) {
        return {
            name: "Our Intern Quit",
            number: 4
        }
    }
}


export const getConferenceMatchupMessage = (ticker1, ticker2, gameNumber, isCreatingBracket) => {
    const conference1 = getConferenceFromTicker(ticker1)
    const conference2 = getConferenceFromTicker(ticker2)
    if (conference1?.name === conference2?.name) {
        if (gameNumber >= 57) {
            return <div className='-mb-5'>
                <p className={`text-center -mb-1 md:text-xl uppercase text-yellow-400 font-bold ${isCreatingBracket && 'shake-animation-light'}`}>{conference1?.name}</p>
                <div className='relative'>
                    <p className='text-center sm:text-xs md:text-sm uppercase  text-yellow-400 font-bold'>Conf.
                        Championship</p>

                </div>
            </div>
        } else return <p
            className='text-center text-sm md:text-lg uppercase -mb-5 text-white/70 font-bold'>Conference {conference1?.number}: {conference1?.name}</p>
    } else {
        if (gameNumber === 63) {
            return (
                <div className='-mb-5'>
                    <p className={`text-center -mb-1 text-xl md:text-xl uppercase text-yellow-400 font-bold ${isCreatingBracket && 'shake-animation-light'}`}>
                        Championship
                    </p>
                    <div className='relative'>
                        {isCreatingBracket &&
                            <p className='text-center text-xs md:md:text-sm uppercase  text-yellow-400 font-bold'>
                                SELECT YOUR WINNER
                            </p>
                        }
                        <div className=' h-0'>
                            <LottieWrapper json={crown} width={80}/>
                        </div>
                    </div>
                </div>
            )
        }
        if (gameNumber >= 60) {
            return <div className='-mb-5'>
                <p className={`text-center -mb-1 md:text-xl uppercase text-yellow-400 font-bold ${isCreatingBracket && 'shake-animation-light'}`}>Semifinal {gameNumber === 61 ? 1 : 2}</p>
                <div className='relative'>
                    <p className='text-center text-xs md:md:text-sm uppercase  text-yellow-400 font-bold'>
                        {conference1?.name}
                        <span className='text-xs'> vs.</span> {conference2?.name}
                    </p>

                </div>
            </div>
        }
        return <p
            className='text-center -mb-5 md:text-xl uppercase text-yellow-400 font-bold'>{conference1?.name}
            <span className='text-xs'> vs.</span> {conference2?.name}</p>
    }
}
