import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {firebaseAuth} from '../api/firebase';
import {applyActionCode} from 'firebase/auth';
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
    return {
        authUser: state.userReducer.authUser,
    }
}

// https://firebase.google.com/docs/auth/custom-email-handler
function EmailActionHandlerPage(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [apiKey, setApiKey] = useState(searchParams.get("apiKey"))

    // present if coming from email
    const [mode, setMode] = useState(searchParams.get("mode"))
    const [actionCode, setActionCode] = useState(searchParams.get("oobCode"))
    const [continueUrl, setContinueUrl] = useState(searchParams.get("continueUrl"))
    const [lang, setLang] = useState(searchParams.get("lang"))
    const [authSuccessfulØ, setAuthSuccessful] = useState(false);

    // present if coming from google oauth
    const [authType, setAuthType] = useState(searchParams.get("authType"))
    const [redirectUrl, setRedirectUrl] = useState(searchParams.get("redirectUrl"))
    const [providerId, setProviderId] = useState(searchParams.get("providerId"))


    // handle redirect
    useEffect(() => {
        if (!!authType)
            handleOauthRedirect()
        if (!!mode)
            handleEmailRedirect()
    }, [props.authUser])

    const handleOauthRedirect = () => {
        window.location.replace(redirectUrl);
    }

    const handleEmailRedirect = () => {
        switch(mode) {
            case 'verifyEmail':
                return handleVerifyEmail();
            case 'resetPassword':
                return;
            case 'recoverEmail':
                return;
            default:
                // error
                return;
        }
    }

    const handleVerifyEmail = () => {
        if (props?.authUser?.emailVerified) {
            window.location.replace(continueUrl)
        } else {
            applyActionCode(firebaseAuth, actionCode)
            .then((res) => {
                window.location.replace(continueUrl)
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    return <div />
}

export default connect(mapStateToProps, null)(EmailActionHandlerPage);
