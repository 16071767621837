import React from 'react';
import StockLogo from "../atoms/StockLogo";
import TooltipComponent from "../atoms/TooltipComponent";
import SvgTriangle from "../icons/SvgTriangle";
import {renderLiveGameBracketTickerColor} from "../utils/timing";

function MatchupTableCardTeamRow(props) {
    return (
        <div className={`flex font-body flex-row gap-2 md:gap-3 items-center relative`}>
            {props.userSelected &&
                <div className={`absolute -rotate-90 -left-4 md:-left-6 ${(props.realWinner && !props.isRealWinner) ? 'text-red-500 opacity-50' : 'text-yellow-400'}`}>
                    <SvgTriangle className={'w-4 h-4'}/>
                </div>
            }
            <StockLogo border tbd={props.tbd} ticker={props.ticker}
                       className={`
                        w-8 h-8 md:w-10 md:h-10 z-[-1] 
                        ${(props.realWinner && !props.isRealWinner) && 'opacity-50'}
            `}/>
            <p className={`text-sm md:text-lg font-semibold  ${(props.realWinner && !props.isRealWinner) && 'opacity-50 line-through'}  ${renderLiveGameBracketTickerColor(props.gameNumber)}`}>
                {props.tbd && 'TBD' || props.ticker}
            </p>
        </div>
    );
}

export default MatchupTableCardTeamRow;
