import React, {useEffect, useState} from 'react';
import MatchupTableCard from "./MatchupTableCard";
import SvgChevronDown from "../icons/SvgChevronDown";
import CollapseComponent from "../atoms/CollapseComponent";
import { DateTime } from 'luxon';

const roundDaysMap = {
    '1st': 1,
    '2nd': 5,
    '3rd': 9,
    'Quarters': 11,
    'Semis': 13,
    'Finals': 14,
};

const roundGameNumberStart = {
    '1st': 0,
    '2nd': 32,
    '3rd': 48,
    'Quarters': 56,
    'Semis': 60,
    'Finals': 62,
}

const gameViewable = (gameNumber) => {
    const today = DateTime.now().setZone("America/New_York").toFormat("yyyy-MM-dd");
    // round 1
    if (today <= "2024-03-19T16:30:00") return gameNumber < 32;
    if (today <= "2024-03-20T16:30:00") return gameNumber < 36;
    if (today <= "2024-03-21T16:30:00") return gameNumber < 40;
    if (today <= "2024-03-22T16:30:00") return gameNumber < 44;
    // round 2
    if (today <= "2024-03-25T16:30:00") return gameNumber < 48;
    if (today <= "2024-03-26T16:30:00") return gameNumber < 50;
    if (today <= "2024-03-27T16:30:00") return gameNumber < 52;
    if (today <= "2024-03-28T16:30:00") return gameNumber < 54;
    // round 3
    if (today <= "2024-03-29T16:30:00") return gameNumber < 56;
    if (today <= "2024-04-01T16:30:00") return gameNumber < 58;
    // round 4
    if (today <= "2024-04-02T16:30:00") return gameNumber < 60;
    if (today <= "2024-04-03T16:30:00") return gameNumber < 61;
    // round 5
    if (today <= "2024-04-04T16:30:00") return gameNumber < 62;
    // round 6
    return true;
}

function MatchupsTableRoundDisplay(props) {
    const [dayCollapsed, setDayCollapsed] = useState(false);

    const toggleDayCollapse = () => {
        setDayCollapsed(!dayCollapsed);
    };

    useEffect(() => {
        setDayCollapsed(false);
    }, [props.activeTab]);

    return (

        <div key={props.round.day} className="w-full flex flex-col gap-5 font-body">
            <div onClick={toggleDayCollapse}
                 className='flex sticky z-[2] top-[120px] md:top-[144px] bg-black/80 backdrop-blur hover:bg-white/10 cursor-pointer flex-row items-center justify-between border border-white/20 px-5 py-1 rounded-full'>
                <p className="text-lg font-semibold">Day {roundDaysMap[props.activeTab] + props.index}</p>
                <SvgChevronDown className={`transition-transform duration-300 ease ${!dayCollapsed ? 'rotate-180' : ''}`} />
            </div>
            <CollapseComponent isOpen={!dayCollapsed}>
               <div className='flex flex-col gap-3'>
                   {props.round.matchups.map((matchup) => {
                            const matchupIndex = roundGameNumberStart[props.activeTab] + props.index * props.round.matchups.length + matchup.id - 1;
                            const game = !!props.masterBracket?.[matchupIndex]?.ticker1 ? props.masterBracket?.[matchupIndex] : null;
                            return <MatchupTableCard tbd={!game || !gameViewable(matchupIndex)} matchup={{...game, id: matchupIndex}} userMatchups={props.userMatchups} key={matchup.id}/>
                        }
                   )}
               </div>
            </CollapseComponent>
        </div>

    );
}

export default MatchupsTableRoundDisplay;
