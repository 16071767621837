import React from "react";


function LeaderboardHeaders() {
    return (
        <div className='text-[9px] md:text-sm p-5 pb-0 font-semibold w-full flex flex-row items-center opacity-50'>
            <div className='flex-[1.1]'>
                <p>
                    Player
                </p>
            </div>
            <div className='flex-[.4]'>
                <p>
                    Winner
                </p>
            </div>
            {/*<div className='flex-[.4]'>*/}
            {/*    <p>*/}
            {/*        Top 8 left*/}
            {/*    </p>*/}
            {/*</div>*/}
            <div className='flex-[.4]'>
                <p>
                    # wins
                </p>
            </div>
            <div className='flex-[.25]'>
                <p>
                    Score
                </p>
            </div>
        </div>
    )
}

export default LeaderboardHeaders
