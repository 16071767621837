import React, {useState, useEffect} from 'react';
import {sectionClass} from '../consts/dummy';
import AnimatedTabs from '../components/atoms/AnimatedTabs';
import MatchupsTableRoundDisplay from "../components/matchuptable/MatchupsTableRoundDisplay";
import {connect} from 'react-redux';

const roundGamesMap = {
    '1st': 32,
    '2nd': 16,
    '3rd': 8,
    'Quarters': 4,
    'Semis': 2,
    'Finals': 1,
};

const mapStateToProps = (state) => {
    return {
        userBracket: state.userReducer.userBracket,
        readonlyMasterBracket: state.userReducer.readonlyMasterBracket,
    }
}

function MatchupsTable(props) {
    const [activeTab, setActiveTab] = useState('1st');
    const [userMatchups, setUserMatchups] = useState([]);

    const getMatchupsForRound = (round) => {
        const matchupsPerDay = roundGamesMap[round] / (round === '1st' ? 4 : round === '2nd' ? 4 : round === '3rd' ? 2 : round === 'Quarters' ? 2 : 1);
        const days = round === '1st' ? 4 : round === '2nd' ? 4 : round === '3rd' ? 2 : round === 'Quarters' ? 2 : 1;

        return Array.from({length: days}).flatMap((day) => ({
            day: day + 1,
            matchups: Array.from({length: matchupsPerDay}).map((_, index) => ({
                id: index + 1,
            })),
        }));
    };

    const rounds = getMatchupsForRound(activeTab);

    const _generateUserMatchups = () => {
        console.log(props)
        const userMatchups = [];
        for (var i = 0; i < 32; i++)
            userMatchups.push({ticker1: props.readonlyMasterBracket[i].ticker1, ticker2: props.readonlyMasterBracket[i].ticker2});
        for (var i = 0; i < 62; i += 2) {
            const ticker1 = props.userBracket[`g${i}`];
            const ticker2 = props.userBracket[`g${i+1}`];
            userMatchups.push({ticker1: ticker1, ticker2: ticker2})
        }
        return userMatchups
    }

    useEffect(() => {
        setUserMatchups(_generateUserMatchups);
    }, [props.userBracket])

    return (
        <div className={`${sectionClass}`}>
            <p className='text-4xl bg-black text-center mx-auto uppercase'>Matchups</p>
            <div className='w-full'>
                <div className='w-full sticky top-[74px] md:top-[74px] z-[3] pt-1 pb-3 bg-black/80 backdrop-blur'>
                    <AnimatedTabs
                        activeTab={activeTab}
                        changeTab={setActiveTab}
                        tabs={[
                            {title: '1st'},
                            {title: '2nd'},
                            {title: '3rd'},
                            {title: 'Quarters'},
                            {title: 'Semis'},
                            {title: 'Finals'},
                        ]}
                    />
                </div>
                <div className='w-full flex flex-col items-center gap-5'>
                    {rounds.map((round, index) => (
                        <MatchupsTableRoundDisplay
                            masterBracket={props.masterBracket}
                            userMatchups={userMatchups}
                            activeTab={activeTab}
                            key={activeTab+ index}
                            index={index}
                            round={round}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(MatchupsTable);
