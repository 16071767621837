import React, {useEffect, useState} from 'react';
import {withParentSize} from "@visx/responsive";
import {scaleLinear} from '@visx/scale';
import {Group} from '@visx/group';
import {Bar, Line, LinePath, Circle} from '@visx/shape';
import {Threshold} from '@visx/threshold';
import {DateTime} from 'luxon';
import {defaultStyles, Tooltip, withTooltip} from '@visx/tooltip';
import {localPoint} from "@visx/event";
import {bisector} from "d3-array";
import {curveMonotoneX} from "@visx/curve";
import {baseBlack70,} from "../consts/styleconsts";
import {getHistoricData} from '../api/stock';
import Spinner from '../components/atoms/Spinner';
import TimeframeSelectRow from "../components/bracket/TimeframeSelectRow";
import SvgStockUp from "../components/icons/SvgStockUp";
import SvgStockDown from "../components/icons/SvgStockDown";
import {wait} from "@testing-library/user-event/dist/utils";
import {connect} from 'react-redux';
import {federalHolidays} from './utils';

// utils
const min = (arr, fn1, fn2) => Math.min(...arr.map(fn1), ...arr.map(fn2))
const max = (arr, fn1, fn2) => Math.max(...arr.map(fn1), arr.map(fn2))
const extent = (arr, fn1, fn2) => [min(arr, fn1), max(arr, fn2)]
const getDate = (timestamp_unix) => DateTime.fromMillis(timestamp_unix?.label || 0);

const mapDispatchToProps = (dispatch) => {
    return {
        updateChartData: (chartData) => {
            dispatch({
                type: "UPDATE_CHART_DATA",
                chartData
            })
        },
    }
}

const mapStateToProps = (state) => {
    return {
        chartData: state.userReducer.chartData,
    }
}

function GamedayGraph({
                             // passed props

                             leftStock,
                             rightStock,
                             width = '100%',
                             height,
                             margin = {left: 0, right: 0, top: 0, bottom: 0},
                             viewType = 'fixed',
                             onMouseMove = () => {
                             },
                             onMouseLeave = () => {
                             },

                             // enhancer props
                             parentWidth,
                             parentHeight,
                             showTooltip,
                             hideTooltip,
                             tooltipData,
                             tooltipTop = 0,
                             tooltipLeft = 0,

                             // redux
                             chartData,
                             updateChartData,
                         }) {
    // state
    const [loading, setLoading] = useState(false);
    const [leftStockData, setLeftStockData] = useState([]);
    const [rightStockData, setRightStockData] = useState([]);
    const [combinedData, setCombinedData] = useState([]);
    const [xScale, setXScale] = useState(() => () => 0);
    const [yScale, setYScale] = useState(() => () => 0);
    const [chartSpan, setChartSpan] = useState('1d');
    const [stock1Override, setStock1Override] = useState(null)
    const [stock1HoverPrice, setStock1HoverPrice] = useState(null)
    const [stock2Override, setStock2Override] = useState(null)
    const [stock2HoverPrice, setStock2HoverPrice] = useState(null)
    const [stock1EndVal, setStock1EndVal] = useState(null)
    const [stock2EndVal, setStock2EndVal] = useState(null)

    // bounds
    const xMin = margin?.left || 0;
    const xMax = (parentWidth || 506) - (margin?.left || 0) - (margin?.right || 0);
    const yMin = margin?.top || 0;
    const yMax = parentHeight - (margin?.top || 0) - (margin?.bottom || 0) - 150 + 68;

    // accessors
    const timeAccessor = (d) => d?.t || 0;
    const percentAccessor = (d, prevClose) => ((d?.c ? d.c - prevClose : prevClose) / prevClose * 100).toFixed(1);
    const prevCloseAccessor = (stock, stockData) => stock?.previous_close || stockData[0]?.c;
    const bisectDate = bisector(timeAccessor).left;
    const fixedWindowEndTime = (arr) => DateTime.fromMillis(timeAccessor(arr[0].left)).set({
        hour: 16,
        minute: 0,
        second: 0
    })

    const formatDate = (datetime) => datetime.toFormat(getHistoricDataArgs(chartSpan).dateFormat);

    // initialize
    useEffect(() => {
        if (!leftStock?.ticker || !rightStock?.ticker) {
            return;
        }
        handleSpanChange(chartSpan);
    }, [leftStock?.ticker, rightStock?.ticker, parentWidth, parentHeight])

    // change chart
    const handleSpanChange = async (span) => {
        setLoading(true);
        setChartSpan(span)
        const {leftData, rightData} = await _getHistoricData(span);
        const combinedData = getCombinedData(leftData, rightData, span);
        getCurrentPercent(combinedData);
        getXScale(combinedData, span);
        getYScale(combinedData);
        setLoading(false);
    }

    // Load data
    const _getHistoricData = async (_span) => {
        const span = _span || chartSpan;
        const {start, multiplier, timespan} = getHistoricDataArgs(span);

        // get left stock data
        let leftData;
        if (chartData?.[leftStock?.ticker]?.[span] !== undefined) {
            // data for this ticker/span has been cached
            leftData = chartData[leftStock.ticker][span];
        } else {
            const res1 = await getHistoricData(leftStock?.ticker, "", start.toFormat("yyyy-MM-dd"), "", multiplier, timespan, span);
            leftData = res1?.results || [];
            const cachedData = chartData?.[leftStock?.ticker] || {};
            cachedData[span] = leftData;
            updateChartData({[leftStock.ticker]: cachedData})
        }
        setLeftStockData(leftData);

        // get right stock data
        let rightData;
        if (chartData?.[rightStock?.ticker]?.[span] !== undefined) {
            // data for this ticker/span has been cached
            rightData = chartData[rightStock.ticker][span];
        } else {
            const res2 = await getHistoricData(rightStock?.ticker, "", start.toFormat("yyyy-MM-dd"), "", multiplier, timespan, span);
            rightData = res2?.results || [];
            const cachedData = chartData?.[rightStock?.ticker] || {};
            cachedData[span] = rightData;
            updateChartData({[rightStock.ticker]: cachedData})
        }
        setRightStockData(rightData);

        return {leftData, rightData};
    }

    const getCurrentPercent = (combinedData) => {
        const lastLeftDataPoint = combinedData[combinedData.length - 1].left;
        const lastRightDataPoint = combinedData[combinedData.length - 1].right;
        setStock1EndVal(percentAccessor(lastLeftDataPoint, prevCloseAccessor(leftStock, combinedData.map(d => d.left))));
        setStock2EndVal(percentAccessor(lastRightDataPoint, prevCloseAccessor(rightStock, combinedData.map(d => d.right))));
    }

    const slopeIntercept = (p1, p2, timeStep) => {
        const x1 = timeAccessor(p1);
        const y1 = p1.c;
        const x2 = timeAccessor(p2);
        const y2 = p2.c;
        const m = (y2 - y1) / ((x2 - x1) / timeStep);
        return m;
    }

    // flag indicating if timestamp is within market hours (9:30am EST - 4:00pm EST)
    const isMarketHour = (lux, timespan) => {
        // don't check day if buckets are larger than days
        const weekend = timespan !== 'week' && (lux.weekday === 6 || lux.weekday === 7);
        const holiday = timespan !== 'week' && (federalHolidays.includes(lux.toFormat("yyyy-MM-dd")));
        // don't check hour/minute if buckets are larger than hours
        const dayOnly = timespan === 'week' || timespan === 'day'
        const preMarket = !dayOnly && (lux.hour < 9 || (lux.hour === 9 && lux.minute < 30));
        const postMarket = !dayOnly && (lux.hour > 16 || (lux.hour === 16 && lux.minute > 0));
        return !(weekend || holiday || preMarket || postMarket);
    }

    const preprocessData = (stockData, span) => {
        const marketHoursOnly = span !== '1d';
        const {start, timespan, multiplier} = getHistoricDataArgs(span);
        const timeStep = multiplier * 60000 * (timespan === 'minute' ? 1 : timespan === 'hour' ? 60 : timespan === 'day' ? 1440 : timespan === 'week' ? 10080 : 1);
        const end = DateTime.now();
        let processedData = [];
        let i = 0;  // stock data index
        let j = start.setZone("America/New_York").set({hour: 4, minute: 0, second: 0,}).ts    // time step index
        let flag = true;


        // expected timestamp and stock data timestamps will increment by the same amount
        // so we can check for missing data by simple equality comparison
        while (j < end.ts && i < stockData.length) {
            const lux = DateTime.fromMillis(j).setZone("America/New_York")
            if (marketHoursOnly && !isMarketHour(lux, timespan)) {
                j += timeStep;
                continue;
            }

            const d = stockData[i];
            const t = DateTime.fromMillis(timeAccessor(d));

            // skip data that is too early
            if (t < j) {
                i++;
            } else {
                // initially set timestamps equal to facilitate difference checking going forward
                if (flag) {
                    j = t.ts;
                    flag = false;
                }

                // stock data is missing data point at expected timestamp, fill
                if (j < t) {
                    const p1 = i !== 0 ? stockData[i - 1] : d;
                    const m = slopeIntercept(p1, d, timeStep);
                    const y = m * ((j - p1.t) / timeStep) + p1.c;
                    processedData.push({
                        c: y,
                        t: j,
                    })
                    j += timeStep;
                    // stock data present
                } else {
                    processedData.push({
                        c: d.c,
                        t: j,
                    })
                    i++;
                }
            }
        }

        // fill data at end
        while (j < end.ts) {
            processedData.push({
                c: stockData?.[stockData.length - 1]?.c || 0,
                t: j,
            })
            j += timeStep;
        }

        // retain date information while stitching together disjoint dates
        if (marketHoursOnly) {
            processedData = processedData.map((d, i) => {
                return {c: d.c, t: i, label: d.t}
            })
        } else {
            processedData = processedData.map((d, i) => {
                return {c: d.c, t: d.t, label: d.t}
            })
        }

        var ii = 0;
        const processedDataDupsRemoved = [];
        while (ii < processedData.length - 1) {
            if (processedData[ii].t === processedData[ii+1].t)
                ii++;
            processedDataDupsRemoved.push(processedData[ii]);
            ii++;
        }

        return processedDataDupsRemoved;
    }

    const getCombinedData = (leftStockData, rightStockData, span) => {
        const leftStockDataProcessed = preprocessData(leftStockData, span);
        const rightStockDataProcessed = preprocessData(rightStockData, span);
        const combinedData = leftStockDataProcessed.map((e, i) => {
            return {
                t: leftStockDataProcessed[i].t,
                label: leftStockDataProcessed[i].label,
                left: leftStockDataProcessed[i],
                right: {
                    ...rightStockDataProcessed[i < rightStockDataProcessed.length ? i : rightStockDataProcessed.length - 1],
                    t: leftStockDataProcessed[i].t
                },
            }
        });
        setCombinedData(combinedData);
        return combinedData;
    }

    // scales
    const getXScale = (combinedData, span) => {
        // const xScale = scaleTime({
        const xScale = scaleLinear({
            domain: span !== '1d' ?
                [combinedData[0]?.t || 0, combinedData[combinedData.length - 1]?.t || 9999999999]
                :
                [combinedData[0]?.t || 0, fixedWindowEndTime(combinedData)],
            range: [xMin, xMax]
        })
        setXScale(() => xScale);
    }
    const getYScale = (combinedData) => {
        const yScale = scaleLinear({
            domain: [
                Math.min(...combinedData.map(d => percentAccessor(d.left, prevCloseAccessor(leftStock, combinedData.map(d => d.left)))), ...combinedData.map(d => percentAccessor(d.right, prevCloseAccessor(rightStock, combinedData.map(d => d.right))))),
                Math.max(...combinedData.map(d => percentAccessor(d.left, prevCloseAccessor(leftStock, combinedData.map(d => d.left)))), ...combinedData.map(d => percentAccessor(d.right, prevCloseAccessor(rightStock, combinedData.map(d => d.right))))),
            ],
            range: [yMax, yMin],
            nice: true
        })
        setYScale(() => yScale);
    }

    // handle mouse enter
    const handleTooltip = ({event, combinedData, xScale, yScale}, isTouchEvent = false) => {
        if (combinedData.length == 0) return;
        const point = localPoint(event) || {x: 0};
        const x = isTouchEvent ? point.x - event.target.getBoundingClientRect().left : point.x;
        const x0 = xScale.invert(x);
        const index = bisectDate(combinedData, x0, 1, combinedData.length - 1);
        const d0 = combinedData[index - 1];
        const d1 = combinedData[index];
        let d = d0;
        if (d1 && getDate(d1).isValid) {
            d = x0 - getDate(d0).valueOf() > getDate(d1).valueOf() - x0 ? d1 : d0;
        }
        setStock1Override(parseFloat(percentAccessor(d.left, prevCloseAccessor(leftStock, combinedData.map(d => d.left))).padStart(4, '0')))
        setStock2Override(parseFloat(percentAccessor(d.right, prevCloseAccessor(rightStock, combinedData.map(d => d.right))).padStart(4, '0')))
        setStock1HoverPrice(parseFloat(d.left.c))
        setStock2HoverPrice(parseFloat(d.right.c))
        showTooltip({
            tooltipData: d,
            tooltipLeft: xScale(timeAccessor(d)),
            tooltipTop: {
                left: yScale(percentAccessor(d.left, prevCloseAccessor(leftStock, combinedData.map(d => d.left))).padStart(4, '0')),
                right: yScale(percentAccessor(d.right, prevCloseAccessor(rightStock, combinedData.map(d => d.right))).padStart(4, '0'))
            }
        });
    }

    const handleTouchStart = () => {
        const handleTouchMove = (e) => {
            e.preventDefault(); // Prevent scrolling
        };

        // Add the touchmove listener to the document when touch starts
        document.addEventListener('touchmove', handleTouchMove, {passive: false});

        // Clean up the touchmove listener when the touch ends
        const endTouch = () => {
            document.removeEventListener('touchmove', handleTouchMove);
            // Also remove these cleanup listeners to ensure they don't pile up
            document.removeEventListener('touchend', endTouch);
            document.removeEventListener('touchcancel', endTouch);
        };

        // Add listeners to clean up after the touch ends
        document.addEventListener('touchend', endTouch);
        document.addEventListener('touchcancel', endTouch);
    };

    const strokeWidth = 2.5;
    return (
        <div style={{display: 'block'}} className='max-w-[90vw] flex flex-col'>
            <div className={'flex flex-row items-center justify-between mb-4'}>
                <div className='font-body'>
                    <p className='font-semibold text-sm md:text-lg'>
                        Live Score
                    </p>
                </div>
                <div className='flex flex-col min-w-24 md:w-36 text-sm md:text-lg'>
                    <p style={{color: leftStock?.color}}
                       className={`inline-flex ${stock1Override && (stock1Override > stock2Override ? 'opacity-100' : 'opacity-50')}`}>
                        <span className='text-white text-right mr-2'>{leftStock?.ticker}</span>
                        <span className='text-right'>{stock1Override || stock1EndVal}%</span>
                        {(stock1Override || stock1EndVal) >= 0 ? <SvgStockUp className={'-mt-0.5 md:mt-0'}/> :
                            <SvgStockDown className={'-mt-0.5 md:mt-0'}/>}
                    </p>
                    <p style={{color: rightStock?.color}}
                       className={`inline-flex ${stock1Override && (stock2Override > stock1Override ? 'opacity-100' : 'opacity-50')}`}>
                        <span className='text-white text-right mr-2'>{rightStock?.ticker}</span>
                        {/*<span*/}
                        {/*    className='text-right text-gray-500'>{`($${(stock2HoverPrice || combinedData?.[combinedData.length - 1]?.right.c)?.toFixed(2) || ""})`}</span>*/}
                        <span className='text-right'>{stock2Override || stock2EndVal}%</span>
                        {(stock2Override || stock2EndVal) >= 0 ? <SvgStockUp className={'-mt-0.5 md:mt-0'}/> :
                            <SvgStockDown className={'-mt-0.5 md:mt-0'}/>}
                    </p>
                </div>
            </div>
            {(loading) ?
                <div style={{
                    height: height,
                    width: width,
                    backgroundColor: 'transparent',
                    borderRadius: 14,
                    display: 'flex',
                    alignItems: 'center'
                }}><Spinner/></div>
                :
                <svg width={width} height={height}>
                    <rect x={0} y={0} width='100%' height='100%' fill={'transparent'} rx={14}/>
                    <Group>
                        {/* <AxisBottom top={yMax - 20} scale={xScale} numTicks={12} stroke='#b3b3b3' tickStroke='#b3b3b3' tickLabelProps={{fill: '#b3b3b3'}}/> */}
                        {/* <AxisLeft left={xMin - 10} scale={yScale} numTicks={6} stroke='#b3b3b3' tickStroke='#b3b3b3' tickLabelProps={{fill: '#b3b3b3'}}/> */}
                        <LinePath
                            data={combinedData.map(d => d.left)}
                            x={(d, i) => xScale(d?.t)}
                            y={(d, i) => yScale(0)}
                            stroke={'#ffffff30'}
                            style={{zIndex: 0}}
                            strokeWidth={1}
                            curve={curveMonotoneX}
                            strokeDasharray={16}
                        />
                        <LinePath
                            data={combinedData.map(d => d.left)}
                            x={(d, i) => xScale(d?.t)}
                            y={(d, i) => yScale(percentAccessor(d, prevCloseAccessor(leftStock, combinedData.map(d => d.left))))}
                            stroke={leftStock?.color}
                            strokeWidth={strokeWidth}
                            curve={curveMonotoneX}
                            opacity={stock1Override ? ((stock1Override > stock2Override) ? 1 : .5) : 1}
                        />

                        <LinePath
                            data={combinedData.map(d => d.right)}
                            x={(d, i) => xScale(d?.t)}
                            y={(d, i) => yScale(percentAccessor(d, prevCloseAccessor(rightStock, combinedData.map(d => d.right))))}
                            stroke={rightStock?.color}
                            strokeWidth={strokeWidth}
                            curve={curveMonotoneX}
                            opacity={stock1Override ? ((stock2Override > stock1Override) ? 1 : .5) : 1}
                        />
                        {leftStockData.length > 0 && (
                            <Circle
                                cx={xScale(timeAccessor(leftStockData[leftStockData.length - 1]))}
                                cy={yScale(percentAccessor(leftStockData[leftStockData.length - 1], prevCloseAccessor(leftStock, leftStockData)))}
                                r={4} // Radius of the dot
                                fill={leftStock?.color}
                            />
                        )}

                        {rightStockData.length > 0 && (
                            <Circle
                                cx={xScale(timeAccessor(rightStockData[rightStockData.length - 1]))}
                                cy={yScale(percentAccessor(rightStockData[rightStockData.length - 1], prevCloseAccessor(rightStock, rightStockData)))}
                                r={4} // Radius of the dot
                                fill={rightStock?.color}
                            />
                        )}
                        {/*START GRAPH LOGOS*/}
                        <rect
                            x={width - 18}
                            y={yScale(stock1EndVal) + 10}
                            width={16}
                            height={16}
                            fill={leftStock?.color}
                            strokeWidth={3}
                            stroke={leftStock?.color}
                            style={{pointerEvents: "none"}}
                        />
                        <rect
                            x={width - 18}
                            y={yScale(stock2EndVal) + 10}
                            width={16}
                            height={16}
                            fill={rightStock?.color}
                            strokeWidth={3}
                            stroke={rightStock?.color}
                            style={{pointerEvents: "none"}}
                        />
                        <image
                            href={`${process.env.PUBLIC_URL}/logos/${leftStock.ticker}.png`}
                            x={width - 18}
                            y={yScale(stock1EndVal) + 10}
                            width={16}
                            height={16}
                            clipPath="url(#clipPathLeftStock)"
                        />
                        <image
                            href={`${process.env.PUBLIC_URL}/logos/${rightStock.ticker}.png`}
                            x={width - 18}
                            y={yScale(stock2EndVal) + 10}
                            width={16}
                            height={16}
                            clipPath="url(#clipPathRightStock)"
                        />
                        {/*END GRAPH LOGOS*/}

                        <Threshold
                            id={`${Math.random()}`}
                            data={combinedData}
                            x={(d, i) => xScale(d?.t)}
                            y0={(d, i) => yScale(percentAccessor(d.left, prevCloseAccessor(leftStock, combinedData.map(d => d.left))))}
                            y1={(d, i) => yScale(percentAccessor(d.right, prevCloseAccessor(rightStock, combinedData.map(d => d.right))))}
                            clipAboveTo={0}
                            clipBelowTo={yMax}
                            belowAreaProps={{
                                fill: rightStock?.color,
                                fillOpacity: 0,
                            }}
                            aboveAreaProps={{
                                fill: leftStock?.color,
                                fillOpacity: 0,
                            }}
                        />
                    </Group>
                    <Bar
                        x={xMin}
                        y={yMin}
                        width='100%'
                        height='100%'
                        fill="transparent"
                        rx={14}
                        data={combinedData}
                        onTouchStart={(event) => {
                            wait(500).then(() => {
                                handleTouchStart(); // Lock scrolling by setting up global touchmove handler
                                // Additional logic for your component's touch handling
                                handleTooltip({
                                    event,
                                    combinedData,
                                    xScale,
                                    yScale,
                                }, true);
                            }) // true indicates this is a touch event
                        }}
                        onTouchMove={(event) => {
                            // Your existing touch move logic
                            handleTooltip({
                                event,
                                combinedData,
                                xScale,
                                yScale,
                            }, true)
                        }}
                        onTouchEnd={(event) => {
                            hideTooltip();
                        }}
                        // No need to change onTouchEnd or onTouchCancel as cleanup is handled globally
                        onMouseMove={(event) => {
                            handleTooltip({
                                event,
                                combinedData,
                                xScale,
                                yScale,
                            });
                            onMouseMove();
                        }}
                        onMouseLeave={(event) => {
                            hideTooltip();
                            onMouseLeave();
                            setStock1Override(null)
                            setStock2Override(null)
                            setStock1HoverPrice(null)
                            setStock2HoverPrice(null)
                        }}
                    />

                    {tooltipData && (
                        <g>
                            <Line
                                from={{x: tooltipLeft, y: 0}}
                                to={{x: tooltipLeft, y: yMax + 5}}
                                stroke={'#ffffff20'}
                                strokeWidth={1}
                                style={{pointerEvents: "none"}}
                            />
                            <circle
                                cx={tooltipLeft}
                                cy={tooltipTop.left}
                                r={4}
                                fill={leftStock?.color}
                                strokeWidth={2}
                                stroke={baseBlack70}
                                style={{pointerEvents: "none"}}
                            />
                            <circle
                                cx={tooltipLeft}
                                cy={tooltipTop.right}
                                r={4}
                                fill={rightStock?.color}
                                strokeWidth={2}
                                stroke={baseBlack70}
                                style={{pointerEvents: "none"}}
                            />
                        </g>
                    )}
                </svg>
            }
            {!!tooltipLeft && tooltipLeft > 0 ?
                <Tooltip
                    left={tooltipLeft > (width * .9) ? (width * .9) : tooltipLeft < (width * .1) ? width * .1 : tooltipLeft}
                    style={{
                        ...defaultStyles,
                        textAlign: 'center',
                        transform: 'translateX(-65%)',
                        backgroundColor: 'black',
                        font: ''
                    }}
                    className='font-body border p-0 border-white/20 text-xs md:text-xs whitespace-nowrap'
                >
                    {formatDate(getDate(tooltipData))}
                </Tooltip>
                : null
            }
            {!!tooltipLeft && tooltipLeft > 0 ?
                <Tooltip
                    left={tooltipLeft > (width * .9) ? (width * .9) : tooltipLeft < (width * .1) ? width * .1 : tooltipLeft}
                    style={{
                        ...defaultStyles,
                        textAlign: 'center',
                        transform: 'translateX(-65%)',
                        backgroundColor: '#000000A0',
                        font: '',
                        flexDirection: tooltipTop.right < tooltipTop.left ? 'column-reverse' : 'column',
                        top: tooltipTop.right < tooltipTop.left ? tooltipTop.right : tooltipTop.left,
                    }}
                    className='font-body flex flex-col border p-0 backdrop-blur-xl border-white/20 text-xs md:text-xs whitespace-nowrap'
                >
                    <p
                        className='text-center'
                        style={{color: leftStock.color}}
                    >{leftStock.ticker} {`$${(stock1HoverPrice || combinedData?.[combinedData.length - 1]?.left.c)?.toFixed(2) || ""}`}</p>
                    <p
                        className='text-center'
                        style={{color: rightStock.color}}
                    >{rightStock.ticker} {`$${(stock2HoverPrice || combinedData?.[combinedData.length - 1]?.right.c)?.toFixed(2) || ""}`}</p>
                </Tooltip>
                : null
            }
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(withTooltip(withParentSize(GamedayGraph)));

// configure chart data
export const getHistoricDataArgs = (span) => {
    const today = DateTime.now();
    switch (span) {
        case '1d':
            return {
                start: today.set({hour: 4, minute: 0, second: 0}),
                multiplier: 15,
                timespan: 'minute',
                dateFormat: 'hh:mm a'
            };
        case '1w':
            return {
                start: today.minus({weeks: 1}),
                multiplier: 1,
                timespan: 'hour',
                dateFormat: 'ccc h a'
            };
        case '1m':
            return {
                start: today.minus({months: 1}),
                multiplier: 1,
                timespan: 'hour',
                dateFormat: 'ccc MM/dd'
            };
        case '3m':
            return {
                start: today.minus({months: 3}),
                multiplier: 1,
                timespan: 'day',
                dateFormat: 'MM/dd'
            };
        case '1y':
            return {
                start: today.minus({years: 1}),
                multiplier: 1,
                timespan: 'day',
                dateFormat: 'MM/dd'
            };
        default:
            return {start: today.minus({weeks: 1}), multiplier: 180, timespan: 'minute', dateFormat: 'ccc hh a'};
    }
}

