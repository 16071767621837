import React, {useEffect, useRef, useState} from 'react';
import {Button} from "../components/atoms/Button";
import {Link} from "react-router-dom";
import {sectionClass} from "../consts/dummy";
import Lottie from "react-lottie";
import animationData from '../img/lottie/HowToPlayGraph-[copy].json'
import phones from '../img/phones.png'

function HowToPlaySection(props) {
    const onHome = props.onHome
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const parentRef = useRef();
    const [lottieHeight, setLottieHeight] = useState(0);

    const aspectRatio = 1.1;

    useEffect(() => {
        const updateSize = () => {
            if (parentRef.current) {
                const parentWidth = parentRef.current.offsetWidth;
                setLottieHeight(parentWidth / aspectRatio);
            }
        };

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return (
        <div className={`${sectionClass}`}>
            <div className='flex flex-col gap-3 '>
                <p className='text-4xl'>
                    {onHome ? 'HOW TO PLAY' : 'GAME RULES:'}
                </p>
                <p className={'font-body text-lg font-medium'}>
                    Every day, <span className='text-yellow-400 font-semibold'>matches of stocks are pitted against each other 1 vs 1</span> and
                    the winner is based on the more positive %
                    move over the day.
                </p>
                <div className='flex flex-col items-center'>
                    <div className='py-5 w-full max-w-[440px] ' ref={parentRef}>
                        <Lottie options={defaultOptions} height={lottieHeight} width={"100%"}/>
                    </div>
                </div>
                {onHome ?
                    <p className={'font-body text-lg font-medium'}>
                        <span className='text-yellow-400 font-semibold'>Points are earned for every win</span> and you
                        earn
                        more points for every correct prediction as the tournament progresses.
                    </p>
                    :
                    <p className={'font-body text-lg font-medium'}>
                        The bracket starts with 64 tickers, voted on by you. You’ll have a full week to make your picks,
                        then all brackets are locked and <span
                        className='text-yellow-400 font-semibold'>madness ensues</span>. Six rounds, only one stock can
                        win it all.
                        Think you have what it takes?
                    </p>
                }
            </div>
            <div className='pt-5 flex flex-col items-center w-full'>
                <img alt={'phones'} src={phones} className='w-full max-w-[440px] object-contain'/>
            </div>
            {onHome ?
                <Link to={'/how-to-play'} className='w-full'>
                    <Button className='w-full'>
                        Full Rulebook
                    </Button>
                </Link>
                :
                <></>
            }
        </div>
    );
}

export default HowToPlaySection;
