import React, {useState, useEffect} from 'react';
import MatchupBody from "../matchupdetails/MatchupBody";
import SvgClose from "../icons/SvgClose";
import TooltipComponent from "../atoms/TooltipComponent";
import {getMatchupStaticData, getMatchupLiveData} from '../../api/matchups';

function MatchPreviewModal(props) {
    const predictedWinner = null

    const gradientColor1 = props.preloadedMatchup?.[props.preloadedMatchup?.ticker1]?.color
    const gradientColor2 = props.preloadedMatchup?.[props.preloadedMatchup?.ticker2]?.color

    return (
        <div
            className={`fixed bg-black top-0 z-50 items-center flex flex-col duration-300 ${props.isOpen ? 'h-[100dvh] overflow-y-scroll border-b-4  md:border-b-8 border-neutral-900' : 'h-0 overflow-hidden'} w-full`}
        >
            <div className='relative w-full flex flex-col items-center mt-12 px-4'>
                <div className='absolute top-0 left-0 right-0 bottom-0 z-[-1]'
                     style={{background: `linear-gradient(13deg, ${gradientColor1 + '25'}, ${gradientColor2 + '25'}, black)`}}>

                </div>
                <div onClick={props.close}
                     className='flex flex-col mb-2 w-full items-end pt-3 opacity-80 hover:opacity-100 cursor-pointer'>
                    <SvgClose className={'w-8 h-8'}/>
                </div>
                <div className='max-w-[600px] flex flex-col gap-8 pb-20'>
                    <MatchupBody
                        gameNumber={props?.gameNumber}
                        viewOnly={props.viewOnly}
                        isPreview={true}
                        preloadedMatchup={props.preloadedMatchup}
                    />
                </div>
            </div>
        </div>
    );
}

export default MatchPreviewModal;
