import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Marquee from "react-fast-marquee";
import BracketLiveComponent from "../components/bracketlive/BracketLiveComponent";
import {dummyBracket} from "../components/utils/dummy";
import BracketLiveHomeOverlay from "../components/bracketlive/BracketLiveHomeOverlay";
import AlertBanner from "../components/atoms/AlertBanner";


const mapStateToProps = (state) => {
    return {
        authUser: state.userReducer.authUser,
        ogImage: state.userReducer.ogImage,
        userBracket: state.userReducer.userBracket,
        mobile: state.userReducer.mobile,
        readonlyMasterBracket: state.userReducer.readonlyMasterBracket,
    }
}

function LiveHomePage(props) {

    const isAuthenticated = !!props.authUser
    const hasSubmitBracket = !!props.userBracket?.submitted
    return (
        <div className={`flex flex-col items-center pb-0 px-0 w-[100vw] min-h-[100dvh]`}>
            <div className='w-full -mt-8'>
                <div className='flex flex-col items-center justify-center pt-4 px-4'>
                    <p className='text-xl md:text-2xl uppercase'>
                        Stonk Madness Bracket
                    </p>
                    <p className='text-body opacity-50 uppercase'>
                        March 19 - April 5
                    </p>
                    {/*todo: banner shown leading up to and during tourney, hide when over*/}
                    {/*<div className='md:w-[600px] mt-3'>*/}
                    {/*    <AlertBanner hasBracket={false} centered/>*/}
                    {/*</div>*/}
                    <div className='flex flex-row items-center text-sm md:text-lg mt-2 gap-3 md:gap-5'>
                        <Link to={'/about'}>
                            <p className='text-body text-yellow-400 hover:underline uppercase'>
                                About
                            </p>
                        </Link>
                        <Link to={'/how-to-play'}>
                            <p className='text-body text-yellow-400 hover:underline uppercase'>
                                How To Play
                            </p>
                        </Link>
                        <Link to={'/prizes'}>
                            <p className='text-body text-yellow-400 hover:underline uppercase'>
                                Prizes
                            </p>
                        </Link>
                        <Link to={'/schedule'}>
                            <p className='text-body text-yellow-400 hover:underline uppercase'>
                                Schedule
                            </p>
                        </Link>
                    </div>
                </div>
                <BracketLiveComponent
                    liveBracket={props.readonlyMasterBracket}
                    loadingAuthUser={props.loadingAuthUser}
                />
                {/*<BracketLiveHomeOverlay*/}
                {/*    isAuthenticated={isAuthenticated}*/}
                {/*    hasSubmitBracket={hasSubmitBracket}*/}
                {/*    mobile={props.mobile}*/}
                {/*/>*/}
            </div>
        </div>
    );
}

export default connect(mapStateToProps)(LiveHomePage);
