const initialState = {
    user: {},
    authUser: {},
    mobile: true,
    waitlistModalOpen: false,
    userBracket: {},
    chartData: {},
    afterhourUsers: {},
    leaderboard: {},
    globalLoading: false,
    readonlyMasterBracket: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_USER":
            return {
                ...state,
                user: action.user
            }
        case "SET_MOBILE":
            return {
                ...state,
                mobile: action.mobile
            }
        case "TOGGLE_WAITLIST_MODAL":
            return {
                ...state,
                waitlistModalOpen: !state.waitlistModalOpen
            }
        case "SET_AUTH_USER":
            return {
                ...state,
                authUser: action.authUser
            }
        case "CLEAR_USER":
            return {...initialState}
        case "SET_USER_BRACKET":
            return {
                ...state,
                userBracket: action.userBracket,
            }
        case "SET_MASTER_BRACKET":
            return {
                ...state,
                masterBracket: action.masterBracket,
            }
        case "UPDATE_CHART_DATA":
            return {
                ...state,
                chartData: {...state.chartData, ...action.chartData}
            }
        case "SET_CHART_DATA":
            return {
                ...state,
                chartData: action.chartData
            }
        case "UPDATE_AFTERHOUR_USER":
            return {
                ...state,
                afterhourUsers: {...state.afterhourUsers, ...{[action.afterhourUser.username]: action.afterhourUser}}
            }
        case "SET_AFTERHOUR_USERS":
            return {
                ...state,
                afterhourUsers: action.afterhourUsers
            }
        case "SET_LEADERBOARD":
            return {
                ...state,
                leaderboard: action.leaderboard
            }
        case "UPDATE_LEADERBOARD":
            const position = state.leaderboard?.top?.map((e, i) => {return {username: e.username, p: i}})?.[0]?.p;
            const newTop = [...(state.leaderboard?.top?.slice(0, position) || []), {...state.leaderboard?.top?.[position], winner: action.winner}, ...(state.leaderboard?.top?.slice(position+1) || [])];
            return {
                ...state,
                leaderboard: {...state.leaderboard, top: newTop}
            }
        case "SET_READONLY_MASTER_BRACKET":
            return {
                ...state,
                readonlyMasterBracket: action.masterBracket,
            }
        default:
            return state;
    }
};
