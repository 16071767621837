import React from 'react';
import BracketLiveGameCard from "./BracketLiveGameCard";
import BracketBGCharactersA from "../bracket/BracketBGCharactersA";

function BracketLiveEightBatch(props) {
    return (
        <div className={`flex ${props.right ? 'flex-row-reverse' : 'flex-row'} gap-4 items-stretch relative`}>
            <div className='flex flex-col gap-4 relative'>
                <BracketLiveGameCard right={props.right}
                                     tbd gameNumber={props.start}
                                     liveBracket={props.liveBracket}
                                     openMatchPreviewModal={props.openMatchPreviewModal}
                                     userMatchups={props.userMatchups}
                                     viewOnly={props.viewOnly} loading={props.loading}
                                     setFocusedGameNumber={props.setFocusedGameNumber}
                                     focusedGameNumber={props.focusedGameNumber}
                />
                <BracketLiveGameCard right={props.right} tbd gameNumber={props.start + 1}
                                     liveBracket={props.liveBracket}
                                     openMatchPreviewModal={props.openMatchPreviewModal}
                                     userMatchups={props.userMatchups}
                                     viewOnly={props.viewOnly} loading={props.loading}
                                     setFocusedGameNumber={props.setFocusedGameNumber}
                                     focusedGameNumber={props.focusedGameNumber}
                />
                <BracketLiveGameCard right={props.right} tbd gameNumber={props.start + 2}
                                     liveBracket={props.liveBracket}
                                     openMatchPreviewModal={props.openMatchPreviewModal}
                                     userMatchups={props.userMatchups}
                                     viewOnly={props.viewOnly} loading={props.loading}
                                     setFocusedGameNumber={props.setFocusedGameNumber}
                                     focusedGameNumber={props.focusedGameNumber}
                />
                <BracketLiveGameCard right={props.right} tbd gameNumber={props.start + 3}
                                     liveBracket={props.liveBracket}
                                     openMatchPreviewModal={props.openMatchPreviewModal}
                                     userMatchups={props.userMatchups}
                                     viewOnly={props.viewOnly} loading={props.loading}
                                     setFocusedGameNumber={props.setFocusedGameNumber}
                                     focusedGameNumber={props.focusedGameNumber}
                />
            </div>
            <div className='flex flex-col justify-around gap-4 relative'>
                <BracketLiveGameCard right={props.right} tbd gameNumber={((props.start / 4) * 2) + 32}
                                     liveBracket={props.liveBracket}
                                     openMatchPreviewModal={props.openMatchPreviewModal}
                                     userMatchups={props.userMatchups}
                                     viewOnly={props.viewOnly} loading={props.loading}
                                     setFocusedGameNumber={props.setFocusedGameNumber}
                                     focusedGameNumber={props.focusedGameNumber}
                />
                <BracketLiveGameCard right={props.right} tbd gameNumber={((props.start / 4) * 2) + 33}
                                     liveBracket={props.liveBracket}
                                     openMatchPreviewModal={props.openMatchPreviewModal}
                                     userMatchups={props.userMatchups}
                                     viewOnly={props.viewOnly} loading={props.loading}
                                     setFocusedGameNumber={props.setFocusedGameNumber}
                                     focusedGameNumber={props.focusedGameNumber}
                />
            </div>
            <div className={`flex flex-col justify-around gap-4 relative ${props.right ? '-mr-8' : '-ml-8'}`}>
                <BracketBGCharactersA start={props.start}/>
                <BracketLiveGameCard right={props.right}
                                     tbd
                                     gameNumber={(props.start / 4) + 48}
                                     liveBracket={props.liveBracket}
                                     openMatchPreviewModal={props.openMatchPreviewModal}
                                     viewOnly={props.viewOnly}
                                     userMatchups={props.userMatchups}
                                     loading={props.loading}
                                     setFocusedGameNumber={props.setFocusedGameNumber}
                                     focusedGameNumber={props.focusedGameNumber}
                />
            </div>
        </div>
    );
}

export default BracketLiveEightBatch;
