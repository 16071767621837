export const GRAPH_COLORS = [
    "red",
    "green",
    "blue",
    "",
    "",
    "",
]

export const STUEY_PICKS = [
    "{loser} is about to crash harder than a crypto bro's ego after a bear market. Get ready for the free fall",
    "The odds of {loser} succeeding are like finding a rational argument on Twitter.",
    "Imagine thinking {loser} will rise. That's like expecting a financial advisor at Burning Man. Delusional at best.",
    "You have a better chance of explaining blockchain to your grandma than {loser} has of making a comeback.",
    "{loser} is about to drop harder than a finance grad's dreams when they see their first student loan bill.",
    "Watching {loser} trying to climb is like seeing a penny stock promoter at a finance conference. Embarrassing and doomed.",
    "Relying on {loser} for gains? Might as well ask a magic 8-ball for stock tips.",
    "Betting on {loser} is like playing poker blindfolded. You're in for a surprise, and it won't be pleasant.",
    "{loser}'s performance might be sadder than a forgotten birthday.",
    "{loser} is on track to be a bigger letdown than a finance intern's first paycheck.",
    "The only thing dropping faster than {loser} is the dignity of someone drunk texting their ex at 2 AM.",
    "{winner} reminds me of my ex-girlfriend - take that however you will",
    "I just like {winner}, I dont know why",
    "{winner} seems stupid enough to bet on, so I'm in.",
    "Expect {loser} to crash like a Zoom call with bad Wi-Fi. Disconnected, frustrating, and a total mess",
    "{loser} thinks it's 2008 still, good luck.",
    "Holding {loser} is like believing in Santa as an adult. It's time to face the harsh reality.",
    "Betting on {loser} is like betting on a sunken ship to win a race. It's already at the bottom.",
    "{loser} is unraveling faster than my plans after I say 'just one more drink.'",
    "{loser} is about to be worth as much as my ex's promises. Empty and valueless.",
    "Holding onto {loser} is like refusing to leave the Titanic. The ship's sinking, and you're going down with it.",
    "Investing in {loser} is like entering a haunted house. Terrifying, full of regret, and no easy way out.",
    "{loser} is like a soap opera plot. Dramatic, unbelievable, and always ending in tragedy.",
    "{loser} is about to collapse like a sprinter who thought the race was shorter.",
    "Like they say... {loser} can keep down, longer than you can stay solvent.",
    'Evidently {winner} thought "Confirmation Bias" meant "Malicious Compliance"',
    "Let's all just have a moment of silence for {loser}",
    "I have no clue. Just pick a stock so I can go home.",
    "{winner} went for a hail marry... where {loser} seems to have had one too many bloody marrys...",
    "{loser} is going to crumble worse than the Falcons up 28-3",
    "{loser} will go from Great Value, To Great Financial Crisis in record time.",
    "You can slap lipstick on a pig, and make {winner} the belle of the ball evidently",
    "{loser} is set to be the underdog who didn't even show up to the race.",
    "Investing in {loser} is like backing a cyclist with two flat tires. Going nowhere fast.",
    "{loser} is about to crash like a finance bro's ego when he can't flex his 'portfolio gains' at the bar.",
    "Investing in {loser} is like taking stock tips from a finance bro who still lives with his parents.",
    "The value of {loser} could decline faster than public trust in politicians.",
    "Expect {loser} to crash worse than a government website on launch day.",
    "Pick {winner} if you just got laid off and need some good news.",
    "Impossible that {winner} beats {loser} but it probably will.",
    "The stock market hates you, just go with {winner}",
    "Probably bet the farm on {winner}",
    "Student loan debt? {winner} can help",
    "If {winner} says AI enough times it'll beat {loser}",
    "Just wait until WSB hears about {winner}",
    "{winner}'s not f***ing leaving",
    "{loser}'s gonna blow it worse than the Cowboys do in the playoffs.",
    "{loser}'s gonna get slapped harder than Chris Rock",
    "{loser}'s gonna bomb harder than any Amy Schumer movie.",
    "{loser} might take an arrow to the knee",
    "{loser} ran out of PEDs they're cooked now",
    "{winner} is juicing harder than the Russian olympic team.",
    "{loser}, it's a no from me dawg. - Randy Jackson",
    "I see the value in {winner}, and for that reason I'm out - Mark Cuban",
    "I'd swipe right on {winner}",
    "I'd swipe left on {loser}",
    "I'm a cartoon. Why are you possibly listening to me.",
    "Cathie Woods is probably long {loser}, so no way I'm choosing that.",
    "I'd rather go to an Island Boys concert than choose {loser}",
    "{winner}. You miss 100% of the shots you don't take - Wayne Gretzky - Michael Scott - Stuey",
    "{winner}'s even more of a sure thing than Joey Chestnut at a hot dog eating contest.",
    "Choosing {loser} is like showing up to a gun fight with a #2 pencil.",
    "I'm only really here because AI makes everything seem cool",
    "Heads it's {winner}, Tails it's {loser}",
    "It doesn't really matter just go with {winner}",
    "Obviously {winner} is better",
    "Obviously {loser} sucks",
    "I don't trust people who pick {winner}",
    "I'm all in on {winner}",
    "I wouldn't touch {loser} with a 10 foot tendie",
    "You aren't winning this. Just choose {winner}.",
    "{winner} is going to wake up and choose violence.",
]
.map(value => ({value, sort: Math.random()}))
.sort((a, b) => a.sort - b.sort)    
.map(({ value }) => value)

export const USE_LIVE_VIEW = true
