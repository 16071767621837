import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            width="64px"
            height="64px"
            {...props}
        >
            <path fill={'currentColor'} d="M32 50L2 20h60z"/>
        </svg>
    )
}

export default SvgComponent
