import React, {useEffect, useState} from 'react';
import {first_place_prizes, prizes, sectionClass} from "../consts/dummy";
import Marquee from "react-fast-marquee";
import users from '../img/users.png'
import {Link} from "react-router-dom";
import {Button} from "../components/atoms/Button";
import diamond from "../img/stickers/SM_DIAMOND HANDS.png";
import SvgLockOpen from "../components/icons/SvgLockOpen";

function PrizesSection(props) {
    const [rocketPercent, setRocketPercent] = useState("5%");
    const formatter = Intl.NumberFormat('en', {notation: 'compact'});

    useEffect(() => {
        const percent = Math.max(5, Math.min(95, props.waitlistCount / 1000000 * 100));
        const percentString = `${Math.round(percent)}%`
        setRocketPercent(percentString)
    }, [props.waitlistCount])

    return (
        <div className={`${sectionClass} ${props.onHome && '-mt-12'}`}>
            {!props.onHome &&
                <p className='text-4xl'>
                    PRIZE BREAKDOWN:
                </p>
            }
            <p className={`font-body text-lg font-medium relative ${props.onHome && 'text-center'}`}>
                {props.onHome &&
                    '64 stocks. 6 rounds of action. Join thousands of players in a battle for the best brackets.'
                }
                The <span
                className='text-yellow-400 font-semibold'>top 20 brackets</span> walk away with a prize and all the
                bragging rights.
            </p>
            {props.onHome &&
                <div className='flex flex-row items-center justify-center w-full gap-2 relative'>
                    <img src={users} alt={'users'} className='h-10'/>
                    <p className='text-md font-semibold font-body mr-8'>
                        Join {formatter.format(props.waitlistCount)}+ other players and counting.
                    </p>
                    <img src={diamond} alt={'flame'} className='absolute h-12 md:h-16 right-0 -top-12 md:-top-4'/>
                </div>
            }
            <div className='flex flex-col gap-1 w-full'>
                <p className='uppercase text-2xl text-center mb-3'>
                    Best Bracket Prize:
                </p>
                {first_place_prizes.slice(0,1).map(prize =>
                    <div key={prize.title}
                         className={`flex flex-1 flex-col items-center gap-2 ${prize.locked && 'opacity-50 hover:opacity-80'}`}>
                        <img src={prize.img} alt={prize.title}
                             className={'w-[24vw] h-[24vw] max-w-48 max-h-52 bg-white/10 rounded-xl p-2 aspect-square object-contain '}/>
                        <div className='flex flex-col w-full items-center gap-1'>
                            <p className='text-sm font-body text-center  font-semibold'>
                                {prize.title}
                            </p>
                            <div className='flex text-green-500 flex-row items-center gap-1 text-center'>
                                <p className='text-xs opacity-70 font-medium font-body'>
                                    <SvgLockOpen className={'w-4 h-4 opacity-70 inline-flex mr-1'}/>
                                    {prize.unlock} brackets created
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                {/*<div className='flex flex-row justify-between w-full gap-3'>*/}
                {/*    {first_place_prizes.slice(1).map(prize =>*/}
                {/*        <div key={prize.title}*/}
                {/*             className={`flex flex-1 flex-col items-center gap-2 ${prize.locked && 'opacity-50 hover:opacity-80'}`}>*/}
                {/*            <img src={prize.img} alt={prize.title}*/}
                {/*                 className={'w-[24vw] h-[24vw] max-w-48 max-h-52 bg-white/10 rounded-xl p-2 aspect-square object-contain '}/>*/}
                {/*            <div className='flex flex-col w-full items-center gap-1'>*/}
                {/*                <p className='text-sm font-body text-center  font-semibold'>*/}
                {/*                    {prize.title}*/}
                {/*                </p>*/}
                {/*                <div className='flex flex-row items-center gap-1 text-center'>*/}
                {/*                    <p className='text-xs opacity-70 font-medium font-body'>*/}
                {/*                        <SvgLock className={'w-4 h-4 opacity-70 inline-flex mr-1'}/>*/}
                {/*                        {prize.unlock} brackets created*/}
                {/*                    </p>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</div>*/}
                {/*<div className='flex flex-row items-end mt-3'>*/}
                {/*    <div className='flex flex-col items-center ml-[12%] gap-1'>*/}
                {/*        <img src={applevision} alt={'thumbnail'} className='w-6 h-6 object-contain'/>*/}
                {/*        <SvgLock className={'w-4 h-4 opacity-40'}/>*/}
                {/*    </div>*/}
                {/*    <div className='flex flex-col items-center ml-[12%] gap-1'>*/}
                {/*        <img src={lvball} alt={'thumbnail'} className='w-6 h-6 object-contain'/>*/}
                {/*        <SvgLock className={'w-4 h-4 opacity-40'}/>*/}
                {/*    </div>*/}
                {/*    <div className='flex flex-col items-center ml-auto mr-2 gap-1'>*/}
                {/*        <img src={model3} alt={'thumbnail'} className='w-6 h-6 object-contain'/>*/}
                {/*        <SvgLock className={'w-4 h-4 opacity-40'}/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className='h-4 rounded-full bg-green-500/20 relative'>*/}
                {/*    <div className='h-4 bg-green-500 rounded-full relative' style={{width: rocketPercent}}>*/}
                {/*    </div>*/}
                {/*    <div className={`shake-element absolute -top-0.5`} style={{left: rocketPercent}}>*/}
                {/*        <div className='rotate-90 -mt-4'>*/}
                {/*            <img alt={'rocket'} src={rocket} style={{width: 20}} className={'scale-[1.5]'}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            <div className='flex flex-col gap-1 max-w-[620px] overflow-hidden -mx-4'>
                <p className='uppercase text-2xl text-center mb-3'>
                    2nd-20th Place Prizes:
                </p>
                <div className='w-[100vw] max-w-[620px]  overflow-hidden'>
                    <Marquee speed={20} direction={'right'}>
                        {prizes.map(prize =>
                            <div key={prize.title}
                                 className='bg-white/10 p-2 rounded-xl text-center flex flex-col items-center gap-2 mr-4'>
                                <img alt={prize.title} src={prize.img}
                                     className='w-[16vw] h-[16vw] max-w-28 max-h-28 object-contain'/>
                                <p className='text-xs font-body'>
                                    {prize.title.slice(0, 12) + ((prize.title.length > 12) ? '...' : '')}
                                </p>
                            </div>
                        )}
                    </Marquee>
                </div>
            </div>
            <Link to={'/prizes'} className='w-full'>
                <Button className='w-full'>
                    Prize Details
                </Button>
            </Link>
        </div>
    )
        ;
}

export default PrizesSection;
