import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={18}
            height={18}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_471_19686)">
                <path
                    d="M17.182 7.09h-4.637V4.637a.818.818 0 00-.818-.818H6.273a.818.818 0 00-.818.818v.818H.818A.818.818 0 000 6.273v7.09c0 .452.366.819.818.819H17.182a.818.818 0 00.818-.818V7.909a.818.818 0 00-.818-.818zm-15.546 0h3.819v5.455H1.636V7.091zm5.455-.817v-.819h3.818v7.091H7.091V6.273zm9.273 6.272h-3.819V8.727h3.819v3.818z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_471_19686">
                    <path fill="currentColor" d="M0 0H18V18H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default SvgComponent
