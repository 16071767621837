import {DateTime} from "luxon";
import {getDurationUntilStart, getGameStartAndEndTimes} from "./helpers";
import {gamesBegin} from "../../consts/dummy";


const testingDates = [
    // Before the tournament starts (Day 0, before March 19)
    "2024-03-18T08:00:00", // March 18, 8:00 AM - Before tournament start

    // Day 1 of the tournament
    "2024-03-19T08:00:00", // March 19, 8:00 AM - Before first game starts
    "2024-03-19T12:00:00", // March 19, 12:00 PM - Midday of first game day
    "2024-03-19T16:05:00", // March 19, 4:05 PM - Just after games end on the first day
    "2024-03-19T19:00:00", // March 19, 7:00 PM - Evening after the first game day

    // Day 2 of the tournament
    "2024-03-20T08:00:00", // March 20, 8:00 AM - Before games start on the second day
    "2024-03-20T12:00:00", // March 20, 12:00 PM - Midday of the second game day
    "2024-03-20T16:05:00", // March 20, 4:05 PM - Just after games end on the second day
    "2024-03-20T19:00:00", // March 20, 7:00 PM - Evening after the second game day

    // Day 3 of the tournament
    "2024-03-21T08:00:00", // March 21, 8:00 AM - Before games start on the third day
    "2024-03-21T12:00:00", // March 21, 12:00 PM - Midday of the third game day
    "2024-03-21T16:05:00", // March 21, 4:05 PM - Just after games end on the third day
    "2024-03-21T19:00:00", // March 21, 7:00 PM - Evening after the third game day
];

const testTime = DateTime.fromISO(testingDates[2]).setZone("America/New_York", {keepLocalTime: true})


export const renderGameTimer = (gameNumber, longLabel) => {
    if (gameNumber !== undefined) {
        const now = DateTime.now().setZone("America/New_York");
        const matchTimes = getGameStartAndEndTimes(gameNumber);
        const startTime = DateTime.fromISO(matchTimes.start, {zone: 'America/New_York'});
        const endTime = DateTime.fromISO(matchTimes.end, {zone: 'America/New_York'});
        const verifiedTime = DateTime.fromISO(matchTimes.scoresFinal, {zone: 'America/New_York'});

        const gameDay = DateTime.fromISO(matchTimes.start, {zone: 'America/New_York'}).startOf('day');
        const today = now.startOf('day');

        let message = '';
        if (today.equals(gameDay)) {
            if (now <= endTime) {
                const calculatedTimeUntilEnd = getDurationUntilStart(matchTimes?.end).countdown;
                message = longLabel ? `${calculatedTimeUntilEnd} left` : `${calculatedTimeUntilEnd}`;
            } else if (now > endTime && now <= verifiedTime) {
                message = longLabel ? 'Game finished! Verifying scores' : `Verifying`;
            } else if (now > verifiedTime) {
                message = longLabel ? 'Final scores verified' : `Final`;
            }
            return message;
        } else {
            return null
        }
    }
}

export const renderTournamentTimer = () => {
    const now = DateTime.now().setZone("America/New_York");
    const tournamentStartTime = DateTime.fromISO(gamesBegin, {zone: "America/New_York"}).startOf('day');
    const nineThirty = now.set({hour: 9, minute: 30, second: 0});
    const sixteen = now.set({hour: 16, minute: 0, second: 0});
    // Verified scores switch time
    const sixteenThirty = now.set({hour: 17, minute: 0});
    const startOfNextDayGames = nineThirty.plus({days: 1});

    const tournamentDay = Math.floor(now.diff(tournamentStartTime, 'days').days) + 0;

    if (now.weekday === 6 || now.weekday === 7) {
        return {message: 'Check back during the week!', bgColor: 'bg-gray-400/30'};
    }


    let message;
    let bgColor;

    if (now < tournamentStartTime) {
        //Before tournament starts
        const timeLeft = getDurationUntilStart(gamesBegin);
        message = `Games start soon.`;
        bgColor = 'bg-yellow-400/30';
    }
    if (now < nineThirty || now < sixteen) {
        // Gameday, before the games start or  Games are live
        const timeLeft = getDurationUntilStart(sixteen.toISO());
        message = `Games live now!`;
        bgColor = 'bg-green-400/30';
    } else if (now < sixteenThirty) {
        // Verifying scores
        message = 'Verifying final scores';
        bgColor = 'bg-red-400/30';
    } else {
        // After games end and before midnight
        const timeLeft = getDurationUntilStart(startOfNextDayGames.toISO());
        message = `Day complete! Check back in tomorrow`;
        bgColor = 'bg-blue-400/30';
    }

    return ({message, bgColor})
    // return ({message: 'Check back in Monday for Round 3!', bgColor: 'bg-yellow-400/30'})
}


export const renderLiveGameBracketTickerColor = (gameNumber) => {
    const now = DateTime.now().setZone("America/New_York");
    const matchTimes = getGameStartAndEndTimes(gameNumber);
    const endTime = DateTime.fromISO(matchTimes.end, {zone: 'America/New_York'});
    const preMatch = DateTime.fromISO(matchTimes.preMatch, {zone: 'America/New_York'});
    const gameDay = DateTime.fromISO(matchTimes.start, {zone: 'America/New_York'}).startOf('day');
    const today = now.startOf('day');
    let color = '';
    if (today.equals(gameDay)) {
        if (now <= preMatch) {
            color = ``;
        } else if (now <= endTime) {
            color = `text-yellow-400`;
        } else if (now > endTime) {
            color = ``;
        }
        return color;
    } else {
        return ''
    }
}
