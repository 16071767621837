import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {connect} from "react-redux";

import HomePage from './pages/HomePage';
import HowToPlayPage from './pages/HowToPlayPage';
import NotFoundPage from "./pages/NotFoundPage";
import Navbar from "./components/navigation/Navbar";
import Banner from "./components/Banner";
import SchedulePage from "./pages/SchedulePage";
import Footer from "./components/navigation/Footer";
import NavMenu from "./components/navigation/NavMenu";
import JoinWaitlistStickyButton from "./components/JoinWaitlistStickyButton";
import ScrollToTop from "./components/navigation/ScrollToTop";
import JoinWaitlistFlow from "./components/flows/JoinWaitlistFlow";
import PrizesPage from "./pages/PrizesPage";
import 'react-toastify/dist/ReactToastify.css';

// pages
import BracketPage from "./pages/BracketPage";
import CollapseComponent from "./components/atoms/CollapseComponent";
import BracketFooter from "./components/navigation/BracketFooter";
import HubPage from "./pages/HubPage";
import MatchupPage from "./pages/MatchupPage";
import AuthPage from "./pages/AuthPage";
import EmailActionHandlerPage from './pages/EmailActionHandlerPage';

// api
import {getUser} from './api/users';
import {getMasterBracket} from './api/brackets';
import {getSupabaseTokens, listenAuthUser, validateUsername} from './api/auth';
import LeaderboardPage from "./pages/LeaderboardPage";
import LoadingSection from "./components/atoms/LoadingSection";
import {ToastContainer} from "react-toastify";
import LiveHomePage from "./pages/LiveHomePage";
import GetRemindersPage from "./pages/GetRemindersPage";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        waitlistModalOpen: state.userReducer.waitlistModalOpen,
        mobile: state.userReducer.mobile,
        globalLoading: state.userReducer.globalLoading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleWaitlistModal: () => {
            dispatch({
                type: "TOGGLE_WAITLIST_MODAL",
            })
        },
        setMobile: (mobile) => {
            dispatch({
                type: "SET_MOBILE",
                mobile
            })
        },
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user
            })
        },
        setAuthUser: (authUser) => {
            dispatch({
                type: "SET_AUTH_USER",
                authUser
            })
        },
        setUserBracket: (userBracket) => {
            dispatch({
                type: "SET_USER_BRACKET",
                userBracket
            })
        },
        setMasterBracket: (masterBracket) => {
            dispatch({
                type: "SET_MASTER_BRACKET",
                masterBracket
            })
        },
        setReadonlyMasterBracket: (masterBracket) => {
            dispatch({
                type: "SET_READONLY_MASTER_BRACKET",
                masterBracket
            })
        }
    }
}

function Router(props) {
    const [navMenuOpen, setNavMenuOpen] = useState(false);
    const [_authUser, _setAuthUser] = useState(undefined);
    const location = useLocation();
    const [loadingAuthUser, setLoadingAuthUser] = useState(true)
    const bypassEmailVerification = true;

    const openNavMenu = () => {
        document.body.style.overflowY = 'hidden'
        setNavMenuOpen(true)
    }

    const closeNavMenu = () => {
        document.body.style.overflowY = 'scroll'
        setNavMenuOpen(false)
    }

    const resize = () => props.setMobile(window.innerWidth <= 760);

    useEffect(() => {
        resize();
    }, [location.pathname]);

    const masterBracketLoaded = useRef(false);
    useEffect(() => {
        listenAuthUser(handleAuthUserUpdate)
        if (!props.masterBracket && !masterBracketLoaded.current) {
            masterBracketLoaded.current = true;
            getMasterBracket().then(res => {
                props.setMasterBracket(res);

                // for (var i = 0; i < 62; i++) {
                //     res[i].winner_id = res[i].ticker1;
                //     const nextGame = Math.floor(i / 2) + 32;
                //     const ticker = i % 2 === 0 ? 'ticker1' : 'ticker2';
                //     res[nextGame][ticker] = res[i].ticker1;
                //     res[nextGame][res[i].ticker1] = res[i][res[i].ticker1]
                // }
                // res[62].winner_id = 'AAPL';

                const res2 = {};
                for (var i = 0; i < 63; i++) {
                    const ticker1 = res[i].ticker1
                    const ticker2 = res[i].ticker2
                    res2[i] = {
                        ...res[i],
                        ticker1,
                        ticker2,
                        winner_id: res[i].winner_id,
                        [ticker1]: res[i][ticker1],
                        [ticker2]: res[i][ticker2],
                    }
                }

                for (var i = 0; i < 63; i++) {
                    if (res2[i].winner_id === null)
                        break;

                    if (i === 62) break;
                    const nextGame = Math.floor(i / 2) + 32;
                    const ticker = i % 2 === 0 ? 'ticker1' : 'ticker2';
                    res2[nextGame][ticker] = res2[i].winner_id;
                    res2[nextGame][res2[i].winner_id] = res2[i][res2[i].winner_id];
                }

                props.setReadonlyMasterBracket({...res2});
            })
        }
    }, [props.authUser])

    const userLoaded = useRef(false);
    const handleAuthUserUpdate = (authUser) => {
        setLoadingAuthUser(false);
        _setAuthUser(authUser);
        props.setAuthUser(authUser);
        if (!authUser) return;

        // prevent double load
        if (userLoaded.current)
            return;
        userLoaded.current = true;

        getUser(authUser.displayName).then(res => {
            props.setUser(res?.user || {});
            props.setUserBracket(res?.user?.brackets || {})
        }).catch((err) => {
            console.log('auth err', err);
            props.setUser({});
            props.setUserBracket({});
        })
    }

    const PrivateRoute = (props) => {
        const {children} = props;
        const username_re = /^((?!admin)[a-z0-9_]){3,20}$/i;
        const authenticated = !!_authUser && (_authUser.emailVerified || (bypassEmailVerification && _authUser?.email)) && validateUsername(_authUser.displayName);

        // should only be necessary on new user's very first load
        if (authenticated && !_authUser?.sbToken)
            getSupabaseTokens(_authUser);

        return authenticated ? (
            <>{children}</>
        ) : (
            <Navigate to="/authentication" replace={true}/>
        )
    }

    const fullWidth = location.pathname === '/bracket' || location.pathname.slice(0, 9) === '/bracket/' || location.pathname.includes('/authentication') || location.pathname === '/';
    const hideJoinWaitlist = location.pathname.includes('/bracket') || location.pathname === '/hub' || location.pathname === '/matchup' || location.pathname === '/authentication' || location.pathname.includes('matchup') || location.pathname === '/leaderboard' || location.pathname === '/'
    return (
        _authUser === undefined || loadingAuthUser ?
            <LoadingSection fullScreen/>
            :
            <div>
                <ScrollToTop/>
                <div className="text-white min-h-[100dvh] items-center flex flex-col relative">
                    <div className='fixed w-full flex flex-col items-center z-10'>
                        {/*<CollapseComponent isOpen={!fullWidth} className='w-full'>*/}
                        {/*    <Banner/>*/}
                        {/*</CollapseComponent>*/}
                        <div className='w-full'>
                            <Navbar openNavMenu={openNavMenu}/>
                        </div>
                    </div>
                    <div className='flex flex-col items-center pt-28 w-full'>
                        <div className={`${!fullWidth ? 'max-w-[620px]' : 'w-[100vw] flex flex-col items-center'}`}>
                            <Routes>
                                <Route path="/about" element={<HomePage/>}/>
                                <Route path="/how-to-play" element={<HowToPlayPage/>}/>
                                <Route path="/schedule" element={<SchedulePage/>}/>
                                <Route path="/matchup/:matchup_id" element={<MatchupPage/>}/>
                                <Route path="/prizes" element={<PrizesPage/>}/>
                                <Route path="/toolate" element={<GetRemindersPage/>}/>
                                <Route path="/leaderboard" element={<PrivateRoute><LeaderboardPage/></PrivateRoute>}/>
                                <Route path="/bracket" element={<PrivateRoute><BracketPage
                                    loadingAuthUser={loadingAuthUser}/></PrivateRoute>}/>
                                <Route path="/bracket/:username"
                                       element={<BracketPage viewOnly/>}/>
                                <Route path="/"
                                       element={<LiveHomePage/>}/>
                                <Route path="/hub" element={<PrivateRoute><HubPage/></PrivateRoute>}/>
                                <Route path="/authentication" element={<AuthPage/>}/>
                                <Route path="/__/auth/action" element={<EmailActionHandlerPage/>}/>
                                <Route path="*" element={<NotFoundPage/>}/>
                            </Routes>
                            {fullWidth ?
                                <BracketFooter/>
                                :
                                <Footer/>
                            }
                        </div>
                    </div>
                    {/*{!hideJoinWaitlist &&*/}
                    {/*    <JoinWaitlistStickyButton*/}
                    {/*        openJoinWaitlist={props.toggleWaitlistModal}*/}
                    {/*    />*/}
                    {/*}*/}
                    <JoinWaitlistFlow
                        open={props.waitlistModalOpen}
                        close={props.toggleWaitlistModal}
                        mobile={props.mobile}
                    />
                    <NavMenu
                        open={navMenuOpen}
                        close={closeNavMenu}
                    />
                </div>
                <ToastContainer
                    position={'top-center'}
                    autoClose={3000}
                    newestOnTop={true}
                    draggable={true}
                    theme={'dark'}
                />
            </div>
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(Router);
