import React, {useEffect, useState} from "react";
import E_COVERING_EYES from '../brand/emojis/E_COVERING EYES.png';
import E_CRYING from '../brand/emojis/E_CRYING.png';
import E_DEVIL_HAPPY from '../brand/emojis/E_DEVIL HAPPY.png';
import E_DEVIL_SAD from '../brand/emojis/E_DEVIL SAD.png';
import E_GLASSES from '../brand/emojis/E_GLASSES.png';
import E_HEARTS from '../brand/emojis/E_HEARTS.png';
import E_MELTING from '../brand/emojis/E_MELTING.png';
import E_SHOCKED from '../brand/emojis/E_SHOCKED.png';
import E_SMILE from '../brand/emojis/E_SMILE.png';
import E_STRAIGHT_FACE from '../brand/emojis/E_STRAIGHT FACE.png';
import E_TONGUE_OUT from '../brand/emojis/E_TONGUE OUT.png';

const randomPhrases = [
    "Lacing up $AMC's moon boots",
    "Feeding $BB to the bears or bulls?",
    "Setting $NOK signals to battle mode",
    "Prepping $MSFT's systems, removing lint",
    "Tuning in to $NFLX's strategy livestream",
    "Testing $NVDA's chips in various salsas and dips",
    "Rolling the dice for $DKNG's fate",
    "Buffing $AMD's silicon swords",
    "Inflating $TSLA tires for the speed run",
    "Stocking $BA's bar carts with grandmas cookies",
    "Finding economic equilibrium",
    "Gathering infinity stones",
    "Harvesting tendies",
    "Scraping Cramer pump up videos",
    "Closing our eyes and throwing darts",
    "Reminiscing about the good old days",
    "Running to the bathroom really quick",
    "Activating $CROX sport mode",
    "Checking to see if EA added a better career mode",
    "Forecasting financial costs",
    "Not speeding, just following the flow of traffic",
    "Stopping for coffee",
    "Equipping puffer vest",
    "Starting to experiment by adding loafers to the wardrobe",
    'Spinning in circles'
];

const leaderboardPhrases = [
    'Sifting through the phonies',
    'Checking for any insider trading',
    'Probably could optimize this load time more',
    'Finding Eric and moving him down 2 spots (joking)',
    'Analyzing degenerates',
    "Validating scores and laughing at the bad ones",
    "Some more number crunching",
    "Taking a bathroom break",
    "Checking for food poisoning",
    "Measuring how much each player really wanted it",
    "Aligning the constellations",
    "Congrats you found a loading easter egg"
]

const emojiList = [
    E_COVERING_EYES,
    E_CRYING,
    E_DEVIL_HAPPY,
    E_DEVIL_SAD,
    E_GLASSES,
    E_HEARTS,
    E_MELTING,
    E_SHOCKED,
    E_SMILE,
    E_STRAIGHT_FACE,
    E_TONGUE_OUT
];

function LoadingSection(props) {
    const [ellipsis, setEllipsis] = useState('');
    const [phrase, setPhrase] = useState('');
    const [emoji, setEmoji] = useState('');

    // Function to animate ellipsis
    const animateEllipsis = () => {
        const ellipsisStates = ['', '.', '..', '...'];
        setEllipsis(prevState => {
            const currentIndex = ellipsisStates.indexOf(prevState);
            const nextIndex = (currentIndex + 1) % ellipsisStates.length;
            return ellipsisStates[nextIndex];
        });
    };

    // Function to update phrase and emoji
    const updateContent = () => {
        setEmoji(getRandomEmoji());
        if (props.type === 'leaderboard') {
            setPhrase(getLeaderboardPhrase());
        } else {
            setPhrase(getRandomPhrase());
        }
    };

    // Hook to animate ellipsis
    useEffect(() => {
        const intervalId = setInterval(animateEllipsis, 500);
        return () => clearInterval(intervalId);
    }, []);

    // Hook to update phrase and emoji every 2 seconds
    useEffect(() => {
        updateContent(); // Initial update
        const intervalId = setInterval(updateContent, 2000);
        return () => clearInterval(intervalId);
    }, [props.type]); // Rerun when props.type changes

    // Random phrase from randomPhrases list
    const getRandomPhrase = () => randomPhrases[Math.floor(Math.random() * randomPhrases.length)];

    // Random phrase from leaderboardPhrases list
    const getLeaderboardPhrase = () => leaderboardPhrases[Math.floor(Math.random() * leaderboardPhrases.length)];

    // Random emoji from emojiList
    const getRandomEmoji = () => emojiList[Math.floor(Math.random() * emojiList.length)];

    return (
        <div
            className={`items-center justify-center flex flex-col flex-1 h-[90dvh] md:h-[100dvh] ${props.className} ${props.fullScreen && 'min-h-[100dvh]'}`}>
            <div className={'animate-pulse items-center flex flex-col gap-5 text-white'}>
                <img src={emoji} alt={'loader'} className={'w-12 h-12 grow-spin-shrink'}/>
                {props.fullScreen &&
                    <p className='uppercase -mb-3 text-xl'>
                        Loading
                    </p>
                }
                <p className='font-body text-xs md:text-sm opacity-70 text-white'>
                    {props.label ? props.label + ellipsis : phrase + ellipsis}
                </p>
            </div>
        </div>
    );
}

export default LoadingSection;
