import React from 'react';
import ModalComponent from "../atoms/ModalComponent";
import DownloadAfterHourCard from "../../sections/cards/DownloadAfterHourCard";

function AfterHourModal(props) {
    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            toggle={props.close}
            backdropClass={'bg-purple-500/40'}
        >
            <DownloadAfterHourCard
                inModal={true}
                closeModal={props.close}
                title={props.title}
                description={props.description}
                buttonTitle={props.buttonTitle}
            />
        </ModalComponent>
    );
}

export default AfterHourModal;
