import React, {useEffect, useState} from "react";

function RandomCharacter(props) {
    const [character, setCharacter] = useState(null)


    useEffect(() => {
        setCharacter(getRandomTicker())
    }, [props.trigger]);


    const getRandomTicker = () => {
        const tickers = [
            "AAPL",
            "META", "GOOG", "MSFT", "AMZN", "SHOP", "V", "MA", "NFLX", "DIS", "TSLA", "RIVN",
            "PYPL",
            "SQ", "RBLX", "EA", 'PDD', 'BABA', 'NKE', 'LULU', 'UA',
            'CROX',
            'HD',
            'LOW',
            'T', 'VZ', 'WMT', 'TGT', 'GME', 'AMC', 'PENN', 'DKNG', "NVDA", "AMD", "SMCI", "ARM",
            'MCD',
            "CMG", "DPZ", "PZZA", "PEP", "KO", "MNST", "CELH",
            "SBUX",
            "DNUT", "TLRY", "WEN", 'BKNG', 'ABNB', 'COIN', 'HOOD', 'UBER', 'LYFT', 'SNAP', 'PINS', 'MTCH', 'BMBL', 'LUV', 'DAL', 'SPOT', 'SIRI', 'F', 'GM']
        return tickers[Math.floor(Math.random() * tickers.length)];
    };


    return (
        <img src={`https://zyidwzbarunqmcnmfukk.supabase.co/storage/v1/object/public/characters/md/${character}.png`}
             alt={character} className={`w-12 max-h-16 object-contain scale-80 ${props.className} `}/>
    )
}

export default RandomCharacter
