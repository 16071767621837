import React, {useState} from 'react';
import {FAQ, sectionClass} from "../consts/dummy";
import {Button} from "../components/atoms/Button";
import FAQItem from "../components/FAQItem";
import eyes from '../img/stickers/SM_EYES EMOJI.png'

function FAQSection(props) {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleItem = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const openRules = () => {
        window.open('https://zyidwzbarunqmcnmfukk.supabase.co/storage/v1/object/public/public_assets/Stonk%20Madness%202024%20Contest%20Rules%20(1).pdf', '_blank', 'noreferrer')
    }


    return (
        <div className={`${sectionClass} relative`}>
            <img alt={'eyes'} src={eyes} className={'absolute w-28 -top-8 right-0 rotate-6'}/>
            <p className='text-4xl'>
                FAQ
            </p>
            {FAQ.map((item, index) =>
                <FAQItem
                    item={item}
                    key={item.question}
                    itemSelected={activeIndex !== null}
                    isActive={activeIndex === index}
                    onToggle={() => toggleItem(index)}
                />
            )}
            <div className='flex flex-col items-center w-full'>
                <Button variant={'secondary'} onClick={openRules}>
                    THE FINE PRINT
                </Button>
            </div>
        </div>
    );
}

export default FAQSection;
