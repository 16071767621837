import React from 'react';
import SvgChevronDown from "../components/icons/SvgChevronDown";
import CollapseComponent from "./atoms/CollapseComponent";

function FAQItem({item, isActive, onToggle, itemSelected}) {
    const chevronClasses = `transition-transform duration-300 ease ${isActive ? 'rotate-180' : ''}`;
    return (
        <div
            className={`font-body w-full flex flex-col gap-2 ${(itemSelected && !isActive) && 'opacity-50 hover:opacity-100'}`}>
            <div
                onClick={onToggle}
                className='flex flex-row w items-center justify-between text-yellow-400 cursor-pointer'>
                <p className='font-semibold text-xl'>
                    {item.question}
                </p>
                <SvgChevronDown className={chevronClasses}/>
            </div>
            <CollapseComponent isOpen={isActive}>
                {item.link ?
                    <a className='text-yellow-400 underline' href={'https://www.youtube.com/watch?v=L-l6tHeseDY'}
                       target={'_blank'} rel={'noreferrer'}>{item.answer}</a>
                    :
                    <p>{item.answer}</p>
                }
            </CollapseComponent>
        </div>
    );
}

export default FAQItem;
