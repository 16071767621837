import React, {useEffect, useRef, useState} from 'react';
import BracketHalf from "../../components/bracket/BracketHalf";
import BracketCenter from "../../components/bracket/BracketCenter";
import BracketRoundHeaders from "../../components/bracket/BracketRoundHeaders";
import BracketCreationBottomSheet from "./creation/BracketCreationBottomSheet";
import MatchPreviewModal from "../modals/MatchPreviewModal";
import {connect} from 'react-redux';
import BracketCompleteModal from "../modals/BracketCompleteModal";
import BracketConfirmationModal from "../modals/BracketConfirmationModal";
import BracketPlayerCard from "./view-only/BracketPlayerCard";
import {useLocation} from "react-router-dom";
import ShareSelectionModal from "../modals/ShareSelectionModal";
import {preloadCharts} from '../../api/matchups';
import {toast} from "react-toastify";
import BracketLoadingStateOverlay from "./BracketLoadingStateOverlay";
import BracketLiveHalf from "../../components/bracketlive/BracketLiveHalf";
import BracketLiveCenter from "../../components/bracketlive/BracketLiveCenter";
import {useNavigate} from 'react-router-dom';
import { USE_LIVE_VIEW } from 'consts/consts';

const mapStateToProps = (state) => {
    return {
        authUser: state.userReducer.authUser,
        userBracket: state.userReducer.userBracket,
        masterBracket: state.userReducer.masterBracket,
        readonlyMasterBracket: state.userReducer.readonlyMasterBracket,
        mobile: state.userReducer.mobile,
        user: state.userReducer.user,
        leaderboard: state.userReducer.leaderboard,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserBracket: (userBracket) => {
            dispatch({
                type: "SET_USER_BRACKET",
                userBracket
            })
        },
        updateLeaderboard: (username, winner) => {
            dispatch({
                type: "UPDATE_LEADERBOARD",
                username: username,
                winner: winner,
            })
        }
    }
}

function BracketComponent({masterBracket, mobile, username, ...props}) {
    const location = useLocation()
    const navigate = useNavigate();
    const scrollContainerRef = useRef(null); // Ref for the bracket body
    const headerContainerRef = useRef(null); // Ref for the header to apply scroll changes
    const [matchPreviewModalOpen, setMatchPreviewModalOpen] = useState(false);
    const [shareModalOpen, setShareModalOpen] = useState(false)
    const [matchPreviewMatchup, setMatchPreviewMatchup] = useState(null)
    const [gameNumber, setGameNumber] = useState(0);
    const [gamesSelected, setGamesSelected] = useState(new Set());
    const [showEditBracket, setShowEditBracket] = useState(false);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(0);
    const [showConfirmationScreen, setShowConfirmationScreen] = useState(false);
    const [showCompleteScreen, setShowCompleteScreen] = useState(false)
    // used to view another user's bracket
    const [isViewOnlyPreview, setIsViewOnlyPreview] = useState(false);
    const [isPreviewCheckLoading, setIsPreviewCheckLoading] = useState(true)
    const [userBracket, setUserBracket] = useState(props.userBracket);
    const [isEditing, setIsEditing] = useState(false)
    const [preloadLoading, setPreloadLoading] = useState(true);
    const [showMilestoneLottie, setShowMilestoneLottie] = useState(false);
    const milestoneTimeoutRef = useRef(null);
    const [userMatchups, setUserMatchups] = useState([]);


    // determine if this view is a preview of another user's bracket
    useEffect(() => {
        setIsPreviewCheckLoading(true)
        const isViewOnlyPreview = !!props.queriedBracket
        setIsViewOnlyPreview(isViewOnlyPreview);
        setUserBracket(isViewOnlyPreview ? props.queriedBracket : props.userBracket);
        updateMasterWithUserBracket();
        setIsPreviewCheckLoading(false);
        setUserMatchups(_generateUserMatchups);
    }, [props.queriedBracket])

    useEffect(() => {
        setLoading((!userBracket || !masterBracket || isPreviewCheckLoading || preloadLoading));
        setIsEditing(location?.state?.isEditing)
    }, [userBracket, masterBracket, isPreviewCheckLoading, preloadLoading])

    const openMatchPreviewModal = (matchup) => {
        setMatchPreviewMatchup(matchup)
        setMatchPreviewModalOpen(true)
        document.body.style.overflowY = 'hidden'
    }
    const closeMatchPreviewModal = () => {
        setMatchPreviewModalOpen(false)
        document.body.style.overflowY = 'scroll'
    }

    // preload 1w data
    const preloaded = useRef(false);
    useEffect(() => {
        if (!masterBracket || preloaded.current)
            return;
        preloaded.current = true;

        const tickers = [...new Set(Object.keys(masterBracket).reduce((a, k) => [...a, masterBracket[k].ticker1, masterBracket[k].ticker2], []))]
        preloadCharts(tickers.slice(0, 2))
            .then((cachedData) => preloadCharts(tickers.slice(0, 2), cachedData))
            .then((cachedData) => preloadCharts(tickers.slice(2, 4), cachedData))
            .then((cachedData) => preloadCharts(tickers.slice(4, 8), cachedData))
            .then((cachedData) => preloadCharts(tickers.slice(8, 16), cachedData))
            .then((cachedData) => preloadCharts(tickers.slice(16, 32), cachedData))
            .then((cachedData) => preloadCharts(tickers.slice(32, 64), cachedData))
            .finally(() => {
                setPreloadLoading(false);
            })
    }, [masterBracket])

    // handle analytics
    useEffect(() => {
        if (!!props.userBracket && !props.userBracket?.submitted)
            window.amplitude.track("Bracket Creation Started")
    }, [])

    // Function to synchronize scroll position
    const handleScroll = () => {
        if (scrollContainerRef.current && headerContainerRef.current) {
            // Apply the scroll position from scrollContainerRef to headerContainerRef
            // console.log(scrollContainerRef.current.scrollLeft)
            headerContainerRef.current.scrollLeft = scrollContainerRef.current.scrollLeft;
        }
    };

    useEffect(() => {
        // Add scroll event listener to the scroll container
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
        }

        // Remove event listener on cleanup
        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    function scrollToGame(gameNumber) {
        const container = document.getElementById('scrollable-bracket');
        const gameElement = document.getElementById(`game-${gameNumber + 1}`);
        if (container && gameElement) {
            //Left to right
            if (!mobile) {
                container.scroll({
                    left: gameElement.offsetParent?.offsetLeft > container.offsetWidth / 2 ? container.offsetWidth : 0, // Center in the container
                    behavior: 'smooth'
                });
            }
            if (mobile) {
                if (gameNumber <= 46) {
                    container.scroll({
                        left: 0,
                        behavior: 'smooth'
                    });
                }
                if (gameNumber > 46 && gameNumber < 50) {
                    container.scroll({
                        left: 200,
                        behavior: 'smooth'
                    });
                }
                if (gameNumber > 58) {
                    container.scroll({
                        left: 500,
                        behavior: 'smooth'
                    });
                }
            }
            //Top
            let topPosition = gameElement.offsetTop;
            let parent = gameElement.offsetParent;
            while (parent) {
                topPosition += parent.offsetTop;
                parent = parent.offsetParent;
            }
            if (gameNumber < 60) {
                window.scroll({
                    top: topPosition - (gameNumber > 54 ? 180 : (mobile ? 80 : 100)),
                    behavior: 'smooth'
                });
            }
        }
    }

    const getNextGameKey = (_gameNumber) => {
        const nextGameNumber = Math.floor(_gameNumber / 2) + 32;
        const nextGameTickerKey = _gameNumber % 2 == 0 ? 'ticker1' : 'ticker2';
        return {nextGameNumber, nextGameTickerKey};
    }

    const handleBracketSubmission = () => {
        navigate("/toolate")
        return;

        setGameNumber(0);
        setShowConfirmationScreen(false);
        setShowCompleteScreen(true)
        setShowEditBracket(true);
        userBracket.submitted = true;
        props.updateLeaderboard(props.authUser?.displayName, userBracket?.g62);
        // saveUserBracket(props.authUser, userBracket);
        window.amplitude.track("Bracket Created", {champion: userBracket.g62})
    }

    const handleGoBack = () => {
        const newGameNumber = Math.max(0, gameNumber - 1);
        masterBracket[Math.floor(newGameNumber / 2) + 32][`ticker${newGameNumber % 2 === 0 ? 1 : 2}`] = "";
        masterBracket[newGameNumber].winner_id = null;
        setGameNumber(newGameNumber);
        scrollToGame(newGameNumber);
        setRefresh(refresh => 1 - refresh)
    }

    const notify = (message) => toast(message);

    const showMilestone = (_gameNumber) => {
        const milestones = [16, 32, 48, 56, 60, 62];
        if (milestones.includes(_gameNumber + 1)) {
            setShowMilestoneLottie(_gameNumber + 1);

            // Clear any existing timeout to ensure we don't have multiple waiting to execute
            if (milestoneTimeoutRef.current) {
                clearTimeout(milestoneTimeoutRef.current);
            }

            // Set a new timeout and store its ID in the ref
            milestoneTimeoutRef.current = setTimeout(() => {
                setShowMilestoneLottie(false);
                milestoneTimeoutRef.current = null; // Reset the ref once the timeout has executed
            }, 4000);
        }
    }

    // User selects game winner
    const handleWinnerSelection = (_gameNumber, ticker, noAutoFocus) => {
        navigate("/toolate")
        return;

        // triggerRandomNotification()
        if (isViewOnlyPreview) return;
        gamesSelected.add(_gameNumber);
        // Set winner of currently selected game
        userBracket[`g${_gameNumber}`] = ticker;
        masterBracket[_gameNumber].winner_id = ticker;

        // Show milestone popup
        showMilestone(_gameNumber)

        // Final submission
        if (_gameNumber === 62) {
            setShowConfirmationScreen(true);
            return;
        }

        // Propagate selection to downstream rounds
        const winnerData = masterBracket[_gameNumber][ticker]
        const otherTicker = masterBracket[_gameNumber]['ticker1'] === ticker ? masterBracket[_gameNumber]['ticker2'] : masterBracket[_gameNumber]['ticker1'];
        let nextGameNumber = _gameNumber;
        let firstLevelDown = true;
        do {
            const res = getNextGameKey(nextGameNumber);
            nextGameNumber = res.nextGameNumber;
            let nextGameTickerKey = res.nextGameTickerKey;

            if (masterBracket[nextGameNumber][nextGameTickerKey] === otherTicker) {
                masterBracket[nextGameNumber][nextGameTickerKey] = ticker;
                masterBracket[nextGameNumber][ticker] = masterBracket[_gameNumber][ticker];
                if (masterBracket[nextGameNumber].winner_id === otherTicker) {
                    masterBracket[nextGameNumber].winner_id = ticker;
                    userBracket[`g${nextGameNumber}`] = ticker;
                }
                firstLevelDown = false;
            } else if (!masterBracket[nextGameNumber][nextGameTickerKey]) {
                // next round has not been picked, only replace if 1 round after actual selection
                if (!firstLevelDown) break;
                masterBracket[nextGameNumber][nextGameTickerKey] = ticker;
                masterBracket[nextGameNumber][ticker] = masterBracket[_gameNumber][ticker];
                firstLevelDown = false;
            } else {
                // next round is from another lineage and should not be replaced
                break;
            }
        } while (nextGameNumber < 62)

        if (!noAutoFocus) {
            setGameNumber(Math.min(_gameNumber + 1, 62));
            scrollToGame(_gameNumber)
        }

        if (!userBracket?.submitted)
            props.setUserBracket(userBracket);

        setRefresh(refresh => 1 - refresh)

        // save progress if first bracket
        // if (!userBracket?.submitted)
        //     saveUserBracket(props.authUser, userBracket)

    }

    // Fill out bracket
    useEffect(() => {
        setShowEditBracket(true)
        if (props.loadingQueriedBracket)
            return;
        if (!!masterBracket && gameNumber === 0) {
            updateMasterWithUserBracket();
            const gameArr = [...Array(63).keys()].map(i => userBracket?.[`g${i}`] || "");
            const _gameNumber = gameArr.reduce((n, g) => g === "" ? n : n + 1, 0);
            setGameNumber(_gameNumber)
        }
    }, [userBracket, masterBracket, props.loadingQueriedBracket, props.queriedBracket])

    // User who has previously submitted decides to edit bracket
    const handleEditBracket = () => {
        setShowEditBracket(false);
        setIsViewOnlyPreview(false)
        resetBracket();
    }

    // Use user's submitted bracket to fill out bracket screen
    const updateMasterWithUserBracket = () => {
        const bracket = !!props?.queriedBracket ? props?.queriedBracket : userBracket;
        if (!masterBracket || !bracket)
            return;

        for (var i = 0; i < 63; i++) {
            const ticker = bracket[`g${i}`];
            masterBracket[i].winner_id = ticker;
            if (i === 62) break;
            const matchupIndex = Math.floor(i / 2) + 32;
            const tickerKey = i % 2 === 0 ? 'ticker1' : 'ticker2';

            masterBracket[matchupIndex][tickerKey] = ticker;
            masterBracket[matchupIndex][ticker] = masterBracket[i][ticker];
        }
        setRefresh(refresh => 1 - refresh)
    }

    const resetBracket = () => {
        for (var i = 0; i < 32; i++)
            masterBracket[i].winner_id = null;
        for (var i = 32; i < 63; i++)
            masterBracket[i] = {id: i, round: masterBracket[i].round, winner_id: null};
        setGameNumber(0);
        setGamesSelected(new Set());
    }

    const openShareModal = () => {
        setShareModalOpen(true)
    }

    const closeShareModal = () => {
        setShareModalOpen(false)
    }

    const _generateUserMatchups = () => {
        const isViewOnlyPreview = !!props.queriedBracket
        const matchupsBracket = isViewOnlyPreview ? props.queriedBracket : props.userBracket

        const userMatchups = [];
        for (var i = 0; i < 32; i++)
            userMatchups.push({
                ticker1: props.readonlyMasterBracket[i]?.ticker1,
                ticker2: props.readonlyMasterBracket[i]?.ticker2
            });
        for (var i = 0; i < 62; i += 2) {
            const ticker1 = matchupsBracket[`g${i}`];
            const ticker2 = matchupsBracket[`g${i + 1}`];
            userMatchups.push({ticker1: ticker1, ticker2: ticker2})
        }
        return userMatchups
    }

    const isViewingSelf = !props.queriedBracket ? true : (props.authUser?.displayName === props.queriedBracket?.username)
    return (
        <>
            {/*{(!mobile && !isViewOnlyPreview && !showEditBracket) &&*/}
            {/*    <div className='sticky top-[93dvh] z-[0] mb-3 w-1/2 mx-auto'>*/}
            {/*        <p className='text-center text-sm uppercase'>*/}
            {/*            BRACKET PROGRESS*/}
            {/*        </p>*/}
            {/*        /!*Todo: bracket progress variant for desktop*!/*/}
            {/*        <BracketCreationProgressBar desktop numSelected={gamesSelected.size}/>*/}
            {/*    </div>*/}
            {/*}*/}
            <div
                ref={headerContainerRef}
                className='sticky  pointer-events-none top-[4rem] z-[2] overflow-scroll hide-scrollbar'>
                <div
                    className=' min-w-[780px] md:min-w-[1100px] px-5  border-b border-white/10 bg-black/80 backdrop-blur'>
                    <BracketRoundHeaders columnWidth={'w-[160px]'}/>
                </div>
            </div>
            {((showEditBracket && !isViewOnlyPreview && !loading && !isPreviewCheckLoading) && (location.pathname.includes('/bracket/') ? isViewingSelf : true)) &&
                <>
                    <BracketPlayerCard
                        showEditBracket={true}
                        handleEditBracket={handleEditBracket}
                        mobile={mobile}
                        bracket={props.userBracket}
                        openShareModal={openShareModal}
                    />
                </>
            }
            {(loading || props.loadingAuthUser || isPreviewCheckLoading === null || props.loadingQueriedBracket) &&
                <BracketLoadingStateOverlay mobile={mobile}/>
            }
            <div
                ref={scrollContainerRef}
                className={`overflow-x-scroll bg-white/5 w-full p-5 pb-20 flex flex-col gap-5 ${!showEditBracket && 'pb-32'}`}
                id={'scrollable-bracket'}>
                <div className='min-w-[780px] md:min-w-[1100px] relative'>
                    {USE_LIVE_VIEW ?
                        <BracketLiveHalf
                            loading={(loading || props.loadingQueriedBracket)}
                            top
                            focusedGameNumber={gameNumber}
                            liveBracket={props.readonlyMasterBracket}
                            userBracket={userBracket}
                            userMatchups={userMatchups}
                            mobile={mobile}
                            setFocusedGameNumber={setGameNumber}
                            openMatchPreviewModal={openMatchPreviewModal}
                            viewOnly={isViewOnlyPreview || showEditBracket}
                            isEditingBracket={!showEditBracket && !isViewOnlyPreview}
                        /> :
                        <BracketHalf
                            loading={(loading || props.loadingQueriedBracket)}
                            top
                            focusedGameNumber={gameNumber}
                            masterBracket={props.masterBracket}
                            handleWinnerSelection={() => {}}
                            mobile={mobile}
                            setGameNumber={setGameNumber}
                            openMatchPreviewModal={openMatchPreviewModal}
                            viewOnly={isViewOnlyPreview || showEditBracket}
                            isEditingBracket={!showEditBracket && !isViewOnlyPreview}
                        />
                    }
                    {USE_LIVE_VIEW ?
                        <BracketLiveCenter
                            loading={loading || props.loadingQueriedBracket}
                            focusedGameNumber={gameNumber}
                            liveBracket={props.readonlyMasterBracket}
                            userBracket={userBracket}
                            userMatchups={userMatchups}
                            mobile={mobile}
                            setFocusedGameNumber={setGameNumber}
                            openMatchPreviewModal={openMatchPreviewModal}
                            viewOnly={isViewOnlyPreview || showEditBracket}
                            isEditingBracket={!showEditBracket && !isViewOnlyPreview}
                            isViewingSelf={isViewingSelf}
                        /> :
                        <BracketCenter
                            loading={loading || props.loadingQueriedBracket}
                            focusedGameNumber={gameNumber}
                            masterBracket={props.masterBracket}
                            userBracket={userBracket}
                            mobile={mobile}
                            setGameNumber={setGameNumber}
                            openMatchPreviewModal={openMatchPreviewModal}
                            viewOnly={isViewOnlyPreview || showEditBracket}
                            isEditingBracket={!showEditBracket && !isViewOnlyPreview}
                            isViewingSelf={isViewingSelf}
                        />
                    }
                    {USE_LIVE_VIEW ?
                        <BracketLiveHalf
                            loading={loading || props.loadingQueriedBracket}
                            focusedGameNumber={gameNumber}
                            liveBracket={props.readonlyMasterBracket}
                            userBracket={userBracket}
                            userMatchups={userMatchups}
                            mobile={mobile}
                            setFocusedGameNumber={setGameNumber}
                            openMatchPreviewModal={openMatchPreviewModal}
                            viewOnly={isViewOnlyPreview || showEditBracket}
                            isEditingBracket={!showEditBracket && !isViewOnlyPreview}
                        /> :
                        <BracketHalf
                            loading={loading || props.loadingQueriedBracket}
                            focusedGameNumber={gameNumber}
                            masterBracket={props.masterBracket}
                            mobile={mobile}
                            setGameNumber={setGameNumber}
                            openMatchPreviewModal={openMatchPreviewModal}
                            viewOnly={isViewOnlyPreview || showEditBracket}
                            isEditingBracket={!showEditBracket && !isViewOnlyPreview}
                        />
                    }
                </div>
            </div>
            {!loading && !isViewOnlyPreview && !isPreviewCheckLoading && !props.loadingQueriedBracket &&
                <>
                    {(!showEditBracket || !USE_LIVE_VIEW) &&
                        <BracketCreationBottomSheet
                            openMatchPreviewModal={openMatchPreviewModal}
                            showMilestoneLottie={showMilestoneLottie}
                            currentGame={masterBracket?.[gameNumber % 63]}
                            gameNumber={gameNumber % 63}
                            onPick={() => {
                            }}
                            onBack={handleGoBack}
                            matchPreviewModalOpen={matchPreviewModalOpen}
                            scrollToGame={scrollToGame}
                            masterBracket={masterBracket}
                            resetBracket={resetBracket}
                        />
                    }
                </>
            }
            {isViewOnlyPreview && !loading && !isPreviewCheckLoading && !props.loadingQueriedBracket &&
                <BracketPlayerCard
                    showEditBracket={isViewingSelf}
                    handleEditBracket={handleEditBracket}
                    bracket={props.queriedBracket}
                    openShareModal={openShareModal}
                />
            }
            <MatchPreviewModal
                isOpen={matchPreviewModalOpen}
                close={closeMatchPreviewModal}
                gameNumber={gameNumber}
                masterBracket={masterBracket}
                viewOnly={isViewOnlyPreview}
                onPick={isViewOnlyPreview ? () => {
                } : handleWinnerSelection}
                mobile={mobile}
                preloadedMatchup={matchPreviewMatchup}
            />
            {!!masterBracket &&
                <>
                    <BracketConfirmationModal
                        isOpen={showConfirmationScreen}
                        masterBracket={masterBracket}
                        onClick={handleBracketSubmission}
                        close={() => {
                            masterBracket[62].winner_id = null;
                            setShowConfirmationScreen(false)
                        }}

                    />
                    <BracketCompleteModal
                        isOpen={showCompleteScreen}
                        masterBracket={masterBracket}
                        close={() => setShowCompleteScreen(false)}
                        openShareModal={openShareModal}

                    />
                </>
            }
            <ShareSelectionModal mobile={mobile} isOpen={shareModalOpen} close={closeShareModal}
                                 username={username || props.user.username} champion={userBracket?.g62}/>

        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(BracketComponent);
