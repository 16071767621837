import React from 'react';
import LottieWrapper from "./LottieWrapper";
import chat from '../img/lottie/chat.json'
import {Button} from "./atoms/Button";
import {WEBSITE_LINK} from "../consts/dummy";
import AfterHourWordmark from "./brand/logos/AfterHourWordmark";

function JoinTheConvoWidget(props) {

    return (
        <div className='flex flex-col items-center w-full justify-center'>
            <div className='bg-black/20 w-full p-5 rounded-xl flex flex-col items-center gap-1'>
                <div className='flex flex-row items-center justify-between -mt-1'>
                    <p className='w-full uppercase mt-0.5'>
                        Comments ON
                    </p>
                    <AfterHourWordmark className={'h-5 w-20'}/>
                </div>
                <div className='w-full md:w-[361px]'>
                    <LottieWrapper
                        json={chat}
                        speed={.8}
                    />
                </div>
                <a className='w-full shake-animation-light' target={'_blank'} rel={'noreferrer'}
                   href={WEBSITE_LINK}>
                    <Button variant={'purple'} className='w-full'>
                        Join the Convo!
                    </Button>
                </a>
            </div>
        </div>
    );
}

export default JoinTheConvoWidget;
