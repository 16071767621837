import axios, {FRONTEND_URL} from "./axios";
import {all} from 'axios';
import { firebaseAuth } from './firebase';
import {
    signInWithPhoneNumber,
    RecaptchaVerifier,
    sendEmailVerification,
    updateEmail,
    onAuthStateChanged,
    GoogleAuthProvider,
    signInWithRedirect,
    signInWithPopup,
    OAuthProvider,
    getRedirectResult,
    updateProfile,
} from 'firebase/auth';
import {createUser} from './users';
import store from '../Store';
import supabase from './supabase';

const googleAuthProvider = new GoogleAuthProvider();
const appleAuthProvider = new OAuthProvider('apple.com');

export const initializeRecaptcha = (elementId) => {
    window.recaptchaVerifier = new RecaptchaVerifier(firebaseAuth, elementId, {
      'size': 'invisible',
      'callback': (response) => {
          console.log("repatcha solved")
      }
    });
}
export const sendVerificationCode = async (phoneNumber) => {
    const appVerifier = window.recaptchaVerifier;
    await signInWithPhoneNumber(firebaseAuth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
            console.log("sms code sent");
            // used to verify sms code
            window.confirmationResult = confirmationResult; 
        }).catch(error => {
            // SMS not sent
            console.log(error);
        })
}

/** Verify SMS code
 * 
 * Successful verification retrieves user if exists
 * or creates new user if not.
 */
export const verifyPhoneCode = async (code) => {
    const verifier = window.confirmationResult;
    return verifier.confirm(code)
        .then((result) => {
            const authUser = result.user;
            return authUser;
        }).catch((error) => {
            console.log(error);
            return null;
        })
}

export const setUserEmail = async (authUser, email) => {
    try {
        await updateEmail(authUser, email);
        return "";
    } catch (error) {
        console.log(error);
        return error.code === 'auth/requires-recent-login' ? 'Credentials expired' : "Email already in use";
    }
}

export const sendVerifyEmail = async (authUser) => {
    // continue url
    const actionCodeSettings = {
        url: `${FRONTEND_URL}/authentication`
    }
    return await sendEmailVerification(authUser, actionCodeSettings)
        .then((res) => {
            return true;
        })
        .catch((err) => {
            return false;
        })
}

export const validateUsername = (username) => {
    if (!username)
        return false;
    const re = /^(?!admin)[a-z0-9_]{3,20}$/i;
    return re.test(username.toLowerCase());
}

// HIGH LOAD TIME, only use if absolutely necessary
export const getAfterhourUser = async (authUser, username=null, email=null) => {
    // Grab username if it exists but not provided so we can use cached data
    if (!username) {
        username = authUser?.displayName;

        // handle google/apple auth returning AH user
        // since display name will be their actual name
        // not username
        if (!validateUsername(username))
            username = null;
    }

    // get user from cache if present
    const afterhourUsers = store.getState().userReducer.afterhourUsers;
    if (!!username && afterhourUsers?.[username]) {
        return afterhourUsers[username];
    }

    const token = authUser?.accessToken;
    const endpoint = '/users/afterhour';
    try {
        const config = {
            headers: {
                'token': token,
            },
            params: {
                username: username,
                email: email
            }
        }
        const res = await axios.get(endpoint, config);
        const afterhourUser = res.data;
        await store.dispatch({
            type: "UPDATE_AFTERHOUR_USER",
            afterhourUser,
        });
        return afterhourUser;
    } catch (err) {
        return null;
    }
}

// HIGH LOAD TIME, only use if absolutely necessary
export const getAfterhourUsers = async (authUser, usernames) => {
    const _usernames = new Set(usernames);
    _usernames.delete(undefined);

    const loadedUsers = store.getState().userReducer.afterhourUsers;
    const _unloadedUsers = [..._usernames].reduce((a, username) => {
        if (!loadedUsers?.[username])
            a.push(username);
        return a;
    }, [])

    const token = authUser?.accessToken;
    const res = await axios.post(`/users/afterhour`, [..._usernames], {headers: {'token': token}})

    const afterhourUsers = res.data.reduce((obj, user) => ({...obj, [user.username]: user}), {});
    await store.dispatch({
        type: "SET_AFTERHOUR_USERS",
        afterhourUsers,
    });

    return res.data;
}

export const listenAuthUser = (callback) => {
    onAuthStateChanged(firebaseAuth, async (authUser) => {
        if (!!authUser) {

            if (!validateUsername(authUser?.displayName)) {
                // pass
            } else if (!authUser?.email) {
                // pass
            } else if (!authUser?.sbToken){
                // supabase auth
                const res = await axios.post("/users/supabase", {
                    authUser: authUser
                })
                authUser.sbToken = res.data?.access_token;
                authUser.sbRefreshToken = res.data?.refresh_token;
                authUser.sbExpiresAt = res.data?.expires_at;
                console.log(authUser)
            }

            callback(authUser);
        } else {
            callback(null);
        }
    });
}

export const signInWithGoogle = () => {
    return signInWithPopup(firebaseAuth, googleAuthProvider)
        .then((result) => {
            const authUser = result.user;
            return authUser;
        }).catch((error) => {
            console.log(error);
            return null;
        })
}

export const signInWithApple = async () => {
    await signInWithPopup(firebaseAuth, appleAuthProvider);
    return getRedirectResult(firebaseAuth)
        .then((result) => {
            // const credential = OAuthProvider.credentialFromResult(result);
            // if (credential) {
            //     const accessToken = credential.accessToken;
            //     const idToken = credential.idToken;
            // }
            const authUser = result.user;
            return authUser
        }).catch((error) => {
            console.log(error);
            return null
        })
}

export const setAuthUsername = async (username) => {
    updateProfile(firebaseAuth.currentUser, {
        displayName: username
    }).then(() => {
        // profile updated
    }).catch((error) => {
        console.log(error);
    })
}

export const logoutUser = async () => {
    firebaseAuth.signOut();
    window.location.reload(true);
}

export const getSupabaseTokens = async (authUser) => {
    const res = await axios.post("/users/supabase", {
        authUser: authUser
    })
    const sbToken = res.data?.access_token;
    const sbRefreshToken = res.data?.refresh_token;
    const sbExpiresAt = res.data?.expires_at;
    authUser.sbToken = sbToken;
    authUser.sbRefreshToken = sbRefreshToken;
    authUser.sbExpiresAt = sbExpiresAt;
    return {sbToken, sbRefreshToken, sbExpiresAt};
}

const setAHUserEmail = (authUser) => {
    if (!authUser?.email)
        return;

    const res = axios.put("/users/afterhour", {email: authUser?.email}, {
            headers: {
                token: authUser?.accessToken,
            }
        }).then((res) => {
        }).catch((err) => console.log(err))
}

export const checkPhoneExists = async (phoneNumber) => {
    const {data, error} = await supabase
        .from("users")
        .select("*")
        .eq("phone_number", phoneNumber)

    const exists = data.length > 0;
    return exists;
}
