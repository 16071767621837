import React, {useState} from 'react';
import StonkMadnessLogo from "../brand/logos/StonkMadnessLogo";
import SvgMenu from "../icons/SvgMenu";
import AfterHourStu from "../brand/logos/AfterHourStu";
import AfterHourModal from "../modals/AfterHourModal";
import {Link, useLocation} from "react-router-dom";
import SvgHub from "../icons/custom/SvgHub";
import {connect} from "react-redux";
import SvgLogout from "../icons/SvgLogout";
import ConfirmationModal from "../modals/ConfirmationModal";
import {logoutUser} from "../../api/auth";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

function Navbar(props) {
    const [afterHourModalOpen, setAfterHourModalOpen] = useState(false)
    const [confirmLogoutModalOpen, setConfirmLogoutModalOpen] = useState(false)
    const location = useLocation();
    const openAfterHourModal = () => {
        setAfterHourModalOpen(true)
    }
    const closeAfterHourModal = () => {
        setAfterHourModalOpen(false)
    }

    const openConfirmLogoutModal = () => {
        setConfirmLogoutModalOpen(true)
    }

    const closeConfirmLogoutModal = () => {
        setConfirmLogoutModalOpen(false)
    }

    const isOnHub = location.pathname === '/hub'
    const isOnBracket = location.pathname === '/bracket'
    const isOnLeaderboard = location.pathname === '/leaderboard'
    const transparentBG = location.pathname.includes('/matchup') || location.pathname.includes('/authentication')
    return (
        <nav
            className={`${transparentBG ? 'bg-transparent' : 'bg-black/80'} backdrop-blur text-white flex flex-row items-center justify-center py-2 px-4`}>
            <div className='flex max-w-[620px] w-full flex-row items-center justify-between '>
                <Link to={'/'} className='hover:-rotate-6 hover:scale-105 transition-all'>
                    <StonkMadnessLogo className={'h-[60px] duration-300'}/>
                </Link>
                <ul className='flex flex-row items-center gap-4'>
                    <li onClick={openAfterHourModal}
                        className='hover:rotate-12 rounded-full outline-offset-2 outline outline-transparent hover:outline-yellow-400/30 hover:scale-110 duration-300 cursor-pointer'
                    >
                        <AfterHourStu className={'w-7 h-7'}/>
                    </li>
                    {/*{!!props.authUser &&*/}
                    {/*<Link to={'/bracket'}>*/}
                    {/*    <li className={!isOnBracket ? `opacity-80 hover:opacity-100 cursor-pointer` : null}>*/}
                    {/*        <SvgBracket/>*/}
                    {/*        {isOnBracket &&*/}
                    {/*            <div className='w-full h-0.5 rounded-full bg-white opacity-80 mt-1 -mb-1.5'/>*/}
                    {/*        }*/}
                    {/*    </li>*/}
                    {/*</Link>*/}
                    {/*}*/}
                    {/*{!!props.authUser &&*/}
                    {/*    <Link to={'/leaderboard'}>*/}
                    {/*        <li className={!isOnLeaderboard ? `opacity-80 hover:opacity-100 cursor-pointer` : null}>*/}
                    {/*            <SvgPodium className={'w-6 h-6'}/>*/}
                    {/*            {isOnLeaderboard &&*/}
                    {/*                <div className='w-full h-0.5 rounded-full bg-white opacity-80 mt-1 -mb-1.5'/>*/}
                    {/*            }*/}
                    {/*        </li>*/}
                    {/*    </Link>*/}
                    {/*}*/}
                    {!!props.authUser &&
                        <Link to={'/hub'}>
                            <li className={!isOnHub ? `opacity-80 hover:opacity-100 cursor-pointer` : null}>
                                <SvgHub/>
                                {isOnHub &&
                                    <div className='w-full h-0.5 rounded-full bg-white opacity-80 mt-1 -mb-1.5'/>
                                }
                            </li>
                        </Link>
                    }
                    {!!props.authUser &&
                        <button className={''} onClick={openConfirmLogoutModal}>
                            <SvgLogout className={'opacity-80 hover:text-red-500 hover:opacity-100 cursor-pointer'}/>
                        </button>
                    }
                    <li onClick={props.openNavMenu} className='opacity-80 hover:opacity-100 cursor-pointer'>
                        <SvgMenu className={'w-7 h-7'}/>
                    </li>
                </ul>
            </div>
            <AfterHourModal
                isOpen={afterHourModalOpen}
                close={closeAfterHourModal}
            />
            <ConfirmationModal
                isOpen={confirmLogoutModalOpen}
                confirmText={'Log Me Out'}
                onConfirm={logoutUser}
                close={closeConfirmLogoutModal}
                title={'Are you sure you want to log out?'}
                description={'At least pinky promise to come back later.'}
            />
        </nav>
    );
}

export default connect(mapStateToProps, null)(Navbar);
