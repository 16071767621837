import axios from './axios'

export const joinWaitlist = async (email) => {
    const endpoint = `/waitlist?email=${email}`
    try {
        const config = {
        }
        const res = await axios.post(endpoint, config);
        window.amplitude.track("Waitlist Joined", {email: email})
        return res.data;
    } catch (err) {
        console.log(err)
    }   
}

export const getWaitlistCount = async () => {
    const endpoint = `/waitlist/count`
    try {
        const config = {
        }
        const res = await axios.get(endpoint, config);
        return res.data;
    } catch (err) {
        console.log(err)
    }   
}

export const joinWaitlistToolate = async (email) => {
    const endpoint = `/waitlist/toolate?email=${email}`
    try {
        const config = {
        }
        const res = await axios.post(endpoint, config);
        return res.data;
    } catch (err) {
        console.log(err)
    }   
}
