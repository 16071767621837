import React, {useState, useEffect} from 'react';
import StockLogo from "../../components/atoms/StockLogo";
import SvgSlash from "../../components/icons/custom/SvgSlash";
import MatchupStatusIndicator from "./MatchupStatusIndicator";
import SvgTrendingUp from "../icons/SvgTrendingUp";
import SvgTrendingDown from "../icons/SvgTrendingDown";
import TooltipComponent from "../atoms/TooltipComponent";
import Spinner from "../atoms/Spinner";
import WinPercentageBar from "../WinPercentageBar";
import SvgInfo from "../icons/SvgInfo";

function MatchupVersusSection(props) {
    const [matchup, setMatchup] = useState({});
    const [activelySelectedSide, setActivelySelectedSide] = useState(false);
    const [score1, setScore1] = useState(0);
    const [score2, setScore2] = useState(0);
    const [winner, setWinner] = useState("");

    useEffect(() => {
        setMatchup(props?.preloadedMatchup || props.matchup);
        setActivelySelectedSide(props.isActivelySelectingSide);
        setScore1(props.preloadedMatchup?.ticker1_cached_score || 0);
        setScore2(props.preloadedMatchup?.ticker2_cached_score || 0);
        setWinner(props.readonlyMasterBracket?.[props.preloadedMatchup?.id]?.winner_id || "")
    }, [props.preloadedMatchup, props.liveData, props.readonlyMasterBracket])

    return (
        <div className='flex flex-col gap-1'>
            <div className='flex flex-row w-full items-center my-3 relative'>
                <div
                    className={`flex flex-col w-[120px] md:w-[180px] flex-1 gap-2 items-center ${(props.predictedWinner && (props.predictedWinner === matchup?.ticker2)) ? 'opacity-100' : ''}`}>
                    <StockLogo
                        loading={!matchup?.ticker1}
                        ticker={matchup?.ticker1}
                        className={`w-12 h-12 md:w-16 md:h-16 md:hidden`} border/>
                    <div className='text-center flex flex-col flex-1 w-[132px] md:w-[180px] '>
                        <div
                            className={`flex flex-row items-center justify-center gap-1  ${(!!winner && (winner === matchup?.ticker2)) ? 'opacity-50' : ''}`}>
                            <p className={`text-3xl md:text-4xl font-bold ${(!!winner && (winner === matchup?.ticker2)) ? 'line-through' : ''} ${activelySelectedSide === 'left' && 'text-green-500 underline'}`}>
                                {matchup?.ticker1}
                            </p>
                            <StockLogo
                                loading={!matchup?.ticker1}
                                ticker={matchup?.ticker1}
                                className={'w-12 h-12 md:w-10 md:h-10 mb-1.5 hidden md:block'} border/>
                            <SvgSlash className={`h-[1.9rem] w-[10px] -mt-0.5`}
                                      style={{color: matchup?.[matchup?.ticker1]?.color}}/>
                            <span className='text-[12px] mb-3'>{matchup?.[matchup?.ticker1]?.seed}</span>
                        </div>
                        <p>
                            {matchup?.[matchup?.ticker1]?.price}
                        </p>
                        <div className={`text-sm font-body opacity-80 truncate mb-1`}>
                            {matchup?.[matchup?.ticker1]?.name || '--'}
                        </div>
                        <TooltipComponent
                            ToggleComponent={
                                <div
                                    className='text-lg px-2 md:text-2xl font-body font-semibold opacity-100 inline-flex mx-auto rounded-lg hover:bg-white/10'>
                                    <SvgTrendingUp
                                        className={'w-4 h-4 md:w-6 md:h-6  mt-1 text-green-500 mr-0.5'}/> {matchup?.[matchup?.ticker1]?.preloaded_details?.ytd_num_green_days} : {matchup?.[matchup?.ticker1]?.preloaded_details?.ytd_num_red_days}
                                    <SvgTrendingDown
                                        className={'w-4 h-4 md:w-6 md:h-6 mt-1 text-red-400 ml-0.5'}/>
                                    <SvgInfo className={'w-3 h-3 md:w-4 md:h-4 mt-2 ml-1 opacity-50'}/>
                                </div>
                            }
                            hover
                            placement={'bottom'}
                            label={'W/L is determined by # of green vs red days so far this year.'}
                        />
                        {(props.predictedWinner && (props.predictedWinner === matchup?.ticker1)) &&
                            <p className='uppercase text-green-500 -mb-1'>
                                Selected
                            </p>
                        }
                        <p className={`font-body text-3xl bg-white/10 mx-auto px-4 mt-4 rounded-xl py-2 ${(!!winner && (winner === matchup?.ticker1)) ? 'border border-yellow-400' : ''}`}>
                           <span className='font-semibold'>
                                {`${score1 >= 0 ? "+" : ""}${score1.toFixed(2)}%`}
                           </span>
                        </p>
                    </div>
                </div>
                <p className='mx-2 text-md font-body font-semibold opacity-50'>
                    vs.
                </p>
                <div
                    className={`flex flex-col w-[120px] md:w-[180px] flex-1 gap-2 items-center ${(props.predictedWinner && (props.predictedWinner === matchup?.ticker1)) ? 'opacity-100' : ''}`}>
                    <StockLogo
                        loading={!matchup?.ticker2}
                        ticker={matchup?.ticker2}
                        className={'w-12 h-12 md:w-16 md:h-16 md:hidden'} border/>
                    <div className='text-center flex flex-col flex-1 flex-1 w-[132px] md:w-[180px] '>
                        <div className={`flex flex-row items-center justify-center gap-1  ${(!!winner && (winner === matchup?.ticker1)) ? 'opacity-50' : ''}`}>
                            <span className='text-[12px] mb-3'>{matchup?.[matchup?.ticker2]?.seed}</span>
                            <SvgSlash className={`h-[1.9rem] w-[10px] -mt-0.5`}
                                      style={{color: matchup?.[matchup?.ticker2]?.color}}/>
                            <StockLogo
                                loading={!matchup?.ticker2}
                                ticker={matchup?.ticker2}
                                className={'w-12 h-12 md:w-10 md:h-10 mb-1.5 hidden md:block'} border/>
                            <p className={`text-3xl md:text-4xl font-bold ${(!!winner && (winner === matchup?.ticker1)) ? 'line-through' : ''} ${activelySelectedSide === 'left' && 'text-green-500 underline'}`}>
                                {matchup?.ticker2}
                            </p>
                        </div>
                        <div className={`text-sm font-body opacity-80 truncate mb-1`}>
                            {matchup?.[matchup?.ticker2]?.name || '--'}
                        </div>
                        <TooltipComponent
                            ToggleComponent={
                                <div
                                    className='text-lg px-2 md:text-2xl font-body font-semibold opacity-100 truncate inline-flex mx-auto rounded-lg hover:bg-white/10'>
                                    <SvgInfo className={'w-3 h-3 md:w-4 md:h-4 mt-2 mr-1 opacity-50'}/>
                                    <SvgTrendingUp
                                        className={'w-4 h-4 md:w-6 md:h-6  mt-1 text-green-500 mr-0.5'}/> {matchup?.[matchup?.ticker2]?.preloaded_details?.ytd_num_green_days} : {matchup?.[matchup?.ticker2]?.preloaded_details?.ytd_num_red_days}
                                    <SvgTrendingDown
                                        className={'w-4 h-4 md:w-6 md:h-6 mt-1 text-red-400 ml-0.5'}/>
                                </div>
                            }
                            hover
                            placement={'bottom'}
                            label={'W/L is determined by # of green vs red days so far this year.'}
                        />
                        {(props.predictedWinner && (props.predictedWinner === matchup?.ticker2)) &&
                            <p className='uppercase text-green-500 -mb-1'>
                                Selected
                            </p>
                        }
                        <p className={`font-body text-3xl bg-white/10 mx-auto px-4 mt-4 rounded-xl py-2 ${(!!winner && (winner === matchup?.ticker2)) ? 'border border-yellow-400' : ''}`}>
                           <span className='font-semibold'>
                                {`${score2 >= 0 ? "+" : ""}${score2.toFixed(2)}%`}
                           </span>
                        </p>
                    </div>
                </div>
            </div>
            {props.matchup?.id < 33 &&
                <MatchupStatusIndicator/>
            }
        </div>
    );
}

export default MatchupVersusSection;
