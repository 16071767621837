import axios from "./axios";
import { firebaseAuth } from './firebase';
import supabase from './supabase';
import {getSupabaseTokens} from './auth';

export const getUser = async (username) => {
    const endpoint = `/users?username=${username}`
    try {
        const config = {
        }
        const res = await axios.get(endpoint, config);
        return res.data;
    } catch (err) {
        console.log(err)
    }
}

const createSupabaseAuthUser = async (authUser) => {
    try {
        const { data, error } = await supabase.auth.signUp({
            email: authUser?.email,
            password: authUser?.uid
        })
        return getSupabaseTokens(authUser);
    } catch (err) {
        console.log(err)
    }
}

export const createUser = async (authUser) => {
    // supabase auth user must be created prior to hitting backend
    let _sbToken = authUser?.sbToken;
    if (!_sbToken) {
        const {sbToken, sbRefreshToken, sbExpiresAt} = await createSupabaseAuthUser(authUser);
        _sbToken = sbToken;
    }

    const endpoint = `/users`;
    try {
        const config = {
            headers: {
                jwt: _sbToken,
                'Content-Type': 'application/json'
            }
        }
        const res = await axios.post(endpoint, {authUser: {...authUser, _sbToken}}, config);
        window.location.reload(true);
    } catch (err) {
        console.log(err)
    }
}

export const checkUserExists = async (username) => {
    const token = firebaseAuth.currentUser.accessToken;
    const endpoint = `/users`;

    try {
        const config = {
            headers: {
                'token': token,
            },
            params: {
                username: username
            }
        }
        const res = await axios.head(endpoint, config);
        return true;
    } catch (err) {
        return false;
    }
}

