import React from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'

function ConfettiComponent() {
    const {width, height} = useWindowSize()
    return (
       <div className='z-50'>
           <Confetti
               width={width}
               height={height}
               recycle={false}
               friction={.99}
               colors={[
                   // '#C0A3FF', '#A77EFF', '#7030F9', '#5226B1', '#301766',
                   '#FFFAB4', '#FBF382', '#FBF26C', '#F6EB43', '#8B8417',
                   '#BCFFAC', '#BFFD74', '#50ED61', '#309C3A', '#1B5921'
               ]}
               className={'z-[100]'}
           />
       </div>
    )
}

export default ConfettiComponent
