import React from 'react';
import HowToPlaySection from "../sections/HowToPlaySection";
import {pageClass} from "../consts/dummy";
import PointsPerRoundSection from "../sections/PointsPerRoundSection";
import FAQSection from "../sections/FAQSection";
import PageHeader from "../components/navigation/PageHeader";
import PrizesSection from "../sections/PrizesSection";

function HowToPlayPage() {
    return (
        <div className={pageClass}>
            <PageHeader
                title={'HOW TO PLAY'}
                desc={'We kept it simple so even the smoothest of brains can play.'}
            />
            <HowToPlaySection/>
            <PointsPerRoundSection/>
            <PrizesSection/>
            <FAQSection/>
        </div>
    );
}

export default HowToPlayPage;
