import React, {useEffect, useState} from 'react';
import {Navigate, useParams} from 'react-router-dom';
import BracketComponent from "../components/bracket/BracketComponent";
import {connect} from 'react-redux';
import {fetchBracket, getLeaderboard} from '../api/brackets';

const mapStateToProps = (state) => {
    return {
        authUser: state.userReducer.authUser,
        ogImage: state.userReducer.ogImage,
    }
}

const IMAGES = [
    'https://media.istockphoto.com/id/925640584/photo/american-football-players-confronting-before-the-beginning-of-a-match.jpg?s=612x612&w=0&k=20&c=wqHJXNK09kZPmqHRN8piADmbf-ydCM706qbk2dFZl2c=',
    'https://media.gettyimages.com/id/1182604329/photo/two-businesspeople-standing-face-to-face.jpg?s=612x612&w=gi&k=20&c=SQDDx13IqCylB2VQZQbG-iL8RqfnyjXzKtv3yDdvBmQ=',
    'https://www.telegraph.co.uk/content/dam/business/2021/04/13/130421_ECON_INTEL_NEWSLETTER_trans_NvBQzQNjv4BqqVzuuqpFlyLIwiB6NTmJwfSVWeZ_vEN7c6bHu2jJnT8.png?imwidth=680',
    'https://images.moneycontrol.com/static-hindinews/2024/02/market-bull-bear-1200-MC-1-637x435.jpg?impolicy=website&width=770&height=431',
]

function BracketPage(props) {
    const {username} = useParams();
    const [loading, setLoading] = useState(true);
    const [bracket, setBracket] = useState(null);
    const [loadingQueriedBracket, setLoadingQueriedBracket] = useState(true)
    const [userNotFound, setUserNotFound] = useState(false);

    useEffect(() => {
        if (username && !bracket) {
            fetchBracket(props.authUser, username)
                .then((res) => {
                    if (res.length === 0) {
                        setUserNotFound(true);
                    } else {
                        setBracket(res[0])
                    }
                })
        }

        // load leaderboard if not loaded
        if (!!props.authUser) {
            getLeaderboard(props.authUser, props.authUser?.displayName)
                .then((res) => {
                })
        }
        setLoadingQueriedBracket(false)
    }, [props.authUser])

    // analytics
    useEffect(() => {
        window.amplitude.track("Bracket Page Viewed", {is_self: !username || username === bracket?.username})
    }, [])

    return (userNotFound) ? <Navigate to="/404" replace={true}/> : (
        <div className={`flex flex-col items-center pb-0 px-0 w-full min-h-[100dvh]`}>
            <div className='w-full -mt-8'>
                {/*<div*/}
                {/*    className='flex flex-col mt-2 max-w-[100vw] relative z-[0] py-1 bg-green-500/30 hover:bg-green-500/40 cursor-pointer'>*/}
                {/*    <div className='w-16 absolute left-0 top-0 bottom-0 z-[2]'*/}
                {/*         style={{background: 'linear-gradient(to right, black, #00000000)'}}*/}
                {/*    />*/}
                {/*    <div className='w-16 absolute right-0 top-0 bottom-0 z-[2]'*/}
                {/*         style={{background: 'linear-gradient(to left, black, #00000000)'}}*/}
                {/*    />*/}
                {/*    <Marquee*/}
                {/*        speed={20}*/}
                {/*        className='overflow-hidden'>*/}
                {/*        <p className='text-xl md:text-md  text-green-500 text-center uppercase mx-3'>*/}
                {/*            CAST YOUR VOTE TO SEE YOUR FAVORITE STOCKS SELECTED FOR THE BRACKET. FINAL SEEDING ANNOUNCED MAR 11.*/}
                {/*        </p>*/}
                {/*        <p className='text-xl md:text-md  text-green-500 text-center uppercase mx-3'>*/}
                {/*            CAST YOUR VOTE TO SEE YOUR FAVORITE STOCKS SELECTED FOR THE BRACKET. FINAL SEEDING ANNOUNCED MAR 11.*/}
                {/*        </p>*/}
                {/*    </Marquee>*/}
                {/*</div>*/}
                <BracketComponent queriedBracket={bracket} loadingQueriedBracket={loadingQueriedBracket}
                                  loadingAuthUser={props.loadingAuthUser} username={username}/>
            </div>
        </div>
    );
}

export default connect(mapStateToProps)(BracketPage);
